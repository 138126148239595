import React from "react";

class calloutbuttonsPass extends React.Component {
  state = {
    clickedAll: "btn btn-outline-primary position-relative m-2",
    clickedPending: "btn btn-warning position-relative m-2",
    clickedResolved: "btn btn-light position-relative m-2",
  };

  componentDidMount() {
    // Get the info that ids the type of user
    // Then  set a state variable so that we can use it to decide which button set to render
  }

  handleButtonClick(buttonPressed) {
    // set the Clicked button to the correct button class

    this.state.clickedAll =
      buttonPressed === "All"
        ? "btn btn-outline-primary position-relative m-2"
        : "btn btn-light position-relative m-2";

    this.state.clickedPending =
      buttonPressed === "Pending"
        ? "btn btn-warning position-relative m-2"
        : "btn btn-light position-relative m-2 ";

    this.state.clickedResolved =
      buttonPressed === "Resolved"
        ? "btn btn-success position-relative m-2"
        : "btn btn-light position-relative m-2";

    // this.state.clickedClass = "btn btn-primary position-relative m-2";
    // now handle the event that will filter the tickets
    this.props.handleCalloutSort(buttonPressed);
  }

  render() {
    const { ticketsTotals } = this.props;
    const userTypeID = this.state.userTypeID;

    return (
      <div className="center">
        <button
          type="button"
          className={this.state.clickedAll}
          onClick={() => this.handleButtonClick("All")}
        >
          All
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
            {ticketsTotals[0]}
          </span>
        </button>
        <button
          type="button"
          className={this.state.clickedPending}
          onClick={() => this.handleButtonClick("Pending")}
        >
          Picked Up
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
            {ticketsTotals[1]}
          </span>
        </button>

        <button
          type="button"
          className={this.state.clickedResolved}
          onClick={() => this.handleButtonClick("Resolved")}
        >
          Dropped
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
            {ticketsTotals[2]}
          </span>
        </button>
      </div>
    );
  }
}

export default calloutbuttonsPass;
