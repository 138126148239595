import React from "react";
import { Link } from "react-router-dom";

//Components
import NavbarRace from "../components/navbarRace";
import RaceEntityHero from "../components/raceEntityHero";

//Components
import withMyHook from "./../components/common/withMyHook";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class PageRaceHome extends React.Component {
  state = {
    entityName: "",
    entityUserDataRetreived: [],
    goToPage: "",
    groupID: "",
    groupName: "",
    raceName: "",
    raceID: "",
    raceNameYear: "",
    userTypeID: "",
    stagedataRetreived: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const raceIdRetreived = this.state.myParams.id;
    const groupNameRetreived = localStorage.getItem("groupName");
    const groupIDRetreived = localStorage.getItem("groupID");
    const entityName = localStorage.getItem("entityName");
    const entityID = localStorage.getItem("entityID");
    const userTypeID = localStorage.getItem("userTypeID");

    if (raceIdRetreived === "") return;
    let { data: race } = await httpService.get(
      config.apiEndpointRaceGet + "?id=" + raceIdRetreived
    );

    localStorage.setItem("raceID", raceIdRetreived);
    localStorage.setItem("raceNameYear", race.raceNameYear);
    localStorage.setItem("raceName", race.raceName);
    localStorage.setItem("stageID", "");
    localStorage.setItem("stageName", "");

    // Get the stages details based on the Race id
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStagesGetByRaceID + "?raceID=" + raceIdRetreived
    );

    // Get entity Users by entity ID
    let { data: DbdataEU } = await httpService.get(
      config.apiEndpointEntityUsersGet + "?entityID=" + entityID
    );

    // userTypeID
    let goToPage = "pageEntityRaces";
    if (userTypeID === "6") goToPage = "";

    this.setState({
      entityName,
      entityUserDataRetreived: DbdataEU,
      goToPage,
      groupName: groupNameRetreived,
      groupID: groupIDRetreived,
      raceName: race.raceName,
      raceNameYear: race.raceNameYear,
      raceID: raceIdRetreived,
      raceDescription: race.raceDescription,
      stagedataRetreived: Dbdata,
    });
  }

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const {
      entityName,
      entityUserDataRetreived,
      goToPage,
      stagedataRetreived,
      raceNameYear,
      raceDescription,
      raceID,
    } = this.state;
    return (
      <React.Fragment>
        <NavbarRace />

        <div className="container container-top-70 row-fit ">
          <div className="row row-fit  center">
            <span className="button-padding">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("Back to Races")}
              >
                <Link to={`/${goToPage}`} className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>
              </OverlayTrigger>
            </span>

            <div data-aos="fade-right">
              <h5>{entityName}</h5>
              <RaceEntityHero
                stagedataRetreived={stagedataRetreived}
                entityUserDataRetreived={entityUserDataRetreived}
                raceNameYear={raceNameYear}
                raceDescription={raceDescription}
                raceID={raceID}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageRaceHome);
