import React, { Component } from "react";

import { Link } from "react-router-dom";

// Services
import httpService from "../services/httpService";
import config from "../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//  Icons

import {
  Bicycle,
  CardText,
  Clock,
  ClockFill,
  GeoAltFill,
  GeoAlt,
  PersonVcardFill,
  QuestionLg,
} from "react-bootstrap-icons";

import { FaEdit } from "react-icons/fa";
// IoMdExit
import { IoIosExit, IoIosLogIn } from "react-icons/io";
import { BsChatSquareDots, BsListUl } from "react-icons/bs";
import { FiUser } from "react-icons/fi";

class PassengerCard extends Component {
  state = {
    ticketIdLocal: this.props.ticket.id,

    gotToForm: "",
    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
  };

  async componentDidMount() {
    const userTypeID = localStorage.getItem("userTypeID");

    let str1 = "abcClean";
    let accordId = str1.concat(this.state.ticketIdLocal);

    let gotToForm = "";
    if (userTypeID === "3") gotToForm = ``;
    if (userTypeID === "4") gotToForm = ``;
    if (userTypeID === "5")
      gotToForm = `entityUserPassengerForm/${this.props.ticket.id}`;

    // GPS location storage
    window.navigator.geolocation.getCurrentPosition((success) =>
      this.setState({
        myLatitude: success.coords.latitude,
        myLongitude: success.coords.longitude,
      })
    );

    if (navigator.geolocation) {
      navigator.geolocation.enableHighAccuracy = true;
      navigator.geolocation.watchPosition((position) =>
        this.setState({
          myLatitude: position.coords.latitude,
          myLongitude: position.coords.longitude,
          myAltitude: position.coords.altitude,
          myHeading: position.coords.heading,
          mySpeed: position.coords.speed,
          mySpeedKM: position.coords.speed * 3.6,
        })
      );
    }

    this.setState({ gotToForm });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  handleRefreshTickets() {
    this.props.refreshTickets();
  }

  handleDischarge = async () => {
    // here use the passengerTicketID to marke the passenger ticket as discharged in database

    //  Create time and date of geo location
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const today = new Date().toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const time = new Date().toLocaleTimeString();

    let formData = new FormData();

    formData.append("id", this.state.ticketIdLocal);
    formData.append("disTime", time);
    formData.append("disDate", today);
    formData.append("disLatitude", this.state.myLatitude);
    formData.append("disLongitude", this.state.myLongitude);

    try {
      await httpService.post(
        config.apiEndpointPassTicketDropOffUpdate,
        formData
      );
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while updating.");
    }

    this.handleRefreshTickets();
  };

  handleReinsate = async () => {
    let formData = new FormData();

    formData.append("id", this.state.ticketIdLocal);

    try {
      await httpService.post(
        config.apiEndpointPassTicketReinstateUpdate,
        formData
      );
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while updating.");
    }

    this.handleRefreshTickets();
  };

  createIDHash() {
    let str1 = "#abc";
    let accordIdHash = str1.concat(this.state.ticketIdLocal);

    return accordIdHash;
  }
  createID() {
    let str1 = "abc";
    let accordId = str1.concat(this.state.ticketIdLocal);

    return accordId;
  }

  render() {
    const { refreshTickets, ticket } = this.props;

    const { gotToForm } = this.state;
    const { ticketID } = ticket.id;
    return (
      <div className="card shadow-sm m-1 passeger-card-border ">
        <div className="row">
          <div className="card-group-display ">
            <div className="row  align-vert">
              <div className="details-align mt-1">
                <div className="details-icon ">
                  <CardText color="#858796" size="25" />
                </div>
                <div className="card-passenger-heading">{ticket.id}</div>
              </div>
            </div>
            <div className="row">
              <div className="details-align mt-1">
                <div className="details-icon ">
                  <PersonVcardFill color="#858796" size="25" />
                </div>
                <div className="card-passenger-heading">
                  {ticket.participantNo}
                </div>
              </div>
            </div>
          </div>
          <div className="card-group-display ">
            <div className="details-align py-1">
              <div className="details-icon">
                <FiUser color="#858796" size="25" />
              </div>
              <div className="card-passenger-heading">{ticket.passLoad}</div>
            </div>
            <div className="details-align py-1">
              <div className="details-icon">
                <Bicycle color="#858796" size="30" />
              </div>
              <div className="card-passenger-heading">{ticket.cycleLoad}</div>
            </div>
          </div>
          <div className="col px-1 m-1">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={this.createIDHash()}
                  aria-expanded="false"
                  aria-controls={this.createID()}
                >
                  <div className="accordion-heading-text ">
                    <BsListUl />
                    <span className="icon-text-padding">Details</span>
                  </div>
                </button>
              </h2>
              <div
                id={this.createID()}
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <div className="card details-card-ticket">
                    <div className="details-align mt-1">
                      <div className="details-icon ">
                        <CardText color="#002072" size="25" />
                      </div>
                      <div>{ticket.id}</div>
                    </div>
                    <div className="details-align mt-1">
                      <div className="details-icon ">
                        <PersonVcardFill color="#002072" size="25" />
                      </div>
                      <div>{ticket.participantNo}</div>
                    </div>

                    <hr className="m-2" />
                    <div>Pickup</div>

                    <div className="details-align py-1">
                      <div className="details-icon">
                        <Clock color="#002072" size="25" />
                      </div>
                      <div>{ticket.ticketTime}</div>
                    </div>

                    <div className="details-align py-1">
                      <div className="details-icon">
                        <GeoAlt color="#002072" size="25" />
                      </div>
                      <div>
                        {ticket.latitude}
                        {","}
                        {ticket.longitude}
                      </div>
                    </div>

                    <div className="details-align py-1">
                      <div className="details-icon">
                        <BsChatSquareDots color="#002072" size="25" />
                      </div>
                      <div>{ticket.commentText}</div>
                    </div>
                    <hr className="m-2" />
                    <div>Drop Off</div>
                    <div className="details-align py-1">
                      <div className="details-icon">
                        <ClockFill color="#002072" size="25" />
                      </div>
                      <div>{ticket.disTime}</div>
                    </div>

                    <div className="details-align py-1">
                      <div className="details-icon">
                        <GeoAltFill color="#002072" size="25" />
                      </div>
                      <div>
                        {ticket.disLatitude}
                        {","}
                        {ticket.disLongitude}
                      </div>
                    </div>
                    <hr className="m-2" />
                    <div className="details-align py-1">
                      <span className="button-padding">
                        <Link to={`/${gotToForm}`} className="btn p-0 ">
                          <div className="details-align py-1">
                            <FaEdit color="#5cb85c" size={25} />
                            <div className="m-1">Edit Ticket</div>
                          </div>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="center-horizontal-components">
              <span className="button-padding">
                {ticket.completed == 0 && (
                  <Link
                    to=""
                    onClick={() => this.handleDischarge()}
                    className="btn p-0 "
                  >
                    <IoIosExit color="#5cb85c" size={75} />
                    Drop Off
                  </Link>
                )}
                {ticket.completed == 1 && (
                  <Link
                    to=""
                    onClick={() => this.handleReinsate()}
                    className="btn p-0 "
                  >
                    <IoIosLogIn color="#5cb85c" size={65} />
                    Re-instate
                  </Link>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PassengerCard;
