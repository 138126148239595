// import "./styles.css";
import React, { useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  Polyline,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.fullscreen/Control.FullScreen.js";
import "leaflet.fullscreen/Control.FullScreen.css";

import { BaseMaps } from "./common/baseMaps";
import { GetGPXCoords } from "./getGPXCoords.jsx";
import gpxParser from "gpxparser";

import { defaultIcon } from "../icons/defaultIcon";
import { PoiLayer } from "./poiLayer";

export const RideAlong = ({
  centerPosition,
  defaultZoom,
  geoLocationUser,
  entityUserIcon,
  mapURL,
  // mapURLs,
  POIMarkerData,
}) => {
  // Map user location
  const [position, setPosition] = useState("");
  const [position2, setPosition2] = useState("");

  const lineOptions2 = { color: "blue" };
  let lineOptions = { color: "purple" };
  let bespokeIcon = "";
  const [mapInstance, setMap] = useState(null);
  let gpx = new gpxParser();

  function GetIcon(Icon) {
    if (Icon) {
      return L.icon({
        // setUp the Paths
        iconUrl: require("../images/mapicons/entityIcons/" + Icon.iconUrl),
        iconShadowUrl: require("../images/mapicons/entityIcons/" +
          Icon.shadowUrl),
        iconAnchor: [Icon.iconAnchorH * 1, Icon.iconAnchorV * 1], // point of the icon which will correspond to marker's location
        iconSize: [Icon.iconSizeW * 1, Icon.iconSizeH * 1], // size of the icon,
        popupAnchor: [Icon.popupAnchorH * 1, Icon.popupAnchorV * 1], // point from which the popup should open relative to the iconAnchor
        shadowAnchor: [Icon.shadowAnchorH * 1, Icon.shadowAnchorV * 1], // the same for the shadow
        shadowSize: [Icon.shadowSizeW * 1, Icon.shadowSizeH * 1], // size of the shadow
      });
    } else {
      return defaultIcon;
    }
  }

  useEffect(() => {
    const load1 = async (url) => {
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "text/xml");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "X-Requested-With");
      myHeaders.append("Access-Control-Allow-Headers", "access");
      myHeaders.append("Access-Control-Allow-Methods", "GET");
      myHeaders.append("Access-Control-Allow-Credentials", "true");

      await fetch(url, {
        mode: "no-cors",
        headers: myHeaders,
      })
        .then((d) => d.text())
        .then((d) => {
          gpx.parse(d);
          const positions = gpx.tracks[0].points.map((p) => [p.lat, p.lon]);
          setPosition(positions);
        });
    };

    let MymapURl = require("../gpx/" + mapURL);
    load1(MymapURl);
  }, []); // Close use Effect

  // functions here

  function LocationSetMap() {
    const [position, setPositionE] = useState(null);

    let [myAltitude, setAltitude] = useState(null);
    let [myHeading, setHeading] = useState(null);
    let [mySpeed, setSpeed] = useState(null);

    // Create a numeric outut format type ES6 style
    const formatWholeNum = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    myAltitude = formatWholeNum.format(myAltitude);
    myHeading = formatWholeNum.format(myHeading);
    mySpeed = formatWholeNum.format(mySpeed);

    if (myAltitude === NaN) {
      myAltitude = 0;
    }
    if (myHeading === NaN) {
      myAltitude = 0;
    }
    if (myHeading === NaN) {
      mySpeed = 0;
    }

    const map = useMap();
    let counter = 0;

    useEffect(() => {
      map
        .locate({
          watch: true,
          enableHighAccuracy: true,
          setView: false,
        })
        .on("locationfound", function (e) {
          // Using one time only set up as the location found event gets called hundreds of times
          // No need to call it more then once for every time it finds a location.
          if (counter === 0) {
            map.setView(e.latlng, map.getZoom());
            ++counter;
          }
        });
    }, [map]);

    return position === null ? null : <div></div>;
  }

  return (
    <MapContainer
      center={centerPosition}
      zoom={defaultZoom}
      scrollWheelZoom
      style={{ height: "100vh" }}
      fullscreenControl={true}
      whenCreated={setMap}
    >
      <LocationSetMap />

      <LayersControl position="topright">
        <BaseMaps />
        <PoiLayer POIMarkerData={POIMarkerData} />
      </LayersControl>

      <Polyline pathOptions={lineOptions} positions={position}>
        <Popup closeButton={false}> Double Century 2022</Popup>
      </Polyline>

      <Marker
        position={geoLocationUser}
        icon={GetIcon(entityUserIcon)}
      ></Marker>
    </MapContainer>
  );
};
