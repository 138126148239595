import React, { Component } from "react";
import { Link } from "react-router-dom";

import "leaflet/dist/leaflet.css";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

//Components
import { RideAlong } from "../components/rideAlong.jsx";
import withMyHook from "./../components/common/withMyHook";

import { ToastContainer, Zoom, toast } from "react-toastify";
// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Other
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

class PageRideAlong extends React.Component {
  state = {
    defaultZoom: "13",
    entityUserIcon: "",
    entityID: "",
    entityName: "",
    entityUserId: "",
    intervalTickets: "",
    map: "",
    // cycle tour 2023
    // mapURL: "63c6966af3a10.gpx",

    // Double century 2023
    // mapURL: "64a15ec295eec.gpx",

    // Stage 1
    // mapURL: "63d5515cf0b3f.gpx",
    // stage 2
    // mapURL: "63d5518507f14.gpx",
    // stage 3
    // mapURL: "63d551b19de42.gpx",
    // stage 4
    // mapURL: "63d551d41e557.gpx",

    // Race to the sea
    // mapURL: "64b80df0aa637.gpx",

    // Old hermon
    // mapURL: "6499cd041627b.gpx",

    // Trail Seeker Well 2023
    // mapURL: "64f74b843dd48.gpx",

    // Dogs Bollocks
    // mapURL: "64c7f9858f03f.gpx",

    // Race to the sea
    //mapURL: "64b80df0aa637.gpx",

    // GravelMonster
    // mapURL: "64cdf7c8dd47a.gpx",

    // Boland Race of truth
    // mapURL: "64bea7592c874.gpx",

    // Prison Break
    // mapURL: "6522db225c9d1.gpx",

    // Sanlam 2023 42KM
    // mapURL: "65048cfe95045.gpx",

    // // Boland 100
    // mapURL: "652c3fa822478.gpx",

    // Double Century 2023
    mapURL: "64a15ec295eec.gpx",

    mapURLs: [],
    raceID: "",
    stageID: "",
    stageName: "",

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    // gps info
    myAccuracy: "",
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
    interval: "",

    centrePosition: [-34.08403956119237, 18.47900390625],
    data: [],
    ticketMarkerData: [],
    POIMarkerData: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    this.on();
    // get the application storage
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    const entityName = localStorage.getItem("entityName");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const userTypeID = localStorage.getItem("userTypeID");
    const entityUserId = localStorage.getItem("entityUserId");
    const stageID = this.state.myParams.id;

    // set the GPX url here Not sure why this os not working
    // might be a url issue try ./xxxxxx.gpx to check if this helps
    const mapURL = "../gpx/63d4373f2309d.gpx";

    // Get the gpx file names for the polylines
    let { data: mapURLs } = await httpService.get(
      config.apiEndpointGpxsGet + "?stageID=" + stageID
    );

    // Get the Stage information
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    //set the stage details
    localStorage.setItem("stageID", stageID);
    localStorage.setItem("stageName", stage.stageName);
    const stageName = stage.stageName;

    // get the default icon for this Entity User to display to the screen
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationIcon +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID +
        "&" +
        "entityUserId=" +
        entityUserId
    );

    this.setState({ entityUserIcon: Dbdata });

    //Get the list of poi markers for this stage
    let { data: POIdata } = await httpService.get(
      config.apiEndpointPoigetGet + "?stageID=" + stageID
    );

    if (userTypeID == 5) {
      // GPS location storage
      window.navigator.geolocation.getCurrentPosition((success) =>
        this.setState({
          myLatitude: success.coords.latitude,
          myLongitude: success.coords.longitude,
        })
      );

      if (navigator.geolocation) {
        navigator.geolocation.enableHighAccuracy = true;
        navigator.geolocation.watchPosition((position) =>
          this.setState({
            myLatitude: position.coords.latitude,
            myLongitude: position.coords.longitude,
            myAltitude: position.coords.altitude,
            myHeading: position.coords.heading,
            mySpeed: position.coords.speed,
            mySpeedKM: position.coords.speed * 3.6,
            myAccuracy: position.coords.accuracy,
          })
        );
      }

      // 10000 = 20 seconds
      const MINUTE_MS = 10000;
      const interval = setInterval(() => {
        this.sendData();
      }, MINUTE_MS);

      this.setState({ interval });
    }

    this.setState({
      entityID,
      entityName,
      entityUserId,
      mapURL,
      mapURLs,
      POIMarkerData: POIdata,
      raceID,
      raceNameYear,
      stageID,
      stageName,
    });

    this.off();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }
  handleLocate() {}

  async sendData() {
    // Get the latest Geo data
    let {
      myAccuracy,
      myLatitude,
      myLongitude,
      myAltitude,
      myHeading,
      mySpeed,
      mySpeedKM,
      entityID,
      stageID,
      entityUserId,
    } = this.state;

    // Create a numeric outut format type ES6 style
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Format the data to two decimal points
    myAltitude = formatter.format(myAltitude);
    myHeading = formatter.format(myHeading);
    mySpeed = formatter.format(mySpeed);
    mySpeedKM = formatter.format(mySpeedKM);

    //  Create time and date of geo location
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const today = new Date().toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const time = new Date().toLocaleTimeString();

    // go to database and store the info everytime there is a location update
    let formData = new FormData();
    //add the data to formdata to send to db
    formData.append("latitude", myLatitude);
    formData.append("longitude", myLongitude);
    formData.append("altitude", myAltitude);
    formData.append("heading", myHeading);
    formData.append("speed", mySpeedKM);
    formData.append("time", time);
    formData.append("date", today);
    formData.append("timestamp", timestamp);
    formData.append("stageID", stageID);
    formData.append("entityUserId", entityUserId);
    formData.append("entityID", entityID);

    // send the data to the database via Http
    if (myLatitude != "" && myLongitude != "") {
      try {
        const { data: retrievedGeoData } = await httpService.post(
          config.apiEndpointSendEUGeoLocation,
          formData
        );
        // toast.info("Sent");
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    }
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      color,
      cssOverride,
      defaultZoom,
      entityName,
      entityUserIcon,
      loading,
      mapURL,
      mapURLs,
      POIMarkerData,
      raceNameYear,
      stageName,
    } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div id="overlay" className="overlay" onClick={() => this.off()}>
          <div id="text" className="text">
            <PuffLoader
              color={color}
              loading={loading}
              cssOverride={cssOverride}
              size={50}
            />
            Loading Data...
          </div>
        </div>

        <Link to={`/pageEntityUserHome/`} className="btn p-0">
          <ArrowLeftCircle color="#002072" size={35} />
        </Link>

        <Link to={`/entityUserTicketForm/new`} className="btn p-0">
          <PlusCircle color="#002072" size={35} />
        </Link>

        <span className="entity-map-logo">
          {raceNameYear} - {stageName} - {entityName}
        </span>

        <RideAlong
          accuracy={this.state.myAccuracy}
          centerPosition={this.state.centrePosition}
          defaultZoom={defaultZoom}
          entityUserIcon={entityUserIcon}
          geoLocationUser={[this.state.myLatitude, this.state.myLongitude]}
          mapURL={mapURL}
          mapURLs={mapURLs}
          POIMarkerData={POIMarkerData}
        />
      </React.Fragment>
    );
  }
}

export default withMyHook(PageRideAlong);
