import React from "react";

// Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

class FileUpload extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedFile: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });
  }

  submit = async () => {
    //
    const data = new FormData();
    data.append("file", this.state.selectedFile);

    console.warn(this.state.selectedFile);

    try {
      await httpService.post(config.apiEndpointfileUpload, data);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while adding.");
    }

    // let url = "http://localhost:8000/upload.php";

    // axios
    //   .post(url, data, {
    //     // receive two parameter endpoint url ,form data
    //   })
    //   .then((res) => {
    //     // then print response status
    //     console.warn(res);
    //   });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <div>
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <br />
            <br />

            <h3 className="text-white">
              React File Upload Example - Tutsmake.com
            </h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="text-white">Select File :</label>
                <input
                  type="file"
                  className="form-control"
                  name="upload_file"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6">
                <button
                  type="submit"
                  className="btn btn-dark"
                  onClick={() => this.submit()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
