import React from "react";
// icons
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const Input = ({ name, label, error, ...rest }) => {
  return (
    <div>
      <input
        {...rest}
        name={name}
        id={name}
        className="form-control input-color"
      />

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Input;
