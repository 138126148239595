const tickets = [
  {
    id: 1,
    title: "Cycle Sweep",
    comment:
      "Puncture. On site with cyclist repairing him self. No assistance needed at this time.",
    raceNumber: 15396,
    source: "Moto Marshal",
    sourceName: "Simon Groves",
    date: "10.11.2021",
    time: "08:15",
    resolved: true,
    allocation: "Moto Marshals",
    allocationBY: "VOC",
    location: "-33.888087, 20.327454",
    notes: "No action taken needed",
    priority: true,
    ticketType: { id: "1", name: "Cycle Sweep" },
  },
  {
    id: 2,
    title: "Information",
    comment: "Spectators are gathering in large numbers.",
    raceNumber: 18796,
    source: "Call Centre",
    sourceName: "Dawn Foster",
    date: "10.11.2021",
    time: "09:10",
    resolved: false,
    allocation: "Traffic",
    allocationBY: "Call Centre",
    location: "-33.888087, 20.327454",
    notes: "Sending someone out to control",
    priority: false,
    ticketType: { id: "3", name: "Informational" },
  },
  {
    id: 3,
    title: "Tempreture",
    comment: "16 dgrees",
    raceNumber: 98554,
    source: "Participant",
    sourceName: "William Wilkinson",
    date: "10.11.2021",
    time: "08:08",
    resolved: false,
    allocation: "None",
    allocationBY: "None",
    location: "-33.888087, 20.327454",
    notes: "",
    priority: false,
    ticketType: { id: "25", name: "Temperture" },
  },
  {
    id: 4,
    title: "Cycle Sweep",
    comment: "Participant unable to continue and needs to be collected",
    raceNumber: 98745,
    source: "Spectator",
    sourceName: "Nelly Niggles",
    date: "10.11.2021",
    time: "08:05",
    resolved: false,
    allocation: "Sweep",
    allocationBY: "VOC",
    location: "-33.888087, 20.327454",
    notes: "Informed sweep mini vehicle and will collect",
    priority: false,
    ticketType: { id: "1", name: "Cycle Sweep" },
  },
  {
    id: 5,
    title: "Medical",
    comment: "Rider has fallen and is badly hurt",
    raceNumber: "E15-41",
    source: "Sector Marshal",
    sourceName: "Ryan Reynolds",
    date: "10.11.2021",
    time: "08:00",
    resolved: false,
    allocation: "Medical",
    allocationBY: "VOC",
    location: "-33.888087, 20.327454",
    notes: "Send ambulance ASAP",
    priority: false,
    ticketType: { id: "2", name: "Medical" },
  },
];

export function getTickets() {
  return tickets;
}
