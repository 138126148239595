import React, { Component } from "react";

import { Link } from "react-router-dom";

//Components
import VehicleCards from "./../components/vehicleCards";
import NavbarRace from "../components/navbarRace";
import withMyHook from "../components/common/withMyHook";
import SearchBox from "../components/common/searchBox";

// other
import { ToastContainer, Zoom, toast } from "react-toastify";
import _ from "lodash";

import {
  ArrowCounterclockwise,
  ArrowLeftCircle,
  PlusCircle,
  Table,
} from "react-bootstrap-icons";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Services
import httpService from "../services/httpService.js";
import config from "../config.json";

class pageVehiclePassenger extends Component {
  state = {
    entityID: "",
    goToPage: "",
    gotToForm: "",
    groupName: "",
    groupID: "",
    stageID: "",
    raceID: "",
    raceNameYear: "",
    stage: "",

    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
    interval: "",

    // Pagination
    searchQueryPN: "",
    currentPage: "1",
    pageSize: 20,

    NotificationDataRetreived: [],
    vehicleStats: [],

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    this.on();
    const stageID = this.state.myParams.id;

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    const userTypeID = localStorage.getItem("userTypeID");

    // clear vehicle info
    localStorage.setItem("vehicleID", "");
    localStorage.setItem("vehicleName", "");

    console.log("In page vehicle passneger");

    console.log("AFTER DATABASE CALL");
    console.log("stageID = " + stageID);
    console.log("entityID = " + entityID);

    this.setState({
      entityID,
      groupName,
      groupID,
      stageID,
      raceID,
      raceNameYear,
    });

    // Get the Stage details for display to the user
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );
    this.setState({ stage });

    // set the stage ID
    localStorage.setItem("stageName", stage.stageName);
    localStorage.setItem("stageID", stageID);

    //Get the stats for vehicle to create the vehicle cards
    try {
      // Get the vehicles and ticket stats
      let { data: vehicleStatsDB } = await httpService.get(
        config.apiEndpointVehiclePassStatsGet +
          "?stageID=" +
          stageID +
          "&" +
          "entityID=" +
          entityID
      );

      // sort the vehicle stats in name order
      const vehicleStatsDBSorted = _.orderBy(vehicleStatsDB, "name", "asc");

      this.setState({ vehicleStats: vehicleStatsDBSorted });
    } catch (error) {
      this.off();
    }

    let goToPage = "";
    if (userTypeID === "3") goToPage = `pageRaceHome/${raceID}`;
    if (userTypeID === "4") goToPage = `pageRaceEntityHome/${raceID}`;
    if (userTypeID === "5") goToPage = `pageEntityUserHome`;

    let gotToForm = "";
    if (userTypeID === "3") gotToForm = `notificationForm/new`;
    if (userTypeID === "4") gotToForm = `notificationEntityForm/new`;

    this.setState({ goToPage, gotToForm });

    // Now set the radion button as checked
    const cbTickets = document.querySelector("#mainTicketBox");
    cbTickets.checked = true;

    // Switch on the Auto refresh tickets
    this.autoRefreshTickets();

    this.off();
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  refreshTickets = async () => {
    const stageID = localStorage.getItem("stageID");
    const entityID = localStorage.getItem("entityID");

    // Get the vehicles and ticket stats
    let { data: vehicleStatsDB } = await httpService.get(
      config.apiEndpointVehiclePassStatsGet +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );

    // sort the vehicle stats in name order
    const vehicleStatsDBSorted = _.orderBy(vehicleStatsDB, "name", "asc");

    this.setState({ vehicleStats: vehicleStatsDBSorted });
  };

  autoRefreshTickets = async () => {
    // This code will be used to refresh the tickets every 1 minute
    const cbTickets = document.querySelector("#mainTicketBox");

    if (cbTickets.checked == true) {
      // set the 1 minute to a variable and let the user set it
      this.interval = setInterval(() => this.refreshTickets(), 6000);
    } else {
      clearInterval(this.interval);
    }
  };

  handleSearchPN = (query) => {
    this.setState({
      searchQueryPN: query,
      selectedTicketType: "",
      currentPage: 1,
    });
  };

  handlePageReset = () => {
    this.setState({ currentPage: 1 });
  };

  handlePageIncrement = (sentCurrentPage, pagesCount) => {
    if (sentCurrentPage < pagesCount) {
      sentCurrentPage++;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageDecrement = (sentCurrentPage) => {
    if (sentCurrentPage != 1) {
      sentCurrentPage--;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      color,
      cssOverride,
      currentPage,
      goToPage,
      gotToForm,
      stage,
      loading,
      pageSize,
      searchQueryPN,
      vehicleStats,
    } = this.state;

    let filtered = vehicleStats;

    console.log(filtered);

    if (searchQueryPN)
      filtered = vehicleStats.filter((m) =>
        m.name.toLowerCase().includes(searchQueryPN.toLowerCase())
      );

    return (
      <React.Fragment>
        <NavbarRace />
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div id="overlay" className="overlay" onClick={() => this.off()}>
          <div id="text" className="text">
            <PuffLoader
              color={color}
              loading={loading}
              cssOverride={cssOverride}
              size={50}
            />
            Loading Data...
          </div>
        </div>
        <div className="container container-top-70 ">
          <div className="row" data-aos="zoom-in" data-aos-duration="5000">
            <div className="row">
              <div className="button-padding ">
                <div className="button-align">
                  <Link to={`/${goToPage}`} className="btn p-0 ">
                    <ArrowLeftCircle color="#002072" size={35} />
                  </Link>

                  <Link
                    to=""
                    onClick={() => this.refreshTickets()}
                    className="btn p-0"
                  >
                    <ArrowCounterclockwise color="#002072" size={35} />
                  </Link>
                  <Link to="/pagePassengerEntity/" className="btn p-0 center">
                    <Table color="#002072" size={28} />
                  </Link>

                  <div className="col button-align mt-2">
                    <label className="switch margin-left">
                      <input
                        id="mainTicketBox"
                        name="mainTicketBox"
                        type="checkbox"
                        onClick={this.autoRefreshTickets}
                      />
                      <span className="slider round"></span>
                    </label>
                    <h6 className="button-padding ml-1">Auto refresh</h6>
                  </div>
                </div>

                <h5>{stage.stageName} - Vehicle/ Passengers</h5>
              </div>
              <SearchBox
                value={searchQueryPN}
                onChange={this.handleSearchPN}
                placeholder="Search vehicle  name.."
              />
            </div>

            <div className="row m-0 center">
              <VehicleCards
                allTicketsTotal={filtered.length}
                currentPage={currentPage}
                onPageDecrement={this.handlePageDecrement}
                onPageIncrement={this.handlePageIncrement}
                onPageChange={this.handlePageChange}
                refreshTickets={this.refreshTickets}
                pageSize={pageSize}
                ticketData={filtered}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(pageVehiclePassenger);
