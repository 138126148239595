import React from "react";
import { Link } from "react-router-dom";

class NavbarRace extends React.Component {
  render() {
    return (
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand " to="/">
            <h4>tracKtion</h4>
          </Link>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/pageRaceHome"
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/pageGroupRaces">
                  Races
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page"  to="/pageEntity">
                  Entities
                </Link>
              </li>
          
            </ul>
          </div> */}
        </div>
      </nav>
    );
  }
}

export default NavbarRace;
