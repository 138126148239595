import React, { Component } from "react";
class NotFound extends React.Component {
  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <div className="container container-top-70">
        <div className="row">
          <div className="form-container">
            <div className="page-not-found">440</div>

            <p>Page Not Found</p>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
