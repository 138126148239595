import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "./../../components//navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class EntityUserRoleForm extends Form {
  state = {
    data: {
      description: "",
      name: "",
    },
    errors: {},
    entityID: "",
    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    description: Joi.string().label("User Role Description"),
    name: Joi.string().label("User Role Name"),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;

    // get variables from the local storage
    const entityID = localStorage.getItem("entityID");

    //set State with the Variables
    this.setState({ entityID });

    // get data from database to display options in textfields
    if (ID === "new") return;
    let { data: returnedData } = await httpService.get(
      config.apiEndpointEntityUserRoleGet + "?id=" + ID
    );

    if (!returnedData) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(returnedData) });
  }

  // Submit a new or update
  doSubmit = async () => {
    const ID = this.state.myParams.id;
    const entityID = this.state.entityID;

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      formData.append("entityID", entityID);
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointEntityUserRolesAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry

      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointEntityUserRolesUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate("/pageEntityUserRoles");
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointEntityUserRolesdelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate("/pageEntityUserRoles");
  };

  mapToViewModel(data) {
    return {
      description: data.description,
      name: data.name,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />

        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link to="/pageEntityUserRoles" className="btn p-0">
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.raceNameYear}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h4>User Role Details</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>User Role Name</td>
                      <td>{this.renderInput("name", "Ticket Type Name")}</td>
                    </tr>
                    <tr>
                      <td>User Role Description</td>
                      <td>
                        {this.renderTextArea(
                          "description",
                          "Ticket Type Description",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(EntityUserRoleForm);
