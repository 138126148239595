import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TableHeader extends React.Component {
  raiseSort = (path) => {
    //set the path and order depending on how its currently sorted
    const sortColumn = { ...this.props.sortColumn };

    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }

    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column) => {
    const { sortColumn } = this.props;
    let iconText = sortColumn.order === "asc" ? "arrow-down" : "arrow-up";

    if (column.path !== sortColumn.path) return null;
    return <FontAwesomeIcon icon={iconText} size="sm" />;
  };

  render() {
    return (
      <thead>
        <tr>
          {this.props.columns.map((column) => (
            <th
              className="clickable table-heading"
              key={column.path || column.key}
              onClick={() => this.raiseSort(column.path)}
            >
              {column.label}

              {this.renderSortIcon(column)}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
