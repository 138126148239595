import React, { Component } from "react";

//Components
import Form from "../components/common/form";
import withMyHook from "../components/common/withMyHook";
import myDocument from "../components/common/myDocument";
// Services
import httpService from "../services/httpService.js";
import config from "../config.json";

// Utilities
import Joi from "joi-browser";
import { PDFViewer } from "@react-pdf/renderer";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

class PageLogin extends Form {
  state = {
    data: {
      usercell: "",
      userPassword: "",
    },

    userDetails: {},
    errors: {},

    myNavigate: this.props.myNavigate,
    myParams: this.props.myParams,
  };

  schema = {
    // usercell: Joi.string().label("Cell Number"),
    usercell: Joi.string()
      .regex(/^[0-9]{10}$/)
      .label("Cell No"),
    userPassword: Joi.string().label("Password"),
  };

  componentDidMount() {
    // Store the data for the other pages in the site
    localStorage.setItem("authID", "");
    localStorage.setItem("active", "");
    localStorage.setItem("entityID", "");
    localStorage.setItem("entityName", "");
    localStorage.setItem("entityJocRepID", "");
    localStorage.setItem("entityUserId", "");
    localStorage.setItem("entityUserRoleDesc", "");
    localStorage.setItem("entityUserRoleId", "");
    localStorage.setItem("entityUserRoleName", "");
    localStorage.setItem("groupID", "");
    localStorage.setItem("groupName", "");
    localStorage.setItem("groupUserTypeID", "");
    localStorage.setItem("groupUserTypeDescription", "");
    localStorage.setItem("raceID", "");
    localStorage.setItem("raceName", "");
    localStorage.setItem("raceNameYear", "");
    localStorage.setItem("stageID", "");
    localStorage.setItem("stageName", "");
    localStorage.setItem("userName", "");
    localStorage.setItem("userSurname", "");
    localStorage.setItem("userTypeID", "");
  }

  //Get the information form the authentication table
  doSubmit = async () => {
    // console.log("usercell = " + this.state.usercell);
    // console.log("userPassword = " + this.state.userPassword);

    let dataToSend = this.state.data;
    let formData = new FormData();

    Object.keys(dataToSend).forEach((key) => {
      formData.append(key, dataToSend[key]);
    });

    try {
      const { data: userDetails } = await httpService.post(
        config.apiEndpointAuth,
        formData
      );
      this.state.userDetails = userDetails;
    } catch (error) {
      toast.warning("No match for these login details");
      if (error.reponse && error.reponse.status === 404) console.log(error);
    }

    //Get all the returned data
    const userDetails = this.state.userDetails;

    //Load the variables
    let authID = userDetails.authID;
    let active = userDetails.active;
    let entityName = userDetails.entityName;
    let entityID = userDetails.entityID;
    let entityJocRepID = userDetails.entityJocRepID;
    let entityUserId = userDetails.entityUserId;
    let entityUserRoleId = userDetails.entityUserRoleId;
    let entityUserRoleName = userDetails.entityUserRoleName;
    let groupID = userDetails.groupID;
    let groupName = userDetails.groupName;
    let groupUserTypeID = userDetails.groupUserTypeID;
    let groupUserTypeDescription = userDetails.groupUserTypeDescription;
    let raceID = userDetails.raceID;
    let raceName = userDetails.raceName;
    let stageID = userDetails.stageID;
    let stageName = userDetails.stageName;
    let userTypeID = userDetails.userTypeID;
    let userName = userDetails.userName;
    let userSurname = userDetails.userSurname;

    // Store the data for the other pages in the site
    localStorage.setItem("authID", authID);
    localStorage.setItem("active", active);
    localStorage.setItem("entityID", entityID);
    localStorage.setItem("entityJocRepID", entityJocRepID);
    localStorage.setItem("entityName", entityName);
    localStorage.setItem("entityUserId", entityUserId);
    localStorage.setItem("entityUserRoleId", entityUserRoleId);
    localStorage.setItem("entityUserRoleName", entityUserRoleName);
    localStorage.setItem("groupID", groupID);
    localStorage.setItem("groupName", groupName);
    localStorage.setItem("groupUserTypeID", groupUserTypeID);
    localStorage.setItem("groupUserTypeDescription", groupUserTypeDescription);
    localStorage.setItem("raceID", raceID);
    localStorage.setItem("raceName", raceName);
    localStorage.setItem("raceNameYear", "");
    localStorage.setItem("stageID", stageID);
    localStorage.setItem("stageName", stageName);
    localStorage.setItem("userName", userName);
    localStorage.setItem("userSurname", userSurname);
    localStorage.setItem("userTypeID", userTypeID);

    //Now route the user to the correct home page based on their userType

    // User Type legend

    if (active === 0) {
      this.state.myNavigate("/");
      return;
    }
    // Site Admin eg. The programmer Boss Dude
    if (userTypeID == 1) {
      this.state.myNavigate("/pageSiteAdmin");
    }
    // Group User eg. David Bellairs
    if (userTypeID == 2) {
      this.state.myNavigate("/pageGroupHome");
    }
    // Team User eg. Micheal Simpson
    if (userTypeID == 3) {
      this.state.myNavigate("/pageTeamMemberHome");
    }
    // Entity Head eg. Donavan Daniels (BMW moto Marshals)
    if (userTypeID == 4) {
      this.state.myNavigate("/pageEntityHome");
    }
    // Entity Joc Rep   eg. David Ffolkes  (BMW moto Marshals)
    if (userTypeID == 6) {
      this.state.myNavigate(`/pageRaceEntityHome/${raceID}`);
    }
    // Entity User e.g Brent Laaks (BMW moto Marshals)
    if (userTypeID == 5) {
      this.state.myNavigate("/pageEntityUserHome");
    }
  }; //close do submit

  doClear = () => {
    const dataCleared = {
      usercell: "",
      userPassword: "",
    };

    this.setState({ data: this.mapToViewModel(dataCleared) });
  };

  mapToViewModel(data) {
    return {
      usercell: data.usercell,
      userPassword: data.userPassword,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      // <React.Fragment>
      //   <PDFViewer>
      //     <myDocument />
      //   </PDFViewer>
      // </React.Fragment>
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div className="login-center">
          <div className="row block-width">
            <div>
              <div className="account-wall">
                <img
                  className="profile-img"
                  src="https://tracktion.co.za/images/tracktion.jpg"
                  alt=""
                />
                <p className="profile-name">Sports Event Management</p>

                <form className="form-signin" onSubmit={this.handleSubmit}>
                  <table className="table table-login">
                    <tbody>
                      <tr>
                        <td>Cell Number</td>
                        <td> {this.renderInput("usercell", "Cell")}</td>
                      </tr>
                      <tr>
                        <td>Password (Optional)</td>
                        <td>
                          {this.renderPassword("userPassword", "Password")}
                        </td>
                      </tr>
                      <tr align="center">
                        <td colSpan={2}>
                          <span className="button-padding">
                            {this.renderSaveButton("Login")}
                          </span>
                          <span className="button-padding">
                            {this.renderButtonClear("Clear")}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <a
                href="https://developsoftware.co.za/"
                className="text-center new-account"
              >
                Designed by Develop Software ©
              </a>
            </div>
          </div>
        </div>
        {/* <PDFViewer>
          <myDocument />
        </PDFViewer> */}
      </React.Fragment>
    );
  }
}

export default withMyHook(PageLogin);
