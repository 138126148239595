import React, { Component } from "react";

import { Link } from "react-router-dom";

// Services
import httpService from "../services/httpService";
import config from "../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//  Icons

import {
  PersonFillGear,
  TicketPerforatedFill,
  Whatsapp,
} from "react-bootstrap-icons";

import { FaEdit } from "react-icons/fa";
import { TbBus } from "react-icons/tb";
// IoMdExit
import { IoIosExit } from "react-icons/io";
import { BsChatSquareDots, BsListUl } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { GiCarWheel, GiSteeringWheel } from "react-icons/gi";

class EntityUserCardRD extends Component {
  state = {
    ticketIdLocal: this.props.ticket.vehicleID,

    gotToForm: "",
    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",

    warningColor: "",
  };

  async componentDidMount() {
    const userTypeID = localStorage.getItem("userTypeID");
    const stageID = localStorage.getItem("stageID");

    let str1 = "abcClean";
    let accordId = str1.concat(this.state.ticketIdLocal);

    let gotToForm = "";
    if (userTypeID === "3") gotToForm = ``;
    if (userTypeID === "4") gotToForm = `PageEntityUserPassengers/${stageID}`;
    if (userTypeID === "5") gotToForm = `PageEntityUserPassengers/${stageID}`;

    this.setState({ gotToForm });

    // set the colors based on the capacity and load for each vehicle
  }

  render() {
    const { refreshTickets, ticket } = this.props;
    const { ticketIdLocal } = this.state;

    // set the colors for each depending on the percentage

    const { gotToForm } = this.state;
    const { ticketID } = ticket.id;

    return (
      <div className="card shadow-sm m-1 passeger-card-border card-width-vehicle">
        <div className="row">
          <div className="card-group-display align-vert">
            <div className="row">
              <div className="details-align mt-1">
                <div className="card-vehicle-heading-lg">
                  <Link
                    // to={`/pagePassengerVehicle/${ticketIdLocal}`}
                    to=""
                    className="btn p-0"
                  >
                    {ticket.fullName}
                    <span className="card-vehicle-heading-nc"></span>
                  </Link>
                </div>
              </div>
            </div>
            <hr className="my-1" />
            <div className="row mx-2">
              <div className="details-align">
                <div className="details-icon">
                  <PersonFillGear color={"#858796"} size={20} />
                </div>
                <span>{ticket.userRole}</span>
              </div>
            </div>
            <div className="row mx-2">
              <div className="details-align">
                <div className="details-icon">
                  <TicketPerforatedFill color={"#858796"} size={20} />
                </div>
                <span>{ticket.ticketCount} Ticket/s Placed</span>
              </div>
            </div>

            <div className="row mx-2 my-1">
              <div className="details-align">
                <div className="details-icon">
                  <Whatsapp color="#128c7e" size={20} />
                </div>
                <div>
                  <a
                    href={`https://wa.me/+27${ticket.cell}?text=`}
                    target="_blank"
                  >
                    {ticket.cell}
                  </a>
                </div>
              </div>
            </div>
            <hr className="my-1" />
            <div className="mb-3"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default EntityUserCardRD;
