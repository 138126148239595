import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ToggleIcon extends React.Component {
  render() {
    return (
      <FontAwesomeIcon
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        title="Click to mark ticket as priority"
        icon={this.props.iconPriority === true ? "exclamation" : "exclamation"}
        size={this.props.iconPriority === true ? "lg" : "lg"}
        style={{ cursor: "pointer" }}
        color={this.props.iconPriority === true ? "red" : "lightgrey"}
        onClick={this.props.onClick}
      />
    );
  }
}

export default ToggleIcon;
