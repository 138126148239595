import React from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import { EntityVehicleMarker } from "./entityVehicleMarker";

export const EntityVehicleLayer = ({ vehicleData, entityName }) => {
  // if there is no entity User data
  let entitynameNew = entityName;

  // console.log("entityName = " + entityName);
  // let numrows = vehicleData.length;
  // console.log("numrows  = " + numrows);

  if (!vehicleData) {
    return <div></div>;
  } else {
    if (entityName) {
      return (
        <LayersControl.Overlay checked name="Vehicles">
          <LayerGroup>
            {/* Now go to each ticket type and create the layers that need to show on the map */}
            {vehicleData.map((singleVehicle) => (
              <EntityVehicleMarker
                key={singleVehicle.vehicleID}
                singleVehicle={singleVehicle}
              />
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
      );
    } else {
      return <div></div>;
    }
  }
};
