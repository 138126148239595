import React from "react";
import _ from "lodash";

class Pagination extends React.Component {
  render() {
    const {
      itemsCount,
      pageSize,
      currentPage,
      onPageChange,
      onPageDecrement,
      onPageIncrement,
    } = this.props;
    const pagesCount = Math.ceil(itemsCount / pageSize);

    if (pagesCount === 1) return null;
    const pages = _.range(1, pagesCount + 1);

    return (
      <nav aria-label="Page navigation example ">
        <ul className="pagination pagination-padding ">
          <li className="page-item ">
            <a
              className="page-link text-darkblue"
              onClick={() => onPageDecrement(currentPage)}
            >
              Previous
            </a>
          </li>
          {pages.map((page) => (
            <li
              key={page}
              className={
                page === currentPage ? "page-item active" : "page-item"
              }
            >
              <a
                className="page-link text-darkblue"
                onClick={() => onPageChange(page)}
              >
                {page}
              </a>
            </li>
          ))}

          <li className="page-item">
            <a
              className="page-link text-darkblue"
              onClick={() => onPageIncrement(currentPage, pagesCount)}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Pagination;
