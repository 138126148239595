import React from "react";

class CalloutButtons extends React.Component {
  state = {
    clickedAll: "btn btn-outline-primary position-relative m-2",
    clickedPriority: "btn btn-light position-relative m-2",
    clickedPending: "btn btn-light position-relative m-2",
    clickedResolved: "btn btn-light position-relative m-2",
    clickedMytickets: "btn btn-light position-relative m-2",
    userTypeID: "",
  };

  componentDidMount() {
    // Get the info that ids the type of user
    // Then  set a state variable so that we can use it to decide which button set to render
    let userTypeID = localStorage.getItem("userTypeID");
    this.setState({ userTypeID: userTypeID });
  }

  handleButtonClick(buttonPressed) {
    // set the Clicked button to the correct button class

    this.state.clickedPriority =
      buttonPressed === "Priority"
        ? "btn btn-danger position-relative m-2"
        : "btn btn-light position-relative m-2";

    this.state.clickedAll =
      buttonPressed === "All"
        ? "btn btn-outline-primary position-relative m-2"
        : "btn btn-light position-relative m-2";

    this.state.clickedPending =
      buttonPressed === "Pending"
        ? "btn btn-warning position-relative m-2"
        : "btn btn-light position-relative m-2";

    this.state.clickedResolved =
      buttonPressed === "Resolved"
        ? "btn btn-success position-relative m-2"
        : "btn btn-light position-relative m-2";

    this.state.clickedMytickets =
      buttonPressed === "My Tickets"
        ? "btn btn-secondary position-relative m-2"
        : "btn btn-light position-relative m-2";

    // this.state.clickedClass = "btn btn-primary position-relative m-2";
    // now handle the event that will filter the tickets
    this.props.handleCalloutSort(buttonPressed);
  }

  render() {
    const { ticketsTotals } = this.props;
    const userTypeID = this.state.userTypeID;

    return (
      <div>
        <button
          type="button"
          className={this.state.clickedAll}
          onClick={() => this.handleButtonClick("All")}
        >
          All Tickets
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
            {ticketsTotals[0]}
          </span>
        </button>
        <button
          type="button"
          className={this.state.clickedMytickets}
          onClick={() => this.handleButtonClick("My Tickets")}
        >
          My Tickets
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
            {ticketsTotals[4]}
          </span>
        </button>

        <button
          type="button"
          className={this.state.clickedPriority}
          onClick={() => this.handleButtonClick("Priority")}
        >
          Priority
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {ticketsTotals[1]}
          </span>
        </button>

        <button
          type="button"
          className={this.state.clickedPending}
          onClick={() => this.handleButtonClick("Pending")}
        >
          Pending
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
            {ticketsTotals[2]}
          </span>
        </button>

        <button
          type="button"
          className={this.state.clickedResolved}
          onClick={() => this.handleButtonClick("Resolved")}
        >
          Resolved
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
            {ticketsTotals[3]}
          </span>
        </button>
      </div>
    );
  }
}

export default CalloutButtons;
