import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "./../../components//navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class TicketTypeForm extends Form {
  state = {
    data: {
      defaultIconID: "",
      description: "",
      name: "",
      alertName: "",
      alertCell: "",
    },
    raceNameYear: "",
    raceID: "",

    icons: [],
    errors: {},

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    defaultIconID: Joi.string().label("Default Icon"),
    description: Joi.string().label("Description"),
    name: Joi.string().label("Ticket Name"),
    alertName: Joi.string().label("Alert Name"),
    alertCell: Joi.string()
      .regex(/^[0-9]{10}$/)
      .label("Cell No"),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;

    // get variables from the local storage
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");

    //set State with the Variables
    this.setState({ raceID, raceNameYear });

    // get data from database to display options in textfields

    // Get all entity icons
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointIconsGetByType + "?typeID=2"
    );
    this.setState({ icons: Dbdata });

    if (ID === "new") return;
    let { data: stage } = await httpService.get(
      config.apiEndpointTicketTypeGet + "?id=" + ID
    );

    if (!stage) return this.state.myNavigate("/not-found");

    this.setState({ data: this.mapToViewModel(stage) });
  }

  // Submit a new or update
  doSubmit = async () => {
    const ID = this.state.myParams.id;
    const raceID = this.state.raceID;

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      formData.append("raceID", raceID);
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointTicketTypesAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry

      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointTicketTypeUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate("/pageTicketTypes");
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointTicketTypedelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate("/pageTicketTypes");
  };

  mapToViewModel(data) {
    return {
      description: data.description,
      name: data.name,
      defaultIconID: data.defaultIconID,
      alertName: data.alertName,
      alertCell: data.alertCell,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />

        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link to="/pageTicketTypes" className="btn p-0">
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.raceNameYear}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Ticket Type Details</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ticket Type Name</td>
                      <td>{this.renderInput("name", "Ticket Type Name")}</td>
                    </tr>
                    <tr>
                      <td>Ticket Type Description</td>
                      <td>
                        {this.renderTextArea(
                          "description",
                          "Ticket Type Description",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Map Icon</td>
                      <td>
                        {" "}
                        {this.renderSelect(
                          "defaultIconID",
                          "iconName",
                          this.state.icons,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Whats App - Alert Person Name</td>
                      <td>{this.renderInput("alertName", "Alert Name")}</td>
                    </tr>

                    <tr>
                      <td>Whats App - Alert cell number</td>
                      <td>{this.renderInput("alertCell", "Alert Cell no")}</td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(TicketTypeForm);
