import React from "react";
import { ArrowLeftCircle } from "react-bootstrap-icons";
import config from "../../config.json";
import Form from "../../components/common/form";
import httpService from "./../../services/httpService.js";
import Joi, { log } from "joi-browser";
import { Link } from "react-router-dom";
import NavbarEntity from "./../../components/navbarEntity";
import PuffLoader from "react-spinners/PuffLoader";
import { ToastContainer, Zoom, toast } from "react-toastify";
import withMyHook from "../../components/common/withMyHook";
import gpxParser from "gpxparser";

class gpxUploadDBForm extends Form {
  state = {
    data: {
      gpxfileID: "",
    },
    entityJocRepID: "",
    entityName: "",
    entityID: "",
    entityUserId: "",
    groupID: "",

    raceID: "",
    raceNameYear: "",
    selectedFile: "",
    stageName: "",
    stageID: "",

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    entites: [],
    entityUsersDB: [],
    entitiesAllocated: [],
    errors: {},
    ticketTypesData: [],
    dataRetreived: [],
    circuitPolyLines: [],
    circuitElevation: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    gpxfileID: Joi.string().label(" Stage"),
  };

  componentDidMount() {
    const ID = this.state.myParams.id;
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const stageName = localStorage.getItem("stageName");
    const stageID = localStorage.getItem("stageID");

    this.setState({
      raceID,
      raceNameYear,
      stageID,
      stageName,
    });

    // get all the routes for the dropdown where the coords flag is 0
    httpService
      .get(config.apiEndpointGpxsGet + "?stageID=" + stageID)
      .then((response) => {
        const retrievedData = response.data;
        if (!retrievedData) {
          // return this.state.myNavigate("/not-found");
        } else {
          this.setState({ dataRetreived: retrievedData });
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // get a list of routes for the dropdown
    if (ID !== "new") {
      this.setState({ data: this.mapToViewModel(ID) });
    }
  }

  getChosenGPXCoords = async (url) => {
    const myHeaders = new Headers();
    let gpxP = new gpxParser();

    myHeaders.append("Content-Type", "text/xml");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Headers", "X-Requested-With");
    myHeaders.append("Access-Control-Allow-Headers", "access");
    myHeaders.append("Access-Control-Allow-Methods", "GET");
    myHeaders.append("Access-Control-Allow-Credentials", "true");

    await fetch(url, {
      mode: "no-cors",
      headers: myHeaders,
    })
      .then((d) => d.text())
      .then((d) => {
        gpxP.parse(d);
        const singlePosition = gpxP.tracks[0].points.map((p) => [p.lat, p.lon]);
        const elevation = gpxP.tracks[0].points.map((p) => p.ele);

        const TempCircuitArray = [];
        const TempElevationArray = [];

        TempCircuitArray.push(singlePosition);
        TempElevationArray.push(elevation);

        this.state.circuitPolyLines = [...TempCircuitArray];
        this.state.circuitElevation = [...TempElevationArray];
      });

    // console.log(this.state.circuitPolyLines);
  };

  doSubmit = () => {
    // Get the group Id for a new entry
    const ID = this.state.myParams.id;
    const gpxfileID = this.state.data.gpxfileID;
    const circuitPolyLines = this.state.circuitPolyLines;
    const elevation = this.state.circuitElevation;

    this.on();

    if (ID === "new") {
      // add a new entry
      // let dataToSend = this.state.data;/
      let formData = new FormData();

      // Add the static info to the formData

      // Need
      // gpxFileID
      // lat long and elevation array

      formData.append("gpxdata", circuitPolyLines);
      formData.append("elevation", elevation);
      formData.append("gpxfileID", gpxfileID);

      httpService
        .post(config.apiEndpointGpxCoordsAdd, formData)
        .catch((error) => {
          if (error.reponse && error.reponse.status === 404) {
            toast.error("Something failed while adding.");
          }
        });

      // now go and update the record to show that Gpx cords have been added

      let formDataU = new FormData();
      formDataU.append("gpxfileID", gpxfileID);
      try {
        httpService.post(config.apiEndpointGpxCoordsUpdate, formDataU);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    } //close new

    this.off();
    this.state.myNavigate(`/pageGPXfilesUpload/${this.state.stageID}`);
  };

  doDelete = () => {
    this.on();
    const ID = this.state.myParams.id;
    const stageID = this.state.stageID;
    const gpxfileID = this.state.data.gpxfileID;

    httpService
      .get(config.apiEndpointGpxCoordsDelete + "?id=" + gpxfileID)
      .then((deleteReply) => {
        var myObject = JSON.parse(deleteReply);
      })
      .catch((error) => {
        if (error.reponse && error.reponse.status === 404) {
          toast.error("Something failed while deleting.");
        }
      });

    // now go and update the record to show that Gpx cords have been added

    let formDataU = new FormData();
    formDataU.append("gpxfileID", gpxfileID);
    try {
      httpService.post(config.apiEndpointGpxCoordsUnflag, formDataU);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while updating.");
    }

    this.state.myNavigate(`/pageGPXfilesUpload/${this.state.stageID}`);
    this.off();
  };
  mapToViewModel(data) {
    return {
      gpxfileID: data,
    };
  }
  handleInputChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
    const file = event.target.files[0];
    const fileURL = URL.createObjectURL(file);
    // console.log(fileURL);
    this.getChosenGPXCoords(fileURL);
  };
  on() {
    document.getElementById("overlay").style.display = "block";
  }
  off() {
    document.getElementById("overlay").style.display = "none";
  }
  selectElement(id, valueToSelect) {
    let element = document.getElementById(id);
    element.value = valueToSelect;
  }
  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const { color, cssOverride, loading, stageID } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />

        <NavbarEntity />

        <div className="container container-top-70">
          <div id="overlay" className="overlay" onClick={() => this.off()}>
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={`/pageGPXfilesUpload/${stageID}`}
                          className="btn p-0"
                        >
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.raceNameYear}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>GPX upload - {this.state.stageName}</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> Circuit name</td>
                      <td>
                        {this.renderSelect(
                          "gpxfileID",
                          "givenName",
                          this.state.dataRetreived,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Upload File</td>
                      <td>
                        <input
                          type="file"
                          className="form-control"
                          name="upload_file"
                          accept=".gpx"
                          onChange={this.handleInputChange}
                        />
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withMyHook(gpxUploadDBForm);
