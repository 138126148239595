import React from "react";
import Table from "./common/table";
import ToggleIcon from "./common/toggleIcon";
import Pagination from "./common/pagination";
import { paginate } from "../utils/paginate";

class TicketListTable extends React.Component {
  state = {
    pageSize: 20,
    currentPage: 1,
  };

  getBadgeClasses(ticket) {
    let classes = "badge rounded-pill ";
    classes +=
      ticket.resolved === true
        ? "bg-success m-2 badgePadding"
        : "bg-warning m-2 badgePadding";
    return classes;
  }
  getBadgeText(ticket) {
    // console.log("ticket= ") + ticket;
    let badgeText = ticket.resolved === true ? "Resolved" : "Pending";
    return badgeText;
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageIncrement = (setCurrentPage, pagesCount) => {
    if (setCurrentPage < pagesCount) {
      setCurrentPage++;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  handlePageDecrement = (setCurrentPage) => {
    if (setCurrentPage != 1) {
      setCurrentPage--;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  columns = [
    { path: "id", label: "Id" },
    { path: "ticketType.name", label: "Ticket Type" },
    { path: "comment", label: "Comment" },
    //  change to from once I have figured it out.
    { path: "allocToEntityName", label: "Allocated to" },
    { path: "ticketTime", label: "Time" },
    {
      path: "",
      key: "toggle",
      content: (ticket) => (
        <ToggleIcon
          iconPriority={ticket.priority}
          onClick={() => this.props.onToggleIcon(ticket)}
        />
      ),
    },
    {
      path: "",
      key: "resolved",
      content: (ticket) => (
        <div
          className={this.getBadgeClasses(ticket)}
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          title="Click to mark ticket as resolved"
          style={{ cursor: "pointer" }}
          onClick={() => this.props.onStatus(ticket)}
        >
          {this.getBadgeText(ticket)}
        </div>
      ),
    },
  ];

  render() {
    const {
      filteredTickets,
      onSort,
      sortColumn,
      onDelete,
      onStatus,
      allTicketsTotal,
    } = this.props;

    let { ticketsTotals } = this.props;

    const { pageSize, currentPage } = this.state;

    const ticketsPag = paginate(filteredTickets, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={ticketsPag}
            onDelete={onDelete}
            onStatus={onStatus}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allTicketsTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPageDecrement={this.handlePageDecrement}
            onPageIncrement={this.handlePageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default TicketListTable;
