import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "./../../components//navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class stageForm extends Form {
  state = {
    data: {
      distance: "",
      elevation: "",
      endDate: "",
      endVenue: "",
      stageDescription: "",
      stageName: "",
      stageTypeId: "",
      startDate: "",
      startVenue: "",
    },
    errors: {},
    groupID: "",
    raceNameYear: "",
    raceID: "",
    raceTypes: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    distance: Joi.string().label("Distance"),
    elevation: Joi.string().label("Elevation"),
    endDate: Joi.string().label("End Date"),
    endVenue: Joi.string().label("End Venue"),
    stageDescription: Joi.string().label("Stage Description"),
    stageName: Joi.string().label("Stage Name"),
    stageTypeId: Joi.string().label("Stage Type"),
    startDate: Joi.string().label("Start Date"),
    startVenue: Joi.string().label("Start Venue"),
  };

  async componentDidMount() {
    const stageID = this.state.myParams.id;

    // get variables from the local storage
    const groupID = localStorage.getItem("groupID");
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");

    //set State with the Variables
    this.setState({ groupID, raceID, raceNameYear });

    // get data from database to display options in textfields

    const { data: retrievedRaceTypes } = await httpService.get(
      config.apiEndpointRaceTypesGet
    );
    this.setState({ raceTypes: retrievedRaceTypes });

    if (stageID === "new") return;
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    if (!stage) return this.state.myNavigate("/not-found");

    this.setState({ data: this.mapToViewModel(stage) });
  }

  // Submit a new or update
  doSubmit = async () => {
    const ID = this.state.myParams.id;
    const groupID = this.state.groupID;
    const raceID = this.state.raceID;

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      formData.append("raceID", raceID);
      formData.append("groupID", groupID);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointStageAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry

      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointStageUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate("/pageStages");
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointStageDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate("/pageStages");
  };

  mapToViewModel(stage) {
    return {
      distance: stage.distance,
      elevation: stage.elevation,
      endDate: stage.endDate,
      endVenue: stage.endVenue,
      stageDescription: stage.stageDescription,
      stageName: stage.stageName,
      stageTypeId: stage.stageType,
      startDate: stage.startDate,
      startVenue: stage.startVenue,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />

        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link to="/pageStages" className="btn p-0">
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.raceNameYear}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Stage Details</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Stage Name</td>
                      <td>{this.renderInput("stageName", "Stage Name")}</td>
                    </tr>
                    <tr>
                      <td>Stage Description</td>
                      <td>
                        {this.renderTextArea(
                          "stageDescription",
                          "Stage Description",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Stage Type</td>
                      <td>
                        {this.renderSelect(
                          "stageTypeId",
                          "description",
                          this.state.raceTypes,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Start Date</td>
                      <td>
                        {this.renderInput("startDate", "Start Date", "date")}
                      </td>
                    </tr>
                    <tr>
                      <td>End Date</td>
                      <td>{this.renderInput("endDate", "End Date", "date")}</td>
                    </tr>

                    <tr>
                      <td>Start Venue</td>
                      <td>{this.renderInput("startVenue", "Start Venue")}</td>
                    </tr>
                    <tr>
                      <td>End Venue</td>
                      <td>{this.renderInput("endVenue", "End Venue")}</td>
                    </tr>
                    <tr>
                      <td>Distance</td>
                      <td>{this.renderInput("distance", "Distance")}</td>
                    </tr>
                    <tr>
                      <td>Elevation</td>
                      <td>{this.renderInput("elevation", "Elevation")}</td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(stageForm);
