import React from "react";

class EntityUserTicketStatsRow extends React.Component {
  render() {
    const { entityUserName, entityUserSurname, ticketCount } = this.props;

    if (entityUserName == "") return;

    return (
      <tr>
        <th className="leftComponents">{entityUserName}</th>
        <th className="leftComponents">{entityUserSurname}</th>
        <th className="totals">{ticketCount}</th>
      </tr>
    );
  }
}

export default EntityUserTicketStatsRow;
