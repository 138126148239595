import React from "react";
import { FaThumbsUp } from "react-icons/fa";

class TicketCommentRow extends React.Component {
  render() {
    const { ticketComment } = this.props;
    let displayname = "";

    if (ticketComment.enitityUserId == 0) {
      displayname =
        "(JOC Rep) " +
        ticketComment.entityJocRepName +
        " " +
        ticketComment.entityJocRepSurname;
    } else {
      displayname = ticketComment.enitityUserFullname;
    }

    return (
      <div>
        <div className="card comment-row">
          <div>
            <div>
              <h6>{ticketComment.comment}</h6>
            </div>
            <div className="comment-subtext">
              <div className="comment-row-text">
                {displayname} - {ticketComment.entityName}
              </div>
              <div className="comment-time"> {ticketComment.commentTime}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TicketCommentRow;
