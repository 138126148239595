import React from "react";
import { Link } from "react-router-dom";

class RaceCard extends React.Component {
  render() {
    const {
      endDate,
      group,
      month,
      raceId,
      raceDescription,
      raceType,
      startDate,
      year,
      raceNameYear,
    } = this.props;

    return (
      <div
        className="card card-width-race card-margin-race shadow  "
        data-aos="zoom-in"
        data-aos-duration="5000"
      >
        <div className="card-body">
          <Link to={`/raceForm/${raceId}`}>
            <h5>{raceNameYear}</h5>
          </Link>

          <p className="card-text">{raceDescription}</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Group:</div>
              <div className="card-subtext">{group}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Race Type:</div>
              <div className="card-subtext">{raceType}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Start Date:</div>
              <div className="card-subtext">{startDate}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">End Date:</div>
              <div className="card-subtext">{endDate}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Month:</div>
              <div className="card-subtext">{month}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Year:</div>
              <div className="card-subtext">{year}</div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default RaceCard;
