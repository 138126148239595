import React from "react";

import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

class StageUserRoleAllocationTable extends React.Component {
  columns = [
    {
      path: "name",
      label: "Name",
      content: (stageUserRole) => (
        <Link to={`/stageUserRoleAllocationForm/${stageUserRole.id}`}>
          <h6>{stageUserRole.name}</h6>
        </Link>
      ),
    },
    { path: "surname", label: "Surname" },
    { path: "cell", label: "Cell" },
    { path: "email", label: "Email" },
    { path: "userRole", label: "Role" },
    { path: "iconName", label: "Icon" },
    { path: "vehicle", label: "Vehicle" },
    // { path: "registration", label: "registration" },
  ];

  render() {
    const {
      currentPage,
      filteredRaceUsers,
      onPageDecrement,
      onPageIncrement,
      onPageChange,
      onSort,
      sortColumn,
      allRaceUsersTotal,
      pageSize,
    } = this.props;

    const raceUsersPag = paginate(filteredRaceUsers, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={raceUsersPag}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allRaceUsersTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onPageDecrement={onPageDecrement}
            onPageIncrement={onPageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default StageUserRoleAllocationTable;
