import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";

import L from "leaflet";

import GLightbox from "glightbox";
import EntityListTable from "./entityListTable";
import { geolocationIcon } from "../icons/geolocationIcon";
import { defaultIcon } from "../icons/defaultIcon";

export const GeoMarkerEU = ({ geoLocation }) => {
  // console.log(geoLocation.id);

  // convert the timestamp
  // let dateFormat = new Date(geoLocation.timestampstage);
  let dateFormat = new Date(parseInt(geoLocation.timestampstage));
  // dateFormat = parseInt(geoLocation.timestampstage);
  let geoTime = dateFormat.getHours() + ":" + dateFormat.getMinutes();

  let geoDate = dateFormat.toDateString();
  // console.log(dateFormat);
  // Media information starts here.
  // get the information from geoLocation to populate the function
  // ==========================================================================

  if (geoLocation.latitude == "") {
    return <div></div>;
  } else {
    return (
      <div>
        <Marker
          draggable={false}
          position={[geoLocation.latitude, geoLocation.longitude]}
          icon={geolocationIcon}
        >
          <Popup closeButton={false}>
            <div className="card" style={{ width: 16 + "rem" }}>
              <div className="card-body"></div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Date: {geoDate}</li>
                <li className="list-group-item">Time: {geoTime}</li>
                <li className="list-group-item">Speed: {geoLocation.speed}</li>
                <li className="list-group-item">
                  heading: {geoLocation.heading}
                </li>
                <li className="list-group-item">
                  <a
                    href={`https://www.google.com/maps/place/${geoLocation.latitude},${geoLocation.longitude}`}
                    target="_blank"
                  >
                    Google Maps: {geoLocation.latitude} ,{" "}
                    {geoLocation.longitude}
                  </a>
                </li>
              </ul>
            </div>
          </Popup>
        </Marker>
      </div>
    );
  }
};
