import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  LayersControl,
  LayerGroup,
  MapContainer,
  Marker,
  Popup,
  Polyline,
  ScaleControl,
  Tooltip,
  useMapEvents,
} from "react-leaflet";

import { BaseMaps } from "./common/baseMaps.jsx";
import { GetGPXCoords } from "./getGPXCoords.jsx";
import { GeoMarkerEU } from "./geoMarkerEU.jsx";

// Services
import httpService from "../services/httpService.js";
import config from "../config.json";

const center = {
  lat: 18.417152,
  lng: -33.927579,
};

const defaultMarker = {
  id: "new",
  POIName: "POI Name ",
  POIDesc: "POI Description",
  POIlatitiude: "",
  POIongitude: "",
  icon: {
    id: "164",
    iconName: "Default Icon",
    iconUrl: "default-Icon.png",
    shadowUrl: "default-Icon.png",
    iconUrlResolved: "default-Icon.png",
    iconSizeW: "40",
    iconSizeH: "45",
    shadowSizeW: "35",
    shadowSizeH: "45",
    iconAnchorH: "22",
    iconAnchorV: "35",
    shadowAnchorH: "22",
    shadowAnchorV: "40",
    popupAnchorH: "-3",
    popupAnchorV: "-76",
  },
};

// Replace this with a setting the user inputs
let lineOptions = { color: "purple" };
let lineOptionsRed = { color: "green" };

const mapEntityTracker = ({
  centrePositionRet,
  mapSettingsID,
  mapURLs,
  MarkerData,
  polylineData,
  stageID,
  zoomLevel,
}) => {
  if (zoomLevel && centrePositionRet) {
    return (
      <React.Fragment>
        <MapContainer
          center={centrePositionRet}
          zoom={zoomLevel}
          scrollWheelZoom
          fullscreenControl={true}
        >
          <LayersControl position="topright">
            <BaseMaps />
          </LayersControl>

          {/* {mapURLs &&
            mapURLs.map((mapURL) => (
              // (lineOptions.color = mapURL.lineColor),
              // console.log(mapURL.lineColor),
              <Polyline
                key={mapURL.gpxName}
                pathOptions={(lineOptions = { color: mapURL.lineColor })}
                positions={GetGPXCoords(mapURL.gpxName)}
              >
                <Popup closeButton={false}>{mapURL.description}</Popup>
              </Polyline>
            ))} */}
          {/* <Polyline pathOptions={lineOptionsRed} positions={polylineData} /> */}

          {/* add geo location markers to the map */}
          {MarkerData &&
            MarkerData.map((geoLocation) => (
              // <LayersControl.Overlay checked name="Geo Markers">
              <LayerGroup>
                <GeoMarkerEU key={geoLocation.id} geoLocation={geoLocation} />
              </LayerGroup>
              // </LayersControl.Overlay>
            ))}

          <ScaleControl />
        </MapContainer>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export default mapEntityTracker;
