import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";

import { defaultIcon } from "./../icons/defaultIcon";
import L from "leaflet";

import GLightbox from "glightbox";
import EntityListTable from "./entityListTable";

export const TicketMarker = ({ ticketsByType }) => {
  // Media information starts here.
  // get the information from ticketsByType to populate the function
  // ==========================================================================

  let filetype = ticketsByType.fileType;
  let mediaURL = "https://www.tracktion.co.za/uploadedimages/";
  let fileName = ticketsByType.fileName;
  mediaURL = mediaURL + fileName;

  if (filetype == "mp4") {
    filetype = "video";
  } else {
    filetype = "image";
  }

  function GetIcon(Icon, resolved) {
    let IconUrlResolvedStatus = "";
    let IconUrlResolvedStatusShadow = "";

    if (Icon) {
      // setUp the Paths
      if (resolved) {
        IconUrlResolvedStatus = Icon.iconUrlResolved;
        IconUrlResolvedStatusShadow = Icon.shadowUrl;
      } else {
        IconUrlResolvedStatus = Icon.iconUrl;
        IconUrlResolvedStatusShadow = Icon.shadowUrl;
      }

      return L.icon({
        iconUrl: require("../images/mapicons/tickets/" + IconUrlResolvedStatus),
        iconShadowUrl: require("../images/mapicons/tickets/" +
          IconUrlResolvedStatusShadow),
        iconAnchor: [Icon.iconAnchorH * 1, Icon.iconAnchorV * 1], // point of the icon which will correspond to marker's location
        iconSize: [Icon.iconSizeW * 1, Icon.iconSizeH * 1], // size of the icon,
        popupAnchor: [Icon.popupAnchorH * 1, Icon.popupAnchorV * 1], // point from which the popup should open relative to the iconAnchor
        shadowAnchor: [Icon.shadowAnchorH * 1, Icon.shadowAnchorV * 1], // the same for the shadow
        shadowSize: [Icon.shadowSizeW * 1, Icon.shadowSizeH * 1], // size of the shadow
      });
    } else {
      return defaultIcon;
    }
  }

  const myLightbox = GLightbox({
    elements: [
      {
        href: mediaURL,
        type: filetype,
        title:
          "(" + ticketsByType.id + ") " + ticketsByType.entityTicketTypename,
        description: ticketsByType.comment,
      },
    ],
  });

  if (ticketsByType.latitude == "") {
    return <div></div>;
  } else {
    return (
      <div>
        <Marker
          draggable={true}
          position={[ticketsByType.latitude, ticketsByType.longitude]}
          icon={GetIcon(ticketsByType.icon, ticketsByType.resolved)}
        >
          <Popup closeButton={false}>
            <div className="card" style={{ width: 16 + "rem" }}>
              <div className="card-body">
                <h5 className="card-title">
                  {ticketsByType.ticketType.name} (#{ticketsByType.id})
                </h5>
                <p className="card-text">{ticketsByType.comment}</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Time: {ticketsByType.ticketTime}
                </li>
                <li className="list-group-item">
                  Participant: {ticketsByType.participantNo}
                </li>
                <li className="list-group-item">
                  Media:{" "}
                  <span className="media-color">
                    <a
                      onClick={() => myLightbox.open()}
                      style={{ cursor: "pointer" }}
                    >
                      {ticketsByType.fileType}
                    </a>
                  </span>
                </li>

                <li className="list-group-item">
                  Created By: {ticketsByType.createdByEntityUserName}{" "}
                  {ticketsByType.createdByEntityUserSurname} <br />
                  Allocated From: {ticketsByType.fromEntityname}
                </li>
                <li className="list-group-item">
                  Allocated To: {ticketsByType.allocToEntityName}
                  <br />
                  Tasked To: {ticketsByType.enitityUserName}{" "}
                  {ticketsByType.enitityUserSurname}
                </li>
                <li className="list-group-item">
                  <a
                    href={`https://www.google.com/maps/place/${ticketsByType.latitude},${ticketsByType.longitude}`}
                    target="_blank"
                  >
                    Google Maps: {ticketsByType.latitude} ,{" "}
                    {ticketsByType.longitude}
                  </a>
                </li>
              </ul>
            </div>
          </Popup>
        </Marker>
      </div>
    );
  }
};
