import React from "react";

import NavbarEntity from "../components/navbarEntity";
import EntityHero from "../components/entityHero";

class PageRaceSummary extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <NavbarEntity />
        <div className="container container-top-70">
          <div className="col">
            <div className="col">
              <div className="row">
                {/* The number of stages cards created here will be determained by the number of stages  */}
                <div className="col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Stage 1</h5>
                      <p className="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                      <a href="#" className="btn btn-primary">
                        Go to stage
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Stage 2</h5>
                      <p className="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                      <a href="#" className="btn btn-primary">
                        Go to stage
                      </a>
                    </div>
                  </div>
                </div>
                {/* End of the stages section */}
              </div>
            </div>

            {/* Start of  Entity information  The information displayed here is determained buy the entity login  and the users attached to the entity*/}
            <div className="col container-top-sm">
              <div className="card text-center">
                <div className="card-header">
                  <h5>Static Marshals</h5>
                </div>
                <div className="card-body">
                  <div className="card-text">
                    <table width="100%">
                      <tr>
                        <th>Name</th>
                        <th>Surame</th>
                        <th>Cell</th>
                        <th>Email</th>
                        <th>Role</th>
                      </tr>
                      <tr>
                        <td>John</td>
                        <td>Wilson</td>
                        <td>083 565 2575</td>
                        <td>don@developsoftware.co.za</td>
                        <td>Sector Marshal</td>
                      </tr>

                      <tr>
                        <td>John</td>
                        <td>Wilson</td>
                        <td>083 565 2575</td>
                        <td>don@developsoftware.co.za</td>
                        <td>Sector Marshal</td>
                      </tr>
                      <tr>
                        <td>John</td>
                        <td>Wilson</td>
                        <td>083 565 2575</td>
                        <td>don@developsoftware.co.za</td>
                        <td>Sector Marshal</td>
                      </tr>
                      <tr>
                        <td>John</td>
                        <td>Wilson</td>
                        <td>083 565 2575</td>
                        <td>don@developsoftware.co.za</td>
                        <td>Sector Marshal</td>
                      </tr>
                      <tr>
                        <td>John</td>
                        <td>Wilson</td>
                        <td>083 565 2575</td>
                        <td>don@developsoftware.co.za</td>
                        <td>Sector Marshal</td>
                      </tr>
                      <tr>
                        <td>John</td>
                        <td>Wilson</td>
                        <td>083 565 2575</td>
                        <td>don@developsoftware.co.za</td>
                        <td>Sector Marshal</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="card-footer text-muted">
                  Race is running for 2 Hr 45 min
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageRaceSummary;
