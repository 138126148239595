import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

//Pages
import PageEntity from "./pages/pageEntity";
import PageEntityHome from "./pages/pageEntityHome";
import PageEntityJocRep from "./pages/pageEntityJocRep";
import PageEntityMap from "./pages/pageEntityMap";
import PageEntityRaceAlloc from "./pages/pageEntityRaceAlloc";
import PageEntityRaces from "./pages/pageEntityRaces";
import PageEntityStatistics from "./pages/pageEntityStatistics";
import PageEntityStages from "./pages/pageEntityStages";
import PageEntityTicketAlloc from "./pages/pageEntityTicketAlloc";
import PageEnityUsers from "./pages/pageEnityUsers";
import PageEntityUserTicketCards from "./pages/PageEntityUserTicketCards";
import PageEntityUserTracking from "./pages/pageEntityUserTracking";
import PageEntityUserHome from "./pages/pageEntityUserHome";
import PageEntityUserPassengers from "./pages/pageEntityUserPassengers";
import PageEntityUserRoles from "./pages/pageEntityUserRoles";
import PageGeoDemo from "./pages/pageGeoDemo";
import PageGPXfiles from "./pages/pageGPXfiles";
import PageGPXfilesUpload from "./pages/pageGPXfilesUpload";
import PageGroupHome from "./pages/pageGroupHome";
import PageGroups from "./pages/pageGroups";
import PageGroupUser from "./pages/pageGroupUser";
import PageGroupRaces from "./pages/pageGroupRaces";
import PageHome from "./pages/pageHome";
import PageLogin from "./pages/pageLogin";
import PageMapOptions from "./pages/pageMapOptions";
import Pagenotfound from "./pages/pageNotFound";
import PageNotificationTypes from "./pages/pageNotificationTypes";
import PageNotificationsEntity from "./pages/pageNotificationsEntity";
import PagePOI from "./pages/pagePOI";
import PageRaceEntityHome from "./pages/pageRaceEntityHome";
import PageRaceHome from "./pages/pageRaceHome";
import PageRaceSummary from "./pages/pageRaceSummary";
import PageRaceUser from "./pages/pageRaceUser";
import PageRaceUserAllocation from "./pages/pageRaceUserAlloction";
import PageRDmap from "./pages/pageRDMap";
import PageRideAlong from "./pages/pageRideAlong";
import PageSiteAdmin from "./pages/pageSiteAdmin";
import PageSiteAdminRaces from "./pages/pageSiteAdminRaces";
import PageTicketAllocation from "./pages/pageTicketAllocation";
import PageTeamMemberHome from "./pages/pageTeamMemberHome";
import PageTestPHPData from "./pages/pageTestPHPData";
import PageTicketCards from "./pages/pageTicketCards";
import PageRDTicketCards from "./pages/PageRDTicketCards";
import PageTicketList from "./pages/pageTicketList";
import PageTicketTypes from "./pages/pageTicketTypes";
import PageStages from "./pages/pageStages";
import PageStageEntityJocRepAllocation from "./pages/pageStageEntityJocRepAllocation";
import PageStageEntityUserRoleAllocation from "./pages/pageStageEntityUserRoleAllocation";
import PageVehicles from "./pages/pageVehicles";
import PageVehiclePassenger from "./pages/pageVehiclePassenger";
import PagePassengerEntity from "./pages/pagePassengerEntity";
import PagePassengerVehicle from "./pages/pagePassengerVehicle";

import FileUpload from "./pages/FileUpload";

//Forms
import EntityDefaultIconForm from "./pages/forms/entityDefaultIconForm";
import EntityDefaultPasswordForm from "./pages/forms/entityDefaultPasswordForm";
import EntityJocDefaultPasswordForm from "./pages/forms/entityJocDefaultPasswordForm";
import EntityForm from "./pages/forms/entityForm";
import EntityRaceAllocForm from "./pages/forms/entityRaceAllocForm";
import EntityJocRepForm from "./pages/forms/entityJocRepForm";
import EntityUserForm from "./pages/forms/entityUserForm";
import EntityUserPassengerForm from "./pages/forms/entityUserPassengerForm";
import EntityUserRoleForm from "./pages/forms/entityUserRoleForm";
import EntityUserTicketForm from "./pages/forms/entityUserTicketForm";
import GpxUploadForm from "./pages/forms/gpxUploadForm";
import GpxUploadDBForm from "./pages/forms/gpxUploadDBForm";
import GroupForm from "./pages/forms/groupForm";
import GroupUserForm from "./pages/forms/groupUserForm";
import NotFound from "./pages/pageNotFound";
import NotificationTypeForm from "./pages/forms/notificationTypeForm";
import NotificationForm from "./pages/forms/notificationForm";
import NotificationEntityForm from "./pages/forms/notificationEntityForm";
import PoiForm from "./pages/forms/poiForm";
import RaceForm from "./pages/forms/raceForm";
import RaceUserForm from "./pages/forms/raceUserForm";
import RaceUserAllocationForm from "./pages/forms/raceUserAllocationForm";
import StageForm from "./pages/forms/stageForm";
import StageJocRepAllocationForm from "./pages/forms/stageJocRepAllocationForm";
import StageUserRoleAllocationForm from "./pages/forms/stageUserRoleAllocationForm";
import TicketForm from "./pages/forms/ticketForm";
import TicketTypeForm from "./pages/forms/ticketTypeForm";
import TicketTypeEntityAllocationForm from "./pages/forms/ticketTypeEntityAllocationForm";
import VehicleForm from "./pages/forms/vehicleForm";

//Vendor Libraries

import "bootstrap/dist/js/bootstrap";
import "leaflet/dist/leaflet";
import "glightbox/dist/js/glightbox";

// Css
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "../src/css/spems.css";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import "../src/css/login.css";
import "glightbox/dist/css/glightbox.css";
import "antd/dist/antd.variable.min.css";

//font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHeart,
  faExclamation,
  faExclamationCircle,
  faArrowDown,
  faArrowUp,
  faAdjust,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";

//AOS Animations
import AOS from "aos";
import "aos/dist/aos.css";

//Other
import reportWebVitals from "./reportWebVitals";

// Icons for the entire project here
// library.add(fab, faCheckSquare, faCoffee, faHeart)
library.add(
  faExclamation,
  faHeart,
  faExclamationCircle,
  faArrowDown,
  faArrowUp,
  faAdjust,
  faUserClock
);

AOS.init();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {/* Pages */}
        <Route path="/pagenotfound" element={<Pagenotfound />} />
        <Route path="/fileUpload" element={<FileUpload />} />

        <Route path="/pageLogin" element={<PageLogin />} />
        <Route path="/pageEntityHome" element={<PageEntityHome />} />
        <Route path="/pageEntityJocRep" element={<PageEntityJocRep />} />
        <Route path="/pageEntityJocRep/:id" element={<PageEntityJocRep />} />
        <Route path="/pageEntity" element={<PageEntity />} />
        <Route path="/pageEntityMap" element={<PageEntityMap />} />
        <Route path="/pageEntityMap/:id" element={<PageEntityMap />} />
        <Route path="/pageEntityRaces" element={<PageEntityRaces />} />
        <Route path="/pageEntityRaceAlloc" element={<PageEntityRaceAlloc />} />

        <Route path="/pageEntityStages" element={<PageEntityStages />} />
        <Route
          path="/pageEntityStatistics/:id"
          element={<PageEntityStatistics />}
        />
        <Route
          path="/pageEntityTicketAlloc/:id"
          element={<PageEntityTicketAlloc />}
        />
        <Route path="/pageEnityUsers" element={<PageEnityUsers />} />
        <Route path="/pageEnityUsers/:id" element={<PageEnityUsers />} />

        <Route path="/pageEntityUserHome" element={<PageEntityUserHome />} />

        <Route
          path="/pageEntityUserPassengers/:id"
          element={<PageEntityUserPassengers />}
        />
        <Route
          path="/pageEntityUserTracking"
          element={<PageEntityUserTracking />}
        />

        <Route
          path="/pageEntityRaceAlloc/:id"
          element={<PageEntityRaceAlloc />}
        />
        <Route
          path="/PageEntityUserTicketCards"
          element={<PageEntityUserTicketCards />}
        />
        <Route
          path="/pageEntityUserTicketCards/:id"
          element={<PageEntityUserTicketCards />}
        />
        <Route path="/pageEntityUserRoles" element={<PageEntityUserRoles />} />
        <Route
          path="/pageEntityUserRoles/:id"
          element={<PageEntityUserRoles />}
        />
        <Route path="/PageGeoDemo" element={<PageGeoDemo />} />
        <Route path="/pageGPXfiles/:id" element={<PageGPXfiles />} />
        <Route
          path="/pageGPXfilesUpload/:id"
          element={<PageGPXfilesUpload />}
        />
        <Route path="/pageGroup" element={<PageGroups />} />
        <Route path="/pageGroupHome" element={<PageGroupHome />} />
        <Route path="/pageGroupRaces" element={<PageGroupRaces />} />
        <Route path="/pageGroupUser" element={<PageGroupUser />} />
        <Route path="/pageHome" element={<PageHome />} />
        <Route path="/pageMapOptions/:id" element={<PageMapOptions />} />

        <Route
          path="/pageNotificationTypes"
          element={<PageNotificationTypes />}
        />

        <Route
          path="/pageNotificationsEntity/:id"
          element={<PageNotificationsEntity />}
        />
        <Route path="/pagePOI/:id" element={<PagePOI />} />

        <Route path="/pageRaceEntityHome" element={<PageRaceEntityHome />} />
        <Route
          path="/pageRaceEntityHome/:id"
          element={<PageRaceEntityHome />}
        />
        <Route path="/pageRaceHome" element={<PageRaceHome />} />
        <Route path="/pageRaceHome/:id" element={<PageRaceHome />} />
        <Route path="/pageRaceSummary" element={<PageRaceSummary />} />
        <Route path="/pageRaceUser" element={<PageRaceUser />} />
        <Route path="/pageRDMap/:id" element={<PageRDmap />} />
        <Route path="/pageRideAlong/:id" element={<PageRideAlong />} />
        <Route
          path="/pageRaceUserAllocation"
          element={<PageRaceUserAllocation />}
        />
        <Route
          path="/pageRaceUserAllocation/:id"
          element={<PageRaceUserAllocation />}
        />
        <Route path="/pageSiteAdmin" element={<PageSiteAdmin />} />
        <Route path="/pageSiteAdminRaces" element={<PageSiteAdminRaces />} />
        <Route path="/pageStages" element={<PageStages />} />
        <Route
          path="/pageStageEntityJocRepAllocation/:id"
          element={<PageStageEntityJocRepAllocation />}
        />
        <Route
          path="/pageStageEntityUserRoleAllocation/:id"
          element={<PageStageEntityUserRoleAllocation />}
        />
        <Route path="/pageRDTicketCards/:id" element={<PageRDTicketCards />} />
        <Route
          path="/pageTicketAllocation/:id"
          element={<PageTicketAllocation />}
        />
        <Route path="/pageTeamMemberHome" element={<PageTeamMemberHome />} />
        <Route path="/pageTestPHPData" element={<PageTestPHPData />} />
        <Route path="/pageTicketCards/:id" element={<PageTicketCards />} />
        <Route path="/pageTicketList/:id" element={<PageTicketList />} />
        <Route path="/pageTicketTypes" element={<PageTicketTypes />} />
        <Route path="/pageVehicles" element={<PageVehicles />} />
        <Route path="/pageVehicles/:id" element={<PageVehicles />} />
        <Route
          path="/pageVehiclePassenger/:id"
          element={<PageVehiclePassenger />}
        />
        <Route path="/pagePassengerEntity/" element={<PagePassengerEntity />} />
        <Route
          path="/pagePassengerEntity/:id"
          element={<PagePassengerEntity />}
        />
        <Route
          path="/pagePassengerVehicle/:id"
          element={<PagePassengerVehicle />}
        />

        {/* Forms */}
        <Route
          path="/entityDefaultIconForm/:id"
          element={<EntityDefaultIconForm />}
        />

        <Route
          path="/entitydefaultPasswordForm/:id"
          element={<EntityDefaultPasswordForm />}
        />
        <Route
          path="/entityJocDefaultPasswordForm/:id"
          element={<EntityJocDefaultPasswordForm />}
        />
        <Route path="/entityForm/" element={<EntityForm />} />
        <Route path="/entityForm/:id" element={<EntityForm />} />
        <Route path="/entityRaceAllocForm" element={<EntityRaceAllocForm />} />
        <Route
          path="/entityRaceAllocForm/:id"
          element={<EntityRaceAllocForm />}
        />
        <Route path="/entityJocRepForm" element={<EntityJocRepForm />} />
        <Route path="/entityJocRepForm/:id" element={<EntityJocRepForm />} />
        <Route path="/entityUserForm/" element={<EntityUserForm />} />
        <Route path="/entityUserForm/:id" element={<EntityUserForm />} />
        <Route
          path="/entityUserPassengerForm/:id"
          element={<EntityUserPassengerForm />}
        />
        <Route
          path="/entityUserRoleForm/:id"
          element={<EntityUserRoleForm />}
        />

        <Route
          path="/entityUserTicketForm/:id"
          element={<EntityUserTicketForm />}
        />
        <Route path="/gpxUploadForm/:id" element={<GpxUploadForm />} />
        <Route path="/GpxUploadDBForm/:id" element={<GpxUploadDBForm />} />

        <Route path="/groupForm" element={<GroupForm />} />
        <Route path="/groupForm/:id" element={<GroupForm />} />
        <Route path="/groupUserForm" element={<GroupUserForm />} />
        <Route path="/groupUserForm/:id" element={<GroupUserForm />} />
        <Route
          path="/notificationTypeForm/:id"
          element={<NotificationTypeForm />}
        />
        <Route path="/notificationForm/:id" element={<NotificationForm />} />
        <Route
          path="/notificationEntityForm/:id"
          element={<NotificationEntityForm />}
        />
        <Route path="/poiForm/:id" element={<PoiForm />} />
        <Route path="/raceForm" element={<RaceForm />} />
        <Route path="/raceForm/:id" element={<RaceForm />} />
        <Route path="/raceUserForm" element={<RaceUserForm />} />
        <Route path="/raceUserForm/:id" element={<RaceUserForm />} />
        <Route
          path="/raceUserAllocationForm"
          element={<RaceUserAllocationForm />}
        />
        <Route
          path="/raceUserAllocationForm/:id"
          element={<RaceUserAllocationForm />}
        />

        <Route path="/stageForm" element={<StageForm />} />
        <Route path="/stageForm/:id" element={<StageForm />} />

        <Route
          path="/stageJocRepAllocationForm/:id"
          element={<StageJocRepAllocationForm />}
        />
        <Route
          path="/stageUserRoleAllocationForm/:id"
          element={<StageUserRoleAllocationForm />}
        />
        <Route path="/ticketform" element={<TicketForm />} />
        <Route path="/ticketform/:id" element={<TicketForm />} />
        <Route path="/TicketTypeForm/:id" element={<TicketTypeForm />} />
        <Route
          path="/ticketTypeEntityAllocationForm/:id"
          element={<TicketTypeEntityAllocationForm />}
        />
        <Route path="/vehicleForm/" element={<VehicleForm />} />
        <Route path="/vehicleForm/:id" element={<VehicleForm />} />
        {/* Other */}
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/" element={<PageLogin />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
