import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { defaultIcon } from "../icons/defaultIcon";
import L from "leaflet";

function GetIcon(Icon) {
  if (Icon) {
    return L.icon({
      // setUp the Paths
      iconUrl: require("../images/mapicons/POI/" + Icon.iconUrl),
      iconShadowUrl: "",
      iconAnchor: [Icon.iconAnchorH * 1, Icon.iconAnchorV * 1], // point of the icon which will correspond to marker's location
      iconSize: [Icon.iconSizeW * 1, Icon.iconSizeH * 1], // size of the icon,
      popupAnchor: [Icon.popupAnchorH * 1, Icon.popupAnchorV * 1], // point from which the popup should open relative to the iconAnchor
      shadowAnchor: [Icon.shadowAnchorH * 1, Icon.shadowAnchorV * 1], // the same for the shadow
      shadowSize: [Icon.shadowSizeW * 1, Icon.shadowSizeH * 1], // size of the shadow
    });
  } else {
    return defaultIcon;
  }
}

export const PoiMarker = ({ poiMarker }) => {
  if (poiMarker.POIlatitiude == "") {
    return <div></div>;
  } else {
    return (
      <div>
        <Marker
          draggable={false}
          position={[poiMarker.POIlatitiude, poiMarker.POIongitude]}
          icon={GetIcon(poiMarker.icon)}
        >
          <Tooltip direction="right" offset={[30, -15]} opacity={1}>
            {poiMarker.POIName}
          </Tooltip>
          <Popup closeButton={false}>
            <div className="card" style={{ width: 16 + "rem" }}>
              <div className="card-body">
                <h5 className="card-title">{poiMarker.POIName}</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{poiMarker.POIDesc}</li>
                <li className="list-group-item">
                  <a
                    href={`https://www.google.com/maps/place/${poiMarker.POIlatitiude},${poiMarker.POIongitude}`}
                    target="_blank"
                  >
                    Google Maps:{poiMarker.POIlatitiude} ,{" "}
                    {poiMarker.POIongitude}
                  </a>
                </li>
                <li className="list-group-item"></li>
              </ul>
            </div>
          </Popup>
        </Marker>
      </div>
    );
  }
};
