import React from "react";
import { Link } from "react-router-dom";

class StageCard extends React.Component {
  render() {
    const {
      stageDescription,
      distance,
      elevation,
      endDate,
      endVenue,
      stageId,
      stageName,
      stageType,
      startDate,
      startVenue,
    } = this.props;

    return (
      <div
        className="card card-width-race-group  border-radius-4 shadow mb-3"
        data-aos="zoom-in"
        data-aos-duration="5000"
      >
        <div className="card-body border-radius-4 ">
          <Link to={`/stageForm/${stageId}`}>
            <h5>{stageName}</h5>
          </Link>

          <p className="card-text">{stageDescription}</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Stage Type:</div>
              <div className="card-subtext">{stageType}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Start Date:</div>
              <div className="card-subtext">{startDate}</div>
              <div className="card-race-heading-sm">End Date:</div>
              <div className="card-subtext">{endDate}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Start Venue:</div>
              <div className="card-subtext">{startVenue}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">End Venue:</div>
              <div className="card-subtext">{endVenue}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Distance:</div>
              <div className="card-subtext">{distance}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Elevation:</div>
              <div className="card-subtext">{elevation}</div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default StageCard;
