import React from "react";
import { Link } from "react-router-dom";

class NavbarEntity extends React.Component {
  render() {
    return (
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand " to="/">
            <h4>tracKtion</h4>
          </Link>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/pageEntityHome"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  // href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tickets
                </a>

                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      aria-current="page"
                      to="/pageTicketCards"
                    >
                      Cards
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="dropdown-item"
                      aria-current="page"
                      to="/pageTicketList"
                    >
                      List
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    );
  }
}

export default NavbarEntity;
