import React from "react";
import { EntityTicketMap } from "./entityTicketMap";
import { EntityUserMap } from "./entityUserMap";
import { VehicleMap } from "./vehicleMap";

class EntityMapAccord extends React.Component {
  state = {
    Firstname: "",
  };
  render() {
    const { entityUserData, onFlyTo, ticketMarkerData, vehicleData } =
      this.props;
    const userInfo = entityUserData.length;
    const ticketInfo = ticketMarkerData.length;
    const vehicleInfo = vehicleData.length;

    // console.log("userInfo =  " + userInfo);
    // console.log("ticketInfo = " + ticketInfo);
    // console.log("vehicleInfo = " + vehicleInfo);

    // if both tickets and markers have data
    if (!userInfo == 0 && !ticketInfo == 0) {
      return (
        <React.Fragment>
          <div className="marginTop-20"></div>
          <h6>Map Markers</h6>
          <div className="marginBottom10">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Tickets
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {ticketMarkerData &&
                      ticketMarkerData.map((ticketDataByType) => (
                        <EntityTicketMap
                          key={ticketDataByType.id}
                          ticketDataByType={ticketDataByType}
                          onFlyTo={onFlyTo}
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Members
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {entityUserData &&
                      entityUserData.map((entityUser) => (
                        <EntityUserMap
                          key={entityUser.entityUserID}
                          entityUser={entityUser}
                          onFlyTo={onFlyTo}
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Vehicles
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {vehicleData &&
                      vehicleData.map((singlevehicle) => (
                        <VehicleMap
                          key={singlevehicle.vehicleID}
                          singlevehicle={singlevehicle}
                          onFlyTo={onFlyTo}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <></>
        </React.Fragment>
      );

      //   if (!userInfo == 0 && ticketInfo == 0) {
      //     return (
      //       <React.Fragment>
      //         <div className="marginTop-20"></div>
      //         <h6>Map Markers</h6>
      //         <div className="marginBottom10">
      //           <div className="accordion" id="accordionExample">
      //             <div className="accordion-item">
      //               <h2 className="accordion-header" id="headingOne">
      //                 <button
      //                   className="accordion-button collapsed "
      //                   type="button"
      //                   data-bs-toggle="collapse"
      //                   data-bs-target="#collapseOne"
      //                   aria-expanded="false"
      //                   aria-controls="collapseOne"
      //                 >
      //                   Tickets
      //                 </button>
      //               </h2>
      //               <div
      //                 id="collapseOne"
      //                 className="accordion-collapse collapse"
      //                 aria-labelledby="headingOne"
      //                 data-bs-parent="#accordionExample"
      //               >
      //                 <div className="accordion-body">A fresh Start :-)</div>
      //               </div>
      //             </div>
      //             <div className="accordion-item">
      //               <h2 className="accordion-header" id="headingTwo">
      //                 <button
      //                   className="accordion-button collapsed"
      //                   type="button"
      //                   data-bs-toggle="collapse"
      //                   data-bs-target="#collapseTwo"
      //                   aria-expanded="false"
      //                   aria-controls="collapseTwo"
      //                 >
      //                   Members
      //                 </button>
      //               </h2>
      //               <div
      //                 id="collapseTwo"
      //                 className="accordion-collapse collapse"
      //                 aria-labelledby="headingTwo"
      //                 data-bs-parent="#accordionExample"
      //               >
      //                 <div className="accordion-body">
      //                   {entityUserData.map((entityUser) => (
      //                     <EntityUserMap
      //                       key={entityUser.entityUserID}
      //                       entityUser={entityUser}
      //                       onFlyTo={onFlyTo}
      //                     />
      //                   ))}
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </React.Fragment>
      //     );
      //   }
      //   if (userInfo == 0 && !ticketInfo == 0) {
      //     return (
      //       <React.Fragment>
      //         <div className="marginTop-20"></div>
      //         <h6>Map Markers</h6>
      //         <div className="marginBottom10">
      //           <div className="accordion" id="accordionExample">
      //             <div className="accordion-item">
      //               <h2 className="accordion-header" id="headingOne">
      //                 <button
      //                   className="accordion-button collapsed "
      //                   type="button"
      //                   data-bs-toggle="collapse"
      //                   data-bs-target="#collapseOne"
      //                   aria-expanded="false"
      //                   aria-controls="collapseOne"
      //                 >
      //                   Tickets
      //                 </button>
      //               </h2>
      //               <div
      //                 id="collapseOne"
      //                 className="accordion-collapse collapse"
      //                 aria-labelledby="headingOne"
      //                 data-bs-parent="#accordionExample"
      //               >
      //                 <div className="accordion-body">
      //                   {ticketMarkerData.map((ticketDataByType) => (
      //                     <EntityTicketMap
      //                       key={ticketDataByType.id}
      //                       ticketDataByType={ticketDataByType}
      //                       onFlyTo={onFlyTo}
      //                     />
      //                   ))}
      //                 </div>
      //               </div>
      //             </div>
      //             <div className="accordion-item">
      //               <h2 className="accordion-header" id="headingTwo">
      //                 <button
      //                   className="accordion-button collapsed"
      //                   type="button"
      //                   data-bs-toggle="collapse"
      //                   data-bs-target="#collapseTwo"
      //                   aria-expanded="false"
      //                   aria-controls="collapseTwo"
      //                 >
      //                   Members
      //                 </button>
      //               </h2>
      //               <div
      //                 id="collapseTwo"
      //                 className="accordion-collapse collapse"
      //                 aria-labelledby="headingTwo"
      //                 data-bs-parent="#accordionExample"
      //               >
      //                 <div className="accordion-body">A fresh Start :-)</div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </React.Fragment>
      //     );
      //   }
      //   if (userInfo == 0 && ticketInfo == 0) {
      //     return (
      //       <React.Fragment>
      //         <div className="marginTop-20"></div>
      //         <h6>Map Markers</h6>
      //         <div className="marginBottom10">
      //           <div className="accordion" id="accordionExample">
      //             <div className="accordion-item">
      //               <h2 className="accordion-header" id="headingOne">
      //                 <button
      //                   className="accordion-button collapsed "
      //                   type="button"
      //                   data-bs-toggle="collapse"
      //                   data-bs-target="#collapseOne"
      //                   aria-expanded="false"
      //                   aria-controls="collapseOne"
      //                 >
      //                   Tickets
      //                 </button>
      //               </h2>
      //               <div
      //                 id="collapseOne"
      //                 className="accordion-collapse collapse"
      //                 aria-labelledby="headingOne"
      //                 data-bs-parent="#accordionExample"
      //               >
      //                 <div className="accordion-body">A fresh Start :-)</div>
      //               </div>
      //             </div>
      //             <div className="accordion-item">
      //               <h2 className="accordion-header" id="headingTwo">
      //                 <button
      //                   className="accordion-button collapsed"
      //                   type="button"
      //                   data-bs-toggle="collapse"
      //                   data-bs-target="#collapseTwo"
      //                   aria-expanded="false"
      //                   aria-controls="collapseTwo"
      //                 >
      //                   Members
      //                 </button>
      //               </h2>
      //               <div
      //                 id="collapseTwo"
      //                 className="accordion-collapse collapse"
      //                 aria-labelledby="headingTwo"
      //                 data-bs-parent="#accordionExample"
      //               >
      //                 <div className="accordion-body">A fresh Start :-)</div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </React.Fragment>
      //     );
      //   }
    }
  }
}

export default EntityMapAccord;
