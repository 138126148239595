import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "../../components/navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class vehicleForm extends Form {
  state = {
    data: {
      name: "",
      passCapacity: "",
      cycleCapacity: "",
      vehicleTypeID: "",
    },
    vehicleTypes: [],

    errors: {},
    entityID: "",

    cycleCapacityData: [
      { id: 0, cycleCapacity: "0" },
      { id: 1, cycleCapacity: "1" },
      { id: 2, cycleCapacity: "2" },
      { id: 3, cycleCapacity: "3" },
      { id: 4, cycleCapacity: "4" },
      { id: 5, cycleCapacity: "5" },
      { id: 6, cycleCapacity: "6" },
      { id: 7, cycleCapacity: "7" },
      { id: 8, cycleCapacity: "8" },
      { id: 9, cycleCapacity: "9" },
      { id: 10, cycleCapacity: "10" },
      { id: 11, cycleCapacity: "11" },
      { id: 12, cycleCapacity: "12" },
      { id: 13, cycleCapacity: "13" },
      { id: 14, cycleCapacity: "14" },
      { id: 15, cycleCapacity: "15" },
      { id: 16, cycleCapacity: "16" },
      { id: 17, cycleCapacity: "17" },
      { id: 18, cycleCapacity: "18" },
      { id: 19, cycleCapacity: "19" },
      { id: 20, cycleCapacity: "20" },
      { id: 30, cycleCapacity: "30" },
      { id: 40, cycleCapacity: "40" },
      { id: 50, cycleCapacity: "50" },
      { id: 60, cycleCapacity: "60" },
      { id: 70, cycleCapacity: "70" },
      { id: 80, cycleCapacity: "80" },
    ],
    passCapacityData: [
      { id: 0, passCapacity: "0" },
      { id: 1, passCapacity: "1" },
      { id: 2, passCapacity: "2" },
      { id: 3, passCapacity: "3" },
      { id: 4, passCapacity: "4" },
      { id: 5, passCapacity: "5" },
      { id: 6, passCapacity: "6" },
      { id: 7, passCapacity: "7" },
      { id: 8, passCapacity: "8" },
      { id: 9, passCapacity: "9" },
      { id: 10, passCapacity: "10" },
      { id: 11, passCapacity: "11" },
      { id: 12, passCapacity: "12" },
      { id: 13, passCapacity: "13" },
      { id: 14, passCapacity: "14" },
      { id: 15, passCapacity: "15" },
      { id: 16, passCapacity: "16" },
      { id: 17, passCapacity: "17" },
      { id: 18, passCapacity: "18" },
      { id: 19, passCapacity: "19" },
      { id: 20, passCapacity: "20" },
      { id: 30, passCapacity: "30" },
      { id: 40, passCapacity: "40" },
      { id: 50, passCapacity: "50" },
      { id: 60, passCapacity: "60" },
      { id: 70, passCapacity: "70" },
      { id: 80, passCapacity: "80" },
    ],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    name: Joi.string().label("Vehicle Name"),
    passCapacity: Joi.string().label("Passenger Capacity"),
    cycleCapacity: Joi.string().label("Bicycle Capacity"),
    vehicleTypeID: Joi.string().label("Vehicle Type"),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;

    const entityID = localStorage.getItem("entityID");
    this.setState({ entityID });

    // Get all vehicle Types
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointVehicleTypesGet
    );
    this.setState({ vehicleTypes: Dbdata });

    // get data from database to display options in textfields
    if (ID === "new") return;
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointVehicleGet + "?id=" + ID
    );

    if (!retreivedData) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(retreivedData) });
  }

  doSubmit = async () => {
    const ID = this.state.myParams.id;
    // Get the group Id for a new entry

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // add the group ID to the form data
      formData.append("entityID", this.state.entityID);
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointVehicleAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointVehicleUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate(`/pageVehicles/${this.state.entityID}`);
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointVehicleDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/pageVehicles/${this.state.entityID}`);
  };

  mapToViewModel(data) {
    return {
      name: data.name,
      passCapacity: data.passCapacity,
      cycleCapacity: data.cycleCapacity,
      vehicleTypeID: data.vehicleTypeID,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <Link
                        to={`/pageVehicles/${this.state.entityID}`}
                        className="btn p-0"
                      >
                        <ArrowLeftCircle color="#002072" size={40} />
                      </Link>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h4>Vehicle Details</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{this.renderInput("name", "Name")}</td>
                    </tr>
                    <tr>
                      <td>Type</td>
                      <td>
                        {/* {this.renderInput("vehicleTypeID", "Type")} */}
                        {this.renderSelect(
                          "vehicleTypeID",
                          "vehicleTypeDesc",
                          this.state.vehicleTypes,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Passenger Capacity</td>
                      <td>
                        {this.renderSelect(
                          "passCapacity",
                          "passCapacity",
                          this.state.passCapacityData,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Bicycle Capacity</td>
                      <td>
                        {this.renderSelect(
                          "cycleCapacity",
                          "cycleCapacity",
                          this.state.cycleCapacityData,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(vehicleForm);
