import React from "react";

const TextArea = ({ name, label, error, rows, cols, ...rest }) => {
  return (
    <div className="textarea-width">
      <textarea
        placeholder="Enter text here..."
        id={name}
        name={name}
        rows={rows}
        cols={cols}
        {...rest}
        className="form-control input-color"
      ></textarea>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default TextArea;
