import React from "react";
import { Link } from "react-router-dom";

//Other
import {
  Calendar2Event,
  Diagram2Fill,
  DoorOpenFill,
} from "react-bootstrap-icons";

class GroupHero extends React.Component {
  render() {
    const { groupName } = this.props;
    return (
      <div className="center">
        <div className="row p-2 border-radius-4 shadow-lg row-fit container-fluid center">
          <div className=" p-3 p-lg-5 pt-lg-3">
            <h4 className="display-6 fw-bold lh-3">{groupName}</h4>

            <div className=" card px-3 mb-4  center-horizontal-components container-fluid row-fit">
              <div className="row container-fluid dashboard-bottom-margin mt-2">
                <div className="col bt-2 ">
                  <div className="button-center">
                    <Link
                      to="/pageGroupRaces"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <Calendar2Event color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Races</div>
                </div>

                <div className="col">
                  <div className="button-center">
                    <Link
                      to="/pageRaceUser"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <Diagram2Fill color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Team Members</div>
                </div>

                <div className="col">
                  <div className="button-center">
                    <Link
                      to="/pageEntity"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <Diagram2Fill color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Entity</div>
                </div>

                <div className="col">
                  <div className="button-center">
                    <Link
                      to="/"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <DoorOpenFill color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Logout</div>
                </div>
              </div>
            </div>

            {/* <div className="mb-4 mb-lg-3 ">
              <div className="row ">
                <div className="col button-center">
                  <Link
                    to="/pageGroupRaces"
                    className="btn btn-primary btn-sm px-4"
                    style={{ marginBottom: 5, marginTop: 5, width: 200 }}
                  >
                    Races
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col button-center">
                  <div>
                    <Link
                      to="/pageRaceUser"
                      className="btn  btn-primary btn-sm px-4"
                      style={{ marginBottom: 5, marginTop: 5, width: 200 }}
                    >
                      Team Members
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col button-center">
                  <div>
                    <Link
                      to="/pageEntity"
                      className="btn btn-primary btn-sm px-4"
                      style={{ marginBottom: 5, marginTop: 5, width: 200 }}
                    >
                      Entities
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default GroupHero;
