import React, { useEffect, useState } from "react";

import {
  LayersControl,
  MapContainer,
  Popup,
  Polyline,
  ScaleControl,
  useMap,
} from "react-leaflet";

import { BaseMaps } from "./common/baseMaps.jsx";
import { GetGPXCoords } from "./getGPXCoords.jsx";

// Services
import httpService from "../services/httpService.js";
import config from "../config.json";

const center = {
  lat: -5.794131,
  lng: 31.120574,
};

// Replace this with a setting the user inputs
let lineOptions = { color: "purple" };

// Post map center and zoom level to the console.

function MapBounds({ mapSettingsID, stageID }) {
  const [centerPosition, setCenterPosition] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(null);
  const map = useMap();

  useEffect(() => {
    async function getMapBounds() {
      map.on("moveend", function onDragEnd() {
        let zoomValue = map.getZoom();
        let center = map.getCenter();
        let Longitude = map.getBounds().getCenter().lng;
        let Latitiude = map.getBounds().getCenter().lat;

        // console.log(map.getMapBounds());

        setCenterPosition(center);
        setZoomLevel(zoomValue);

        // Now convert the zoom level into latitude and long delta
        // initial values
        let latitudedelta = 0.004757;
        let longitudedelta = 0.006866;

        let coef = latitudedelta / longitudedelta; // always the same no matter your zoom

        // find longitudedelta based on the found zoom
        let longitudedeltacalculated = calclongitudedelta(zoomValue);

        // find the latitudedelta with the coefficient
        let latitudedeltacalculated = longitudedeltacalculated * coef;

        function calclongitudedelta(zoom) {
          let power = Math.log2(360) - zoom;
          return Math.pow(2, power);
        }

        // now send this the database
        if (mapSettingsID == "New") {
          let formData = new FormData();

          //add the Id to the data to send to db
          formData.append("cpLat", Latitiude);
          formData.append("cpLong", Longitude);
          formData.append("zoom", zoomValue);
          formData.append("stageID", stageID);
          formData.append("latitudedelta", latitudedeltacalculated);
          formData.append("longitudedelta", longitudedeltacalculated);

          try {
            httpService.post(config.apiEndpointStageOptionsAdd, formData);
          } catch (error) {
            if (error.reponse && error.reponse.status === 404)
              console.log(error.reponse);
          }
        } else {
          let formData = new FormData();

          //add the Id to the data to send to db
          formData.append("id", mapSettingsID);
          formData.append("cpLat", Latitiude);
          formData.append("cpLong", Longitude);
          formData.append("zoom", zoomValue);
          formData.append("stageID", stageID);
          formData.append("latitudedelta", latitudedeltacalculated);
          formData.append("longitudedelta", longitudedeltacalculated);

          try {
            httpService.post(config.apiEndpointStageOptionsUpdate, formData);
          } catch (error) {
            if (error.reponse && error.reponse.status === 404)
              console.log(error.reponse);
          }
        }
      });
    }
    getMapBounds();
  }, []);

  return null;
}

const mapOptions = ({
  centrePositionRet,
  mapSettingsID,
  mapURLs,
  stageID,
  zoomLevel,
}) => {
  if (zoomLevel && centrePositionRet) {
    return (
      <React.Fragment>
        <MapContainer
          center={centrePositionRet}
          zoom={zoomLevel}
          scrollWheelZoom
          // style={{ height: "100vh" }}
          fullscreenControl={true}
        >
          <LayersControl position="topright">
            <BaseMaps />
            <MapBounds mapSettingsID={mapSettingsID} stageID={stageID} />
          </LayersControl>

          {mapURLs &&
            mapURLs.map((mapURL) => (
              // (lineOptions.color = mapURL.lineColor),
              // console.log(mapURL.lineColor),
              <Polyline
                key={mapURL.gpxName}
                pathOptions={(lineOptions = { color: mapURL.lineColor })}
                positions={GetGPXCoords(mapURL.gpxName)}
              >
                <Popup closeButton={false}>{mapURL.description}</Popup>
              </Polyline>
            ))}
          <ScaleControl />
        </MapContainer>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export default mapOptions;
