import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import EntityJocRepTable from "../components/entityJocRepTable";
import SearchBox from "../components/common/searchBox";
import withMyHook from "../components/common/withMyHook";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class PageEntityJocRep extends React.Component {
  state = {
    dataRetreived: [],
    groupName: "",
    groupID: "",
    entityID: "",
    entityName: "",
    goToPage: "",

    raceUserTypesData: [],
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const entityID = this.state.myParams.id;

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const userTypeID = localStorage.getItem("userTypeID");

    // Set the back button address for each user type
    let goToPage = "pageEntityHome";
    if (userTypeID === "4") goToPage = "pageEntityHome";
    if (userTypeID === "3") goToPage = "pageEntity";
    if (userTypeID === "2") goToPage = "pageGroupHome";

    // Set the Entity id in the local Storage
    if (entityID === "") return;
    let { data: entity } = await httpService.get(
      config.apiEndpointEntityGet + "?id=" + entityID
    );
    const retreivedEntityName = entity.entityName;

    localStorage.setItem("entityID", entityID);
    localStorage.setItem("entityName", retreivedEntityName);

    // Get data by entity ID
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointEntityJocRepsGet + "?entityID=" + entityID
    );

    this.setState({
      dataRetreived: Dbdata,
      groupName,
      groupID,
      entityID,
      entityName: retreivedEntityName,
      goToPage,
    });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      dataRetreived,
      searchQuery,
      sortColumn,
      entityName,
      groupName,
      goToPage,
    } = this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allEntriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>{entityName} - JOC Representatives</h5>
            <h6>({groupName})</h6>
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link
                  // set this back button based on user type
                  to={`/${goToPage}`}
                  className="btn p-0"
                >
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>
              </span>
              <Link to="/entityJocRepForm/new" className="btn p-0 ">
                <PlusCircle color="#002072" size={40} />
              </Link>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Joc Representatives ..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              {filtered == "No Data Retreived" ? (
                "No Data"
              ) : (
                <EntityJocRepTable
                  filtereddata={filtered}
                  onSort={this.handleSort}
                  sortColumn={sortColumn}
                  allEntriesTotal={allEntriesTotal}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageEntityJocRep);
