import React from "react";
import TicketCommentRow from "./ticketCommentRow";

class TicketComment extends React.Component {
  render() {
    const { ticketCommentsData } = this.props;

    if (ticketCommentsData.length === 0) {
      return (
        // <div className="media">
        //   <div className="media-body">No comments for the ticket.</div>
        // </div>
        <div className="card comment-body scroller">
          <div className="card comment-row">No comments for this ticket.</div>
        </div>
      );
    } else {
      return (
        <div className="card comment-body scroller">
          {ticketCommentsData.map((ticketComment) => (
            <TicketCommentRow
              key={ticketComment.id}
              ticketComment={ticketComment}
            />
          ))}
        </div>
      );
    }
  }
}

export default TicketComment;
