import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { Marker, Popup, Tooltip } from "react-leaflet";
import { defaultIcon } from "../icons/defaultIcon";
import L from "leaflet";
import { FaEdit } from "react-icons/fa";

// Services
import httpService from "../services/httpService.js";
import config from "../config.json";

function GetIcon(Icon) {
  if (Icon) {
    return L.icon({
      // setUp the Paths
      iconUrl: require("../images/mapicons/POI/" + Icon.iconUrl),
      iconShadowUrl: "",
      iconAnchor: [Icon.iconAnchorH * 1, Icon.iconAnchorV * 1], // point of the icon which will correspond to marker's location
      iconSize: [Icon.iconSizeW * 1, Icon.iconSizeH * 1], // size of the icon,
      popupAnchor: [Icon.popupAnchorH * 1, Icon.popupAnchorV * 1], // point from which the popup should open relative to the iconAnchor
      shadowAnchor: [Icon.shadowAnchorH * 1, Icon.shadowAnchorV * 1], // the same for the shadow
      shadowSize: [Icon.shadowSizeW * 1, Icon.shadowSizeH * 1], // size of the shadow
    });
  } else {
    return defaultIcon;
  }
}

export const PoiMarkerDrag = ({ poiMarker }) => {
  const center = {
    lat: poiMarker.POIongitude,
    lng: poiMarker.POIlatitiude,
  };

  const markerRef = useRef(null);
  const [position, setPosition] = useState(center);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          // go to the database and update the position of the marker by its id
          let formData = new FormData();

          //add the Id to the data to send to db
          formData.append("id", poiMarker.id);
          formData.append("POIlattitude", marker.getLatLng().lat);
          formData.append("POIlongitidue", marker.getLatLng().lng);

          try {
            httpService.post(config.apiEndpointPoiCoordsUpdate, formData);
          } catch (error) {
            if (error.reponse && error.reponse.status === 404)
              console.log(error.reponse);
          }
        }
      },
    }),
    []
  );

  if (poiMarker.POIlatitiude == "") {
    return <div></div>;
  } else {
    return (
      <div>
        <Marker
          draggable={true}
          position={[poiMarker.POIlatitiude, poiMarker.POIongitude]}
          icon={GetIcon(poiMarker.icon)}
          eventHandlers={eventHandlers}
          ref={markerRef}
        >
          <Tooltip direction="right" offset={[30, -15]} opacity={1}>
            {poiMarker.POIName}
          </Tooltip>
          <Popup closeButton={false}>
            <div className="card" style={{ width: 16 + "rem" }}>
              <div className="card-body">
                <h5 className="card-title">{poiMarker.POIName}</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{poiMarker.POIDesc}</li>

                <li className="list-group-item center">
                  <Link
                    to={`/poiForm/${poiMarker.id}`}
                    className="btn p-0"
                    style={{
                      marginBottom: 10,
                      marginTop: 10,
                      marginLeft: 10,
                    }}
                  >
                    <FaEdit color="#002072" size={35} />
                  </Link>
                </li>
              </ul>
            </div>
          </Popup>
        </Marker>
      </div>
    );
  }
};
