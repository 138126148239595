import React, { Component } from "react";

import { Link } from "react-router-dom";

//Components
import NavbarRace from "../components/navbarRace";
import withMyHook from "./../components/common/withMyHook";
import PassengerCards from "../components/PassengerCards";
import SearchBox from "../components/common/searchBox";
import CalloutbuttonsPass from "../components/calloutbuttonsPass";
import ConfirmDialog from "../components/common/confirmDialog";

// other
import { ToastContainer, Zoom, toast } from "react-toastify";
import {
  ArrowLeftCircle,
  ArrowCounterclockwise,
  Bicycle,
  PlusCircle,
} from "react-bootstrap-icons";
import { FiUser } from "react-icons/fi";
import { IoIosExit } from "react-icons/io";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class PageEntityUserPassengers extends Component {
  state = {
    callOutValue: "Pending",
    entityID: "",
    goToPage: "",
    gotToForm: "",
    groupName: "",
    groupID: "",
    stageID: "",
    raceID: "",
    raceNameYear: "",
    stage: "",
    ticketsTotals: "",

    ticketData: [],
    ticketVehicleData: [],

    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
    interval: "",

    // Pagination
    searchQueryPN: "",
    currentPage: "1",
    pageSize: 10,

    NotificationDataRetreived: [],

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    this.on();
    const stageID = this.state.myParams.id;

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    const userTypeID = localStorage.getItem("userTypeID");

    const vehicleName = localStorage.getItem("vehicleName");
    const vehicleRegistration = localStorage.getItem("vehicleRegistration");
    const vehicleID = localStorage.getItem("vehicleID");

    // Get the Stage details for display to the user
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    // Get the tickets for the vehicle and Stage
    let { data: ticketDataDB } = await httpService.get(
      config.apiEndpointPassTicketVehicleGet +
        "?stageID=" +
        stageID +
        "&" +
        "vehicleID=" +
        vehicleID
    );

    // Get the current ticket and vehicle Totals
    let { data: ticketVehicleDataDB } = await httpService.get(
      config.apiEndpointPassTicketVehicleTotalsGet +
        "?stageID=" +
        stageID +
        "&" +
        "vehicleID=" +
        vehicleID
    );

    this.setState({
      ticketData: ticketDataDB,
      ticketVehicleData: ticketVehicleDataDB,
    });

    let goToPage = "";
    if (userTypeID === "3") goToPage = `pageRaceHome/${raceID}`;
    if (userTypeID === "4") goToPage = `pageVehiclePassenger/${stageID}`;
    if (userTypeID === "5") goToPage = `pageEntityUserHome`;

    let gotToForm = "";
    if (userTypeID === "3") gotToForm = ``;
    if (userTypeID === "4") gotToForm = `entityUserPassengerForm/new`;
    if (userTypeID === "5") gotToForm = `entityUserPassengerForm/new`;

    this.setState({
      entityID,
      goToPage,
      gotToForm,
      groupName,
      groupID,
      stageID,
      raceID,
      raceNameYear,
      stage,
      vehicleName,
      vehicleRegistration,
      vehicleID,
    });

    // set the stage ID
    localStorage.setItem("stageName", stage.stageName);
    localStorage.setItem("stageID", stageID);

    this.off();

    // GPS location storage
    window.navigator.geolocation.getCurrentPosition((success) =>
      this.setState({
        myLatitude: success.coords.latitude,
        myLongitude: success.coords.longitude,
      })
    );

    if (navigator.geolocation) {
      navigator.geolocation.enableHighAccuracy = true;
      navigator.geolocation.watchPosition((position) =>
        this.setState({
          myLatitude: position.coords.latitude,
          myLongitude: position.coords.longitude,
          myAltitude: position.coords.altitude,
          myHeading: position.coords.heading,
          mySpeed: position.coords.speed,
          mySpeedKM: position.coords.speed * 3.6,
        })
      );
    }

    // 5000 = 10 seconds
    const MINUTE_MS = 5000;
    const interval = setInterval(() => {
      // this.sendData();
    }, MINUTE_MS);
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  refreshTickets = async () => {
    const stageID = localStorage.getItem("stageID");
    const vehicleID = localStorage.getItem("vehicleID");

    // Get the tickets for the entity User
    let { data: ticketDataDB } = await httpService.get(
      config.apiEndpointPassTicketVehicleGet +
        "?stageID=" +
        stageID +
        "&" +
        "vehicleID=" +
        vehicleID
    );
    this.setState({ ticketData: ticketDataDB });

    // Get the current ticket and vehicle Totals
    let { data: ticketVehicleDataDB } = await httpService.get(
      config.apiEndpointPassTicketVehicleTotalsGet +
        "?stageID=" +
        stageID +
        "&" +
        "vehicleID=" +
        vehicleID
    );

    this.setState({ ticketVehicleData: ticketVehicleDataDB });
  };

  async sendData() {
    // Get the latest Geo data
    let {
      myLatitude,
      myLongitude,
      myAltitude,
      myHeading,
      mySpeed,
      mySpeedKM,
      entityID,
      stageID,
      entityUserId,
    } = this.state;

    // Set Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLongitude", myLongitude);
    localStorage.setItem("myAltitude", myAltitude);

    // Create a numeric outut format type ES6 style
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Format the data to two decimal points
    myAltitude = formatter.format(myAltitude);
    myHeading = formatter.format(myHeading);
    mySpeed = formatter.format(mySpeed);
    mySpeedKM = formatter.format(mySpeedKM);

    //  Create time and date of geo location
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const today = new Date().toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    // set the Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);

    const time = new Date().toLocaleTimeString();

    // go to database and store the info everytime there is a location update
    let formData = new FormData();
    //add the data to formdata to send to db
    formData.append("latitude", myLatitude);
    formData.append("longitude", myLongitude);
    formData.append("altitude", myAltitude);
    formData.append("heading", myHeading);
    formData.append("speed", mySpeedKM);
    formData.append("time", time);
    formData.append("date", today);
    formData.append("timestamp", timestamp);
    formData.append("stageID", stageID);
    formData.append("entityUserId", entityUserId);
    formData.append("entityID", entityID);

    // send the data to the database via Http
    if (myLatitude != "" && myLongitude != "") {
      try {
        const { data: retrievedGeoData } = await httpService.post(
          config.apiEndpointSendEUGeoLocation,
          formData
        );

        toast.info("Sent");
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    }
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  handleSearchPN = (query) => {
    this.setState({
      searchQueryPN: query,
      selectedTicketType: "",
      currentPage: 1,
    });
  };

  handlePageReset = () => {
    this.setState({ currentPage: 1 });
  };

  handlePageIncrement = (sentCurrentPage, pagesCount) => {
    if (sentCurrentPage < pagesCount) {
      sentCurrentPage++;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageDecrement = (sentCurrentPage) => {
    if (sentCurrentPage != 1) {
      sentCurrentPage--;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleCalloutSort = (buttonPressed) => {
    this.setState({ callOutValue: buttonPressed, currentPage: 1 });
  };

  handleDischargeAll = async () => {
    // here use the passengerTicketID to marke the passenger ticket as discharged in database

    if (window.confirm("Are you sure you want to drop off all passengers")) {
      const vehicleID = localStorage.getItem("vehicleID");
      console.log("vehicleID = " + vehicleID);
      //  Create time and date of geo location
      const currentDate = new Date();
      const timestamp = currentDate.getTime();

      const today = new Date().toLocaleDateString(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const time = new Date().toLocaleTimeString();

      let formData = new FormData();
      formData.append("disTime", time);
      formData.append("disDate", today);
      formData.append("disLatitude", this.state.myLatitude);
      formData.append("disLongitude", this.state.myLongitude);
      formData.append("vehicleID", vehicleID);
      formData.append("id", 36);

      try {
        await httpService.post(
          config.apiEndpointPassTicketDropOffVehicleUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }

      this.refreshTickets();
    } else {
      console.log("Thing was not saved to the database.");
    }
  };

  render() {
    let {
      callOutValue,
      color,
      cssOverride,
      currentPage,
      goToPage,
      gotToForm,
      stage,
      loading,
      pageSize,
      searchQueryPN,
      ticketData,
      ticketsTotals,
      ticketVehicleData,
      vehicleName,
      vehicleRegistration,
      vehicleID,
    } = this.state;

    let filtered = ticketData;
    const all = ticketData.length;
    // this fails if there are no tickets
    // const resolvedAll = ticketData.filter((c) => c.completed === "1").length;
    // const pendingAll = ticketData.filter((c) => c.completed === "0").length;

    if (searchQueryPN)
      filtered = ticketData.filter((m) =>
        m.participantNo.toLowerCase().includes(searchQueryPN.toLowerCase())
      );

    // This is to filter the tickets by the call out buttons when clicked
    if (filtered) {
      if (callOutValue === "All") {
      }
      if (callOutValue === "Pending") {
        filtered = filtered.filter((c) => c.completed === "0");
      }
      if (callOutValue === "Resolved") {
        filtered = filtered.filter((c) => c.completed === "1");
      }
    }

    // Calculate the totals of different ticket states
    if (filtered) {
      const pending = filtered.filter((c) => c.completed === "0").length;
      const resolved = filtered.filter((c) => c.completed === "1").length;

      // const all = filtered.length;

      const TotalTickets =
        ticketVehicleData.totalLoad + ticketVehicleData.totalCycleComp;

      ticketsTotals = [
        TotalTickets,
        ticketVehicleData.totalLoad,
        ticketVehicleData.totalCycleComp,
      ];
    }

    return (
      <React.Fragment>
        <NavbarRace />
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div id="overlay" className="overlay" onClick={() => this.off()}>
          <div id="text" className="text">
            <PuffLoader
              color={color}
              loading={loading}
              cssOverride={cssOverride}
              size={50}
            />
            Loading Data...
          </div>
        </div>
        <div className="container container-top-70 ">
          <div className="row" data-aos="zoom-in" data-aos-duration="5000">
            <div className="row">
              <div className="button-padding ">
                <Link to={`/${goToPage}`} className="btn p-0 ">
                  <ArrowLeftCircle color="#002072" size={35} />
                </Link>
                {
                  <Link to={`/${gotToForm}`} className="btn p-0">
                    <PlusCircle color="#002072" size={35} />
                  </Link>
                }
                <Link
                  to=""
                  onClick={() => this.refreshTickets()}
                  className="btn p-0"
                >
                  <ArrowCounterclockwise color="#002072" size={35} />
                </Link>

                <span className="button-padding">
                  {
                    <Link
                      to=""
                      onClick={() => this.handleDischargeAll()}
                      className="btn p-0 "
                    >
                      <IoIosExit color="#5cb85c" size={75} />
                      Drop all
                    </Link>
                  }
                </span>

                <h5>{vehicleName}</h5>
              </div>
            </div>

            <div className="row m-0 center">
              <div className="button-margin-lr mb-2">
                <CalloutbuttonsPass
                  ticketsTotals={ticketsTotals}
                  handleCalloutSort={this.handleCalloutSort}
                />

                <SearchBox
                  value={searchQueryPN}
                  onChange={this.handleSearchPN}
                  placeholder="Search particpant no.."
                />
              </div>
              <div className="center ">
                ({ticketVehicleData.totalLoad}/
                {ticketVehicleData.passengerCapacity}) -
                {ticketVehicleData.totalLoadComp}
                <FiUser color="#858796" size="22" className="m-1 " /> - (
                {ticketVehicleData.totalCycle}/
                {ticketVehicleData.bicycleCapacity}) -
                {ticketVehicleData.totalCycleComp}
                <Bicycle color="#858796" size="25" className="m-1" />
              </div>

              <PassengerCards
                allTicketsTotal={filtered.length}
                currentPage={currentPage}
                onPageDecrement={this.handlePageDecrement}
                onPageIncrement={this.handlePageIncrement}
                onPageChange={this.handlePageChange}
                refreshTickets={this.refreshTickets}
                pageSize={pageSize}
                ticketData={filtered}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageEntityUserPassengers);
