import _ from "lodash";

export function paginate(items,pageNumber,pageSize){

    const startIndex = (pageNumber -1)*pageSize;

   return _(items).slice(startIndex).take(pageSize).value();

    //  this method slices and existing arrany from a start point
    // _.slice(items,startIndex );

    // take method takes the total number of items from the array
    // _.take();

}
