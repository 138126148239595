import React from "react";
import { Link } from "react-router-dom";

import EntityUserInfoSplashRow from "./../components/EntityUserInfoSplashRow";

class EntityUserInfoSplash extends React.Component {
  render() {
    const { entityUserDataRetreived } = this.props;

    if (entityUserDataRetreived) {
      return (
        <div className="col container-top-sm">
          <div className="card text-center">
            <div className="card-header">
              <h5>Members</h5>
            </div>
            <div className="card-body ">
              <table width="100%">
                <thead className="leftComponents card-rowHeader">
                  <tr>
                    <th>Name</th>
                    <th>Surame</th>
                    <th>Cell</th>
                  </tr>
                </thead>
                {entityUserDataRetreived.map((entityUser) => (
                  <EntityUserInfoSplashRow
                    name={entityUser.name}
                    surname={entityUser.surname}
                    key={entityUser.id}
                    cell={entityUser.cell}
                    entityUserID={entityUser.id}
                  />
                ))}
              </table>
            </div>

            <div className="card-footer text-muted"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col container-top-sm">
          <div className="card text-center">
            <div className="card-header">
              <h5>Entity Users</h5>
            </div>
            <div className="card-body">
              <table width="100%">
                <thead className="leftComponents card-rowHeader">
                  <tr>
                    <th>Name</th>
                    <th>Surame</th>
                    <th>Cell</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan={4}>
                      {" "}
                      A fresh start. :-) <br />
                      No entity users loaded yet.
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="card-footer text-muted"></div>
          </div>
        </div>
      );
    }
  }
}

export default EntityUserInfoSplash;
