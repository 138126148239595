import React, { Component } from "react";
import EntityHero from "../components/entityHero";
import NavbarRace from "./../components/navbarRace";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class PageEntityHome extends React.Component {
  state = {
    data: [],
    entityID: "",
    entityName: "",
    groupName: "",
    userName: "",
    userSurname: "",
  };

  // working here .  show only races related to entity Head etc..

  async componentDidMount() {
    const entityID = localStorage.getItem("entityID");
    const entityName = localStorage.getItem("entityName");
    const groupName = localStorage.getItem("groupName");
    const userName = localStorage.getItem("userName");
    const userSurname = localStorage.getItem("userSurname");

    // get the entity details from the database
    let { data: entity } = await httpService.get(
      config.apiEndpointEntityGet + "?id=" + entityID
    );

    this.setState({
      entityID,
      entityName,
      groupName,
      userName,
      userSurname,
      data: entity,
    });
  }
  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let { entityID, entityName, groupName, userName, userSurname, data } =
      this.state;

    return (
      <React.Fragment>
        <NavbarRace />

        <div className="container container-top-70 row-fit">
          <div className="row  row-fit center">
            <h5>
              {userName} {userSurname}
            </h5>
          </div>
          <EntityHero
            entityName={entityName}
            groupName={groupName}
            entityID={entityID}
            data={data}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default PageEntityHome;
