import React from "react";

import GLightbox from "glightbox";
import { SiOpenstreetmap } from "react-icons/si";

export const EntityTicketCardMap = ({ onFlyTo, ticketsByType }) => {
  // console.log("ticketsByType= " + ticketsByType);

  //if there is a ticket
  if (ticketsByType) {
    const coordinates = [ticketsByType.latitude, ticketsByType.longitude];

    // Media information starts here.
    // get the information from ticketsByType to populate the function
    // ==========================================================================
    let filetype = ticketsByType.fileType;
    let mediaURL = "https://www.tracktion.co.za/uploadedimages/";
    let fileName = ticketsByType.fileName;
    mediaURL = mediaURL + fileName;

    if (filetype == "mp4") {
      filetype = "video";
    } else {
      filetype = "image";
    }

    const myLightbox = GLightbox({
      elements: [
        {
          href: mediaURL,
          type: filetype,
          title:
            "(" + ticketsByType.id + ") " + ticketsByType.entityTicketTypename,
          description: ticketsByType.comment,
        },
      ],
    });

    if (ticketsByType.latitude === "") {
      return (
        <React.Fragment>
          <div className="card marginBottom-5 shadow">
            <div className="card-header">
              {ticketsByType.ticketType.name} (#{ticketsByType.id})
            </div>
            <div className="card-body">
              {/* <h5 className="card-title">{ticketsByType.ticketType.id}</h5> */}
              <p className="card-text">
                <b>{ticketsByType.comment}</b>
              </p>
              <br />
              Time:
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(
              {ticketsByType.ticketTime}) <br />
              Participant: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ticketsByType.participantNo}
              <br />
              Media:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              <span className="media-color">
                <a
                  onClick={() => myLightbox.open()}
                  style={{ cursor: "pointer" }}
                >
                  {ticketsByType.fileType}
                </a>
              </span>
              <br />
              Tasked
              To:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ticketsByType.enitityUserName} {ticketsByType.enitityUserSurname}
              <br />
              <a
                href={`https://www.google.com/maps/place/${ticketsByType.latitude},${ticketsByType.longitude}`}
                target="_blank"
              >
                Google Maps:&nbsp;&nbsp;&nbsp;&nbsp;{ticketsByType.latitude} ,{" "}
                {ticketsByType.longitude}
              </a>
              <br />
              Created By:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ticketsByType.createdByEntityUserName}{" "}
              {ticketsByType.createdByEntityUserSurname}
              <br />
              <br />
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="card marginBottom-5 shadow">
            <div className="card-header">
              {ticketsByType.ticketType.name} (#{ticketsByType.id})
            </div>
            <div className="card-body">
              {/* <h5 className="card-title">{ticketsByType.ticketType.id}</h5> */}
              <p className="card-text">
                <b>{ticketsByType.comment}</b>
              </p>
              <br />
              Time:
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(
              {ticketsByType.ticketTime}) <br />
              Participant: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ticketsByType.participantNo}
              <br />
              Media:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              <span className="media-color">
                <a
                  onClick={() => myLightbox.open()}
                  style={{ cursor: "pointer" }}
                >
                  {ticketsByType.fileType}
                </a>
              </span>
              <br />
              Tasked
              To:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ticketsByType.enitityUserName} {ticketsByType.enitityUserSurname}
              <br />
              <a
                href={`https://www.google.com/maps/place/${ticketsByType.latitude},${ticketsByType.longitude}`}
                target="_blank"
              >
                Google Maps:&nbsp;&nbsp;&nbsp;&nbsp;{ticketsByType.latitude} ,{" "}
                {ticketsByType.longitude}
              </a>
              <br />
              Created By:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ticketsByType.createdByEntityUserName}{" "}
              {ticketsByType.createdByEntityUserSurname}
              <br />
              <br />
              <div className=" button-align-end">
                <button
                  className="btn btn-secondry-green button-color-dashboard"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasScrolling"
                  aria-controls="offcanvasScrolling"
                  onClick={() => onFlyTo(coordinates)}
                >
                  <SiOpenstreetmap color="white" size={20} />
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  } else {
    return <div></div>;
  }
};
