import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import StageJocRepAllocationTable from "../components/stageJocRepAllocationTable";
import SearchBox from "../components/common/searchBox";
import withMyHook from "./../components/common/withMyHook";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class PageStageEntityJocRepAllocation extends React.Component {
  state = {
    dataRetreived: [],
    groupName: "",
    groupID: "",
    stageID: "",
    raceID: "",
    raceNameYear: "",
    raceUserTypesData: [],
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
    stage: "",

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const stageID = this.state.myParams.id;

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    this.setState({ groupName, groupID, stageID, raceID, raceNameYear });

    // get the Stage details for display to the user
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );
    this.setState({ stage });

    // set the stage ID
    localStorage.setItem("stageName", stage.stageName);
    localStorage.setItem("stageID", stageID);

    // get the already allocated stage/Joc Rep/Role allocations
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStageJocRepAllocations +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );
    this.setState({ dataRetreived: Dbdata });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      dataRetreived,
      searchQuery,
      sortColumn,
      raceNameYear,
      stage,
      raceID,
    } = this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const allRaceUsersTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>
              {raceNameYear} - {stage.stageName} - Joc Representative Allocation
            </h5>
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link to={`/pageRaceEntityHome/${raceID}`} className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>
              </span>
              <Link to="/stageJocRepAllocationForm/new" className="btn p-0">
                <PlusCircle color="#002072" size={40} />
              </Link>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Names..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <StageJocRepAllocationTable
                filteredRaceUsers={filtered}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                allRaceUsersTotal={allRaceUsersTotal}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageStageEntityJocRepAllocation);
