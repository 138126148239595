import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { defaultIcon } from "../icons/defaultIcon";
import L from "leaflet";

import { FiUser } from "react-icons/fi";
import { Bicycle, Whatsapp } from "react-bootstrap-icons";

function GetIcon(Icon) {
  if (Icon) {
    return L.icon({
      // set up the paths
      iconUrl: require("../images/mapicons/entityIcons/" + Icon.iconUrl),
      iconShadowUrl: require("../images/mapicons/entityIcons/" +
        Icon.shadowUrl),
      iconAnchor: [Icon.iconAnchorH * 1, Icon.iconAnchorV * 1], // point of the icon which will correspond to marker's location
      iconSize: [Icon.iconSizeW * 1, Icon.iconSizeH * 1], // size of the icon,
      popupAnchor: [Icon.popupAnchorH * 1, Icon.popupAnchorV * 1], // point from which the popup should open relative to the iconAnchor
      shadowAnchor: [Icon.shadowAnchorH * 1, Icon.shadowAnchorV * 1], // the same for the shadow
      shadowSize: [Icon.shadowSizeW * 1, Icon.shadowSizeH * 1], // size of the shadow
    });
  } else {
    return defaultIcon;
  }
}

export const EntityVehicleMarker = ({ singleVehicle }) => {
  // let cell = singleVehicle.entityUserscell;

  // let cellAmmended = cell.substring(1, 10);

  if (singleVehicle.latitude == "") {
    return <div></div>;
  } else {
    return (
      // This is the popup if the entity has been allocated a vehicle
      <div>
        <Marker
          draggable={true}
          position={[singleVehicle.latitude, singleVehicle.longitude]}
          icon={GetIcon(singleVehicle.entityUsers[0].icon)}
        >
          <Tooltip direction="top" offset={[0, -50]} opacity={0.7} permanent>
            {singleVehicle.vehicleName}
          </Tooltip>
          <Popup closeButton={false}>
            <div className="card" style={{ width: 16 + "rem" }}>
              <div className="card-body">
                <h5 className="card-title">{singleVehicle.vehicleName}</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <div className="center">
                    <div className="mx-2">
                      <span className="mx-2">
                        <FiUser color="#858796" size="25" />
                      </span>
                      {singleVehicle.totalLoad}
                      {"/"}
                      {singleVehicle.passengerCapacity}
                    </div>
                    <div className="mx-2">
                      <span className="mx-2">
                        <Bicycle color="#858796" size="30" />
                      </span>
                      {singleVehicle.totalCycle}
                      {"/"}
                      {singleVehicle.bicycleCapacity}
                    </div>
                  </div>
                </li>

                {singleVehicle.entityUsers.map((entityUserAllocated) => (
                  <li className="list-group-item " key={entityUserAllocated.id}>
                    <div className="row">
                      <div className="details-align">
                        <div className="details-icon">
                          <Whatsapp color="#128c7e" size={20} />
                        </div>
                        <div>
                          <a
                            href={`https://wa.me/+27${entityUserAllocated.cellInt}?text=`}
                            target="_blank"
                          >
                            {entityUserAllocated.name}{" "}
                            {entityUserAllocated.surname}
                            {" - "}
                            {entityUserAllocated.userRole}
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}

                <li className="list-group-item">
                  GPS Clocking:
                  <br />
                  Whom:<b>{singleVehicle.geoName}</b>
                  <br />
                  Time:<b>{singleVehicle.time}</b>
                  <br />
                  Date:<b>{singleVehicle.date}</b> <br />
                  <a
                    href={`https://www.google.com/maps/place/${singleVehicle.latitude},${singleVehicle.longitude}`}
                    target="_blank"
                  >
                    Google Maps: {singleVehicle.latitude} ,{" "}
                    {singleVehicle.longitude}
                  </a>
                </li>
                <li className="list-group-item"></li>
              </ul>
            </div>
          </Popup>
        </Marker>
      </div>
    );
  }
};
