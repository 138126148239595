import React from "react";
import { EntityTicketCardMap } from "./entityTicketCardMap";

export const EntityTicketMap = ({ onFlyTo, ticketDataByType }) => {
  if (ticketDataByType.tickets) {
    return (
      <React.Fragment>
        <div className="accordion " id={`EntityTickets${ticketDataByType.id}`}>
          <div className="accordion-item">
            <h2 className="accordion-header" id={ticketDataByType.id}>
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapseOne${ticketDataByType.id}`}
                aria-expanded="false"
                aria-controls={`collapseOne${ticketDataByType.id}`}
              >
                {ticketDataByType.name} ({ticketDataByType.tickets.length})
              </button>
            </h2>
            <div
              id={`collapseOne${ticketDataByType.id}`}
              className="accordion-collapse collapse"
              aria-labelledby={ticketDataByType.id}
              data-bs-parent={`#EntityTickets${ticketDataByType.id}`}
            >
              <div className="accordion-body">
                {/* map tickets here */}
                {ticketDataByType.tickets.map((ticketsByType) => (
                  <EntityTicketCardMap
                    key={ticketsByType.id}
                    ticketsByType={ticketsByType}
                    onFlyTo={onFlyTo}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
};
