import React from "react";

class ListGroup extends React.Component {
  render() {
    const { items, textProperty, valueProperty, onItemSelect, selectItem } =
      this.props;

    if (items.length == 0) {
      return (
        <ul className="list-group">
          <li>No Data</li>
        </ul>
      );
    }

    return (
      <React.Fragment>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Ticket Type Filters
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-group">
                  {items.map((item) => (
                    <li
                      onClick={() => onItemSelect(item)}
                      key={item[valueProperty]}
                      className={
                        item === selectItem
                          ? "list-group-item  active clickable list-style "
                          : "list-group-item clickable list-style"
                      }
                    >
                      {item[textProperty]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ListGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "id",
};

export default ListGroup;
