import React from "react";
import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

class NotificationTypeTable extends React.Component {
  state = {};

  columns = [
    {
      path: "name",
      label: "Name",
      content: (notificationType) => (
        <Link to={`/notificationTypeForm/${notificationType.id}`}>
          <h6>{notificationType.name}</h6>
        </Link>
      ),
    },
    { path: "description", label: "Description" },
    { path: "lineColor", label: "Line Color" },
    { path: "priority", label: "Order" },
  ];

  render() {
    const {
      allentriesTotal,
      currentPage,
      filteredRaceUsers,
      onDelete,
      onSort,
      pageSize,
      sortColumn,
      onPageDecrement,
      onPageIncrement,
      onPageChange,
    } = this.props;

    const raceUsersPag = paginate(filteredRaceUsers, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={raceUsersPag}
            onDelete={onDelete}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allentriesTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onPageDecrement={onPageDecrement}
            onPageIncrement={onPageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default NotificationTypeTable;
