import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "../../components/navbarGroup";

// Services
import httpService from "../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class EntityDefaultIconForm extends Form {
  state = {
    data: {
      defaultIconID: "",
    },
    entityID: "",
    existingID: "",
    newDataEntry: "",
    raceID: "",
    raceNameYear: "",
    stageID: "",
    stageName: "",

    errors: {},
    dataDB: [],
    icons: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    defaultIconID: Joi.string().label("Default Icon"),
  };

  async componentDidMount() {
    // get local storage
    const entityID = localStorage.getItem("entityID");
    const entityName = localStorage.getItem("entityName");

    // Set the State
    this.setState({
      entityID,
      entityName,
    });

    // Get all entity icons
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointIconsGetByType + "?typeID=3"
    );
    this.setState({ icons: Dbdata });

    // now check to see if one already exists in the allocation table and load this if true

    let { data: retreivedData } = await httpService.get(
      config.apiEndpointIconsEntityIconAllocGet + "?entityID=" + entityID
    );

    // if an Entry exists then set the id or set the id to new
    if (!retreivedData) {
      this.state.newDataEntry = "new";
    } else {
      this.state.newDataEntry = retreivedData.id;
      this.setState({ data: this.mapToViewModel(retreivedData) });
    }
  }

  doSubmit = async () => {
    // Determine if the entry is new
    const ID = this.state.newDataEntry;

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // add the group ID to the form data
      formData.append("entityID", this.state.entityID);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointIconsEntityIconAllocAdd,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointIconsEntityIconAllocUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate(`/pageEntityHome/`);
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.newDataEntry;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointIconsEntityIconAllocDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/pageEntityHome/`);
  };

  mapToViewModel(data) {
    return {
      defaultIconID: data.entityIconID,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link to={`/pageEntityHome`} className="btn p-0">
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.entityName}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Default Map Icon</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Map Icon</td>
                      <td>
                        {this.renderSelect(
                          "defaultIconID",
                          "iconName",
                          this.state.icons,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding ">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(EntityDefaultIconForm);
