import React from "react";
import { Link } from "react-router-dom";

//Components
import NavbarRace from "../components/navbarRace";
import RaceHero from "../components/raceHero";
import withMyHook from "./../components/common/withMyHook";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class PageRaceHome extends React.Component {
  state = {
    groupID: "1",
    groupName: "",
    raceName: "",
    raceID: "",
    raceNameYear: "",
    stagedataRetreived: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const raceIdRetreived = this.state.myParams.id;
    const groupNameRetreived = localStorage.getItem("groupName");
    const groupIDRetreived = localStorage.getItem("groupID");

    if (raceIdRetreived === "") return;
    let { data: race } = await httpService.get(
      config.apiEndpointRaceGet + "?id=" + raceIdRetreived
    );

    // Get the stages details based on the Race id
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStagesGetByRaceID + "?raceID=" + raceIdRetreived
    );

    localStorage.setItem("raceID", raceIdRetreived);
    localStorage.setItem("raceNameYear", race.raceNameYear);
    localStorage.setItem("raceName", race.raceName);

    this.setState({
      groupName: groupNameRetreived,
      groupID: groupIDRetreived,
      raceName: race.raceName,
      raceNameYear: race.raceNameYear,
      raceID: raceIdRetreived,
      raceDescription: race.raceDescription,
      stagedataRetreived: Dbdata,
    });
  }

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const {
      groupName,
      raceNameYear,
      raceDescription,
      raceID,
      stagedataRetreived,
    } = this.state;
    return (
      <React.Fragment>
        <NavbarRace />

        <div className="container container-top-70 ">
          <div className="row">
            <span className="button-padding">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("Back to select race")}
              >
                <Link to="/pageGroupRaces" className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>
              </OverlayTrigger>
            </span>

            <div data-aos="fade-right">
              <h5>{groupName}</h5>

              <RaceHero
                raceNameYear={raceNameYear}
                raceDescription={raceDescription}
                raceID={raceID}
                stagedataRetreived={stagedataRetreived}
              />
            </div>
            <div className="searchBar-width-300" data-aos="fade-left"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageRaceHome);
