import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { defaultIcon } from "../icons/defaultIcon";
import L from "leaflet";

import { FiUser } from "react-icons/fi";
import { Bicycle, Whatsapp } from "react-bootstrap-icons";

function GetIcon(Icon) {
  if (Icon) {
    return L.icon({
      // set up the paths
      iconUrl: require("../images/mapicons/entityIcons/" + Icon.iconUrl),
      iconShadowUrl: require("../images/mapicons/entityIcons/" +
        Icon.shadowUrl),
      iconAnchor: [Icon.iconAnchorH * 1, Icon.iconAnchorV * 1], // point of the icon which will correspond to marker's location
      iconSize: [Icon.iconSizeW * 1, Icon.iconSizeH * 1], // size of the icon,
      popupAnchor: [Icon.popupAnchorH * 1, Icon.popupAnchorV * 1], // point from which the popup should open relative to the iconAnchor
      shadowAnchor: [Icon.shadowAnchorH * 1, Icon.shadowAnchorV * 1], // the same for the shadow
      shadowSize: [Icon.shadowSizeW * 1, Icon.shadowSizeH * 1], // size of the shadow
    });
  } else {
    return defaultIcon;
  }
}

export const EntityUserMarker = ({ entityUser }) => {
  let cell = entityUser.cell;

  let cellAmmended = cell.substring(1, 10);

  if (entityUser.latitude == "") {
    return <div></div>;
  } else {
    // if (entityUser.vehicleID == "") {
    return (
      // This is the popup if the entity has no vehicle allocated to the user
      <div>
        <Marker
          draggable={true}
          position={[entityUser.latitude, entityUser.longitude]}
          icon={GetIcon(entityUser.icon)}
        >
          <Tooltip direction="top" offset={[0, -50]} opacity={0.7} permanent>
            {entityUser.fullName} ({entityUser.userRole})
          </Tooltip>
          <Popup closeButton={false}>
            <div className="card" style={{ width: 16 + "rem" }}>
              <div className="card-body">
                <h5 className="card-title">{entityUser.fullName}</h5>
                <p className="card-text">Role:{entityUser.userRole}</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Cell Number: {entityUser.cell}
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="details-align">
                      <div className="details-icon">
                        <Whatsapp color="#128c7e" size={20} />
                      </div>
                      <div>
                        <a
                          href={`https://wa.me/+27${cellAmmended}?text=`}
                          target="_blank"
                        >
                          WhatsApp
                        </a>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  GPS Clocking:
                  <br />
                  Time:<b>{entityUser.time}</b>
                  <br />
                  Date:<b>{entityUser.date}</b> <br />
                  <a
                    href={`https://www.google.com/maps/place/${entityUser.latitude},${entityUser.longitude}`}
                    target="_blank"
                  >
                    Google Maps: {entityUser.latitude} , {entityUser.longitude}
                  </a>
                </li>
                <li className="list-group-item"></li>
              </ul>
            </div>
          </Popup>
        </Marker>
      </div>
    );
    // } else {
    //   return (
    //     // This is the popup if the entity has been allocated a vehicle
    //     <div>
    //       <Marker
    //         draggable={true}
    //         position={[entityUser.latitude, entityUser.longitude]}
    //         icon={GetIcon(entityUser.icon)}
    //       >
    //         <Tooltip direction="top" offset={[0, -50]} opacity={0.7} permanent>
    //           {entityUser.vehicleDetails.vehicleName}
    //           {/* TODO here i need to map the en */}
    //           {/* ({entityUser.fullName}) */}
    //         </Tooltip>
    //         <Popup closeButton={false}>
    //           <div className="card" style={{ width: 16 + "rem" }}>
    //             <div className="card-body">
    //               <h5 className="card-title">{entityUser.fullName}</h5>
    //               <p className="card-text">Role:{entityUser.userRole}</p>
    //             </div>
    //             <ul className="list-group list-group-flush">
    //               <li className="list-group-item">
    //                 Cell Number: {entityUser.cell}
    //               </li>
    //               <li className="list-group-item">
    //                 {entityUser.vehicleID != 0 && (
    //                   <div className="center">
    //                     <div className="mx-2">
    //                       <span className="mx-2">
    //                         <FiUser color="#858796" size="25" />
    //                       </span>
    //                       {entityUser.vehicleDetails.totalLoad}
    //                       {"/"}
    //                       {entityUser.vehicleDetails.passengerCapacity}
    //                     </div>
    //                     <div className="mx-2">
    //                       <span className="mx-2">
    //                         <Bicycle color="#858796" size="30" />
    //                       </span>
    //                       {entityUser.vehicleDetails.totalCycle}
    //                       {"/"}
    //                       {entityUser.vehicleDetails.bicycleCapacity}
    //                     </div>
    //                   </div>
    //                 )}
    //               </li>
    //               <li className="list-group-item">
    //                 <div className="row">
    //                   <div className="details-align">
    //                     <div className="details-icon">
    //                       <Whatsapp color="#128c7e" size={20} />
    //                     </div>
    //                     <div>
    //                       <a
    //                         href={`https://wa.me/+27${cellAmmended}?text=`}
    //                         target="_blank"
    //                       >
    //                         WhatsApp
    //                       </a>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </li>

    //               <li className="list-group-item">
    //                 GPS Clocking:
    //                 <br />
    //                 Time:<b>{entityUser.time}</b>
    //                 <br />
    //                 Date:<b>{entityUser.date}</b> <br />
    //                 <a
    //                   href={`https://www.google.com/maps/place/${entityUser.latitude},${entityUser.longitude}`}
    //                   target="_blank"
    //                 >
    //                   Google Maps: {entityUser.latitude} ,{" "}
    //                   {entityUser.longitude}
    //                 </a>
    //               </li>
    //               <li className="list-group-item"></li>
    //             </ul>
    //           </div>
    //         </Popup>
    //       </Marker>
    //     </div>
    //   );
    // }
  }
};
