import L from "leaflet";
import iconUrl from "../images/dot-icon.png";
import iconShadowUrl from "../images/dot-icon.png";

// console.log(L.Marker.prototype.options.icon.options);

//Get the origional icon varaibles
let { shadowSize, popupAnchor, tooltipAnchor } =
  L.Marker.prototype.options.icon.options;

let iconSize = [5, 5];
let iconAnchor = [0, 0];

// Pass them to the new Icon we created
export const geolocationIcon = L.icon({
  iconUrl,
  iconShadowUrl,
  iconSize,
  shadowSize,
  iconAnchor,
  popupAnchor,
  tooltipAnchor,
});
