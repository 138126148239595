import React from "react";
import { Link } from "react-router-dom";

//Own Components
import NavbarEntity from "../components/navbarEntity";
import CalloutButtons from "../components/calloutbuttons";
import TicketListTable from "../components/ticketListTable";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

//Components
import withMyHook from "./../components/common/withMyHook";

//Utilities
import _ from "lodash";

class PageTicketList extends React.Component {
  state = {
    all: 0,
    entityID: "",
    entityName: "",
    ticketData: [],
    ticketTypesData: [],
    selectedTicketType: "",
    ticketsTotals: [],
    raceID: "",
    raceNameYear: "",
    stage: [],
    stageID: "",
    sortColumn: { path: "id", order: "desc" },

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const stageID = this.state.myParams.id;
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const entityID = localStorage.getItem("entityID");
    const entityName = localStorage.getItem("entityName");

    // Get the Stage information
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    //set the stage details
    localStorage.setItem("stageID", stageID);
    localStorage.setItem("stageName", stage.stageName);
    const stageName = stage.stageName;

    // Get the Ticket Types
    let { data: ticketTypesDB } = await httpService.get(
      config.apiEndpointTicketTypesGet + "?raceID=" + raceID
    );

    // Get the ticket for the Entity and stage
    // Also get tickets that are allocated to this entity from a different entity

    let { data: ticketDataDB } = await httpService.get(
      config.apiEndpointEntityStageTicketsGet +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );

    this.setState({
      entityID,
      entityName,
      ticketData: ticketDataDB,
      ticketTypesData: [{ _id: "", name: "All Tickets" }, ticketTypesDB],
      raceID,
      raceNameYear,
      stageID,
      stage,
      stageName,
    });
  }

  handleDelete = (ticketId) => {
    let ticketData = this.state.ticketData.filter((c) => c.id !== ticketId);
    this.setState({ ticketData: ticketData });
  };

  handleStatus = (ticket) => {
    const ticketData = [...this.state.ticketData];
    const index = ticketData.indexOf(ticket);
    ticketData[index] = { ...ticket };
    ticketData[index].resolved = !ticketData[index].resolved;
    //Set the priority value so that a resolved card is not a priority
    if (ticketData[index].resolved) {
      ticketData[index].priority = false;
    }
    this.setState({ ticketData: ticketData });
  };

  handleToggleIcon = (ticket) => {
    const ticketData = [...this.state.ticketData];
    const index = ticketData.indexOf(ticket);
    ticketData[index] = { ...ticket };
    ticketData[index].priority = !ticketData[index].priority;

    this.setState({ ticketData: ticketData });
  };

  handleGenreSelect = (ticketType) => {
    this.setState({ selectedTicketType: ticketType, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      all,
      entityName,
      raceID,
      raceNameYear,
      selectedTicketType,
      sortColumn,
      stage,
      stageID,
      ticketData,
      ticketsTotals,
    } = this.state;

    if (ticketData) {
      // calculate the totals of different ticket states
      const pending = ticketData.filter((c) => c.resolved === false).length;
      const resolved = ticketData.filter((c) => c.resolved === true).length;
      const priority = ticketData.filter((c) => c.priority === true).length;
      const all = ticketData.length;
      ticketsTotals = [all, priority, pending, resolved];
    }

    // This will filter the ticket list by Type and then pass it on to the Tickets class
    const filteredTickets =
      selectedTicketType && selectedTicketType._id
        ? ticketData.filter((m) => m.ticketType._id === selectedTicketType._id)
        : ticketData;

    const sorted = _.orderBy(
      filteredTickets,
      [sortColumn.path],
      [sortColumn.order]
    );

    return (
      <React.Fragment>
        <NavbarEntity />
        <div className="container container-top-60">
          <div className="row">
            <div className="button-padding ">
              <Link
                to={`/pageRaceEntityHome/${raceID}`}
                className="btn btn-outline-primary btn-sm px-3"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                Back
              </Link>
              <Link
                to={`/pageTicketCards/${stageID}`}
                className="btn btn-primary btn-sm px-3"
                style={{ marginLeft: 5 }}
              >
                Switch to Cards
              </Link>
              <Link
                to="/TicketForm/new"
                className="btn btn-primary btn-sm px-3"
                style={{ marginLeft: 5 }}
              >
                Create Ticket
              </Link>
              <h5>
                {entityName} - {raceNameYear} -{stage.stageName}
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="callout-buttons-padding">
                <CalloutButtons ticketsTotals={ticketsTotals} />
              </div>
              <TicketListTable
                onDelete={this.handleDelete}
                filteredTickets={sorted}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                onToggleIcon={this.handleToggleIcon}
                onStatus={this.handleStatus}
                allTicketsTotal={all}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageTicketList);
