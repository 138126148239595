import React from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import { PoiMarkerDrag } from "./poiMarkerDrag";

export const PoiLayerDrag = ({ POIMarkerData }) => {
  // if there is no entity User data
  if (!POIMarkerData) {
    return <div></div>;
  } else {
    return (
      <LayersControl.Overlay checked name="Global POI's">
        <LayerGroup>
          {/* Now go to each ticket type and create the layers that need to show on the map */}
          {POIMarkerData.map((poiMarker) => (
            <PoiMarkerDrag key={poiMarker.id} poiMarker={poiMarker} />
          ))}
        </LayerGroup>
      </LayersControl.Overlay>
    );
  }
};
