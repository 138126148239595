import React, { Component } from "react";

import { Link } from "react-router-dom";

// Services
import httpService from "../services/httpService";
import config from "../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//  Icons

import {
  PersonSquare,
  Bicycle,
  Clock,
  ClockFill,
  GeoAltFill,
  GeoAlt,
  PersonVcardFill,
  QuestionLg,
  Whatsapp,
} from "react-bootstrap-icons";

import { FaEdit } from "react-icons/fa";
import { TbBus } from "react-icons/tb";
// IoMdExit
import { IoIosExit } from "react-icons/io";
import { BsChatSquareDots, BsListUl } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { GiCarWheel, GiSteeringWheel } from "react-icons/gi";

class VehicleCard extends Component {
  state = {
    ticketIdLocal: this.props.ticket.vehicleID,

    gotToForm: "",
    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",

    warningColor: "",
  };

  async componentDidMount() {
    const userTypeID = localStorage.getItem("userTypeID");
    const stageID = localStorage.getItem("stageID");

    let str1 = "abcClean";
    let accordId = str1.concat(this.state.ticketIdLocal);

    let gotToForm = "";
    if (userTypeID === "3") gotToForm = ``;
    if (userTypeID === "4") gotToForm = `PageEntityUserPassengers/${stageID}`;
    if (userTypeID === "5") gotToForm = `PageEntityUserPassengers/${stageID}`;

    this.setState({ gotToForm });

    // set the colors based on the capacity and load for each vehicle
  }

  handleRefreshTickets() {
    // this.props.refreshTickets();
  }

  createIDHash() {
    let str1 = "#abc";
    let accordIdHash = str1.concat(this.state.ticketIdLocal);

    return accordIdHash;
  }
  createID() {
    let str1 = "abc";
    let accordId = str1.concat(this.state.ticketIdLocal);

    return accordId;
  }

  //        "bicycleCapacity": "12",
  //       "bicycleCapacityPerc": "33",
  //       "entityUserId": "161",
  //       "entityUserCell": "0835652575",
  //       "entityUserName": "Donavan",
  //       "entityUserSurame": "Daniels",
  //       "entityUserRoleId": "104",
  //       "name": "Sweep Vehicle 2",
  //       "passengerCapacity": "12",
  //       "registration": "CA654321",
  //       "totalCycle": 4,
  //       "totalLoad": 6,
  //       "totalLoadPerc": "50",
  //       "totalCycleComp": 14,
  //       "totalLoadComp": 14,
  //       "vehicleID": "2",
  //       "vehicleTypeID": "1",
  //       "vehicleType": "Minivan"

  render() {
    const { refreshTickets, ticket } = this.props;
    const { ticketIdLocal } = this.state;

    // set the colors for each depending on the percentage

    const bicycleCapacityPerc = ticket.bicycleCapacityPerc;
    const totalLoadPerc = ticket.totalLoadPerc;

    let wheelColor = "#858796";
    let passColor = "m-1";
    let bicycleColor = "m-1";

    if (bicycleCapacityPerc > 0) {
      bicycleColor = "card-vehicle-text-green m-1";
    }
    if (bicycleCapacityPerc >= 50) {
      bicycleColor = "card-vehicle-text-yellow m-1";
    }
    if (bicycleCapacityPerc >= 80) {
      bicycleColor = "card-vehicle-text-red m-1";
    }

    if (totalLoadPerc > 0) {
      passColor = "card-vehicle-text-green m-1";
    }
    if (totalLoadPerc >= 50) {
      passColor = "card-vehicle-text-yellow m-1";
    }
    if (totalLoadPerc >= 80) {
      passColor = "card-vehicle-text-red m-1";
    }

    if (bicycleCapacityPerc > 0 || totalLoadPerc > 0) {
      wheelColor = "#128c7e";
    }
    if (bicycleCapacityPerc >= 50 || totalLoadPerc >= 50) {
      wheelColor = "#f6c23e";
    }
    if (bicycleCapacityPerc >= 80 || totalLoadPerc >= 80) {
      wheelColor = "#e74a3b";
    }

    // normal grey    = #858796 no activity
    // green          = #128c7e some activity
    // warning yellow = #f6d43e 50% capacity
    // red danger     = #e74a3b 90% capacity

    const { gotToForm } = this.state;
    const { ticketID } = ticket.vehicleID;
    let cell = ticket.entityUserCell;

    let cellAmmended = cell.substring(1, 10);

    return (
      <div className="card shadow-sm m-1 passeger-card-border pb-3 card-width-vehicle">
        <div className="row">
          <div className="card-group-display align-vert">
            <div className="row">
              <div className="details-align mt-1">
                <div className="details-icon ">
                  <GiCarWheel color={wheelColor} size={30} />
                </div>
                <div className="card-vehicle-heading-lg">
                  {/* */}
                  <Link
                    to={`/pagePassengerVehicle/${ticketIdLocal}`}
                    // pagePassengerEntity
                    className="btn p-0"
                  >
                    {ticket.name}{" "}
                    {/* <span className="card-vehicle-heading-nc">
                      ({ticket.entityUserName} {ticket.entityUserSurame})
                    </span> */}
                  </Link>
                </div>
              </div>
            </div>
            <hr className="my-1" />
            <div className="row">
              <div className="col">
                <div className="center">
                  <FiUser color="#858796" size="20" />
                  <span className={passColor}>
                    ({ticket.totalLoad}/{ticket.passengerCapacity})
                  </span>
                </div>
              </div>
              <div className="col ">
                <div className="center">
                  <Bicycle color="#858796" size="20" />
                  <span className={bicycleColor}>
                    ({ticket.totalCycle}/{ticket.bicycleCapacity})
                  </span>
                </div>
              </div>
            </div>

            <div className="col px-1 m-1">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={this.createIDHash()}
                    aria-expanded="false"
                    aria-controls={this.createID()}
                  >
                    <div className="accordion-heading-text ">
                      <BsListUl />
                      <span className="icon-text-padding">Details</span>
                    </div>
                  </button>
                </h2>
                <div
                  id={this.createID()}
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="card details-card-ticket">
                      <div className="row">
                        <div className="card-vehicle-heading center">
                          Dropped Off
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="center">
                              <FiUser color="#858796" size="20" />
                              <span className="  m-1">
                                {ticket.totalLoadComp}
                              </span>
                            </div>
                          </div>
                          <div className="col">
                            <div className="center">
                              <Bicycle color="#858796" size="20" />
                              <span className=" m-1">
                                {ticket.totalCycleComp}
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="m-2 hr-width-200" />

                        {ticket.entityUsers.map((entityUserAllocated) => (
                          <div className="row" key={entityUserAllocated.id}>
                            <div className="details-align">
                              <div className="details-icon">
                                <Whatsapp color="#128c7e" size={20} />
                              </div>
                              <div className="card-vehicle-heading-nc">
                                <a
                                  href={`https://wa.me/+27${entityUserAllocated.cellInt}?text=`}
                                  target="_blank"
                                >
                                  {entityUserAllocated.name}{" "}
                                  {entityUserAllocated.surname}
                                  {" - "}
                                  {entityUserAllocated.userRole}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VehicleCard;
