import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "../../components/navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class entityUserForm extends Form {
  state = {
    data: {
      name: "",
      surname: "",
      cell: "",
      email: "",
    },
    errors: {},
    entityID: "",

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    name: Joi.string().label("User Name"),
    surname: Joi.string().label("User Surname"),
    cell: Joi.string()
      .regex(/^[0-9]{10}$/)
      .label("Cell No"),
    email: Joi.string()
      .label("Email Address")
      .email()
      .empty("")
      .optional()
      .allow(""),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;

    const entityID = localStorage.getItem("entityID");
    this.setState({ entityID });

    // get data from database to display options in textfields
    if (ID === "new") return;
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointEntityUserGet + "?id=" + ID
    );

    if (!retreivedData) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(retreivedData) });
  }

  doSubmit = async () => {
    const ID = this.state.myParams.id;
    // Get the group Id for a new entry

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // add the group ID to the form data
      formData.append("entityID", this.state.entityID);
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointEntityUserAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointEntityUserUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate(`/pageEnityUsers/${this.state.entityID}`);
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointEntityUserDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/pageEnityUsers/${this.state.entityID}`);
  };

  mapToViewModel(data) {
    return {
      name: data.name,
      surname: data.surname,
      cell: data.cell,
      email: data.email,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <Link
                        to={`/pageEnityUsers/${this.state.entityID}`}
                        className="btn p-0"
                      >
                        <ArrowLeftCircle color="#002072" size={40} />
                      </Link>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h4>Member Details</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{this.renderInput("name", "Name")}</td>
                    </tr>
                    <tr>
                      <td>Surname</td>
                      <td>{this.renderInput("surname", "Surname")}</td>
                    </tr>
                    <tr>
                      <td>Cell Number</td>
                      <td>{this.renderInput("cell", "Cell")}</td>
                    </tr>
                    <tr>
                      <td>Email Address</td>
                      <td>{this.renderInput("email", "Email")}</td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(entityUserForm);
