import React from "react";
import {
  ChatSquareTextFill,
  DoorOpenFill,
  PinMapFill,
  TicketDetailed,
} from "react-bootstrap-icons";

import { FaMap, FaMapPin, FaRoute } from "react-icons/fa";
import { RiTreasureMapLine } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import CustomButton from "../components/common/customButton";

/**
 * Renders a card with various buttons for setting up and managing a race stage.
 * @class
 * @extends React.Component
 */
class StageRDInfoSplash extends React.Component {
  /**
   * Renders the StageRDInfoSplash component.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    const { stageDescription, stageName, stageID } = this.props;

    console.log("====================================");
    console.log(this.props);
    console.log("====================================");

    return (
      <div className="col-sm-12 entity-splash-padding">
        <div className="card">
          <div className="card-body pb-1">
            <h5 className="card-title">{stageName}</h5>
            <div className="card-text">{stageDescription}</div>

            <div className="row marginTop20  dashboard-stage">
              <div className="card marginTop10 dashboard-stage-card px-0 ">
                <div className="card-body  px-0 pt-0">
                  <h6 className="card-header border-radius-4 ">Setup</h6>
                  <div className="col-sm mt-2 marginBottom-5">
                    <CustomButton
                      tooltipMessage="Create a Circuit For The Race"
                      link={`/pageGPXfiles/${stageID}`}
                      buttonColor="primary"
                      icon={<FaRoute color="white" size={40} />}
                      buttonText="Create Circuit"
                    />
                  </div>
                  <div className="col-sm mt-2 marginBottom-5">
                    <CustomButton
                      tooltipMessage="Upload GPX Files To The Circuit"
                      // link={`/pageGPXfiles/${stageID}`}
                      link={`/pageGPXfilesUpload/${stageID}/`}
                      buttonColor="primary"
                      icon={<RiTreasureMapLine color="white" size={40} />}
                      buttonText="Upload GPX to Circuit"
                    />
                  </div>

                  <div className="col-sm mt-2 marginBottom-5">
                    <CustomButton
                      tooltipMessage="Setup Map Options"
                      link={`/pageMapOptions/${stageID}`}
                      buttonColor="primary"
                      icon={<FaMap color="white" size={40} />}
                      buttonText="Map Options"
                    />
                  </div>

                  <div className="col-sm marginBottom-5">
                    <CustomButton
                      tooltipMessage="Setup Points Of Interests"
                      link={`/pagePOI/${stageID}`}
                      buttonColor="primary"
                      icon={<FaMapPin color="white" size={30} />}
                      buttonText="POIs"
                    />
                  </div>
                </div>
              </div>

              <div className="card marginTop10 dashboard-stage-card px-0 ">
                <div className="card-body  px-0 pt-0">
                  <h6 className="card-header border-radius-4 ">Tools</h6>

                  <div className="col-sm mt-2 marginBottom-5">
                    <CustomButton
                      tooltipMessage="Race Director Dashboard"
                      link={`/pageEntityStatistics/${stageID}`}
                      buttonColor="secondry-green"
                      icon={<MdDashboard color="white" size={30} />}
                      buttonText="Dashboard"
                    />
                  </div>

                  <div className="col-sm mt-2 marginBottom-5">
                    <CustomButton
                      tooltipMessage="Race Circuit Map"
                      link={`/pageRDMap/${stageID}`}
                      buttonColor="secondry-green"
                      icon={<PinMapFill color="white" size={40} />}
                      buttonText="Map"
                    />
                  </div>

                  <div className="col-sm marginBottom-5">
                    <CustomButton
                      tooltipMessage="Race Tickets"
                      link={`/pageRDTicketCards/${stageID}`}
                      buttonColor="secondry-green"
                      icon={<TicketDetailed color="white" size={30} />}
                      buttonText="Tickets"
                    />
                  </div>

                  <div className="col-sm marginBottom-5">
                    <CustomButton
                      tooltipMessage="Race Notifications"
                      link={`/pageNotificationsEntity/${stageID}`}
                      buttonColor="secondry-green"
                      icon={<ChatSquareTextFill color="white" size={30} />}
                      buttonText="Notifications"
                    />
                  </div>
                </div>
              </div>

              <div className="card card-enity-user-width p-2 mt-2 ">
                <div className="row px-2">
                  <div className="p-0">
                    <CustomButton
                      tooltipMessage="Exit"
                      link="/"
                      buttonColor="danger"
                      icon={<DoorOpenFill color="white" size={30} />}
                      buttonText="Logout"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StageRDInfoSplash;
