import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";

// import ReactJoiValidations from "react-joi-validation";

import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarEntity from "./../../components/navbarEntity";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class EntityUserTicketForm extends Form {
  state = {
    data: {
      allocateToEntityID: "",
      commentText: "",
      participantNo: "",
      priorityCheck: "",
      resolvedCheck: "",
      priorityCheck: "",
      resolvedCheck: "",
      ticketTypeID: "",
    },
    entityJocRepID: "",
    entityName: "",
    entityID: "",
    entityUserId: "",
    groupID: "",

    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
    interval: "",

    raceID: "",
    raceNameYear: "",
    selectedFile: "",
    stageName: "",

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    entites: [],
    entityUsersDB: [],
    entitiesAllocated: [],
    errors: {},
    ticketTypesData: [],

    priorityData: [
      { id: 0, description: "No" },
      { id: 1, description: "Yes" },
    ],

    resolvedData: [
      { id: 0, description: "No" },
      { id: 1, description: "Yes" },
    ],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    ticketTypeID: Joi.string().label("Ticket Type"),
    allocateToEntityID: Joi.string().label("Allocated to Entity"),
    participantNo: Joi.string()
      .empty("")
      .optional()
      .allow("")
      .label("Participant No"),
    commentText: Joi.string().label("Comment"),
    priorityCheck: Joi.string().label("Priority"),
    resolvedCheck: Joi.string().label("Resolved"),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;

    const entityID = localStorage.getItem("entityID");
    const entityJocRepID = localStorage.getItem("entityJocRepID");
    const entityName = localStorage.getItem("entityName");
    const entityUserId = localStorage.getItem("entityUserId");
    const groupID = localStorage.getItem("groupID");
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const stageID = localStorage.getItem("stageID");
    const stageName = localStorage.getItem("stageName");

    // Get the Ticket Types
    let { data: ticketTypesDB } = await httpService.get(
      config.apiEndpointTicketTypesAllocatedGet +
        "?raceID=" +
        raceID +
        "&" +
        "entityID=" +
        entityID
    );

    // Get entites by group ID and race ID
    let { data: entityDB } = await httpService.get(
      config.apiEndpointEntitesRaceAllocGetRaceID +
        "?groupID=" +
        groupID +
        "&" +
        "raceID=" +
        raceID
    );

    //Get the entity users that are linked to thge stage and Entity
    let { data: entityUsersDB } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationsReturnUserID +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );
    this.setState({ entityUsersDB });

    // GPS location storage
    window.navigator.geolocation.getCurrentPosition((success) =>
      this.setState({
        myLatitude: success.coords.latitude,
        myLongitude: success.coords.longitude,
      })
    );

    if (navigator.geolocation) {
      navigator.geolocation.enableHighAccuracy = true;
      navigator.geolocation.watchPosition((position) =>
        this.setState({
          myLatitude: position.coords.latitude,
          myLongitude: position.coords.longitude,
          myAltitude: position.coords.altitude,
          myHeading: position.coords.heading,
          mySpeed: position.coords.speed,
          mySpeedKM: position.coords.speed * 3.6,
        })
      );
    }

    // 5000 = 10 seconds
    const MINUTE_MS = 5000;
    const interval = setInterval(() => {
      this.sendData();
    }, MINUTE_MS);

    this.setState({
      ticketTypesData: ticketTypesDB,
      entitiesAllocated: entityDB,
      entityJocRepID,
      entityID,
      entityName,
      entityUserId,
      groupID,
      interval,
      raceID,
      raceNameYear,
      stageName,
      stageID,
    });

    // Get data from database to display options in textfields
    if (ID === "new") return;
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointEntityStageTicketGet + "?id=" + ID
    );

    if (!retreivedData) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(retreivedData) });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  doSubmit = async () => {
    // Get the group Id for a new entry
    const ID = this.state.myParams.id;
    this.on();

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // get date and time for the ticket submision
      let ticketDate = new Date().toISOString().slice(0, 10);

      let today = new Date();
      let ticketTime =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      // console.warn(this.state.selectedFile);

      // add the static info to the formData
      formData.append("entityJocRepID", this.state.entityJocRepID);
      formData.append("entityID", this.state.entityID);
      formData.append("stageID", this.state.stageID);
      formData.append("ticketDate", ticketDate);
      formData.append("ticketTime", ticketTime);
      formData.append("latitude", this.state.myLatitude);
      formData.append("longitude", this.state.myLongitude);
      formData.append("createdByEntityUserId", this.state.entityUserId);
      formData.append("file", this.state.selectedFile);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointEntityStageTicketsAdd,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    }
    this.off();
    this.state.myNavigate("/pageEntityUserHome");
  };

  mapToViewModel(data) {
    return {
      allocateToEntityID: data.allocToEntityId,
      ticketTypeID: data.entityTicketTypeId,
      participantNo: data.participantNo,
      commentText: data.comment,
      priorityCheck: data.priorityCheck,
      resolvedCheck: data.resolvedCheck,
    };
  }

  handleInputChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  async sendData() {
    // Get the latest Geo data
    let {
      myLatitude,
      myLongitude,
      myAltitude,
      myHeading,
      mySpeed,
      mySpeedKM,
      entityID,
      stageID,
      entityUserId,
    } = this.state;

    // Set Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLongitude", myLongitude);
    localStorage.setItem("myAltitude", myAltitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);

    // Create a numeric outut format type ES6 style
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Format the data to two decimal points
    myAltitude = formatter.format(myAltitude);
    myHeading = formatter.format(myHeading);
    mySpeed = formatter.format(mySpeed);
    mySpeedKM = formatter.format(mySpeedKM);

    //  Create time and date of geo location
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const today = new Date().toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    // set the Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);

    const time = new Date().toLocaleTimeString();

    // go to database and store the info everytime there is a location update
    let formData = new FormData();
    //add the data to formdata to send to db
    formData.append("latitude", myLatitude);
    formData.append("longitude", myLongitude);
    formData.append("altitude", myAltitude);
    formData.append("heading", myHeading);
    formData.append("speed", mySpeedKM);
    formData.append("time", time);
    formData.append("date", today);
    formData.append("timestamp", timestamp);
    formData.append("stageID", stageID);
    formData.append("entityUserId", entityUserId);
    formData.append("entityID", entityID);

    // send the data to the database via Http
    if (myLatitude != "" && myLongitude != "") {
      try {
        const { data: retrievedGeoData } = await httpService.post(
          config.apiEndpointSendEUGeoLocation,
          formData
        );
        // toast.info("Sent");
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    }
  }

  selectElement(id, valueToSelect) {
    let element = document.getElementById(id);
    element.value = valueToSelect;
  }

  render() {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const { color, cssOverride, loading } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />

        <NavbarEntity />

        <div className="container container-top-70">
          <div id="overlay" className="overlay" onClick={() => this.off()}>
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link to="/pageEntityUserHome" className="btn p-0">
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.raceNameYear}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Ticket - {this.state.stageName}</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ticket Type *</td>
                      <td>
                        {this.state.ticketTypesData &&
                          this.renderSelect(
                            "ticketTypeID",
                            "name",
                            this.state.ticketTypesData,
                            ""
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Allocate to Entity *</td>
                      <td>
                        {this.state.entitiesAllocated &&
                          this.renderSelect(
                            "allocateToEntityID",
                            "entityName",
                            this.state.entitiesAllocated,
                            ""
                          )}
                      </td>
                    </tr>

                    <tr>
                      <td>Participant No</td>
                      <td>
                        {this.renderInput("participantNo", "Participant No")}
                      </td>
                    </tr>
                    <tr>
                      <td>Ticket Comment *</td>
                      <td>
                        {this.renderTextArea(
                          "commentText",
                          "Comment",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Upload File</td>
                      <td>
                        <input
                          type="file"
                          className="form-control"
                          name="upload_file"
                          onChange={this.handleInputChange}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Priority ?</td>
                      <td>
                        {this.state.priorityData &&
                          this.renderSelect(
                            "priorityCheck",
                            "description",
                            this.state.priorityData,
                            ""
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Resolved ?</td>
                      <td>
                        {this.state.resolvedData &&
                          this.renderSelect(
                            "resolvedCheck",
                            "description",
                            this.state.resolvedData,
                            ""
                          )}
                      </td>
                    </tr>
                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(EntityUserTicketForm);
