import React from "react";
import { Bicycle, Whatsapp } from "react-bootstrap-icons";
import { SiOpenstreetmap } from "react-icons/si";

export const VehicleMap = ({ onFlyTo, singlevehicle }) => {
  const coordinates = [singlevehicle.latitude, singlevehicle.longitude];

  if (singlevehicle.latitude === "") {
    console.log(singlevehicle);

    // console.log("singleehicleInfo = " + singleehicleInfo);
    return (
      <div className="marginTop-5 shadow">
        <div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <h5 className="card-title">{singlevehicle.vehicleName}</h5>
                </div>
                <div className="col center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="marginTop-5 shadow">
        <div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <h5 className="card-title">{singlevehicle.vehicleName}</h5>
                </div>
                <div className="col center">
                  <button
                    className="btn btn-secondry-green button-color-dashboard"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasScrolling"
                    aria-controls="offcanvasScrolling"
                    onClick={() => onFlyTo(coordinates)}
                  >
                    <SiOpenstreetmap color="white" size={20} />
                  </button>
                </div>

                {singlevehicle &&
                  singlevehicle.entityUsers.map((entityUserAllocated) => (
                    <div className="row mb-2" key={entityUserAllocated.id}>
                      <div className="details-align">
                        <div className="details-icon">
                          <Whatsapp color="#128c7e" size={20} />
                        </div>
                        <div>
                          <a
                            href={`https://wa.me/+27${entityUserAllocated.cellInt}?text=`}
                            target="_blank"
                          >
                            {entityUserAllocated.name}{" "}
                            {entityUserAllocated.surname}
                            {" - "}
                            {entityUserAllocated.userRole}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}

                <div>
                  <p>
                    Last GEO location time: ({singlevehicle.time})<br />
                  </p>
                  <a
                    href={`https://www.google.com/maps/place/${singlevehicle.latitude},${singlevehicle.longitude}`}
                    target="_blank"
                  >
                    Google Maps:{singlevehicle.latitude} ,{" "}
                    {singlevehicle.longitude}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
