import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarSiteAdmin from "../components/navbarSiteAdmin";
import GroupUserListTable from "../components/groupUserListTable";
import SearchBox from "../components/common/searchBox";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class PageGroupUser extends React.Component {
  state = {
    dataRetreived: [],
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointGroupUsersGet
    );
    this.setState({ dataRetreived: Dbdata });
  }

  handleDelete = (groupUserId) => {
    let dataRetreived = this.state.dataRetreived.filter(
      (c) => c.id !== groupUserId
    );
    this.setState({ dataRetreived: dataRetreived });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let { dataRetreived, searchQuery, sortColumn } = this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allGroupUsersTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarSiteAdmin />

        <div className="container container-top-70">
          <div className="row">
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link to="/pageSiteAdmin" className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>
              </span>

              <Link to="/groupUserForm/new" className="btn btn-primary">
                New Group User
              </Link>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Names..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <GroupUserListTable
                onDelete={this.handleDelete}
                filteredGroupUsers={filtered}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                allGroupUsersTotal={allGroupUsersTotal}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageGroupUser;
