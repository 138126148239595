import React from "react";
import { Link } from "react-router-dom";

import EntityUserTicketStatsRow from "./../components/entityUserTicketStatsRow";

class entityUserTicketStats extends React.Component {
  render() {
    const { entityUserList, stageUserRoleTotal } = this.props;

    if (stageUserRoleTotal.totalStageUserRoleAlloc == 0) {
      return (
        <div className="col container-top-sm">
          <div className="card text-center">
            <div className="card-header">
              <h5 className="card-title">No. of Tickets placed</h5>
            </div>
            <div className="card-body">
              <table width={250}>
                <thead>
                  <tr className="leftComponents">
                    <th>Name</th>
                    <th>Surname</th>
                    <th>Tickets</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="leftComponents">
                    <th colSpan={3}>No Data</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card-footer text-muted">
              20 tickets placed in total
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="col container-top-sm center-horizontal-components">
        <div className="card text-center">
          <div className="card-header">
            <h5 className="card-title">No. of Tickets created</h5>
          </div>
          <div className="card-body center">
            <table className="">
              <thead>
                <tr className="leftComponents">
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Tickets</th>
                </tr>
              </thead>
              <tbody>
                {entityUserList.map((entityUser) => (
                  <EntityUserTicketStatsRow
                    key={entityUser.id}
                    entityUserName={entityUser.name}
                    entityUserSurname={entityUser.surname}
                    ticketCount={entityUser.ticketCount}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default entityUserTicketStats;
