import React from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import EntityStatistic from "../components/entityStatistic";
import SearchBox from "../components/common/searchBox";
import withMyHook from "../components/common/withMyHook";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import {
  ArrowLeftCircle,
  ArrowCounterclockwise,
  PlusCircle,
} from "react-bootstrap-icons";
//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class pageEntityStatistics extends React.Component {
  state = {
    groupName: "",
    groupID: "",
    raceID: "",
    raceNameYear: "",
    stageID: "",
    interval: "",

    dataRetreived: [],
    vehicleStats: [],
    ticketStats: [],
    dashboardStats: [],

    searchQuery: "",
    sortColumn: { path: "entityName", order: "asc" },

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    //  This will be the race id
    const stageID = this.state.myParams.id;

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");

    try {
      // Get the vehicles and ticket stats
      let { data: dashboardStatsDB } = await httpService.get(
        config.apiEndpointAuthDashboardRDGet + "?stageID=" + stageID
      );
      // sort the vehicle stats in name order
      const dashboardStatsDBSorted = _.orderBy(
        dashboardStatsDB,
        "entityName",
        "asc"
      );

      this.setState({ dashboardStats: dashboardStatsDBSorted });
    } catch (error) {
      this.off();
    }

    this.setState({
      groupName,
      groupID,
      raceNameYear,
      raceID,
      stageID,
    });

    // Now set the radio button as checked
    const cbTickets = document.querySelector("#mainTicketBox");
    cbTickets.checked = true;

    // Switch on the Auto refresh tickets
    this.autoRefreshStats();
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  refreshStats = async () => {
    const stageID = this.state.myParams.id;
    try {
      // Get the vehicles and ticket stats
      let { data: dashboardStatsDB } = await httpService.get(
        config.apiEndpointAuthDashboardRDGet + "?stageID=" + stageID
      );
      // sort the vehicle stats in name order
      const dashboardStatsDBSorted = _.orderBy(
        dashboardStatsDB,
        "entityName",
        "asc"
      );

      this.setState({ dashboardStats: dashboardStatsDBSorted });
    } catch (error) {
      this.off();
    }
  };

  autoRefreshStats = async () => {
    // This code will be used to refresh the tickets every 1 minute
    const cbTickets = document.querySelector("#mainTicketBox");

    if (cbTickets.checked == true) {
      // set the 1 minute to a variable and let the user set it
      this.interval = setInterval(() => this.refreshStats(), 6000);
    } else {
      clearInterval(this.interval);
    }
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    let { searchQuery, sortColumn, raceNameYear, dashboardStats } = this.state;

    const sortedData = _.orderBy(
      dashboardStats,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents

    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.entityName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allEntriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>{raceNameYear}- Race Director - Dashboard</h5>
            <div className="col" data-aos="fade-right">
              <div className="row">
                <div className="button-padding ">
                  <Link
                    to={`/pageRaceHome/${this.state.raceID}`}
                    className="btn p-0"
                  >
                    <ArrowLeftCircle color="#002072" size={40} />
                  </Link>
                  <button className="btn p-0" onClick={this.refreshStats}>
                    <ArrowCounterclockwise color="#002072" size={40} />
                  </button>
                  <label className="switch margin-left">
                    <input
                      id="mainTicketBox"
                      name="mainTicketBox"
                      type="checkbox"
                      onClick={this.autoRefreshStats}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="mx-2 card-passenger-sm">Auto refresh</span>
                </div>
              </div>

              <div className="row marginTop10 button-align"></div>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Entities..."
              />
            </div>
          </div>

          <div className="row center">
            {filtered &&
              filtered.map((entity) => (
                <EntityStatistic
                  key={entity.entityID}
                  entity={entity}
                  refreshTickets={this.refreshTickets}
                />
              ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(pageEntityStatistics);
