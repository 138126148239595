import React from "react";
import { Link } from "react-router-dom";
import {
  ChatSquareTextFill,
  DoorOpenFill,
  PersonFillLock,
  PersonVcardFill,
  PersonWorkspace,
  PeopleFill,
  PinMapFill,
  TicketDetailed,
} from "react-bootstrap-icons";
import { MdDashboard } from "react-icons/md";
import { GiCarWheel } from "react-icons/gi";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class StageInfoSplash extends React.Component {
  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    const {
      stageDescription,
      distance,
      elevation,
      stageName,
      stageType,
      stageID,
    } = this.props;

    return (
      <div className="col-sm-12 entity-splash-padding row-fit">
        <div className="card fit-content center mt-2 row-fit">
          <div className="card-body row-fit pb-1">
            <h5 className="card-title">{stageName}</h5>
            <div className="card-text">{stageDescription}</div>
            <div className="row dashboard-stage mt-0">
              <div className="card marginTop10 dashboard-stage-card px-0 ">
                <div className="card-body  px-0 pt-0">
                  <h6 className="card-header border-radius-4 ">Members</h6>
                  <div className="col-sm mt-2 marginBottom-5">
                    <div className="button-center ">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip(
                          "Allocate members to the stage"
                        )}
                      >
                        <Link
                          to={`/PageStageEntityUserRoleAllocation/${stageID}`}
                          className="btn btn-primary button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          <PersonVcardFill color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue">Allocations</div>
                  </div>

                  <div className="col-sm marginBottom-5">
                    <div className="button-center">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip(
                          "Assign a password for the stage / members"
                        )}
                      >
                        <Link
                          to={`/entitydefaultPasswordForm/${stageID}`}
                          className="btn btn-primary button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          <PersonFillLock color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue ">Password</div>
                  </div>
                </div>
              </div>

              <div className="card marginTop10 dashboard-stage-card px-0">
                <div className="card-body  px-0 pt-0">
                  <h6 className="card-header border-radius-4 ">Joc</h6>
                  <div className="col-sm mt-2 marginBottom-5">
                    <div className="button-center">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip(
                          "Assign Members to the JOC"
                        )}
                      >
                        <Link
                          to={`/pageStageEntityJocRepAllocation/${stageID}`}
                          className="btn btn-primary button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          <PersonWorkspace color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue">Allocate to JOC</div>
                  </div>
                  <div className="col-sm marginBottom-5">
                    <div className="button-center">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip(
                          "Assign a password for the JOC members"
                        )}
                      >
                        <Link
                          to={`/entityJocDefaultPasswordForm/${stageID}`}
                          className="btn btn-primary button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          <PersonFillLock color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue">Password</div>
                  </div>
                </div>
              </div>
              <div className="card marginTop10 dashboard-stage-card px-0">
                <div className="card-body  px-0 pt-0">
                  <h6 className="card-header border-radius-4 ">Tools</h6>
                  <div className="col-sm mt-2 marginBottom-5">
                    <div className="button-center">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip("Race Map")}
                      >
                        <Link
                          to={`/pageEntityMap/${stageID}`}
                          className="btn btn-secondry-green button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          <PinMapFill color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue">Go to Map</div>
                  </div>

                  <div className="col-sm marginBottom-5">
                    <div className="button-center">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip("Race Tickets")}
                      >
                        <Link
                          to={`/pageTicketCards/${stageID}`}
                          className="btn btn-secondry-green button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          <TicketDetailed color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue">All Tickets</div>
                  </div>
                </div>
              </div>
              <div className="card marginTop10 dashboard-stage-card px-0">
                <div className="card-body  px-0 pt-0">
                  <h6 className="card-header border-radius-4 ">Tools</h6>

                  <div className="col-sm mt-2 marginBottom-5">
                    <div className="button-center">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip("Race Notifications")}
                      >
                        <Link
                          to={`/pageNotificationsEntity/${stageID}`}
                          className="btn btn-secondry-green button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          <ChatSquareTextFill color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue">Notifications</div>
                  </div>

                  <div className="col-sm marginBottom-5">
                    <div className="button-center">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip(
                          "Vehicle Passenger Dashboard"
                        )}
                      >
                        <Link
                          to={`/pageVehiclePassenger/${stageID}`}
                          className="btn btn-secondry-green button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          {/* <PeopleFill color="white" size={30} /> */}
                          <GiCarWheel color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue">Vehicles</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row dashboard-stage">
              <div className="card marginTop-5 dashboard-stage-card ">
                <div className="card-body padding-bottom-override">
                  <div className="col-sm marginBottom-5">
                    <div className="button-center">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={this.renderTooltip("Logout")}
                      >
                        <Link
                          to="/"
                          className="btn btn-danger button-color-dashboard"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            height: 55,
                            marginBottom: 5,
                            marginTop: 5,
                            width: 60,
                          }}
                        >
                          <DoorOpenFill color="white" size={30} />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="text-darkblue">Logout</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StageInfoSplash;
