import React, { Component } from "react";
import { Link } from "react-router-dom";

//Utilities
import Joi from "joi-browser";

//Components
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "../../components/navbarGroup";

// Services
import httpService from "../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class ticketTypeEntityAllocation extends Form {
  state = {
    data: {
      ticketTypeID: "",
    },

    entityID: "",
    entityName: "",
    errors: {},
    groupName: "",
    groupID: "",
    noDataMessage: "No Ticket Types left to allocate",
    races: [],
    raceID: "",
    userTicketTypes: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    ticketTypeID: Joi.string().label("Ticket Type"),
  };

  async componentDidMount() {
    const ticketTypeEntityAllocID = this.state.myParams.id;

    // Get the Group Race data stored in memory
    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const entityName = localStorage.getItem("entityName");

    // Needed for the database
    const entityID = localStorage.getItem("entityID");
    const raceID = localStorage.getItem("raceID");

    // Set the State
    this.setState({ groupName, groupID, raceID, entityName, entityID });

    // Fill the drop down list based on if its a new entry ie not already allocated
    // or load the fill list so the user can select an exiting one
    if (ticketTypeEntityAllocID === "new") {
      // Get ticket Types by race ID
      // This is needed as the second statment retunes null if there are no allocations
      let { data: retrievedTicketTypes2 } = await httpService.get(
        config.apiEndpointTicketTypesGet + "?raceID=" + raceID
      );
      this.setState({ userTicketTypes: retrievedTicketTypes2 });

      // Get ticket Types by race ID that are not already allocated
      let { data: retrievedTicketTypes } = await httpService.get(
        config.apiEndpointTicketTypesEntityAllocGet +
          "?raceID=" +
          raceID +
          "&" +
          "entityID=" +
          entityID
      );

      this.setState({ userTicketTypes: retrievedTicketTypes });
    } else {
      // Get ticket Types by race ID
      let { data: retrievedTicketTypes } = await httpService.get(
        config.apiEndpointTicketTypesGet + "?raceID=" + raceID
      );
      this.setState({ userTicketTypes: retrievedTicketTypes });
    }

    // Get data from database to display options in textfields
    if (ticketTypeEntityAllocID === "new") return;
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointEntityTicketAllocationGet +
        "?id=" +
        ticketTypeEntityAllocID
    );

    if (!retreivedData) return this.state.myNavigate("/not-found");
    this.setState({
      data: this.mapToViewModel(retreivedData),
    });
  } // close component did mount

  //Create or update a user
  doSubmit = async () => {
    const ID = this.state.myParams.id;

    if (ID === "new") {
      // Get the group Id for a new entry
      const entityID = localStorage.getItem("entityID");

      // Add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();
      // add the group ID to the form data
      formData.append("entityID", entityID);
      formData.append("raceID", this.state.raceID);
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointEntityTicketAllocationAdd,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();
      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      try {
        await httpService.post(
          config.apiEndpointEntityTicketAllocationUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate(`/pageTicketAllocation/${this.state.entityID}`);
  };

  //Delete a  Group User
  doDelete = async () => {
    const ID = this.state.myParams.id;

    try {
      const deleteReply = await httpService.get(
        config.apiEndpointEntityTicketAllocationDelete + "?id=" + ID
      );
      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/pageTicketAllocation/${this.state.entityID}`);
  };

  mapToViewModel(data) {
    console.log(data);
    return {
      ticketTypeID: data.ticketTypeID,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={`/pageTicketAllocation/${this.state.entityID}`}
                          className="btn p-0"
                        >
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.entityName}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Ticket Type Allocation</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ticket Type</td>
                      <td>
                        {this.state.userTicketTypes &&
                          this.renderSelect(
                            "ticketTypeID",
                            "name",
                            this.state.userTicketTypes,
                            ""
                          )}
                        {!this.state.userTicketTypes &&
                          this.state.noDataMessage}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(ticketTypeEntityAllocation);
