import React from "react";
import { Link } from "react-router-dom";

class GroupCard extends React.Component {
  render() {
    const {
      addressOne,
      addressTwo,
      addressThree,
      addressSuburb,
      addressTownCity,
      addressProvince,
      addressCountry,
      addressCode,
      contact_person,
      groupName,
      groupId,
      tel,
    } = this.props;

    return (
      <div
        className="col card-group"
        data-aos="zoom-in"
        data-aos-duration="5000"
      >
        <div className="card text-center shadow">
          <div className="card-header">
            <Link to={`/groupForm/${groupId}`}>
              <h5>{groupName}</h5>
            </Link>
          </div>
          <div className="card-body">
            <p className="card-text">
              {addressOne} {addressTwo} <br />
              {addressThree} <br />
              {addressSuburb} <br />
              {addressTownCity} <br />
              {addressProvince} <br />
              {addressCountry} {addressCode}
              <br />
              {tel}
            </p>
          </div>
          <div className="card-footer text-muted">
            Contact Person: {contact_person}
          </div>
        </div>
      </div>
    );
  }
}

export default GroupCard;
