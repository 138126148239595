import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components

import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarSiteAdmin from "./../../components/navbarSiteAdmin";

//Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

//Utilites
import Joi from "joi-browser";
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class GroupUserForm extends Form {
  state = {
    data: {
      cell: "",
      email: "",
      firstname: "",
      groupID: "",
      surname: "",
      userPassword: "",
      userTypeId: "",
    },
    errors: {},
    groups: [],
    userTypes: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    groupUserId: Joi.string().label("Group User Id"),
    groupID: Joi.string().label("Group"),
    userTypeId: Joi.string().label("User Type"),
    firstname: Joi.string().label("Firstname"),
    surname: Joi.string().label("Surname"),
    cell: Joi.string()
      .regex(/^[0-9]{10}$/)
      .label("Cell No"),
    email: Joi.string().label("Email Address").email(),
    userPassword: Joi.string().label("Login Password"),
  };

  async componentDidMount() {
    const groupUserID = this.state.myParams.id;

    // Get the list of Groups
    let { data: retrievedGroups } = await httpService.get(
      config.apiEndpointGroupsGet
    );
    this.setState({ groups: retrievedGroups });

    // Get the Group User Types
    let { data: retrievedGroupTypes } = await httpService.get(
      config.apiEndpointGroupUserTypesGet
    );
    this.setState({ userTypes: retrievedGroupTypes });

    // Get the group user
    if (groupUserID === "new") return;
    let { data: groupUser } = await httpService.get(
      config.apiEndpointGroupUserGet + "?id=" + groupUserID
    );
    if (!groupUser) return this.state.myNavigate("/not-found");

    this.setState({ data: this.mapToViewModel(groupUser) });
  }

  doSubmit = async () => {
    const groupUserID = this.state.myParams.id;

    if (groupUserID === "new") {
      // add a new entry
      console.log("In New");
      let dataToSend = this.state.data;
      let formData = new FormData();

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointGroupUserAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();
      //add the Id to the data to send to db
      formData.append("id", groupUserID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      // console.log("formData = " + formData);
      try {
        await httpService.post(config.apiEndpointGroupUserUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate("/pageGroupUser");
  };

  //Submit a delete Group
  doDelete = async () => {
    const groupUserID = this.state.myParams.id;

    try {
      const deleteReply = await httpService.get(
        config.apiEndpointGroupUserDelete + "?id=" + groupUserID
      );

      var myObject = JSON.parse(deleteReply);
      console.log("myObject = " + { ...myObject });
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate("/pageGroupUser");
  };

  doClear = () => {
    // clear State data that could have been updated by the changed event if the user started entering the form
    let data = {
      groupID: "",
      userTypeId: "",
      name: "",
      surname: "",
      cell: "",
      email: "",
      userPassword: "",
    };

    // update the state
    this.state.data = this.data;

    // clear the form fields
    const blankGroup = {
      id: "",
      group: "",
      groupUserId: "",
      groupID: "",
      userType: "",
      name: "",
      surname: "",
      cell: "",
      email: "",
      userPassword: "",
    };

    this.setState({ data: this.mapToViewModel(blankGroup) });
    this.state.myParams.id = "new";
    this.state.myNavigate("/pageGroupUser");
  };

  mapToViewModel(groupUser) {
    return {
      groupUserId: groupUser.id,
      groupID: groupUser.group.id,
      userTypeId: groupUser.userType.id,
      firstname: groupUser.name,
      surname: groupUser.surname,
      cell: groupUser.cell,
      email: groupUser.email,
      userPassword: groupUser.userPassword,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarSiteAdmin />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <Link to="/pageGroupUser" className="btn p-0">
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h4>Group User</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{this.renderInput("firstname", "Name")}</td>
                    </tr>
                    <tr>
                      <td>Surname</td>
                      <td>{this.renderInput("surname", "Surname")}</td>
                    </tr>
                    <tr>
                      <td>Cell Number</td>
                      <td> {this.renderInput("cell", "Cell Number")}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td> {this.renderInput("email", "Email")}</td>
                    </tr>

                    <tr>
                      <td>Group</td>
                      <td>
                        {this.renderSelect(
                          "groupID",
                          "groupName",
                          this.state.groups,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>User Type</td>
                      <td>
                        {this.renderSelect(
                          "userTypeId",
                          "description",
                          this.state.userTypes,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Password</td>
                      <td>{this.renderPassword("userPassword", "Password")}</td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(GroupUserForm);
