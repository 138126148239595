import L from "leaflet";
import iconUrl from "../images/marker-icon.png";
import iconShadowUrl from "../images/marker-shadow.png";

// console.log(L.Marker.prototype.options.icon.options);

//Get the origional icon varaibles
let { iconSize, shadowSize, iconAnchor, popupAnchor, tooltipAnchor } =
  L.Marker.prototype.options.icon.options;

// Pass them to the new Icon we created
export const defaultIcon = L.icon({
  iconUrl,
  iconShadowUrl,
  iconSize,
  shadowSize,
  iconAnchor,
  popupAnchor,
  tooltipAnchor,
});
