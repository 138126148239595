import React, { useCallback, useEffect, useState } from "react";

import "leaflet.fullscreen/Control.FullScreen.js";
import "leaflet.fullscreen/Control.FullScreen.css";
import { GetGPXCoords } from "./getGPXCoords";

import {
  LayersControl,
  MapContainer,
  Polyline,
  Popup,
  ScaleControl,
} from "react-leaflet";

import { BaseMaps } from "./common/baseMaps";

//Layers
import { TicketLayer } from "./ticketLayer";
import { BusTicketLayer } from "./busTicketLayer";
import { BusTicketLayerComplete } from "./busTicketLayerComplete";
import { EntityUserLayer } from "./entityUserLayer";
import { EntityVehicleLayer } from "./entityVehicleLayer";
import { PoiLayer } from "./poiLayer";

export const MapEntity = ({

  busTicketdata,
  defaultZoom,
  centrePosition,
  entityName,
  entityUserData,
  mapURLs,
  POIMarkerData,
  setMapInstance,
  ticketMarkerData,
  vehicleData,
}) => {
  if (entityName === "") {
    entityName = "All Entities";
  }

  const renderPolylines = useCallback(() => {
    return (
      mapURLs?.length &&
      mapURLs.map((mapURL) => (
        <Polyline
          key={mapURL.gpxName}
          pathOptions={{ color: mapURL.lineColor }}
          positions={GetGPXCoords(mapURL.gpxName)}
        >
          <Popup closeButton={false}>{mapURL.description}</Popup>
        </Polyline>
      ))
    );
  }, [mapURLs]);

  return (
    <MapContainer
      center={centrePosition}
      zoom={defaultZoom}
      scrollWheelZoom={true}
      whenCreated={setMapInstance}
      fullscreenControl={true}
    >
      <LayersControl position="topright">
        <BaseMaps />

        {ticketMarkerData?.map((ticketDataByType) => (
          <TicketLayer
            key={ticketDataByType.id}
            ticketDataByType={ticketDataByType}
          />
        ))}

        {busTicketdata && (
          <>
            <BusTicketLayer busTicketdata={busTicketdata} />
            <BusTicketLayerComplete busTicketdata={busTicketdata} />
          </>
        )}

        {entityUserData && (
          <EntityUserLayer
            entityUserData={entityUserData}
            entityName={entityName}
          />
        )}

        {vehicleData && (
          <EntityVehicleLayer
            vehicleData={vehicleData}
            entityName={entityName}
          />
        )}

        {POIMarkerData && <PoiLayer POIMarkerData={POIMarkerData} />}
      </LayersControl>

      {renderPolylines()}

      <ScaleControl />
    </MapContainer>
  );
};
