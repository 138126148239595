import React, { Component } from "react";
import { Link } from "react-router-dom";

import "leaflet/dist/leaflet.css";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

//Components
import EntityOffCanvas from "../components/entityOffCanvas";
import { MapEntity } from "../components/mapEntity";
import { Map } from "./../components/map";

import withMyHook from "./../components/common/withMyHook";
//Other
import { ToastContainer, Zoom, toast } from "react-toastify";
import { ArrowLeftCircle, GearFill } from "react-bootstrap-icons";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

class PageEntityMap extends React.Component {
  state = {
    defaultZoom: "13",
    entityID: "",
    entityName: "",
    entityUserId: "",
    goToPage: "",
    intervalTickets: "",
    map: "",
    raceID: "",
    stageID: "",
    userTypeID: "",

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,
    loadingMessage: "Getting map options",

    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
    interval: "",
    intervalUsers: "",
    intervalTicket: "",
    intervalVehicle: "",

    // arrays
    data: [],
    entityUserData: [],
    mapURLs: [],
    ticketMarkerData: [],
    POIMarkerData: [],
    centrePosition: [],
    vehicleData: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    this.on();
    // set the Loading message
    this.state.loadingMessage = "Setting Map Options";

    // Get the application storage
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    const entityName = localStorage.getItem("entityName");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const userTypeID = localStorage.getItem("userTypeID");
    const entityUserId = localStorage.getItem("entityUserId");
    const stageID = this.state.myParams.id;

    // Get the stage Map options
    let { data: DbMapsConfigdata } = await httpService.get(
      config.apiEndpointStageOptions + "?id=" + stageID
    );

    // set the Loading message
    this.state.loadingMessage = "Setting Stage Information";
    this.state.color = "#7a0d5d";

    // Get the Stage information
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    // Set the stage details
    localStorage.setItem("stageID", stageID);
    localStorage.setItem("stageName", stage.stageName);
    const stageName = stage.stageName;

    // set the Loading message
    this.state.loadingMessage = "Getting Tickets";
    this.state.color = "#267a0d";

    // Get the Ticket types and pass it to the mapEntity
    let { data: allTicketDataDB } = await httpService.get(
      config.apiEndpointEntityStageTicketsByTypeGet +
        "?raceID=" +
        raceID +
        "&" +
        "entityID=" +
        entityID +
        "&" +
        "stageID=" +
        stageID
    );

    // Get the list of Entity Users / role / latest Geo location  allocated to the stage
    // Get the already allocated stage/User/Role allocations except for those who have been allocated a Vehicle

    // set the Loading message
    this.state.loadingMessage = "Finding users";

    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationsGEO +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );

    //  Get the list of Vehicles that have been allocated
    // ? remove other entities vehicles

    // Set the Loading message
    this.state.loadingMessage = "Getting Vehicles";
    this.state.color = "#4bab86";
    try {
      let { data: vehicleData } = await httpService.get(
        config.apiEndpointStageVehicleAllocationsGEO + "?stageID=" + stageID
      );
      this.setState({ vehicleData });
    } catch (error) {
      console.log("error in PageRD map line 117");
    }

    // Set the Loading message
    this.state.loadingMessage = "Getting POI's";
    this.state.color = "#a1185c";
    // Get the list of poi markers for this stage
    let { data: POIdata } = await httpService.get(
      config.apiEndpointPoigetGet + "?stageID=" + stageID
    );

    // Set the Loading message
    this.state.loadingMessage = "Getting Bus tickets";
    this.state.color = "#a86b25";
    // Get the list of bus tickets for the stage
    let { data: busTicketdata } = await httpService.get(
      config.apiEndpointPassGet + "?stageID=" + stageID
    );

    // Set the Loading message
    this.state.loadingMessage = "Setting Route On Map";
    this.state.color = "#a2ba77";

    // Get the gpx file names for the polylines
    let { data: mapURLs } = await httpService.get(
      config.apiEndpointGpxsGet + "?stageID=" + stageID
    );

    let goToPage = "pageEntityHome";
    if (userTypeID === "4") goToPage = `pageRaceEntityHome/${raceID}`;
    if (userTypeID === "5") goToPage = "pageEntityUserHome";
    if (userTypeID === "6") goToPage = `pageRaceEntityHome/${raceID}`;

    const CPlat = parseFloat(DbMapsConfigdata.cpLat);
    const CPlong = parseFloat(DbMapsConfigdata.cpLong);
    let centrePosition = [DbMapsConfigdata.cpLat, DbMapsConfigdata.cpLong];

    if (!DbMapsConfigdata.zoom) {
      DbMapsConfigdata.zoom = "13";
    }

    // if the user is not the Entity user then do ot get the location
    // the update of state everytime a location is found causes all markers and tickets to not be draggable.

    if (userTypeID == 5) {
      // GPS location storage
      window.navigator.geolocation.getCurrentPosition((success) =>
        this.setState({
          myLatitude: success.coords.latitude,
          myLongitude: success.coords.longitude,
        })
      );

      if (navigator.geolocation) {
        navigator.geolocation.enableHighAccuracy = true;
        navigator.geolocation.watchPosition((position) =>
          this.setState({
            myLatitude: position.coords.latitude,
            myLongitude: position.coords.longitude,
            myAltitude: position.coords.altitude,
            myHeading: position.coords.heading,
            mySpeed: position.coords.speed,
            mySpeedKM: position.coords.speed * 3.6,
          })
        );
      }

      // 10 000 = 10 seconds
      const MINUTE_MS = 10000;
      const interval = setInterval(() => {
        this.sendData();
      }, MINUTE_MS);

      this.setState({ interval });
    }

    this.setState({
      busTicketdata,
      centrePosition,
      defaultZoom: DbMapsConfigdata.zoom,
      entityID,
      entityName,
      entityUserId,
      goToPage,
      mapURLs,
      raceID,
      raceNameYear,
      stageID,
      stageName,
      userTypeID,

      // Data arrays here
      POIMarkerData: POIdata,
      ticketMarkerData: allTicketDataDB,
      entityUserData: Dbdata,
    });

    this.off();

    const cbTickets = document.querySelector("#mainTicketBox");
    cbTickets.checked = true;

    this.handleAutoRefreshEntityUsers();
    this.handleAutoRefreshTickets();
    this.handleRefreshVehicles();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    clearInterval(this.state.intervalUsers);
    clearInterval(this.state.intervalTicket);
    clearInterval(this.state.intervalVehicle);
  }

  handleRefreshTickets = async () => {
    // Get the Ticket types and pass it to the mapEntity
    let { data: allTicketDataDB } = await httpService.get(
      config.apiEndpointEntityStageTicketsByTypeGet +
        "?raceID=" +
        this.state.raceID +
        "&" +
        "entityID=" +
        this.state.entityID +
        "&" +
        "stageID=" +
        this.state.stageID
    );

    // Get the list of bus tickets for the stage
    let { data: busTicketdata } = await httpService.get(
      config.apiEndpointPassGet + "?stageID=" + this.state.stageID
    );

    this.setState({ ticketMarkerData: allTicketDataDB, busTicketdata });
  };

  handleRefreshEntityUsers = async () => {
    // Get the Ticket types and pass it to the mapEntity
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationsGEO +
        "?stageID=" +
        this.state.stageID +
        "&" +
        "entityID=" +
        this.state.entityID
    );

    this.setState({ entityUserData: Dbdata });
  };

  handleRefreshVehicles = async () => {
    const stageID = localStorage.getItem("stageID");

    try {
      let { data: vehicleData } = await httpService.get(
        config.apiEndpointStageVehicleAllocationsGEO + "?stageID=" + stageID
      );
      this.setState({ vehicleData });
    } catch (error) {
      console.log("error in PageRD map line 272");
    }
  };

  //================================= Auto refresh ================================//
  // This was set to one minute but it clogs up the server as a shared environment only allows 10 concurrent connections

  handleAutoRefreshVehicles = async () => {
    // This code will be used to refresh the tickets
    // 60 000  = 1 minute
    const cbVehicles = document.querySelector("#mainVehicleBox");
    if (cbVehicles.checked == true) {
      this.intervalVehicle = setInterval(
        () => this.handleRefreshVehicles(),
        60000
      );
    } else {
      clearInterval(this.intervalVehicle);
    }
  };

  handleAutoRefreshTickets = async () => {
    const cbTickets = document.querySelector("#mainTicketBox");
    if (cbTickets.checked == true) {
      this.intervalTicket = setInterval(
        () => this.handleRefreshTickets(),
        60000
      );
    } else {
      clearInterval(this.intervalTicket);
    }
  };

  handleAutoRefreshEntityUsers = async () => {
    const cbEntityUser = document.querySelector("#mainEntityUserBox");
    if (cbEntityUser.checked == true) {
      this.intervalUsers = setInterval(
        () => this.handleRefreshEntityUsers(),
        60000
      );
    } else {
      clearInterval(this.intervalUsers);
    }
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  async sendData() {
    // Get the latest Geo data
    let {
      myLatitude,
      myLongitude,
      myAltitude,
      myHeading,
      mySpeed,
      mySpeedKM,
      entityID,
      stageID,
      entityUserId,
    } = this.state;

    // Set Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLongitude", myLongitude);
    localStorage.setItem("myAltitude", myAltitude);

    // Create a numeric outut format type ES6 style
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Format the data to two decimal points
    myAltitude = formatter.format(myAltitude);
    myHeading = formatter.format(myHeading);
    mySpeed = formatter.format(mySpeed);
    mySpeedKM = formatter.format(mySpeedKM);

    //  Create time and date of geo location
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const today = new Date().toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    // set the Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);

    const time = new Date().toLocaleTimeString();

    // go to database and store the info everytime there is a location update
    let formData = new FormData();
    //add the data to formdata to send to db
    formData.append("latitude", myLatitude);
    formData.append("longitude", myLongitude);
    formData.append("altitude", myAltitude);
    formData.append("heading", myHeading);
    formData.append("speed", mySpeedKM);
    formData.append("time", time);
    formData.append("date", today);
    formData.append("timestamp", timestamp);
    formData.append("stageID", stageID);
    formData.append("entityUserId", entityUserId);
    formData.append("entityID", entityID);

    // send the data to the database via Http
    if (myLatitude != "" && myLongitude != "") {
      try {
        const { data: retrievedGeoData } = await httpService.post(
          config.apiEndpointSendEUGeoLocation,
          formData
        );
        // toast.info("Sent");
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    }
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      busTicketdata,
      centrePosition,
      color,
      cssOverride,
      defaultZoom,
      entityName,
      goToPage,
      entityUserData,
      loading,
      mapURLs,
      POIMarkerData,
      raceNameYear,
      stageName,
      ticketMarkerData,
      vehicleData,
    } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div id="overlay" className="overlay" onClick={() => this.off()}>
          <div id="text" className="text">
            <PuffLoader
              color={color}
              loading={loading}
              cssOverride={cssOverride}
              size={50}
            />
            {this.state.loadingMessage}
          </div>
        </div>
        <Link
          to={`/${goToPage}`}
          className="btn p-0"
          style={{
            marginBottom: 10,
            marginTop: 10,
            marginLeft: 10,
          }}
        >
          <ArrowLeftCircle color="#002072" size={40} />
        </Link>
        <button
          className="btn "
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling"
          aria-controls="offcanvasScrolling"
        >
          <GearFill color="#002072" size={40} />
        </button>
        <span className="entity-map-logo">
          {raceNameYear} - {stageName} - {entityName}
        </span>

        <Map
          busTicketdata={busTicketdata}
          defaultZoom={defaultZoom}
          centrePosition={centrePosition}
          entityUserData={entityUserData}
          mapURLs={mapURLs}
          POIMarkerData={POIMarkerData}
          ticketMarkerData={ticketMarkerData}
          entityName={entityName}
          refreshTickets={this.handleRefreshTickets}
          refreshEntityUsers={this.handleRefreshEntityUsers}
          refreshVehicles={this.handleRefreshVehicles}
          autoRefreshEntityUsers={this.handleAutoRefreshEntityUsers}
          autoRefreshTickets={this.handleAutoRefreshTickets}
          autoRefreshVehicles={this.handleAutoRefreshVehicles}
          vehicleData={vehicleData}
          vehicleEntityName={vehicleData}
        />
      </React.Fragment>
    );
  } //close render
} // close class

// export default PageEntityMap;
export default withMyHook(PageEntityMap);
