import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "./../../components//navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class PoiForm extends Form {
  state = {
    data: {
      iconID: "",
      POIName: "",
      POIDesc: "",
    },
    errors: {},
    groupID: "",
    raceNameYear: "",
    raceID: "",
    stageID: "",
    stageName: "",

    icons: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    iconID: Joi.string().label("Icon Image"),
    POIName: Joi.string().label("POI Name"),
    POIDesc: Joi.string().label("POI Description"),
  };

  async componentDidMount() {
    const poiMarkerID = this.state.myParams.id;

    // get variables from the local storage
    const groupID = localStorage.getItem("groupID");
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const stageID = localStorage.getItem("stageID");
    const stageName = localStorage.getItem("stageName");

    //set State with the Variables
    this.setState({ groupID, raceID, raceNameYear, stageID, stageName });

    // get data from database to display options in textfields
    const { data: retrievedIcons } = await httpService.get(
      config.apiEndpointIconsGetByType + "?typeID=" + 1
    );
    this.setState({ icons: retrievedIcons });

    if (poiMarkerID === "new") return;
    let { data: poiData } = await httpService.get(
      config.apiEndpointPoiGet + "?id=" + poiMarkerID
    );

    if (!poiData) return this.state.myNavigate("/not-found");

    this.setState({ data: this.mapToViewModel(poiData) });
  }

  // Submit a new or update
  doSubmit = async () => {
    const ID = this.state.myParams.id;
    const stageID = this.state.stageID;
    console.log("ID = " + ID);

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      formData.append("stageID", stageID);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointStageAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry

      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointPoiDetailsUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate(`/pagePOI/${this.state.stageID}`);
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointPoiDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }
    this.state.myNavigate(`/pagePOI/${this.state.stageID}`);
  };

  mapToViewModel(data) {
    return {
      iconID: data.iconID,
      POIName: data.POIName,
      POIDesc: data.POIDesc,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />

        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={`/pagePOI/${this.state.stageID}`}
                          className="btn p-0"
                        >
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.stageName}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Point of interest details</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{this.renderInput("POIName", "POI Name")}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>
                        {this.renderTextArea(
                          "POIDesc",
                          "POI Description",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Icon</td>
                      <td>
                        {this.renderSelect(
                          "iconID",
                          "iconName",
                          this.state.icons,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PoiForm);
