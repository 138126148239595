import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "../../components/navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class StageUserRoleAllocationForm extends Form {
  state = {
    data: {
      defaultIconID: "",
      entityUserID: "",
      roleID: "",
      vehicleID: "",
    },
    entityID: "",
    raceNameYear: "",
    stageID: "",
    stageName: "",

    errors: {},
    entityUsers: [],
    roleData: [],
    icons: [],
    vehicles: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    defaultIconID: Joi.string()
      .label("Default Icon")
      .empty("")
      .optional()
      .allow(""),
    vehicleID: Joi.string()
      .label("Default Icon")
      .empty("")
      .optional()
      .allow(""),
    entityUserID: Joi.string().label("Entity User"),
    roleID: Joi.string().label("Role"),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;
    const stageID = localStorage.getItem("stageID");
    const stageName = localStorage.getItem("stageName");
    const entityID = localStorage.getItem("entityID");
    const raceNameYear = localStorage.getItem("raceNameYear");

    // Set the State
    this.setState({ raceNameYear, stageID, stageName, entityID });

    // Load the Select boxes with the data.

    // Get Entity users by entity ID and by stage that have not already been allocated
    if (ID === "new") {
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointEntityUsersUnAllocGet +
          "?entityID=" +
          entityID +
          "&" +
          "stageID=" +
          stageID
      );
      this.setState({ entityUsers: Dbdata });
    } else {
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointEntityUsersGet +
          "?entityID=" +
          entityID +
          "&" +
          "stageID=" +
          stageID
      );
      this.setState({ entityUsers: Dbdata });
    }

    // Get Entity User Roles by Entity ID
    let { data: DbdataRoles } = await httpService.get(
      config.apiEndpointEntityUserRolesGet + "?entityID=" + entityID
    );
    this.setState({ roleData: DbdataRoles });
    console.log(" DbdataRoles = " + DbdataRoles.length);

    // Get all entity icons
    let { data: DbdataICON } = await httpService.get(
      config.apiEndpointIconsGetByType + "?typeID=3"
    );
    this.setState({ icons: DbdataICON });

    // Get the Vehicles allocated to the Entity
    if (ID === "new") {
      // let { data: retreivedVehicleData } = await httpService.get(
      //   config.apiEndpointVehiclesUnAllocGet +
      //     "?entityID=" +
      //     entityID +
      //     "&" +
      //     "stageID=" +
      //     stageID
      // );
      let { data: retreivedVehicleData } = await httpService.get(
        config.apiEndpointVehiclesGet + "?entityID=" + entityID
      );
      this.setState({ vehicles: retreivedVehicleData });
    } else {
      let { data: retreivedVehicleData } = await httpService.get(
        config.apiEndpointVehiclesGet + "?entityID=" + entityID
      );
      this.setState({ vehicles: retreivedVehicleData });
    }

    // Get data from database to display options in textfields
    if (ID === "new") return;
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationGet + "?id=" + ID
    );

    if (!retreivedData) return this.state.myNavigate("/not-found");

    this.setState({ data: this.mapToViewModel(retreivedData) });
  }

  doSubmit = async () => {
    // Get the group Id for a new entry
    const ID = this.state.myParams.id;

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // add the group ID to the form data
      formData.append("stageID", this.state.stageID);
      formData.append("entityID", this.state.entityID);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointStageUserRoleAllocationAdd,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointStageUserRoleAllocationUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate(
      `/pageStageEntityUserRoleAllocation/${this.state.stageID}`
    );
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointStageUserRoleAllocationDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(
      `/pageStageEntityUserRoleAllocation/${this.state.stageID}`
    );
  };

  mapToViewModel(data) {
    return {
      entityUserID: data.entityUserId,
      roleID: data.entityUserRoleId,
      defaultIconID: data.defaultIconID,
      vehicleID: data.vehicleID,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.state.entityUsers) {
      return (
        <React.Fragment>
          <ToastContainer
            draggable={true}
            transition={Zoom}
            autoClose={2000}
            position="top-center"
          />
          <NavbarGroup />
          <div className="container container-top-70">
            <div className="row">
              <div className="form-container">
                <form onSubmit={this.handleSubmit} id="formID">
                  <table className="table table-group">
                    <thead>
                      <tr>
                        <th>
                          <Link
                            to={`/PageStageEntityUserRoleAllocation/${this.state.stageID}`}
                            className="btn p-0"
                          >
                            <ArrowLeftCircle color="#002072" size={40} />
                          </Link>
                        </th>
                        <th>
                          <h4>{this.state.stageName}</h4>
                        </th>
                      </tr>
                      <tr align="center">
                        <th colSpan={2}>
                          <h6>Member / Role Allocation</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Members</td>
                        <td>
                          {this.state.entityUsers &&
                            this.renderSelect(
                              "entityUserID",
                              "nameSurname",
                              this.state.entityUsers,
                              ""
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td>Roles</td>
                        <td>
                          {this.state.roleData &&
                            this.renderSelect(
                              "roleID",
                              "name",
                              this.state.roleData,
                              ""
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td> Map Icon (Optional)</td>
                        <td>
                          {this.state.icons &&
                            this.renderSelect(
                              "defaultIconID",
                              "iconName",
                              this.state.icons,
                              ""
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td> Vehicle (Optional)</td>
                        <td>
                          {this.state.vehicles &&
                            this.renderSelect(
                              "vehicleID",
                              "name",
                              this.state.vehicles,
                              ""
                            )}
                        </td>
                      </tr>

                      <tr align="center">
                        <td colSpan={2}>
                          <span className="button-padding">
                            {this.renderSaveButton("Save")}
                          </span>
                          <span className="button-padding">
                            {this.renderButtonDelete("Delete")}
                          </span>
                          <span className="button-padding">
                            {this.renderButtonClear("Clear")}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ToastContainer
            draggable={true}
            transition={Zoom}
            autoClose={2000}
            position="top-center"
          />
          <NavbarGroup />
          <div className="container container-top-70">
            <div className="row">
              <div className="form-container">
                <form onSubmit={this.handleSubmit} id="formID">
                  <table className="table table-group">
                    <thead>
                      <tr>
                        <th>
                          <Link
                            to={`/PageStageEntityUserRoleAllocation/${this.state.stageID}`}
                            className="btn p-0"
                          >
                            <ArrowLeftCircle color="#002072" size={40} />
                          </Link>
                        </th>
                        <th>
                          <h4>{this.state.stageName}</h4>
                        </th>
                      </tr>
                      <tr align="center">
                        <th colSpan={2}>
                          <h6>Member / Role Allocation</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr align="center">
                        <th colSpan={2}>
                          <h7>All allocations performed</h7>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default withMyHook(StageUserRoleAllocationForm);
