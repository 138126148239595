import React from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import TicketTypeTable from "../components/ticketTypeTable";
import SearchBox from "../components/common/searchBox";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class pageTicketTypes extends React.Component {
  state = {
    dataRetreived: [],
    groupName: "",
    groupID: "",
    raceNameYear: "",
    raceID: "",
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {
    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");

    this.setState({
      groupName,
      groupID,
      raceNameYear,
      raceID,
    });

    // Get ticket Types by race ID
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointTicketTypesGet + "?raceID=" + raceID
    );
    this.setState({ dataRetreived: Dbdata });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let { dataRetreived, searchQuery, sortColumn, raceNameYear, raceID } =
      this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allentriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>{raceNameYear} - Ticket Types</h5>
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link to={`/pageRaceHome/${raceID}`} className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>

                <Link to="/ticketTypeForm/new" className="btn p-0">
                  <PlusCircle color="#002072" size={40} />
                </Link>
              </span>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Types..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <TicketTypeTable
                filteredRaceUsers={filtered}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                allentriesTotal={allentriesTotal}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default pageTicketTypes;
