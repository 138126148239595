import React from "react";
import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

//Other
import { TicketPerforatedFill } from "react-bootstrap-icons";

class entityTicketAllocTable extends React.Component {
  state = {
    pageSize: 8,
    currentPage: 1,
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageIncrement = (setCurrentPage, pagesCount) => {
    if (setCurrentPage < pagesCount) {
      setCurrentPage++;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  handlePageDecrement = (setCurrentPage) => {
    if (setCurrentPage != 1) {
      setCurrentPage--;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  columns = [
    {
      path: "entityName",
      label: "Entity Name",
      content: (dataSent) => (
        // <Link to={`/entityRaceAllocForm/${dataSent.id}`}>
        <h6>{dataSent.entityName}</h6>
      ),
    },
    { path: "entityDesc", label: "Description" },
    {
      path: "",
      // key: "resolved",
      key: "",
      content: (entity) => (
        <div>
          <Link
            to={`/pageTicketAllocation/${entity.id}`}
            className="btn btn-primary btn-sm button-color-dashboard"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              height: 35,
              marginBottom: 5,
              marginTop: 5,
              width: 40,
            }}
          >
            <TicketPerforatedFill color="white" size={30} />
          </Link>
        </div>
      ),
    },
  ];

  render() {
    const { filtereddata, onSort, sortColumn, onDelete, allEntriesTotal } =
      this.props;
    const { pageSize, currentPage } = this.state;
    const dataPag = paginate(filtereddata, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={dataPag}
            onDelete={onDelete}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allEntriesTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPageDecrement={this.handlePageDecrement}
            onPageIncrement={this.handlePageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default entityTicketAllocTable;
