export const ticketTypes = [
  { id: "1", name: "Cycle Sweep" },
  { id: "2", name: "Medical" },
  { id: "3", name: "Informational" },
  { id: "4", name: "Course Caution" },
  { id: "9", name: "Crash" },
  { id: "10", name: "DNF" },
  { id: "11", name: "Rain" },
  { id: "18", name: "Rule Breach" },
  { id: "25", name: "Temperture" },
  { id: "12", name: "Wind" },
  { id: "17", name: "Cycle Repair" },
];

export function getTicketTypes() {
  return ticketTypes.filter((g) => g);
}
