import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "./../../components/navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class EntityForm extends Form {
  state = {
    data: {
      contactPerson: "",
      entityName: "",
      entityDescription: "",
      cell: "",
      email: "",
      userPassword: "",
    },
    groupID: "",
    groupName: "",
    errors: {},

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    contactPerson: Joi.string().label("Contact Person"),
    entityName: Joi.string().label("Entity Name"),
    entityDescription: Joi.string().label("Description"),
    cell: Joi.string()
      .regex(/^[0-9]{10}$/)
      .label("Cell No"),
    email: Joi.string().label("Email Address").email(),
    userPassword: Joi.string().label("Login Password"),
  };

  async componentDidMount() {
    const entityID = this.state.myParams.id;
    const groupID = localStorage.getItem("groupID");
    const groupName = localStorage.getItem("groupName");

    // Set the State
    this.setState({ groupID, groupName });

    // get data from database to display options in textfields
    if (entityID === "new") return;
    let { data: entity } = await httpService.get(
      config.apiEndpointEntityGet + "?id=" + entityID
    );

    if (!entity) return this.state.myNavigate("/not-found");

    this.setState({ data: this.mapToViewModel(entity) });
  }

  // Submit a new Race

  doSubmit = async () => {
    const ID = this.state.myParams.id;
    // Get the group Id for a new entry
    const groupID = localStorage.getItem("groupID");

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // add the group ID to the form data
      formData.append("groupId", groupID);
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointEntitesAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointEntitesUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate("/pageEntity");
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointEntitesDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate("/pageEntity");
  };

  mapToViewModel(data) {
    return {
      contactPerson: data.contactPerson,
      entityName: data.entityName,
      entityDescription: data.entityDescription,
      cell: data.cell,
      email: data.email,
      userPassword: data.userPassword,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link to="/pageEntity" className="btn p-0">
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.groupName}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Entity Details</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Entity Name</td>
                      <td>{this.renderInput("entityName", "Enitity Name")}</td>
                    </tr>
                    <tr>
                      <td>Entity Description / Function</td>
                      <td>
                        {this.renderTextArea(
                          "entityDescription",
                          "Entity Description",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Contact Person</td>
                      <td>
                        {this.renderInput("contactPerson", "Contact Person")}
                      </td>
                    </tr>
                    <tr>
                      <td>Cell Number</td>
                      <td>{this.renderInput("cell", "Cell")}</td>
                    </tr>
                    <tr>
                      <td>Email Address</td>
                      <td>{this.renderInput("email", "Email")}</td>
                    </tr>
                    <tr>
                      <td>Password</td>
                      <td>{this.renderPassword("userPassword", "Password")}</td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(EntityForm);
