import React from "react";

//Services
import { getTickets } from "../services/fakeTicketService";
import { getTicketTypes } from "../services/fakeTicketTypeService";

//Own Components

import NavbarEntity from "../components/navbarEntity";
import _ from "lodash";

class PageHome extends React.Component {
  state = {
    ticketData: [],
    ticketTypesData: [],
    selectedTicketType: "",
    ticketsTotals: [],
    sortColumn: { path: "id", order: "asc" },
  };

  componentDidMount() {
    this.setState({
      ticketData: getTickets(),
      ticketTypesData: [{ _id: "", name: "All Tickets" }, ...getTicketTypes()],
    });
  }

  handleDelete = (ticketId) => {
    let ticketData = this.state.ticketData.filter((c) => c.id !== ticketId);
    this.setState({ ticketData: ticketData });
  };

  handleStatus = (ticket) => {
    console.log("in home handle Status");
    const ticketData = [...this.state.ticketData];
    const index = ticketData.indexOf(ticket);
    ticketData[index] = { ...ticket };
    ticketData[index].resolved = !ticketData[index].resolved;
    //Set the priority value so that a resolved card is not a priority
    if (ticketData[index].resolved) {
      ticketData[index].priority = false;
    }
    this.setState({ ticketData: ticketData });
  };

  handleToggleIcon = (ticket) => {
    const ticketData = [...this.state.ticketData];
    const index = ticketData.indexOf(ticket);
    ticketData[index] = { ...ticket };
    ticketData[index].priority = !ticketData[index].priority;

    this.setState({ ticketData: ticketData });
  };

  handleGenreSelect = (ticketType) => {
    this.setState({ selectedTicketType: ticketType, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      ticketData,
      ticketTypesData,
      selectedTicketType,
      ticketsTotals,
      sortColumn,
    } = this.state;

    // calculate the totals of deffents ticket states
    const pending = ticketData.filter((c) => c.resolved === false).length;
    const resolved = ticketData.filter((c) => c.resolved === true).length;
    const priority = ticketData.filter((c) => c.priority === true).length;
    const all = ticketData.length;

    ticketsTotals = [all, priority, pending, resolved];

    // This will filter the ticket list by Type and then pass it on to the Tickets class
    const filteredTickets =
      selectedTicketType && selectedTicketType._id
        ? ticketData.filter((m) => m.ticketType._id === selectedTicketType._id)
        : ticketData;

    const sorted = _.orderBy(
      filteredTickets,
      [sortColumn.path],
      [sortColumn.order]
    );

    return (
      <React.Fragment>
        <NavbarEntity />
        <div className="container container-top">
          <div className="row ">
            <div className="col listgroup">
              {/* <ListGroup
                items={ticketTypesData}
                selectItem={selectedTicketType}
                onItemSelect={this.handleGenreSelect}
              /> */}
            </div>
            <div className="col tickets">
              <div className="row">
                <div>
                  {/* <CalloutButtons ticketsTotals={ticketsTotals} /> */}
                </div>
                <div>
                  {/* <Tickets
                    ticketData={filteredTickets}
                    onDelete={this.handleDelete}
                    onStatus={this.handleStatus}
                    onToggleIcon={this.handleToggleIcon}
                    allTicketsTotal={all}
                  /> */}
                </div>
              </div>
            </div>

            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col">
              {/* <TicketList
                ticketData={this.state.ticketData}
                onDelete={this.handleDelete}
                onStatus={this.handleStatus}
                onToggleIcon={this.handleToggleIcon}
                allTicketsTotal={all}
                ticketsTotals={ticketsTotals}
              /> */}

              {/* <TicketListTable
                onDelete={this.handleDelete}
                // noOfMovies={totalCount}
                filteredTickets={sorted}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                onToggleIcon={this.handleToggleIcon}
                onStatus={this.handleStatus}
              /> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageHome;
