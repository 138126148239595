import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  Polyline,
  ScaleControl,
  Tooltip,
  useMapEvents,
} from "react-leaflet";

import { BaseMaps } from "./common/baseMaps.jsx";
import { GetGPXCoords } from "./getGPXCoords.jsx";
import { PoiLayerDrag } from "./poiLayerDrag.jsx";
import { PoiMarkerDrag } from "./poiMarkerDrag.jsx";

// Services
import httpService from "../services/httpService.js";
import config from "../config.json";

const center = {
  lat: 18.417152,
  lng: -33.927579,
};

const defaultMarker = {
  id: "new",
  POIName: "POI Name ",
  POIDesc: "POI Description",
  POIlatitiude: "",
  POIongitude: "",
  icon: {
    id: "164",
    iconName: "Default Icon",
    iconUrl: "default-Icon.png",
    shadowUrl: "default-Icon.png",
    iconUrlResolved: "default-Icon.png",
    iconSizeW: "40",
    iconSizeH: "45",
    shadowSizeW: "35",
    shadowSizeH: "45",
    iconAnchorH: "22",
    iconAnchorV: "35",
    shadowAnchorH: "22",
    shadowAnchorV: "40",
    popupAnchorH: "-3",
    popupAnchorV: "-76",
  },
};

// Replace this with a setting the user inputs
let lineOptions = { color: "purple" };

function refreshPOI() {
  console.log("in refresh POI mapPOI");
  window.location.reload(false);
}

function LocationMarker() {
  const [position, setPosition] = useState(null);
  const markerRef = useRef(null);
  const map = useMapEvents({
    click(e) {
      // map.locate();
      defaultMarker.POIlatitiude = e.latlng.lat;
      defaultMarker.POIongitude = e.latlng.lng;
      setPosition(e.latlng);
      // Now go and add the new POI marker to the Database and get the next records number
      //  then update defaultMarker.id with the new information. this way the new marker is created and it can be updated when moved

      // Get the next auto incremetn id and update the default marker
      // let { data: nextID } = httpService.get(config.apiEndpointPoiNextID);

      // console.log(nextID);

      const stageID = localStorage.getItem("stageID");

      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", "new");
      formData.append("stageID", stageID);
      formData.append("iconID", "164");
      formData.append("POIName", "POI Name");
      formData.append("POIDesc", "POI Description");
      formData.append("POIlattitude", e.latlng.lat);
      formData.append("POIlongitidue", e.latlng.lng);

      try {
        let answer = httpService.post(config.apiEndpointPoiAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          console.log(error.reponse);
      }

      // Now try and force a POI refresh by getting all the latest POIS and reload the state
      refreshPOI();
    },
  });

  // const stageID = localStorage.getItem("stageID");
  // useEffect(() => {
  //   const updatePOIs = async (stageID) => {
  //     console.log("in refresh POI");

  //     let { data: POIdata } = await httpService.get(
  //       config.apiEndpointPoigetGet + "?stageID=" + stageID
  //     );

  //     // this.setState({ POIMarkerData: POIdata });
  //   };

  //   updatePOIs(stageID);
  // }, []);

  return position === null ? null : <PoiMarkerDrag poiMarker={defaultMarker} />;
}

function GetPOIInformation() {
  const [POIMarkerDataLocal, setPOIMarkerDataLocal] = useState(null);

  useEffect(() => {
    async function getPOIdata() {
      const stageID = localStorage.getItem("stageID");
      try {
        // Get the list of poi markers for this stage
        let { data: POIdata } = await httpService.get(
          config.apiEndpointPoigetGet + "?stageID=" + stageID
        );
        setPOIMarkerDataLocal(POIdata);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          console.log(error.reponse);
      }
    }
    getPOIdata();
  }, []);
}

const mapPOI = ({
  centrePositionRet,
  mapSettingsID,
  mapURLs,
  POIMarkerData,
  stageID,
  zoomLevel,
}) => {
  if (zoomLevel && centrePositionRet) {
    return (
      <React.Fragment>
        <MapContainer
          center={centrePositionRet}
          zoom={zoomLevel}
          scrollWheelZoom
          fullscreenControl={true}
        >
          <LayersControl position="topright">
            <BaseMaps />
            <PoiLayerDrag POIMarkerData={POIMarkerData} />
          </LayersControl>

          {mapURLs &&
            mapURLs.map((mapURL) => (
              // (lineOptions.color = mapURL.lineColor),
              // console.log(mapURL.lineColor),
              <Polyline
                key={mapURL.gpxName}
                pathOptions={(lineOptions = { color: mapURL.lineColor })}
                positions={GetGPXCoords(mapURL.gpxName)}
              >
                <Popup closeButton={false}>{mapURL.description}</Popup>
              </Polyline>
            ))}
          <LocationMarker />
          <ScaleControl />
        </MapContainer>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export default mapPOI;
