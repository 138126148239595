import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import EntityStageCard from "../components/entityStageCard";

//Common
import SearchBox from "../components/common/searchBox";
import Pagination from "../components/common/pagination";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

//Utilites
import { paginate } from "../utils/paginate";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class PageEntityStages extends React.Component {
  state = {
    currentPage: "1",
    dataRetreived: [],
    entityID: "",
    groupName: "",
    groupID: "",
    pageSize: 2,
    raceId: "",
    searchQuery: "",
    sortColumn: { path: "raceName", order: "asc" },
  };

  async componentDidMount() {
    // get variables from the local storage
    const entityID = localStorage.getItem("entityID");
    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceId = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");

    // unset the variables in local storage
    localStorage.setItem("stageName", "");
    localStorage.setItem("stageID", "");

    //set State with the Variables
    this.setState({ groupName, groupID, raceId, raceNameYear, entityID });

    // Get Stages based on the Race id
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStagesGetByRaceID + "?raceID=" + raceId
    );
    this.setState({ dataRetreived: Dbdata });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const {
      currentPage,
      dataRetreived,
      entityID,
      pageSize,
      raceId,
      raceNameYear,
      searchQuery,
    } = this.state;

    let allDataTotal = 0;

    if (dataRetreived) {
      allDataTotal = dataRetreived.length;
    } else {
      allDataTotal = 0;
    }

    // Order the data
    const sortedData = _.orderBy(
      dataRetreived,
      [this.state.sortColumn.path],
      [this.state.sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.stageName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    allDataTotal = filtered.length;
    //Paginate the data
    const paginatedData = paginate(filtered, currentPage, pageSize);

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70 ">
          <div className="row">
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link to={`/pageRaceEntityHome/${raceId}`} className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>
              </span>

              <h5>{raceNameYear} - Stages</h5>
            </div>
            <div className="col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search stages..."
              />
            </div>
          </div>

          <div className="row card-race-display">
            {paginatedData.map((stage) => (
              <EntityStageCard
                distance={stage.distance}
                elevation={stage.elevation}
                endDate={stage.endDate}
                endVenue={stage.endVenue}
                key={stage.id}
                stageDescription={stage.stageDescription}
                stageId={stage.id}
                stageName={stage.stageName}
                stageType={stage.stageType.description}
                startDate={stage.startDate}
                startVenue={stage.startVenue}
              />
            ))}

            <Pagination
              itemsCount={allDataTotal}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageEntityStages;
