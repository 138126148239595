import React from "react";
import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilities
import { paginate } from "../utils/paginate";

class GroupUserListTable extends React.Component {
  state = {
    pageSize: 8,
    currentPage: 1,
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  columns = [
    {
      path: "name",
      label: "Name",
      content: (groupUser) => (
        <Link to={`/groupUserForm/${groupUser.id}`}>
          <h6>{groupUser.name}</h6>
        </Link>
      ),
    },
    { path: "surname", label: "Surname" },
    { path: "cell", label: "Cell" },
    { path: "email", label: "Email" },
    { path: "group.groupName", label: "Group" },
    { path: "userType.description", label: "Type" },
  ];

  render() {
    const {
      filteredGroupUsers,
      onSort,
      sortColumn,
      onDelete,
      allGroupUsersTotal,
    } = this.props;
    const { pageSize, currentPage } = this.state;
    const groupUsersPag = paginate(filteredGroupUsers, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={groupUsersPag}
            onDelete={onDelete}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allGroupUsersTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default GroupUserListTable;
