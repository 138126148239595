import React from "react";
import { Link } from "react-router-dom";

import {
  ChatSquareTextFill,
  CompassFill,
  DoorOpenFill,
  Geo,
  PinMapFill,
  TicketDetailed,
} from "react-bootstrap-icons";

import { FiUser } from "react-icons/fi";

class StageInfoSplash extends React.Component {
  render() {
    let {
      distance,
      elevation,
      myLatitude,
      myLongitude,
      stageDescription,
      stageName,
      stageType,
      stageID,
      vehicleID,
      vehicleName,
      vehicleRegistration,
    } = this.props;

    if (vehicleID == 0) {
      vehicleID = "";
    }

    return (
      <div className="col-sm-12 entity-splash-padding center">
        <div className="card fit-content center">
          <div className="card-body fit-content p-3">
            <h5 className="card-title">{stageName}</h5>
            <div className="card-text mb-2">{stageDescription}</div>
            <div className="card  ">
              <div className="row px-3 mt-2">
                <div className="col ">
                  <div className="button-center ">
                    <Link
                      to="/entityUserTicketForm/new"
                      className="btn btn-primary button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <Geo color="white" size={40} />
                    </Link>
                  </div>
                  <div className="text-darkblue card-icon-text">
                    Create a Ticket
                  </div>
                </div>
                <div className="col">
                  <div className="button-center">
                    <Link
                      to={`/pageEntityUserTicketCards/${stageID}`}
                      className="btn btn-primary button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <TicketDetailed color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue card-icon-text">
                    All Tickets
                  </div>
                </div>
              </div>

              <div className="row px-3">
                <div className="col">
                  <div className="button-center">
                    <Link
                      to={`/pageEntityMap/${stageID}`}
                      className="btn btn-primary button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <PinMapFill color="white" size={40} />
                    </Link>
                  </div>
                  <div className="text-darkblue card-icon-text">Map</div>
                </div>
                <div className="col">
                  <div className="button-center">
                    <Link
                      to={`/pageRideAlong/${stageID}`}
                      className="btn btn-primary button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <CompassFill color="white" size={40} />
                    </Link>
                  </div>
                  <div className="text-darkblue card-icon-text">Ride Along</div>
                </div>
              </div>

              <div className="row px-3">
                <div className="col">
                  <div className="button-center">
                    <Link
                      to={`/pageNotificationsEntity/${stageID}`}
                      className="btn btn-primary button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <ChatSquareTextFill color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue card-icon-text">
                    Notifications
                  </div>
                </div>
                <div className="col">
                  <div className="button-center">
                    {vehicleID && (
                      <Link
                        to={`/pageEntityUserPassengers/${stageID}`}
                        className="btn btn-primary button-color-dashboard"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 15,
                          height: 55,
                          marginBottom: 5,
                          marginTop: 5,
                          width: 60,
                        }}
                      >
                        <FiUser color="white" size={40} />
                      </Link>
                    )}
                  </div>
                  <div className="text-darkblue card-icon-text">
                    {vehicleID && "Passengers"}
                  </div>
                </div>
              </div>

              <div className="row px-3">
                <div className="col">
                  <div className="button-center">
                    <Link
                      to="/"
                      className="btn btn-primary button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <DoorOpenFill color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue center mb-1">Logout</div>
                </div>
              </div>
            </div>
          </div>
          Geo data: {myLatitude},{myLongitude}
        </div>
      </div>
    );
  }
}

export default StageInfoSplash;
