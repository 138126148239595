import React, { Component } from "react";
import { Link } from "react-router-dom";

//Utilities
import Joi from "joi-browser";

//Components
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "../../components/navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class RaceUserAllocationForm extends Form {
  state = {
    data: {
      teamMemberID: "",
      TMUTypeid: "",
    },

    errors: {},
    groupName: "",
    groupID: "",
    races: [],
    raceID: "",
    raceNameYear: "",
    userTypes: [],
    teamMembers: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    teamMemberID: Joi.string().label("Team Member"),
    TMUTypeid: Joi.string().label("Team Member Type"),
  };

  async componentDidMount() {
    const raceUserAllocID = this.state.myParams.id;

    // Get the Group Race data stored in memory
    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");

    // Set the State
    this.setState({ groupName, groupID, raceID, raceNameYear });

    // Get the list of Team Users

    // Fill the drop down list based on if its a new entry ie not already allocated
    // or load the fill list so the user can select an exiting one
    if (raceUserAllocID === "new") {
      let { data: retrievedTeamMembers } = await httpService.get(
        config.apiEndpointTeamUsersUnallocatedGet + "?groupID=" + groupID
      );
      this.setState({ teamMembers: retrievedTeamMembers });
    } else {
      let { data: retrievedTeamMembers } = await httpService.get(
        config.apiEndpointTeamUsersGet + "?groupID=" + groupID
      );
      this.setState({ teamMembers: retrievedTeamMembers });
    }

    // Get the list of Team User Types
    let { data: retrievedTeamUserTypes } = await httpService.get(
      config.apiEndpointTeamUsersTypesGet
    );
    this.setState({ userTypes: retrievedTeamUserTypes });

    if (raceUserAllocID === "new") return;
    let { data: raceUserAllocData } = await httpService.get(
      config.apiEndpointSingleTeamUserRaceAllocationGet +
        "?id=" +
        raceUserAllocID
    );

    if (!raceUserAllocData) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(raceUserAllocData) });
  } // close component did mount

  //Create or update a user
  doSubmit = async () => {
    const ID = this.state.myParams.id;

    if (ID === "new") {
      // Get the group Id for a new entry
      const groupID = localStorage.getItem("groupID");

      // Add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();
      // add the group ID to the form data
      formData.append("groupId", groupID);
      formData.append("raceID", this.state.raceID);
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointTeamUserRaceAllocationAdd,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      formData.append("raceID", this.state.raceID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointTeamUserRaceAllocationUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate(`/pageRaceUserAllocation/${this.state.raceID}`);
  };

  //Delete a  Group User
  doDelete = async () => {
    const ID = this.state.myParams.id;

    try {
      const deleteReply = await httpService.get(
        config.apiEndpointTeamUserRaceAllocationDelete + "?id=" + ID
      );
      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/pageRaceUserAllocation/${this.state.raceID}`);
  };

  mapToViewModel(raceUseAllocData) {
    return {
      teamMemberID: raceUseAllocData.teamUserID,
      TMUTypeid: raceUseAllocData.teamUserRoleID,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={`/pageRaceUserAllocation/${this.state.raceID}`}
                          className="btn p-0"
                        >
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.raceNameYear}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Team Member Allocation</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Team Member</td>
                      <td>
                        {this.renderSelect(
                          "teamMemberID",
                          "nameSurname",
                          this.state.teamMembers,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>User Type</td>
                      <td>
                        {this.renderSelect(
                          "TMUTypeid",
                          "TMUDescription",
                          this.state.userTypes,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(RaceUserAllocationForm);
