import React, { Component } from "react";

import { Link } from "react-router-dom";

//Components
import NotificationList from "../components/notificationList";
import NavbarRace from "../components/navbarRace";
import withMyHook from "./../components/common/withMyHook";

// other
import { ToastContainer, Zoom, toast } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class PageNotificationsEntity extends Component {
  state = {
    entityID: "",
    goToPage: "",
    gotToForm: "",
    groupName: "",
    groupID: "",
    stageID: "",
    raceID: "",
    raceNameYear: "",
    stage: "",

    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
    interval: "",

    NotificationDataRetreived: [],

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    this.on();
    const stageID = this.state.myParams.id;

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    const userTypeID = localStorage.getItem("userTypeID");

    this.setState({
      entityID,
      groupName,
      groupID,
      stageID,
      raceID,
      raceNameYear,
    });

    // Get the Stage details for display to the user
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );
    this.setState({ stage });

    // set the stage ID
    localStorage.setItem("stageName", stage.stageName);
    localStorage.setItem("stageID", stageID);

    let goToPage = "";
    if (userTypeID === "3") goToPage = `pageRaceHome/${raceID}`;
    if (userTypeID === "4") goToPage = `pageRaceEntityHome/${raceID}`;
    if (userTypeID === "5") goToPage = `pageEntityUserHome`;

    let gotToForm = "";
    if (userTypeID === "3") gotToForm = `notificationForm/new`;
    if (userTypeID === "4") gotToForm = `notificationEntityForm/new`;

    this.setState({ goToPage, gotToForm });

    this.off();

    // GPS location storage
    window.navigator.geolocation.getCurrentPosition((success) =>
      this.setState({
        myLatitude: success.coords.latitude,
        myLongitude: success.coords.longitude,
      })
    );

    if (navigator.geolocation) {
      navigator.geolocation.enableHighAccuracy = true;
      navigator.geolocation.watchPosition((position) =>
        this.setState({
          myLatitude: position.coords.latitude,
          myLongitude: position.coords.longitude,
          myAltitude: position.coords.altitude,
          myHeading: position.coords.heading,
          mySpeed: position.coords.speed,
          mySpeedKM: position.coords.speed * 3.6,
        })
      );
    }

    // 5000 = 10 seconds
    const MINUTE_MS = 5000;
    const interval = setInterval(() => {
      this.sendData();
    }, MINUTE_MS);
  }

  async sendData() {
    // Get the latest Geo data
    let {
      myLatitude,
      myLongitude,
      myAltitude,
      myHeading,
      mySpeed,
      mySpeedKM,
      entityID,
      stageID,
      entityUserId,
    } = this.state;

    // Set Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLongitude", myLongitude);
    localStorage.setItem("myAltitude", myAltitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);

    // Create a numeric outut format type ES6 style
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Format the data to two decimal points
    myAltitude = formatter.format(myAltitude);
    myHeading = formatter.format(myHeading);
    mySpeed = formatter.format(mySpeed);
    mySpeedKM = formatter.format(mySpeedKM);

    //  Create time and date of geo location
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const today = new Date().toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    // set the Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLatitude", myLatitude);

    const time = new Date().toLocaleTimeString();

    // go to database and store the info everytime there is a location update
    let formData = new FormData();
    //add the data to formdata to send to db
    formData.append("latitude", myLatitude);
    formData.append("longitude", myLongitude);
    formData.append("altitude", myAltitude);
    formData.append("heading", myHeading);
    formData.append("speed", mySpeedKM);
    formData.append("time", time);
    formData.append("date", today);
    formData.append("timestamp", timestamp);
    formData.append("stageID", stageID);
    formData.append("entityUserId", entityUserId);
    formData.append("entityID", entityID);

    // send the data to the database via Http
    if (myLatitude != "" && myLongitude != "") {
      try {
        const { data: retrievedGeoData } = await httpService.post(
          config.apiEndpointSendEUGeoLocation,
          formData
        );

        toast.info("Sent");
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    }
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let { color, cssOverride, goToPage, gotToForm, stage, loading } =
      this.state;

    return (
      <React.Fragment>
        <NavbarRace />
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div id="overlay" className="overlay" onClick={() => this.off()}>
          <div id="text" className="text">
            <PuffLoader
              color={color}
              loading={loading}
              cssOverride={cssOverride}
              size={50}
            />
            Loading Data...
          </div>
        </div>
        <div className="container container-top-70 ">
          <div className="row" data-aos="zoom-in" data-aos-duration="5000">
            <div className="row">
              <div className="button-padding ">
                <Link to={`/${goToPage}`} className="btn p-0 ">
                  <ArrowLeftCircle color="#002072" size={35} />
                </Link>
                {gotToForm && (
                  <Link to={`/${gotToForm}`} className="btn p-0">
                    <PlusCircle color="#002072" size={35} />
                  </Link>
                )}
                <h5>{stage.stageName} - Notifications</h5>
              </div>
            </div>
            <NotificationList />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageNotificationsEntity);
