import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarSiteAdmin from "../components/navbarSiteAdmin";
import GroupCard from "./../components/groupCard";

//Common
import Pagination from "../components/common/pagination";
import SearchBox from "../components/common/searchBox";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

//Utilities
import { paginate } from "../utils/paginate";

//Other
import {
  ArrowCounterclockwise,
  ArrowLeftCircle,
  PlusCircle,
} from "react-bootstrap-icons";

class PageGroups extends React.Component {
  state = {
    currentPage: "1",
    dataRetreived: [],
    pageSize: 3,
    searchQuery: "",
    sortColumn: { path: "groupName", order: "asc" },
  };

  async componentDidMount() {
    let { data: Dbdata } = await httpService.get(config.apiEndpointGroupsGet);
    this.setState({ dataRetreived: Dbdata });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const { dataRetreived, currentPage, pageSize, searchQuery } = this.state;

    let allDataTotal = dataRetreived.length;

    // Order the data
    const sortedData = _.orderBy(
      dataRetreived,
      [this.state.sortColumn.path],
      [this.state.sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.groupName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    allDataTotal = filtered.length;

    //Paginate the data
    const paginatedData = paginate(filtered, currentPage, pageSize);

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarSiteAdmin />

        <div className="container container-top-70 ">
          <div className="row">
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link to="/pageSiteAdmin" className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={35} />
                </Link>
              </span>

              <Link to="/groupForm/new" className="btn p-0">
                <PlusCircle color="#002072" size={35} />
              </Link>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Names..."
              />
            </div>
          </div>
          <div className="row card-group-display">
            {paginatedData.map((group) => (
              <GroupCard
                key={group.id}
                addressOne={group.addressLineOne}
                addressTwo={group.addressLineTwo}
                addressThree={group.addressLineThree}
                addressSuburb={group.addressSuburb}
                addressTownCity={group.addressTownCity}
                addressProvince={group.addressProvince}
                addressCountry={group.addressCountry}
                addressCode={group.addressCode}
                contact_person={group.contactPerson}
                groupId={group.id}
                groupName={group.groupName}
                tel={group.tel}
              />
            ))}
            <Pagination
              itemsCount={allDataTotal}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageGroups;
