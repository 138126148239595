import React from "react";
import EntityMapAccord from "../components/entityMapAccord";
import EntitySettingsAccord from "../components/entitySettingsAccord";

class EntityOffCanvas extends React.Component {
  state = {
    // Firstname: "Donavan",
  };
  render() {
    const {
      autoRefreshEntityUsers,
      autoRefreshTickets,
      autoRefreshVehicles,
      entityUserData,
      mapInstance,
      refreshTickets,
      refreshEntityUsers,
      refreshVehicles,
      ticketMarkerData,
      vehicleData,
    } = this.props;

    function handleFlyTo(coordintes) {
      mapInstance.flyTo(coordintes, 15, { duration: 2 });
    }

    return (
      <React.Fragment>
        <div
          className="offcanvas offcanvas-start"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabIndex="-1"
          id="offcanvasScrolling"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              Map Settings
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>

          <div className="offcanvas-body  margin-right-20">
            <EntitySettingsAccord
              refreshTickets={refreshTickets}
              refreshEntityUsers={refreshEntityUsers}
              refreshVehicles={refreshVehicles}
              autoRefreshTickets={autoRefreshTickets}
              autoRefreshEntityUsers={autoRefreshEntityUsers}
              autoRefreshVehicles={autoRefreshVehicles}
            />
            <EntityMapAccord
              entityUserData={entityUserData}
              ticketMarkerData={ticketMarkerData}
              onFlyTo={handleFlyTo}
              vehicleData={vehicleData}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EntityOffCanvas;
