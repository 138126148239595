import React from "react";
import { Link } from "react-router-dom";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

//Components
import EntityUserInfoSplash from "./../components/EntityUserInfoSplash";
import EntityJocRepInfoSplash from "./../components/entityJocRepInfoSplash";

//Other
import {
  Calendar2Event,
  DoorOpenFill,
  GeoAltFill,
  PersonFillGear,
  PersonVcardFill,
  PersonWorkspace,
} from "react-bootstrap-icons";
import { GiCarWheel } from "react-icons/gi";

class entityHero extends React.Component {
  state = {
    entityName: "",
    groupName: "",
  };

  async componentDidMount() {
    // get data from the database
    const entityID = localStorage.getItem("entityID");
  }

  render() {
    const { entityID, entityName, groupName, data } = this.props;
    const { entityUserDataRetreived, entityJOCDataRetreived } = this.state;

    return (
      <div>
        <div className="row pb-0 pe-lg-0  align-items-center rounded-4 border shadow-lg dashboard-brad px-3 dashboard-min-width">
          <div className=" p-lg-5 pt-lg-3">
            <h4 className="display-6 fw-bold lh-3 mt-3">{entityName}</h4>

            <h6> ({groupName})</h6>

            <div className=" card px-3 mb-4 mb-lg-3 center-horizontal-components container-fluid ">
              <div className="row container-fluid dashboard-bottom-margin mt-2">
                <div className="col bt-2 ">
                  <div className="button-center">
                    <Link
                      to="/pageEntityRaces"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <Calendar2Event color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Races</div>
                </div>

                <div className="col">
                  <div className="button-center">
                    <Link
                      to={`/pageEnityUsers/${entityID}`}
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <PersonVcardFill color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Members</div>
                </div>
              </div>

              <div className="row container-fluid  mb-2">
                <div className="col">
                  <div className="button-center">
                    <Link
                      to="/pageEntityUserRoles"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <PersonFillGear color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Roles</div>
                </div>

                <div className="col">
                  <div className="button-center">
                    <Link
                      to={`/pageEntityJocRep/${entityID}`}
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <PersonWorkspace color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">JOC Reps</div>
                </div>
              </div>

              <div className="row container-fluid  mb-2">
                <div className="col">
                  <div className="button-center">
                    <Link
                      to={`/entityDefaultIconForm/${entityID}`}
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <GeoAltFill color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Icon</div>
                </div>

                <div className="col">
                  <div className="button-center">
                    <Link
                      to={`/pageVehicles/${entityID}`}
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <GiCarWheel color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Vehicles</div>
                </div>
              </div>
              <div className="row container-fluid  mb-2">
                <div className="col">
                  <div className="button-center">
                    <Link
                      to="/"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <DoorOpenFill color="white" size={30} />
                    </Link>
                  </div>
                  <div className="text-darkblue">Logout</div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default entityHero;
