import React, { Component } from "react";
import NavbarSiteAdmin from "../components/navbarSiteAdmin";

class pageTestPHPData extends React.Component {
  state = {
    cell: "+27835652575",
    users: "",
  };

  componentDidMount() {
    try {
      fetch("https://developsoftware.co.za/spems/groups/getGroups.php")
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            items: json,
            DataisLoaded: true,
          });
        });
    } catch (error) {
      console.log("error = " + error);
    }
  }
  // fetch("https://bmwmotomarshals.co.za/pages/mobile/get_marshaltest", {

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const { DataisLoaded, items } = this.state;
    if (!DataisLoaded)
      return (
        <div>
          <h1> Please wait.... </h1>
        </div>
      );

    return (
      <div className="App">
        <h1> Fetch data from an api in react </h1>
        {items.map((item) => (
          <ol key={item.id}>
            User_Name: {item.id}, Full_Name: {item.groupName}, User_Email:
            {item.Email}
          </ol>
        ))}
      </div>
    );
  }
}

export default pageTestPHPData;
