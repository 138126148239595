import React from "react";
import { Link } from "react-router-dom";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
//Other
import { Diagram2Fill } from "react-bootstrap-icons";

class SiteAdminHero extends React.Component {
  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    return (
      <div className="container ">
        <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
          <div className=" p-3 p-lg-5 pt-lg-3">
            <h4 className="display-6 fw-bold lh-3">Sports Event Management</h4>
            Quickly design and customize race events with the ability to create
            multiple race maps,stages tickets, participants and race entities.
            Stay in contact with the teams in the field. The leader in sport
            event software giving you the power to manage all aspects of your
            sport event. TracKtion is a cloud based system that keeps all
            entities informed with events, as they happen.
            <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
              <Link
                to="/pageGroup"
                className="btn btn-outline-primary btn-sm px-4"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                Groups
              </Link>
              <Link
                to="/pageGroupUser"
                className="btn btn-outline-primary btn-sm px-4"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                Users
              </Link>
              <Link
                to="/pageSiteAdminRaces"
                className="btn btn-outline-primary btn-sm px-4"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                Races
              </Link>
              <Link
                to=""
                className="btn btn-outline-primary btn-sm px-4"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                Icons
              </Link>
              <div className="col">
                <div className="button-center">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("Entity User Tracking")}
                  >
                    <Link
                      to="/pageEntityUserTracking"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <Diagram2Fill color="white" size={30} />
                    </Link>
                  </OverlayTrigger>
                </div>
                <div className="text-darkblue">Entity user Tracking</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SiteAdminHero;
