import React, { useState } from "react";
import { Link } from "react-router-dom";

//components
import ToggleIcon from "./common/toggleIcon";
import Form from "./../components/common/form";
import TicketComment from "./ticketcomment";

// Utilities
import { ToastContainer, Zoom } from "react-toastify";
import Joi from "joi-browser";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

//Other
import {
  ArrowCounterclockwise,
  Send,
  Clock,
  Diagram2Fill,
  GeoAltFill,
  Image,
  PencilSquare,
  PersonVcardFill,
  Whatsapp,
} from "react-bootstrap-icons";
import { BsChatSquareDots, BsListUl } from "react-icons/bs";
import GLightbox from "glightbox";

class Ticket extends Form {
  state = {
    data: {
      commentText: "",
    },
    allocToEntityId: this.props.ticket.allocToEntityId,
    commentsTotal: "",
    entityUserID: this.props.ticket.enitityUserId,
    goToPage: "",
    ticketIdLocal: this.props.ticket.id,

    entityUsersDB: [],
    errors: {},
    ticketCommentsData: [],
  };

  schema = {
    commentText: Joi.string().label("Ticket comment"),
  };

  async componentDidMount() {
    const entityID = localStorage.getItem("entityID");
    const stageID = localStorage.getItem("stageID");
    const userTypeID = localStorage.getItem("userTypeID");
    const raceNameYear = localStorage.getItem("raceNameYear");

    // Get the  ticket comments and send the data to the ticket comment component
    let { data: ticketCommentsData } = await httpService.get(
      config.apiEndpointEntityTicketCommentsGet +
        "?ticketID=" +
        this.state.ticketIdLocal
    );
    const commentsTotal = ticketCommentsData.length;

    // Get the list of Entity users / members
    // Get the entity users that are linked to the stage and Entity
    let { data: entityUsersDB } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationsReturnUserID +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );

    this.setState({
      entityUsersDB,
      ticketCommentsData,
      commentsTotal,
      raceNameYear,
    });

    if (userTypeID == "5") {
      this.state.goToPage = "/entityUserTicketForm/" + this.state.ticketIdLocal;
    }
    if (userTypeID == "4") {
      this.state.goToPage = "/ticketform/" + this.state.ticketIdLocal;
    }
    if (userTypeID == "3") {
      this.state.goToPage = "/ticketform/" + this.state.ticketIdLocal;
    }

    // Disable the select entity user allocation
    // if this is a entity user member/
    // and  if the ticket acllocation is not allocated to this entity
    let str1 = "abcClean";
    let accordId = str1.concat(this.state.ticketIdLocal);
    // let select = document.getElementById(accordId);

    // if (userTypeID == 5 || this.state.allocToEntityId != entityID) {
    //   select.disabled = true;

    //   // Set the link so that the entity is unable to clikc through to the form
    //   // page to edit the ticket. it is under the control the allocted entity
    //   this.state.goToPage = "";
    // } else {
    //   if (this.state.entityUserID) {
    //     //Set the select to the user Id retrived from the database
    //     select.value = this.state.entityUserID;
    //   }
    // }

    // Now disable the heading click through  resiolved and Priority buttins
    // if not out allocation entity
  }

  // this code delays a function by a set amount of time
  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  updateLocalCommentsSave = async (data, ticketIdLocal, ticket) => {
    // Validate the comment before updating
    if (data.commentText === "") {
    } else {
      // Go and save the new comment in the database
      this.props.onCommentSave(data, ticketIdLocal, ticket);

      // now clear the text area so that a new comment can be made.
      let dataclear = {
        commentText: "",
      };
      this.setState({ data: this.mapToViewModel(dataclear) });

      // now wait for the new comment to save to the database and then update
      // the state with new comments
      this.delay(1000).then(() => this.refreshComments());
    } //close else of if
  };

  handleUserSelectSave = async (ticketIdLocal) => {
    let str1 = "abcClean";
    let accordId = str1.concat(this.state.ticketIdLocal);

    let select = document.getElementById(accordId);
    let entityUserID = select.options[select.selectedIndex].value;

    // now go to the higher component and update the database with the new allocation
    this.props.onEntityUserUpdate(ticketIdLocal, entityUserID);
  };

  refreshComments = async () => {
    // get the  ticket comments and send the data to the ticket comment component
    let { data: ticketCommentsData } = await httpService.get(
      config.apiEndpointEntityTicketCommentsGet +
        "?ticketID=" +
        this.state.ticketIdLocal
    );

    const commentsTotal = ticketCommentsData.length;
    this.setState({
      ticketCommentsData,
      commentsTotal,
    });
  };

  autoRefreshComments = async () => {
    // this code will be used to refresh the tickets every 1 minute
    let ticketIdLocal = this.state.ticketIdLocal;
    let checkboxname = "#a" + ticketIdLocal;

    const cb = document.querySelector(checkboxname);

    if (cb.checked == true) {
      // set the 1 minute to a variable and let the user set it
      this.interval = setInterval(() => this.refreshComments(), 6000);
    } else {
      clearInterval(this.interval);
    }
  };

  getBadgeClasses() {
    let classes = "badge rounded-pill ";
    classes +=
      this.props.ticket.resolved === true
        ? "bg-success m-2 badgePadding toggle-shadow"
        : "bg-warning m-2 badgePadding toggle-shadow";
    return classes;
  }

  getBadgeText() {
    let badgeText =
      this.props.ticket.resolved === true ? "Resolved" : "Pending";
    return badgeText;
  }
  createIDHash() {
    let str1 = "#abc";
    let accordIdHash = str1.concat(this.state.ticketIdLocal);

    return accordIdHash;
  }
  createID() {
    let str1 = "abc";
    let accordId = str1.concat(this.state.ticketIdLocal);
    return accordId;
  }

  createIDHash2() {
    let str1 = "#abc2";
    let accordIdHash = str1.concat(this.state.ticketIdLocal);

    return accordIdHash;
  }
  createID2() {
    let str1 = "abc2";
    let accordId = str1.concat(this.state.ticketIdLocal);
    return accordId;
  }

  createIDSelectClean() {
    let str1 = "abcClean";
    let accordId = str1.concat(this.state.ticketIdLocal);
    return accordId;
  }

  createCommentRefreshCheck() {
    let str1 = "abc";
    let accordId = str1.concat(this.state.ticketIdLocal);
    return accordId;
  }

  mapToViewModel(data) {
    return {
      commentText: "",
    };
  }

  mapToViewModelEntityUser(data) {
    return {
      entityUserID: data.entityUserID,
    };
  }

  liked() {
    let element = document.getElementById("like");
    element.classList.toggle("liked");
  }

  render() {
    const { ticket, onStatus, onToggleIcon, onCommentSave } = this.props;
    let {
      commentsTotal,
      ticketCommentsData,
      data,
      ticketIdLocal,
      goToPage,
      raceNameYear,
    } = this.state;
    let checkboxname = "a" + ticketIdLocal;
    let createdbyName = "";
    let createdbySurname = "";

    let filetype = ticket.fileType;
    let mediaURL = "https://www.tracktion.co.za/uploadedimages/";
    let fileName = ticket.fileName;
    mediaURL = mediaURL + fileName;

    if (filetype == "mp4") {
      filetype = "video";
      // mediaURL = mediaURL + "62cd5bb2d2726.mp4";
    } else {
      filetype = "image";
    }

    const myLightbox = GLightbox({
      elements: [
        {
          href: mediaURL,
          type: filetype,
          title:
            "(" + this.state.ticketIdLocal + ") " + ticket.entityTicketTypename,
          description: ticket.comment,
          // width: 900,
          // source: "tracKtion",
        },
      ],
    });

    // Place code that ensures that the correct persons name that has placed the ticket goes here.

    if (ticket.entityJocRepID != 0) {
      // If a joc rep  creates the ticket
      createdbyName = "(JOC Rep) " + ticket.entityJocRepName;
      createdbySurname = ticket.entityJocRepSurname;
    } else {
      // if an Entity user places the ticket
      createdbyName = ticket.createdByEntityUserName;
      createdbySurname = ticket.createdByEntityUserSurname;
    }

    let alertCell = "";
    let alertName = "";

    if (ticket.ticketType.alertCell) {
      alertCell = ticket.ticketType.alertCell.substring(1, 10);
    }
    alertName = ticket.ticketType.alertName;

    let whatsAppmessage = "";

    if (ticket.fileType) {
      whatsAppmessage = `https://wa.me/+27${alertCell}?text= *VOC Ticket Alert:* %0a Dear ${alertName}. %0a A ticket has been created for your attention. %0a *${ticket.entityTicketTypename}* Ticket No.(${this.state.ticketIdLocal}) %0a Comment: "${ticket.comment}" %0a Google Maps:  https://www.google.com/maps/place/${ticket.latitude},${ticket.longitude} %0a Image: https://tracktion.co.za/uploadedimages/${fileName} %0a Thank You %0a ${raceNameYear} `;
    } else {
      whatsAppmessage = `https://wa.me/+27${alertCell}?text= *VOC Ticket Alert:* %0a Dear ${alertName}. %0a A ticket has been created for your attention. %0a *${ticket.entityTicketTypename}* Ticket No.(${this.state.ticketIdLocal}) %0a Comment: "${ticket.comment}" %0a Google Maps:  https://www.google.com/maps/place/${ticket.latitude},${ticket.longitude}  %0a Thank You %0a ${raceNameYear} `;
    }

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={1000}
          position="top-center"
        />
        <div className="card m-2 ">
          <div>
            <div className="card-body">
              <div className="cardHeading">
                <h5 className="card-title">
                  <div>
                    <Link to={`${goToPage}`}>
                      <div className="card-heading-text">
                        ({this.state.ticketIdLocal}){" "}
                        {ticket.entityTicketTypename}
                      </div>
                    </Link>
                  </div>
                </h5>
                <div className="center-vertical-components">
                  <div
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Click to mark ticket as resolved"
                    style={{ cursor: "pointer" }}
                    onClick={() => onStatus(ticket)}
                    className={this.getBadgeClasses()}
                  >
                    {this.getBadgeText()}
                  </div>
                  <ToggleIcon
                    id=""
                    iconPriority={ticket.priority}
                    onClick={() => onToggleIcon(ticket)}
                  />
                </div>
              </div>
              <div className="card-text marginBottom10 ">{ticket.comment}</div>

              {/* Accordian starts here*/}
              <div className="media"></div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={this.createIDHash()}
                      aria-expanded="false"
                      aria-controls={this.createID()}
                    >
                      <div className="accordion-heading-text ">
                        <BsListUl />
                        <span className="icon-text-padding">Details</span>
                      </div>
                    </button>
                  </h2>
                  <div
                    id={this.createID()}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="card details-card-ticket">
                        <div className="row">
                          <div className="col-sm">
                            <div className="details-align">
                              <div className="details-icon">
                                <Clock color="#4e73df" size="20" />
                              </div>
                              <div>{ticket.ticketTime}</div>
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="details-align">
                              <div className="details-icon">
                                <PersonVcardFill color="#4e73df" size="20" />{" "}
                              </div>
                              {ticket.participantNo}
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="details-align">
                              <div className="details-icon">
                                <Image color="#4e73df" size="20" />
                              </div>
                              <span className="media-color">
                                <a
                                  onClick={() => myLightbox.open()}
                                  style={{ cursor: "pointer" }}
                                >
                                  {ticket.fileType}
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm">
                            <div className="details-align">
                              <div className="details-icon">
                                <Whatsapp color="#128c7e" size={20} />
                              </div>
                              <div className="">
                                {/* alertName */}
                                <a href={whatsAppmessage} target="_blank">
                                  Send Message
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="details-align">
                              <div className="details-icon">
                                <Diagram2Fill color="#4e73df" size="20" />
                              </div>
                              {ticket.fromEntityname}
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="details-align">
                              <div className="details-icon">
                                <PencilSquare color="#4e73df" size="20" />
                              </div>
                              {createdbyName} {createdbySurname}
                            </div>
                          </div>
                        </div>
                        <div className="row geo-row">
                          <div className="col-sm justify-content-center d-flex ">
                            <GeoAltFill color="#4e73df" size="20" />
                            <a
                              href={`https://www.google.com/maps/place/${ticket.latitude},${ticket.longitude}`}
                              target="_blank"
                            >
                              {ticket.latitude} {","}
                              {ticket.longitude}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Comment accordian starts here */}
              <div className="accordion accordion-flush" id="accordionComments">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={this.createIDHash2()}
                      aria-expanded="false"
                      aria-controls={this.createID2()}
                    >
                      <div className="accordion-heading-text ">
                        <BsChatSquareDots />
                        <span className="icon-text-padding">
                          Comments
                          {commentsTotal > 0 && (
                            <span className="badge bg-info rounded-circle comment-counter-space">
                              {commentsTotal}
                            </span>
                          )}
                        </span>
                      </div>
                    </button>
                  </h2>
                  <div
                    id={this.createID2()}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionComments"
                  >
                    <div className="accordion-body">
                      <section className="content-item">
                        <div className="container accord-set-padding">
                          <div className="row ">
                            <div className="col">
                              <TicketComment
                                ticketCommentsData={ticketCommentsData}
                              />

                              <div className="row  container-fluid group-center  comment-zero-marg-padd">
                                <div className="input-group  container-fluid d-flex   comment-zero-marg-padd ">
                                  <span className="input-group-text ">
                                    <button
                                      className="btn  btn-sm button-margin-clear button-padding-clear button-comment"
                                      onClick={this.refreshComments}
                                    >
                                      <ArrowCounterclockwise
                                        color="#4e73df"
                                        size={20}
                                      />
                                    </button>
                                  </span>
                                  {this.renderTextArea(
                                    "commentText",
                                    "Ticket Comment",
                                    "",
                                    "",
                                    "1"
                                  )}
                                  <span className="input-group-text">
                                    <Link
                                      to=""
                                      onClick={() =>
                                        this.updateLocalCommentsSave(
                                          data,
                                          ticketIdLocal,
                                          ticket
                                        )
                                      }
                                      className="btn btn-sm button-margin-clear button-padding-clear button-comment"
                                    >
                                      <Send color="#4e73df" size={20} />
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } //close render
} // close Counter class

export default Ticket;
