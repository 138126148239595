import React from "react";
import { SiOpenstreetmap } from "react-icons/si";

export const EntityUserMap = ({ onFlyTo, entityUser }) => {
  const coordinates = [entityUser.latitude, entityUser.longitude];

  if (entityUser.latitude === "") {
    return (
      <div className="marginTop-5 shadow">
        <div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <h5 className="card-title">{entityUser.fullName}</h5>
                  <p className="card-text">{entityUser.userRole}</p>
                </div>
                <div className="col center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="marginTop-5 shadow">
        <div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <h5 className="card-title">{entityUser.fullName}</h5>
                  <p className="card-text">{entityUser.userRole}</p>
                </div>
                <div className="col center">
                  <button
                    className="btn btn-secondry-green button-color-dashboard"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasScrolling"
                    aria-controls="offcanvasScrolling"
                    onClick={() => onFlyTo(coordinates)}
                  >
                    <SiOpenstreetmap color="white" size={20} />
                  </button>
                </div>
                <div>
                  <p>
                    Last GEO location time: ({entityUser.time})<br />
                    Cell Number: {entityUser.cell}
                  </p>
                  <a
                    href={`https://www.google.com/maps/place/${entityUser.latitude},${entityUser.longitude}`}
                    target="_blank"
                  >
                    Google Maps:{entityUser.latitude} , {entityUser.longitude}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
