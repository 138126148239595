import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";

import NavbarGroup from "../../components/navbarGroup";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class StageJocRepAllocationForm extends Form {
  state = {
    data: {
      entityJocRepID: "",
    },

    entityID: "",
    raceNameYear: "",
    stageID: "",
    stageName: "",

    errors: {},
    entityJocRepData: [],
    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    entityJocRepID: Joi.string().label("Joc Representative"),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;
    const stageID = localStorage.getItem("stageID");
    const stageName = localStorage.getItem("stageName");
    const entityID = localStorage.getItem("entityID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const groupID = localStorage.getItem("groupID");

    // Set the State
    this.setState({ entityID, groupID, raceNameYear, stageID, stageName });

    // Load the Select boxes with the data.

    // Get Entity joc reps by entity ID
    // Fill the drop down list based on if its a new entry ie not already allocated
    // or load the fill list so the user can select an exiting one
    if (ID === "new") {
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointEntityJocRepsUnallocatedGet + "?entityID=" + entityID
      );
      this.setState({ entityJocRepData: Dbdata });
    } else {
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointEntityJocRepsGet + "?entityID=" + entityID
      );
      this.setState({ entityJocRepData: Dbdata });
    }

    // Get data from database to display options in textfields
    if (ID === "new") return;
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointStageJocRepAllocationGet + "?id=" + ID
    );

    if (!retreivedData) return this.state.myNavigate("/not-found");
    this.setState({
      data: this.mapToViewModel(retreivedData),
    });
  }

  doSubmit = async () => {
    // Get the group Id for a new entry
    const ID = this.state.myParams.id;

    if (ID === "new") {
      // Add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // Add the group ID to the form data
      formData.append("stageID", this.state.stageID);
      formData.append("entityID", this.state.entityID);
      formData.append("groupID", this.state.groupID);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointStageJocRepAllocationAdd,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointStageJocRepAllocationUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate(
      `/pageStageEntityJocRepAllocation/${this.state.stageID}`
    );
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointStageJocRepAllocationDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(
      `/pageStageEntityJocRepAllocation/${this.state.stageID}`
    );
  };

  mapToViewModel(data) {
    return {
      entityJocRepID: data.entityJocRepID,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={`/pageStageEntityJocRepAllocation/${this.state.stageID}`}
                          className="btn p-0"
                        >
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.stageName}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Member Allocation</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Joc Representative</td>
                      <td>
                        {this.state.entityJocRepData &&
                          this.renderSelect(
                            "entityJocRepID",
                            "nameSurname",
                            this.state.entityJocRepData,
                            ""
                          )}
                      </td>
                    </tr>
                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(StageJocRepAllocationForm);
