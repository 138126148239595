import React from "react";
import { geolocated } from "react-geolocated";

// Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";
import Form from "../components/common/form";
import NavbarSiteAdmin from "./../components/navbarSiteAdmin";

class Demo extends Form {
  state = {
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
  };

  componentDidMount() {
    window.navigator.geolocation.getCurrentPosition((success) =>
      this.setState({
        myLatitude: success.coords.latitude,
        myLongitude: success.coords.longitude,
      })
    );

    if (navigator.geolocation) {
      navigator.geolocation.enableHighAccuracy = true;
      navigator.geolocation.watchPosition((position) =>
        this.setState({
          myLatitude: position.coords.latitude,
          myLongitude: position.coords.longitude,
          myAltitude: position.coords.altitude,
          myHeading: position.coords.heading,
          mySpeed: position.coords.speed,
          mySpeedKM: position.coords.speed * 3.6,
        })
      );
    }

    // 30000 = 30 seconds
    const MINUTE_MS = 10000;
    const interval = setInterval(() => {
      this.sendData();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }

  async sendData() {
    // console.log("In Send Data");
    // Get the latest Geo data
    let { myLatitude, myLongitude, myAltitude, myHeading, mySpeed, mySpeedKM } =
      this.state;

    // Create a numeric outut format type ES6 style
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Format the data to two decimal points
    myAltitude = formatter.format(myAltitude);
    myHeading = formatter.format(myHeading);
    mySpeed = formatter.format(mySpeed);
    mySpeedKM = formatter.format(mySpeedKM);

    //  Create time and date of geo location
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    // let date = currentDate.getDate();
    // let month = currentDate.getMonth();
    // let year = currentDate.getFullYear();

    const today = new Date().toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const time = new Date().toLocaleTimeString();

    // go to database and store the infor everytime there is a location update
    let formData = new FormData();
    //add the data to formdata to send to db
    formData.append("latitude", myLatitude);
    formData.append("longitude", myLongitude);
    formData.append("altitude", myAltitude);
    formData.append("heading", myHeading);
    formData.append("speed", mySpeedKM);
    formData.append("time", time);
    formData.append("date", today);
    formData.append("timestamp", timestamp);

    // send the data to the database via Http
    try {
      const { data: retrievedGeoData } = await httpService.post(
        config.apiEndpointSendGeoLocation,
        formData
      );

      toast.info("Sent");
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while adding.");
    }
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      myLatitude,
      myLongitude,
      myAltitude,
      myHeading,
      mySpeedKM,
      mySpeedKM2,
    } = this.state;

    // Create a numeric outut format type ES6 style
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Create a numeric outut format type ES6 style
    const formatWholeNum = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    // Format the data to two decimal points
    myAltitude = formatWholeNum.format(myAltitude);
    myHeading = formatWholeNum.format(myHeading);
    mySpeedKM = formatWholeNum.format(mySpeedKM);

    const center = 250 / 2;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarSiteAdmin />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <table>
                <tbody>
                  <tr>
                    <td className="card-rowtext">Latitude</td>
                    <td className="card-rowHeader row-padding">{myLatitude}</td>
                  </tr>
                  <tr>
                    <td className="card-rowtext">Longitude</td>
                    <td className="card-rowHeader row-padding">
                      {myLongitude}
                    </td>
                  </tr>
                  <tr>
                    <td className="card-rowtext">Altitude </td>
                    <td className="card-rowHeader row-padding">{myAltitude}</td>
                  </tr>
                  <tr>
                    <td className="card-rowtext">Heading (Degs)</td>
                    <td className="card-rowHeader row-padding">{myHeading} </td>
                  </tr>
                  <tr>
                    <td className="card-rowtext">Speed (Km)</td>
                    <td className="card-rowHeader row-padding">{mySpeedKM}</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <button
                        type="button"
                        onClick={() => this.sendData()}
                        className="btn btn-primary"
                      >
                        Send Geo Location
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    {
      /* ) : (
      <div>Getting the location data&hellip; </div>
    ); */
    }
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(Demo);
