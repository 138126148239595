import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import GroupRaceCard from "../components/groupRaceCard";

//Common
import SearchBox from "../components/common/searchBox";
import Pagination from "../components/common/pagination";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle } from "react-bootstrap-icons";

//Services

import httpService from "./../services/httpService.js";
import config from "../config.json";

//Utilites
import { paginate } from "../utils/paginate";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class PageGroupRaces extends React.Component {
  state = {
    backButtonPage: "",
    currentPage: "1",
    dataRetreived: [],
    entityName: "",
    entityID: "",
    groupName: "",
    groupID: "",
    searchQuery: "",
    sortColumn: { path: "raceName", order: "asc" },
    pageSize: 2,
  };

  async componentDidMount() {
    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const userTypeID = localStorage.getItem("userTypeID");
    const entityName = localStorage.getItem("entityName");
    const entityID = localStorage.getItem("entityID");

    // Set the back button address for each user type
    let backButtonPage = "";
    if (userTypeID === "2") backButtonPage = "pageGroupHome";
    if (userTypeID === "3") backButtonPage = "pageTeamMemberHome";
    if (userTypeID === "4") backButtonPage = "pageEntityHome";

    // This is set in case of the user nagigates backwards and then
    // the information is no longer true
    localStorage.setItem("raceID", "");
    localStorage.setItem("raceName", "");
    localStorage.setItem("raceNameYear", "");

    this.setState({ groupName, groupID, backButtonPage, entityName, entityID });

    let { data: Dbdata } = await httpService.get(
      config.apiEndpointRacesGroupIDGet + "?groupID=" + groupID
    );
    this.setState({ dataRetreived: Dbdata });
  } // close component did mount

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    console.log("query = " + query);
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const {
      dataRetreived,
      searchQuery,
      pageSize,
      currentPage,
      groupName,
      backButtonPage,
    } = this.state;

    let allDataTotal = dataRetreived.length;

    // Order the data
    const sortedData = _.orderBy(
      dataRetreived,
      [this.state.sortColumn.path],
      [this.state.sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.raceName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    allDataTotal = filtered.length;

    //Paginate the data
    const paginatedData = paginate(filtered, currentPage, pageSize);

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70 ">
          <div className="row">
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("Back To Group Home")}
                >
                  <Link to={`/${backButtonPage}`} className="btn p-0">
                    <ArrowLeftCircle color="#002072" size={40} />
                  </Link>
                </OverlayTrigger>
                <h5>{groupName} - Races</h5>
              </span>
            </div>
            <div className="col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search race names..."
              />
            </div>
          </div>

          <div className="row card-race-display">
            {paginatedData.map((race) => (
              <GroupRaceCard
                key={race.id}
                raceId={race.id}
                raceName={race.raceName}
                raceType={race.raceType.description}
                startDate={race.startDate}
                endDate={race.endDate}
                group={race.group.groupName}
                year={race.year.description}
                month={race.month.description}
                raceNameYear={race.raceNameYear}
              />
            ))}

            <Pagination
              itemsCount={allDataTotal}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageGroupRaces;
