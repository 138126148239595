import React, { Component } from "react";

// Components
import Notification from "./notification";
import Pagination from "./common/pagination";
import withMyHook from "./../components/common/withMyHook";

//Utilites
import { paginate } from "../utils/paginate";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

class NotificationList extends Component {
  state = {
    pageSize: 4,
    currentPage: 1,

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    userTypeID: "",
    notificationData: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    this.on();

    const stageID = this.state.myParams.id;
    const userTypeID = localStorage.getItem("userTypeID");
    const entityID = localStorage.getItem("entityID");

    // If the user is a User type 3, Race Director or Team member, bring all notifications
    if (userTypeID == 3) {
      // Get notification Types by Stage ID
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointNotificationsGet + "?stageID=" + stageID
      );
      this.setState({ notificationData: Dbdata });
    }

    // If the user is a User type 4,Entity head bring all and only entity notifications
    if (userTypeID == 4) {
      // Get notification Types by race ID and Entity ID
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointNotificationsEntityGet +
          "?stageID=" +
          stageID +
          "&" +
          "entityID=" +
          entityID
      );
      this.setState({ notificationData: Dbdata });
    }

    // If the user is a User type 5,Entity User bring all and only entity notifications
    if (userTypeID == 5) {
      // Get notification Types by race ID and Entity ID
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointNotificationsEntityGet +
          "?stageID=" +
          stageID +
          "&" +
          "entityID=" +
          entityID
      );
      this.setState({ notificationData: Dbdata });
    }

    this.off();
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageIncrement = (setCurrentPage, pagesCount) => {
    if (setCurrentPage < pagesCount) {
      setCurrentPage++;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  handlePageDecrement = (setCurrentPage) => {
    if (setCurrentPage != 1) {
      setCurrentPage--;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    const {
      cssOverride,
      color,
      currentPage,
      loading,
      notificationData,
      pageSize,
    } = this.state;

    const notificationPag = paginate(notificationData, currentPage, pageSize);
    const notificationsTotal = notificationData.length;

    if (notificationsTotal != 0) {
      return (
        <React.Fragment>
          <div
            className=" comment-body scroller-note mb-2 "
            // data-aos="zoom-in"
            // data-aos-duration="5000"
          >
            <div id="overlay" className="overlay" onClick={() => this.off()}>
              <div id="text" className="text">
                <PuffLoader
                  color={color}
                  loading={loading}
                  cssOverride={cssOverride}
                  size={50}
                />
                Uploading Data...
              </div>
            </div>
            <div>
              {notificationPag.map((notification) => (
                <Notification
                  key={notification.id}
                  notificationSingleData={notification}
                />
              ))}
            </div>
          </div>
          <div>
            <Pagination
              itemsCount={notificationsTotal}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              onPageDecrement={this.handlePageDecrement}
              onPageIncrement={this.handlePageIncrement}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className=" comment-body scroller-note mb-2">
            <div id="overlay" className="overlay" onClick={() => this.off()}>
              <div id="text" className="text">
                <PuffLoader
                  color={color}
                  loading={loading}
                  cssOverride={cssOverride}
                  size={50}
                />
                Uploading Data...
              </div>
            </div>
            <div>
              <div>
                <div className="card shadow-sm m-2 note-card-border">
                  <div className="row p-4 center">No Notifications posted.</div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default withMyHook(NotificationList);
