import React from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import { EntityUserMarker } from "./entityUserMarker";

export const EntityUserLayer = ({ entityUserData, entityName }) => {
  // if there is no entity User data
  let entitynameNew = entityName;

  // console.log("entityName = " + entityName);
  let numrows = entityUserData.length;
  // console.log("numrows  = " + numrows);

  if (!entityUserData) {
    return <div></div>;
  } else {
    if (entityName) {
      return (
        <LayersControl.Overlay checked name="Entities">
          <LayerGroup>
            {/* Now go to each ticket type and create the layers that need to show on the map */}
            {entityUserData.map((entityUser) => (
              <EntityUserMarker
                key={entityUser.entityUserID}
                entityUser={entityUser}
              />
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
      );
    } else {
      return <div></div>;
    }
  }
};
