import React from "react";
import { geolocated } from "react-geolocated";
import { Link } from "react-router-dom";

//Components
import NavbarRace from "../components/navbarRace";
import RaceEntityUserHero from "../components/raceEntityUserHero";

//Components
import withMyHook from "./../components/common/withMyHook";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";
import { Platform } from "react";

import { DoorOpenFill } from "react-bootstrap-icons";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

class pageEntityUserHome extends React.Component {
  state = {
    entityID: "",
    entityName: "",
    entityUserId: "",
    groupID: "",
    groupName: "",
    groupIDRetreived: "",
    groupNameRetreived: "",
    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",
    raceName: "",
    raceID: "",
    raceNameYear: "",
    stageID: "",
    userFullName: "",
    userName: "",
    userSurname: "",
    interval: "",

    displayGeoData: [],
    entityUserRoleData: [],
    stagedataRetreived: [],
    vehicleData: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }
  setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }

  async componentDidMount() {
    // Get Local Storage
    let entityUserRoleId = localStorage.getItem("entityUserRoleId");
    let raceID = localStorage.getItem("raceID");
    let groupNameRetreived = localStorage.getItem("groupName");
    let groupIDRetreived = localStorage.getItem("groupID");
    let entityName = localStorage.getItem("entityName");
    let entityID = localStorage.getItem("entityID");
    let entityUserId = localStorage.getItem("entityUserId");
    let userSurname = localStorage.getItem("userSurname");
    let userName = localStorage.getItem("userName");
    let stageID = localStorage.getItem("stageID");

    // Get cookies for apple iPhone that does not support Local storage
    if (entityUserRoleId == "") {
      entityUserRoleId = this.getCookie("entityUserRoleId");
    }
    if (raceID == "") {
      raceID = this.getCookie("raceID");
    }
    if (groupNameRetreived == "") {
      groupNameRetreived = this.getCookie("groupName");
    }
    if (groupIDRetreived == "") {
      groupIDRetreived = this.getCookie("groupID");
    }
    if (entityName == "") {
      entityName = this.getCookie("entityName");
    }
    if (entityID == "") {
      entityID = this.getCookie("entityID");
    }
    if (entityUserId == "") {
      entityUserId = this.getCookie("entityUserId");
    }
    if (userSurname == "") {
      userSurname = this.getCookie("userSurname");
    }
    if (userName == "") {
      userName = this.getCookie("userName");
    }
    if (stageID == "") {
      stageID = this.getCookie("stageID");
    }

    let userFullName = userName + " " + userSurname;

    // Get database information

    if (raceID === "") return;
    let { data: race } = await httpService.get(
      config.apiEndpointRaceGet + "?id=" + raceID
    );

    // Get the stage details based on the Race id
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStagesGetByRaceID + "?raceID=" + raceID
    );

    // Get the Role informaion based in the user RoleID
    let { data: entityUserRoleData } = await httpService.get(
      config.apiEndpointEntityUserRoleGet + "?id=" + entityUserRoleId
    );

    // Get the Vehicle details if allocated
    let { data: vehicleData } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationVehicleGet +
        "?entityUserId=" +
        entityUserId
    );

    // Set Local storage
    localStorage.setItem("raceNameYear", race.raceNameYear);
    localStorage.setItem("raceName", race.raceName);
    localStorage.setItem("entityUserRoleDesc", entityUserRoleData.name);
    localStorage.setItem("vehicleID", vehicleData.vehicleID);
    localStorage.setItem("vehicleName", vehicleData.vehicleName);
    localStorage.setItem(
      "vehicleRegistration",
      vehicleData.vehicleRegistration
    );

    // Set the Cookies
    this.setCookie("raceNameYear", race.raceNameYear, 30);
    this.setCookie("raceName", race.raceName, 30);
    this.setCookie("entityUserRoleDesc", entityUserRoleData.name, 30);

    // !!!!!!!!!!!!!!!!!!!!!!!!!  This causes iPhones to fail   !!!!!!!!!!!!!!!!!!!!!!!!!!!
    // Not sure which part

    // Check to see if Goloaction has been granted
    // if (navigator.geolocation) {
    //   navigator.permissions
    //     .query({ name: "geolocation" })
    //     .then(function (result) {
    //       if (result.state === "granted") {
    //         console.log(result.state);
    //         //If granted then you can directly call your function here
    //       } else if (result.state === "prompt") {
    //         console.log(result.state);
    //       } else if (result.state === "denied") {
    //         //If denied then you have to show instructions to enable location
    //       }
    //       result.onchange = function () {
    //         console.log(result.state);
    //       };
    //     });
    // } else {
    //   alert("GPS Location Not found");
    // }

    // GPS location storage
    window.navigator.geolocation.getCurrentPosition((success) =>
      this.setState({
        myLatitude: success.coords.latitude,
        myLongitude: success.coords.longitude,
      })
    );

    if (navigator.geolocation) {
      navigator.geolocation.enableHighAccuracy = true;
      navigator.geolocation.watchPosition((position) =>
        this.setState({
          myLatitude: position.coords.latitude,
          myLongitude: position.coords.longitude,
          myAltitude: position.coords.altitude,
          myHeading: position.coords.heading,
          mySpeed: position.coords.speed,
          mySpeedKM: position.coords.speed * 3.6,
        })
      );
    }

    // 5000 = 10 seconds
    const MINUTE_MS = 5000;
    const interval = setInterval(() => {
      this.sendData();
    }, MINUTE_MS);

    this.setState({
      entityID,
      entityName,
      entityUserId,
      entityUserRoleData,
      groupName: groupNameRetreived,
      groupID: groupIDRetreived,
      interval,
      raceName: race.raceName,
      raceNameYear: race.raceNameYear,
      raceID: raceID,
      raceDescription: race.raceDescription,
      stageID,
      stagedataRetreived: Dbdata,
      userFullName,
      vehicleData,
    });

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // return () => clearInterval(interval);
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  async sendData() {
    // Get the latest Geo data
    let {
      myLatitude,
      myLongitude,
      myAltitude,
      myHeading,
      mySpeed,
      mySpeedKM,
      entityID,
      stageID,
      entityUserId,
    } = this.state;

    // Set Local storage
    localStorage.setItem("myLatitude", myLatitude);
    localStorage.setItem("myLongitude", myLongitude);
    localStorage.setItem("myAltitude", myAltitude);
    localStorage.setItem("myLatitude", myLatitude);

    // Create a numeric outut format type ES6 style
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Format the data to two decimal points
    myAltitude = formatter.format(myAltitude);
    myHeading = formatter.format(myHeading);
    mySpeed = formatter.format(mySpeed);
    mySpeedKM = formatter.format(mySpeedKM);

    //  Create time and date of geo location
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const today = new Date().toLocaleDateString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const time = new Date().toLocaleTimeString();

    // go to database and store the info everytime there is a location update
    let formData = new FormData();
    //add the data to formdata to send to db
    formData.append("latitude", myLatitude);
    formData.append("longitude", myLongitude);
    formData.append("altitude", myAltitude);
    formData.append("heading", myHeading);
    formData.append("speed", mySpeedKM);
    formData.append("time", time);
    formData.append("date", today);
    formData.append("timestamp", timestamp);
    formData.append("stageID", stageID);
    formData.append("entityUserId", entityUserId);
    formData.append("entityID", entityID);
    formData.append("sendType", "Web Mobile");

    // send the data to the database via Http
    if (myLatitude != "" && myLongitude != "") {
      try {
        const { data: retrievedGeoData } = await httpService.post(
          config.apiEndpointSendEUGeoLocation,
          formData
        );

        toast.info("Sent");
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    }
  }

  render() {
    // Disabled as soem cell phones caused it to fire all the time and
    /// they could not get to the icons
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const {
      displayGeoData,
      entityName,
      entityUserRoleData,
      myLatitude,
      myLongitude,
      stagedataRetreived,
      raceNameYear,
      raceDescription,
      raceID,
      userFullName,
      vehicleData,
    } = this.state;

    if (entityUserRoleData.length != 0) {
      return (
        <React.Fragment>
          <NavbarRace />

          <div className="container container-top-70 center ">
            <div className="row row-fit center">
              <div data-aos="fade-right ">
                <h5>
                  {entityName} - {userFullName}
                </h5>
                <RaceEntityUserHero
                  myLatitude={myLatitude}
                  myLongitude={myLongitude}
                  entityUserRoleData={entityUserRoleData}
                  stagedataRetreived={stagedataRetreived}
                  raceNameYear={raceNameYear}
                  raceDescription={raceDescription}
                  raceID={raceID}
                  vehicleID={vehicleData.vehicleID}
                  vehicleName={vehicleData.vehicleName}
                  vehicleRegistration={vehicleData.vehicleRegistration}
                />
              </div>
              <div className="searchBar-width-300" data-aos="fade-left"></div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <NavbarRace />

          <div className="container container-top-70 center pt-3">
            <div className="row row-fit center">
              <div data-aos="fade-right ">
                <div className="center">
                  <div className="row p-2 pb-0 pe-lg-0 pt-lg-3 align-items-center border-radius-4 shadow-lg row-fit center">
                    <div className="col-lg-12 p-0 p-lg-4 pt-lg-0 ">
                      <div className="row row-fit center text-darkblue p-3">
                        No race allocations have been made. Please contact the
                        race organisers.
                      </div>

                      <div className="row px-3">
                        <div className="col">
                          <div className="button-center">
                            <Link
                              to="/"
                              className="btn btn-primary button-color-dashboard"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 15,
                                height: 55,
                                marginBottom: 5,
                                marginTop: 5,
                                width: 60,
                              }}
                            >
                              <DoorOpenFill color="white" size={30} />
                            </Link>
                          </div>
                          <div className="text-darkblue center mb-1">
                            Logout
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default withMyHook(
  geolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  })(pageEntityUserHome)
);
