import React from "react";

import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

class StageJocRepAllocationTable extends React.Component {
  state = {
    pageSize: 8,
    currentPage: 1,
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageIncrement = (setCurrentPage, pagesCount) => {
    if (setCurrentPage < pagesCount) {
      setCurrentPage++;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  handlePageDecrement = (setCurrentPage) => {
    if (setCurrentPage != 1) {
      setCurrentPage--;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  columns = [
    {
      path: "name",
      label: "Name",
      content: (stageJocRep) => (
        <Link to={`/stageJocRepAllocationForm/${stageJocRep.id}`}>
          <h6>{stageJocRep.name}</h6>
        </Link>
      ),
    },
    { path: "surname", label: "Surname" },
    { path: "cell", label: "Cell" },
    { path: "email", label: "Email" },
  ];

  render() {
    const {
      allRaceUsersTotal,
      filteredRaceUsers,
      onSort,

      sortColumn,
    } = this.props;
    const { pageSize, currentPage } = this.state;
    const raceUsersPag = paginate(filteredRaceUsers, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={raceUsersPag}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allRaceUsersTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPageDecrement={this.handlePageDecrement}
            onPageIncrement={this.handlePageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default StageJocRepAllocationTable;
