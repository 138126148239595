import React from "react";
import { Link } from "react-router-dom";

//Own Components
import Tickets from "../components/tickets";
import NavbarEntity from "../components/navbarEntity";
import CalloutButtons from "../components/calloutbuttons";
import EntityUserTicketStats from "./../components/entityUserTicketStats";

//Common
import ListGroup from "../components/common/listGroup";
import SearchBox from "../components/common/searchBox";

//Components
import withMyHook from "./../components/common/withMyHook";

// Services
import httpService from "./../services/httpService.js";
import config from "./../config.json";

//Utilities
import { ToastContainer, toast } from "react-toastify";

//Other
import {
  ArrowCounterclockwise,
  ArrowLeftCircle,
  PlusCircle,
} from "react-bootstrap-icons";

class PageTicketCards extends React.Component {
  state = {
    all: 0,
    callOutValue: "All",
    currentPage: "1",
    entityJocRepID: "",
    entityUserId: "",
    filteredTickets: "",
    pageSize: 20,
    raceID: "",
    raceNameYear: "",
    resultAddTicket: "",
    searchQuery: "",
    searchQueryPN: "",
    searchQueryNO: "",
    selectedTicketType: "",
    sortColumn: { path: "id", order: "asc" },
    stageName: "",
    totalStageUserRoleAllocation: "",

    entityUserList: [],
    stage: [],
    stageUserRoleTotal: [],
    ticketData: [],
    ticketsTotals: [],
    ticketTypesData: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const stageID = this.state.myParams.id;
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const entityID = localStorage.getItem("entityID");
    const entityName = localStorage.getItem("entityName");
    const entityJocRepID = localStorage.getItem("entityJocRepID");
    const entityUserId = localStorage.getItem("entityUserId");

    // Get the total number of allocated users to the stage and pass it to the relevant screen for validation.
    let { data: stageUserRoleTotal } = await httpService.get(
      config.apiEndpointStageUserRoleTotal +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );

    // Get the Stage information
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    //set the stage details
    localStorage.setItem("stageID", stageID);
    localStorage.setItem("stageName", stage.stageName);
    const stageName = stage.stageName;

    // Get the Ticket Types
    let { data: ticketTypesDB } = await httpService.get(
      config.apiEndpointTicketTypesAllocatedGet +
        "?raceID=" +
        raceID +
        "&" +
        "entityID=" +
        entityID
    );

    // Get the entity User list / Ticket count
    let { data: entityUserList } = await httpService.get(
      config.apiEndpointStageUserRoleAllocations +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );

    // Get the ticket for the Entity and stage
    // Also get tickets that are allocated to this entity from a different entity

    let { data: ticketDataDB } = await httpService.get(
      config.apiEndpointEntityStageTicketsGet +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID +
        "&" +
        "raceID=" +
        raceID
    );

    this.setState({
      entityJocRepID,
      entityID,
      entityName,
      entityUserId,
      entityUserList,
      ticketData: ticketDataDB,
      ticketTypesData: [{ id: "", name: "All Tickets" }, ...ticketTypesDB],
      stageName,
      raceID,
      raceNameYear,
      stage,
      stageID,
      stageUserRoleTotal,
    });

    // Now set the radio button as checked
    const cbTickets = document.querySelector("#mainTicketBox");
    cbTickets.checked = true;

    // Now automatically update all the tickets as they come in
    this.autoRefreshTickets();
  }

  handleStatus = (ticket) => {
    let resolved = "";
    let resolvedDate = "";
    let resolvedTime = "";
    let priority = "0";
    let ID = ticket.id;
    // get the date and time of ticket resolution
    let tempResolvedDate = new Date().toISOString().slice(0, 10);

    let today = new Date();
    let tempResolvedTime =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    const ticketData = [...this.state.ticketData];
    const index = ticketData.indexOf(ticket);
    ticketData[index] = { ...ticket };
    ticketData[index].resolved = !ticketData[index].resolved;

    //Set the priority to false (off) value so that a resolved card is not a priority
    if (ticketData[index].resolved) {
      ticketData[index].priority = false;
      resolved = "1";
      // get the date and time of ticket resolution
      resolvedDate = tempResolvedDate;
      resolvedTime = tempResolvedTime;
      ticketData[index].resolvedDate = tempResolvedDate;
      ticketData[index].resolvedTime = tempResolvedTime;
    } else {
      resolved = "0";
      resolvedDate = "";
      resolvedTime = "";
      ticketData[index].resolvedDate = "";
      ticketData[index].resolvedTime = "";
    }

    this.setState({ ticketData: ticketData });

    // Now Go and update the Database with the change
    this.updateTogglePriority(
      resolved,
      resolvedDate,
      resolvedTime,
      priority,
      ID
    );
  };

  handleToggleIcon = (ticket) => {
    let resolved = "0";
    let resolvedDate = "";
    let resolvedTime = "";
    let priority = "";
    let ID = ticket.id;

    const ticketData = [...this.state.ticketData];
    const index = ticketData.indexOf(ticket);
    ticketData[index] = { ...ticket };
    ticketData[index].priority = !ticketData[index].priority;

    //Set the toggle to pending so that a priority is not a resolved button
    if (ticketData[index].priority) {
      ticketData[index].resolved = false;
      priority = "1";
      ticketData[index].resolvedDate = "";
      ticketData[index].resolvedTime = "";
    } else {
      priority = "0";
    }

    this.setState({ ticketData: ticketData });
    // Now Go and update the Database with the change
    this.updateTogglePriority(
      resolved,
      resolvedDate,
      resolvedTime,
      priority,
      ID
    );
  };

  handleGenreSelect = (ticketType) => {
    this.setState({
      selectedTicketType: ticketType,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedTicketType: "",
      currentPage: 1,
    });
  };

  handleSearchPN = (query) => {
    this.setState({
      searchQueryPN: query,
      selectedTicketType: "",
      currentPage: 1,
    });
  };

  handleSearchNO = (query) => {
    this.setState({
      searchQueryNO: query,
      selectedTicketType: "",
      currentPage: 1,
    });
  };

  async updateTogglePriority(
    resolved,
    resolvedDate,
    resolvedTime,
    priority,
    ID
  ) {
    let formData = new FormData();

    //add the Id to the data to send to db
    formData.append("id", ID);
    formData.append("resolved", resolved);
    formData.append("resolvedTime", resolvedTime);
    formData.append("resolvedDate", resolvedDate);
    formData.append("priority", priority);

    try {
      await httpService.post(
        config.apiEndpointEntityStageTicketUpdatePriorityResolved,
        formData
      );
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while updating.");
    }
  }

  handleCommentSubmit = async (data, ticketIdLocal) => {
    // get date and time for the ticket submission
    let commentDate = new Date().toISOString().slice(0, 10);
    let today = new Date();
    // Format the time into two digit numbers
    let timeMinutes = (today.getMinutes() < 10 ? "0" : "") + today.getMinutes();
    let timeHours = (today.getHours() < 10 ? "0" : "") + today.getHours();
    let timeSeconds = (today.getSeconds() < 10 ? "0" : "") + today.getSeconds();

    let commentTime = timeHours + ":" + timeMinutes + ":" + timeSeconds;

    // Add a new entry

    let formData = new FormData();

    // Add the static info to the formData
    formData.append("ticketid", ticketIdLocal);
    formData.append("entityJocRepID", this.state.entityJocRepID);
    formData.append("entityID", this.state.entityID);
    formData.append("entityUserid", "");
    formData.append("commentdate", commentDate);
    formData.append("commenttime", commentTime);
    formData.append("commentText", data.commentText);

    try {
      await httpService.post(
        config.apiEndpointEntityTicketCommentsAdd,
        formData
      );
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while adding.");
    }
  };

  handleEntityUserUpdate = async (ticketIdLocal, entityUserID) => {
    // Add a new entry
    let formData = new FormData();

    // Add the static info to the formData
    formData.append("ticketid", ticketIdLocal);
    formData.append("entityUserid", entityUserID);

    try {
      await httpService.post(
        config.apiEndpointEntityStageTicketUpdateEntityUser,
        formData
      );
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while adding.");
    }
  };

  refreshTickets = async () => {
    const stageID = localStorage.getItem("stageID");
    const entityID = localStorage.getItem("entityID");
    const raceID = localStorage.getItem("raceID");

    let { data: ticketDataDB } = await httpService.get(
      config.apiEndpointEntityStageTicketsGet +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID +
        "&" +
        "raceID=" +
        raceID
    );

    this.setState({ ticketData: ticketDataDB });
  };

  autoRefreshTickets = async () => {
    // This code will be used to refresh the tickets every 1 minute
    const cbTickets = document.querySelector("#mainTicketBox");

    if (cbTickets.checked == true) {
      // set the 1 minute to a variable and let the user set it
      this.interval = setInterval(() => this.refreshTickets(), 6000);
    } else {
      clearInterval(this.interval);
    }
  };

  handleCalloutSort = (buttonPressed) => {
    this.setState({ callOutValue: buttonPressed, currentPage: 1 });
  };

  handlePageReset = () => {
    this.setState({ currentPage: 1 });
  };

  handlePageIncrement = (sentCurrentPage, pagesCount) => {
    if (sentCurrentPage < pagesCount) {
      sentCurrentPage++;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageDecrement = (sentCurrentPage) => {
    if (sentCurrentPage != 1) {
      sentCurrentPage--;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      callOutValue,
      currentPage,
      entityJocRepID,
      entityName,
      entityUserList,
      filteredTickets,
      pageSize,
      raceID,
      raceNameYear,
      selectedTicketType,
      searchQuery,
      searchQueryPN,
      searchQueryNO,
      stage,
      stageUserRoleTotal,
      ticketData,
      ticketsTotals,
      ticketTypesData,
    } = this.state;

    //Order of the ticket filters

    // filter the data from search input contents
    let filtered = ticketData;

    if (searchQuery)
      filtered = ticketData.filter((m) =>
        m.comment.toLowerCase().includes(searchQuery.toLowerCase())
      );
    if (searchQueryPN)
      filtered = ticketData.filter((m) =>
        m.participantNo.toLowerCase().includes(searchQueryPN.toLowerCase())
      );
    if (searchQueryNO)
      filtered = ticketData.filter((m) =>
        m.id.toLowerCase().includes(searchQueryNO.toLowerCase())
      );

    // This will filter the ticket list by ticket type Type and then pass it on to the Tickets class
    if (filtered) {
      filteredTickets =
        selectedTicketType && selectedTicketType.id
          ? filtered.filter((m) => m.ticketType.id === selectedTicketType.id)
          : filtered;
    }

    // This is to filter the tickets by the call out buttons when clicked
    if (filteredTickets) {
      if (callOutValue === "All") {
      }
      if (callOutValue === "Priority") {
        filteredTickets = filteredTickets.filter((c) => c.priority === true);
      }
      if (callOutValue === "Pending") {
        filteredTickets = filteredTickets.filter((c) => c.resolved === false);
      }
      if (callOutValue === "Resolved") {
        filteredTickets = filteredTickets.filter((c) => c.resolved === true);
      }
      if (callOutValue === "My Tickets") {
        filteredTickets = filteredTickets.filter(
          (c) => c.entityJocRepID === entityJocRepID
        );
      }
    }

    // Calculate the totals of different ticket states
    if (filteredTickets) {
      const pending = filteredTickets.filter(
        (c) => c.resolved === false
      ).length;
      const resolved = filteredTickets.filter(
        (c) => c.resolved === true
      ).length;
      const priority = filteredTickets.filter(
        (c) => c.priority === true
      ).length;
      const myTickets = filteredTickets.filter(
        (c) => c.entityJocRepID === entityJocRepID
      ).length;

      const all = filteredTickets.length;

      ticketsTotals = [all, priority, pending, resolved, myTickets];
    }

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          autoClose={1000}
          position="top-center"
        />
        <NavbarEntity />
        <div className="container container-top-70">
          <div className="row ">
            <div className="button-padding ">
              <Link to={`/pageRaceEntityHome/${raceID}`} className="btn p-0">
                <ArrowLeftCircle color="#002072" size={35} />
              </Link>

              {/* <Link to="/TicketForm/new" className="btn p-0">
                <PlusCircle color="#002072" size={35} />
              </Link> */}
              <Link to="" className="btn p-0">
                <PlusCircle color="#002072" size={35} />
              </Link>
              <Link
                to=""
                onClick={() => window.location.reload()}
                className="btn p-0"
              >
                <ArrowCounterclockwise color="#002072" size={35} />
              </Link>
              <h5>
                {entityName} - {raceNameYear} - {stage.stageName}
              </h5>
            </div>
          </div>

          <div className="row ">
            <div className="col  listgroup ">
              <ListGroup
                items={ticketTypesData}
                selectItem={selectedTicketType}
                onItemSelect={this.handleGenreSelect}
              />
            </div>
            {/* write a media Query that changes the col to col-7 eg when dispaly is > 420 width */}
            <div className="col-7 ticket-min-size">
              <div className="row overwrite-row-gutter ticket-rhs-clear ">
                <CalloutButtons
                  ticketsTotals={ticketsTotals}
                  handleCalloutSort={this.handleCalloutSort}
                />
                <div className="button-align-center ">
                  <div className="button-margin-lr">
                    <SearchBox
                      value={searchQueryNO}
                      onChange={this.handleSearchNO}
                      placeholder="Search Ticket no.."
                    />
                  </div>
                  <div className="button-margin-lr">
                    <SearchBox
                      value={searchQuery}
                      onChange={this.handleSearch}
                      placeholder="Search ticket comment..."
                    />
                  </div>
                  <div className="button-margin-lr">
                    <SearchBox
                      value={searchQueryPN}
                      onChange={this.handleSearchPN}
                      placeholder="Search particpant no.."
                    />
                  </div>
                </div>
                <div className="row marginTop10 ticket-rhs-clear">
                  <div className="col button-align">
                    <label className="switch margin-left">
                      <input
                        id="mainTicketBox"
                        name="mainTicketBox"
                        type="checkbox"
                        onClick={this.autoRefreshTickets}
                      />
                      <span className="slider round"></span>
                    </label>
                    <h6 className="button-padding">Auto refresh</h6>
                  </div>
                  <div className="col button-align-end">
                    <button className="btn " onClick={this.refreshTickets}>
                      <ArrowCounterclockwise color="#002072" size={35} />
                    </button>
                  </div>
                </div>
                <div className="row ticket-rhs-clear">
                  <div className=" ticket-rhs-clear">
                    <Tickets
                      allTicketsTotal={filteredTickets.length}
                      currentPage={currentPage}
                      handlePageReset={this.handlePageReset}
                      onPageDecrement={this.handlePageDecrement}
                      onPageIncrement={this.handlePageIncrement}
                      onPageChange={this.handlePageChange}
                      onCommentSave={this.handleCommentSubmit}
                      onDelete={this.handleDelete}
                      onEntityUserUpdate={this.handleEntityUserUpdate}
                      onStatus={this.handleStatus}
                      onToggleIcon={this.handleToggleIcon}
                      pageSize={pageSize}
                      ticketData={filteredTickets}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <EntityUserTicketStats
                entityUserList={entityUserList}
                stageUserRoleTotal={stageUserRoleTotal}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageTicketCards);
