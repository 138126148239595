import React, { useState, useEffect } from "react";
import gpxParser from "gpxparser";

export const GetGPXCoords = (gpxURL) => {
  let singlePosition = [];

  let TempCitciutArray = [];
  const [circuitPolyLines, setCircuitPolyLines] = useState([]);

  useEffect(() => {
    const getGpxLocations = async (url) => {
      const myHeaders = new Headers();
      let gpxP = new gpxParser();
      // console.log("url = " + url);
      const MymapURl = require("../gpx/" + url);

      myHeaders.append("Content-Type", "text/xml");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "X-Requested-With");
      myHeaders.append("Access-Control-Allow-Headers", "access");
      myHeaders.append("Access-Control-Allow-Methods", "GET");
      myHeaders.append("Access-Control-Allow-Credentials", "true");

      await fetch(MymapURl, {
        mode: "no-cors",
        headers: myHeaders,
      })
        .then((d) => d.text())
        .then((d) => {
          gpxP.parse(d);
          singlePosition = gpxP.tracks[0].points.map((p) => [p.lat, p.lon]);
          TempCitciutArray.push(singlePosition);
        });
      setCircuitPolyLines(TempCitciutArray);
    };

    //Call the method using the gpx file ame and  import URL. then pass the url to the load method
    getGpxLocations(gpxURL);
  }, []);

  // const positions = [-33.946778, 18.443985];

  return circuitPolyLines;
};
