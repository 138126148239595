import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "../../components/navbarGroup";

// Services
import httpService from "../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";
import { times } from "lodash";

class NotificationForm extends Form {
  state = {
    data: {
      notificTypeId: "",
      notificStatusId: "",
      comment: "",
    },

    entityID: "",
    entityJocRepID: "",
    goToPage: "",
    groupID: "",
    raceNameYear: "",
    stageID: "",
    stageName: "",
    userTypeID: "",

    notificationTypes: [],
    notificationStatus: [],
    notificationEntites: [],

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    errors: {},
    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    // Set up the input valdation here:
    notificTypeId: Joi.string().label("Type"),
    notificStatusId: Joi.string().label("Status"),
    comment: Joi.string().label("Comment"),
  };

  async componentDidMount() {
    this.on();
    const ID = this.state.myParams.id;

    // get variables from the local storage
    const entityID = localStorage.getItem("entityID");
    const entityJocRepID = localStorage.getItem("entityJocRepID");
    const groupID = localStorage.getItem("groupID");
    const raceID = localStorage.getItem("raceID");
    const stageID = localStorage.getItem("stageID");
    const stageName = localStorage.getItem("stageName");
    const userTypeID = localStorage.getItem("userTypeID");

    // Get notification Types by race ID
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointNotificationTypesGet + "?raceID=" + raceID
    );
    this.setState({ notificationTypes: Dbdata });

    // Get notification Statues
    let { data: DbdataStatus } = await httpService.get(
      config.apiEndpointNotificationStatusesGet
    );
    this.setState({ notificationStatus: DbdataStatus });

    // Get allocated entities by group ID and race ID
    let { data: DbdataEntites } = await httpService.get(
      config.apiEndpointEntitesRaceAllocGetRaceID +
        "?groupID=" +
        groupID +
        "&" +
        "raceID=" +
        raceID
    );

    //Add an "all" option to the list
    const allentities = {
      id: "0",
      raceID: "",
      raceDesc: "",
      entityDesc: "",
      entityName: "All Entities",
    };

    DbdataEntites.unshift(allentities);
    this.setState({ notificationEntites: DbdataEntites });

    // Set the State
    this.setState({
      entityID,
      entityJocRepID,
      raceID,
      stageID,
      stageName,
      userTypeID,
    });

    this.off();

    let goToPage = "";
    if (userTypeID === "3") goToPage = `pageNotificationsEntity/${stageID}`;
    if (userTypeID === "4") goToPage = `pageNotificationsEntity/${stageID}`;

    this.setState({ goToPage });
    // get data from database to display options in textfields

    if (ID === "new") return;
    let { data: notification } = await httpService.get(
      config.apiEndpointNotificationGet + "?id=" + ID
    );

    if (!notification) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(notification) });
  }

  // Submit a new or update
  doSubmit = async () => {
    this.on();
    const ID = this.state.myParams.id;
    const raceID = this.state.raceID;
    const stageID = this.state.stageID;
    const entityJocRepID = this.state.entityJocRepID;
    const userTypeID = this.state.userTypeID;
    const entityID = this.state.entityID;

    if (ID === "new") {
      // add a new entry
      // get date and time for the ticket submision
      let NotificationDate = new Date().toISOString().slice(0, 10);

      let today = new Date();
      let NotificationTime =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let dataToSend = this.state.data;
      let formData = new FormData();
      formData.append("raceID", raceID);
      formData.append("stageID", stageID);
      formData.append("entityID", entityID);
      formData.append("entityJocRepID", entityJocRepID);
      formData.append("userTypeID", userTypeID);
      formData.append("notificationDate", NotificationDate);
      formData.append("notificationTime", NotificationTime);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      try {
        await httpService.post(
          config.apiEndpointNotificationEntityAdd,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry

      let dataToSend = this.state.data;
      let formData = new FormData();
      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      try {
        await httpService.post(
          config.apiEndpointNotificationEntityUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate(`/${this.state.goToPage}`);
    this.off();
  };

  //Submit a delete
  doDelete = async () => {
    this.on();
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointNotificationdelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/${this.state.goToPage}`);
    this.off();
  };

  mapToViewModel(data) {
    return {
      // "id": "4",
      // "notificEntityId": "0",
      // "notificTypeId": "2",
      // "notificStatusId": "2",
      // "comment": "Please be on the look out for tired riders. They have been out there a while."

      notificTypeId: data.notificTypeId,
      notificStatusId: data.notificStatusId,
      comment: data.comment,
    };
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let { color, cssOverride, goToPage, loading, stageName } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div id="overlay" className="overlay" onClick={() => this.off()}>
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link to={`/${goToPage}`} className="btn p-0">
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{stageName}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Notification</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Type</td>
                      <td>
                        {this.state.notificationTypes &&
                          this.renderSelect(
                            "notificTypeId",
                            "name",
                            this.state.notificationTypes,
                            ""
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {this.state.notificationStatus &&
                          this.renderSelect(
                            "notificStatusId",
                            "name",
                            this.state.notificationStatus,
                            ""
                          )}
                      </td>
                    </tr>

                    <tr>
                      <td>Comment</td>
                      <td>
                        {this.renderTextArea(
                          "comment",
                          "Comment",
                          "",
                          "60",
                          "2"
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(NotificationForm);
