import React from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import { TicketMarker } from "./ticketMarker";

/**
 * Renders a group of markers on a map based on the provided ticket data.
 * @param {Object} ticketDataByType - An object containing ticket data grouped by type.
 * @returns {JSX.Element|null} - A group of markers representing different ticket types on the map.
 */
export const TicketLayer = ({ ticketDataByType }) => {
  // Check if ticketDataByType is valid
  if (
    ticketDataByType &&
    ticketDataByType.tickets &&
    Array.isArray(ticketDataByType.tickets) &&
    typeof ticketDataByType.name === "string"
  ) {
    const { tickets, name } = ticketDataByType;

    // Render the markers as an overlay within a LayerGroup component
    return (
      <LayersControl.Overlay checked name={name}>
        <LayerGroup>
          {/* Create markers for each ticket type */}
          {tickets.map((ticket) => {
            const { id } = ticket;
            if (id !== null && id !== undefined) {
              return <TicketMarker key={id} ticketsByType={ticket} />;
            }
            return null;
          })}
        </LayerGroup>
      </LayersControl.Overlay>
    );
  } else {
    return null;
  }
};
