import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarSiteAdmin from "./../../components/navbarSiteAdmin";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import Joi from "joi-browser";
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class GroupForm extends Form {
  state = {
    data: {
      addressOne: "",
      addressTwo: "",
      addressThree: "",
      addressSuburb: "",
      addressTownCity: "",
      addressProvince: "",
      addressCountry: "",
      addressCode: "",
      companyReg: "",
      companyVat: "",
      contactPerson: "",
      email: "",
      groupName: "",
      tel: "",
      website: "",
    },
    genres: [],
    errors: {},

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    addressOne: Joi.string().label("Street Number"),
    addressTwo: Joi.string().label("Street Name"),
    addressThree: Joi.string().label("Building / Complex"),
    addressSuburb: Joi.string().label("Suburb"),
    addressTownCity: Joi.string().label("Town /City"),
    addressProvince: Joi.string().label("Province"),
    addressCountry: Joi.string().label("Country"),
    addressCode: Joi.string().label("Code"),
    companyReg: Joi.string().label("Company Reg"),
    companyVat: Joi.string().label("Company Vat"),
    contactPerson: Joi.string().label("Contact Person"),
    email: Joi.string().label("Email").email(),
    groupId: Joi.string().label("Group Id"),
    groupName: Joi.string().label("Group Name"),
    tel: Joi.string().label("Telephone Number"),
    website: Joi.string().label("Website"),
  };

  //Load an new group or display a blank page
  async componentDidMount() {
    const groupID = this.state.myParams.id;

    if (groupID === "new") return;

    try {
      const { data: group } = await httpService.get(
        config.apiEndpointGroupGet + "?id=" + groupID
      );
      if (!group) return this.state.myNavigate("/not-found");
      //Load the screen with the retrieved data
      this.setState({ data: this.mapToViewModel(group) });
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while retrieving.");
    }
  }

  //Submit a new Group

  doSubmit = async () => {
    const groupID = this.state.myParams.id;

    if (groupID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointGroupAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();
      //add the Id to the data to send to db
      formData.append("id", groupID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      // console.log("formData = " + formData);
      try {
        await httpService.post(config.apiEndpointGroupUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate("/pageGroup");
  };

  //Submit a delete Group
  doDelete = async () => {
    const groupID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointGroupDelete + "?id=" + groupID
      );

      var myObject = JSON.parse(deleteReply);
      console.log("myObject = " + { ...myObject });
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate("/pageGroup");
  };

  doClear = () => {
    // clear State dats that could have been updated by the changed event if the user started entering the form
    let data = {
      addressOne: "",
      addressTwo: "",
      addressThree: "",
      addressSuburb: "",
      addressTownCity: "",
      addressProvince: "",
      addressCountry: "",
      addressCode: "",
      companyReg: "",
      companyVat: "",
      contactPerson: "",
      email: "",
      groupName: "",
      tel: "",
      website: "",
    };

    // update the state
    this.state.data = this.data;

    // clear the form fields
    const blankGroup = {
      addressLineOne: "",
      addressLineTwo: "",
      addressLineThree: "",
      addressSuburb: "",
      addressTownCity: "",
      addressProvince: "",
      addressCountry: "",
      addressCode: "",
      companyReg: "",
      companyVat: "",
      contactPerson: "",
      email: "",
      groupId: "",
      groupName: "",
      tel: "",
      website: "",
    };

    this.setState({ data: this.mapToViewModel(blankGroup) });
    this.state.myParams.id = "new";
    this.state.myNavigate("/pageGroup");
  };

  mapToViewModel(group) {
    return {
      addressOne: group.addressLineOne,
      addressTwo: group.addressLineTwo,
      addressThree: group.addressLineThree,
      addressSuburb: group.addressSuburb,
      addressTownCity: group.addressTownCity,
      addressProvince: group.addressProvince,
      addressCountry: group.addressCountry,
      addressCode: group.addressCode,
      companyReg: group.companyReg,
      companyVat: group.companyVat,
      contactPerson: group.contactPerson,
      email: group.email,
      groupId: group.id,
      groupName: group.groupName,
      tel: group.tel,
      website: group.website,
    };
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarSiteAdmin />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formGroup">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <div className="button-padding">
                          <Link to="/pageGroup" className="btn p-0">
                            <ArrowLeftCircle color="#002072" size={40} />
                          </Link>
                        </div>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h4>Group Details</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Group Name</td>
                      <td>{this.renderInput("groupName", "Group Name")}</td>
                    </tr>
                    <tr>
                      <td>Contact person</td>
                      <td>
                        {this.renderInput("contactPerson", "Contact Person")}
                      </td>
                    </tr>
                    <tr>
                      <td>Telephone</td>
                      <td> {this.renderInput("tel", "Telephone")}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td> {this.renderInput("email", "Email")}</td>
                    </tr>
                    <tr>
                      <td>Website</td>
                      <td> {this.renderInput("website", "Website")}</td>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h5>Address</h5>
                      </th>
                    </tr>
                    <tr>
                      <td>Street Number</td>
                      <td>{this.renderInput("addressOne", "Street Number")}</td>
                    </tr>
                    <tr>
                      <td>Street Name</td>
                      <td> {this.renderInput("addressTwo", "Street Name")}</td>
                    </tr>
                    <tr>
                      <td>Building Complex</td>
                      <td>
                        {this.renderInput("addressThree", "Building / Complex")}
                      </td>
                    </tr>
                    <tr>
                      <td>Suburb</td>
                      <td>{this.renderInput("addressSuburb", "Suburb")}</td>
                    </tr>
                    <tr>
                      <td>Town City</td>
                      <td>
                        {this.renderInput("addressTownCity", "Town / City")}
                      </td>
                    </tr>
                    <tr>
                      <td>Province</td>
                      <td>{this.renderInput("addressProvince", "Province")}</td>
                    </tr>
                    <tr className="input-padding">
                      <td>Country</td>
                      <td> {this.renderInput("addressCountry", "Country")}</td>
                    </tr>
                    <tr>
                      <td>Code</td>
                      <td> {this.renderInput("addressCode", "Code")}</td>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h5>Invoicing</h5>
                      </th>
                    </tr>
                    <tr>
                      <td>Company Reg</td>
                      <td> {this.renderInput("companyReg", "Company Reg")}</td>
                    </tr>
                    <tr>
                      <td>Company Vat</td>
                      <td> {this.renderInput("companyVat", "Company Vat")}</td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(GroupForm);
