import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

/**
 * Renders a button with a tooltip message and a link.
 */
class CustomButton extends React.Component {
  /**
   * Renders a tooltip component with the given message.
   * @param {string} message - The message to be displayed in the tooltip.
   * @returns {React.Component} - The tooltip component.
   */
  renderTooltip = (message) => (
    <Tooltip id="button-tooltip" className="tooltip-km">
      {message}
    </Tooltip>
  );

  render() {
    const { tooltipMessage, link, buttonColor, icon, buttonText } = this.props;
    const tooltipDelay = { show: 250, hide: 400 };

    return (
      <div className="button-center">
        <OverlayTrigger
          placement="right"
          delay={tooltipDelay}
          overlay={this.renderTooltip(tooltipMessage)}
        >
          <Link
            to={link}
            className={`btn btn-${buttonColor} button-color-dashboard`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              height: 55,
              marginBottom: 5,
              marginTop: 5,
              width: 60,
            }}
          >
            {icon}
          </Link>
        </OverlayTrigger>
        <p className="text-darkblue">{buttonText}</p>
      </div>
    );
  }
}

CustomButton.propTypes = {
  tooltipMessage: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default CustomButton;
