import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup.jsx";
import SearchBox from "../components/common/searchBox.jsx";
import GpxFileTable from "../components/gpxFileTable.jsx";
import withMyHook from "../components/common/withMyHook.jsx";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

//Services
import httpService from "../services/httpService.js";
import config from "../config.json";

class pageGPXfilesUpload extends React.Component {
  state = {
    currentPage: 1,
    dataRetreived: [],
    groupName: "",
    groupID: "",
    pageSize: 8,
    raceNameYear: "",
    raceID: "",
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
    stageID: "",
    stageName: "",

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    try {
      const stageID = this.state.myParams.id;

      const groupName = localStorage.getItem("groupName");
      const groupID = localStorage.getItem("groupID");
      const raceNameYear = localStorage.getItem("raceNameYear");
      const raceID = localStorage.getItem("raceID");
      const userTypeID = localStorage.getItem("userTypeID");

      // Get the Stage details for display to the user
      let { data: stage } = await httpService.get(
        config.apiEndpointStageGetbyID + "?id=" + stageID
      );

      // set the stage ID
      localStorage.setItem("stageName", stage.stageName);
      localStorage.setItem("stageID", stageID);

      this.setState({
        groupName,
        groupID,
        raceNameYear,
        raceID,
        stageID,
        stageName: stage.stageName,
      });

      // Get the GPX files that have been allocated Coordinates already

      let { data: Dbdata } = await httpService.get(
        config.apiEndpointGpxsCoordsGet + "?stageID=" + stageID
      );
      this.setState({ dataRetreived: Dbdata });

      // Set the back button address for each user type
      let goToPage = "/";
      if (userTypeID === "3") goToPage = `pageRaceHome/${raceID}`;
      if (userTypeID === "4") goToPage = `pageRaceEntityHome/${raceID}`;

      this.setState({ goToPage });
    } catch (error) {
      console.error("Error in componentDidMount:", error);
      // Additional error handling code or logging can be added here
    }
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handlePageIncrement = (sentCurrentPage, pagesCount) => {
    if (sentCurrentPage < pagesCount) {
      sentCurrentPage++;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageDecrement = (sentCurrentPage) => {
    if (sentCurrentPage !== 1) {
      sentCurrentPage--;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    let {
      currentPage,
      dataRetreived,
      goToPage,
      pageSize,
      searchQuery,
      sortColumn,
      stageName,
    } = this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.givenName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allentriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>{stageName} - Upload GPX file</h5>
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link to={`/${goToPage}`} className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>

                <Link to="/gpxUploadDBForm/new" className="btn p-0">
                  <PlusCircle color="#002072" size={40} />
                </Link>
              </span>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search files..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <GpxFileTable
                allentriesTotal={allentriesTotal}
                currentPage={currentPage}
                filteredRaceUsers={filtered}
                onPageDecrement={this.handlePageDecrement}
                onPageIncrement={this.handlePageIncrement}
                onPageChange={this.handlePageChange}
                onSort={this.handleSort}
                pageSize={pageSize}
                sortColumn={sortColumn}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(pageGPXfilesUpload);
