import React from "react";
import { Link } from "react-router-dom";

//Components
import StageInfoSplash from "./../components/stageInfoSplash";

class RaceEntityHero extends React.Component {
  render() {
    const { raceNameYear, raceDescription, stagedataRetreived } = this.props;

    if (stagedataRetreived) {
      return (
        <div className="row p-2 pb-0 pe-lg-0 pt-lg-2 border-radius-4 align-items-center shadow-lg row-fit">
          <div className="col-lg-12 p-2 p-lg-4 pt-lg-2 row-fit">
            <h4>{raceNameYear}</h4>

            <div className="row row-fit ">
              {stagedataRetreived.map((stage) => (
                <StageInfoSplash
                  distance={stage.distance}
                  elevation={stage.elevation}
                  key={stage.id}
                  stageDescription={stage.stageDescription}
                  stageName={stage.stageName}
                  stageType={stage.stageType.description}
                  stageID={stage.id}
                />
              ))}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row p-2 pb-0 pe-lg-0 pt-lg-2 border-radius-4 align-items-center shadow-lg row-fit">
          <div className="col-lg-12 p-2 p-lg-4 pt-lg-2 row-fit">
            <h4>{raceNameYear}</h4>

            <div className="row row-fit ">
              <div className="col-sm-12 entity-splash-padding row-fit">
                <div className="card fit-content center mt-2 row-fit ">
                  <div className="card-body row-fit pb-1">
                    <h6>No stages have been set up</h6>
                    <div>The Race Director will add the stage.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default RaceEntityHero;
