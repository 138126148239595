import React from "react";
import { Link } from "react-router-dom";

import { ArrowRightCircle } from "react-bootstrap-icons";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class GroupRaceCard extends React.Component {
  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    const { endDate, month, raceId, raceType, startDate, year, raceNameYear } =
      this.props;

    const userTypeID = localStorage.getItem("userTypeID");
    // Set the back button address for each user type
    let goToPage = "pageRaceHome";

    if (userTypeID === "4") goToPage = "pageRaceEntityHome";

    return (
      <div
        className="card card-width-race-group card-margin-race shadow"
        data-aos="zoom-in"
        data-aos-duration="5000"
      >
        <div className="card-body">
          <h5>{raceNameYear}</h5>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Race Type:</div>
              <div className="card-subtext">{raceType}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Month:</div>
              <div className="card-subtext">{month}</div>
              <div className="card-race-heading-sm">Year:</div>
              <div className="card-subtext">{year}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Start Date:</div>
              <div className="card-subtext">{startDate}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">End Date:</div>
              <div className="card-subtext">{endDate}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="col button-center">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("Select this race")}
              >
                <Link to={`/${goToPage}/${raceId}`} className="btn ">
                  <ArrowRightCircle color="#002072" size={40} />
                </Link>
              </OverlayTrigger>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default GroupRaceCard;
