import React from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import { BusTicketMarker } from "./busTicketMarker";
import { BusTicketMarkerComplete } from "./busTicketMarkerComplete";

export const BusTicketLayerComplete = ({ busTicketdata }) => {
  return (
    <LayersControl.Overlay checked name="Dropped off Passengers">
      <LayerGroup>
        {busTicketdata.map((busTicket) => (
          <BusTicketMarkerComplete key={busTicket.id} busTicket={busTicket} />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};
