import React from "react";
import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

//Other
import {
  Calendar2Event,
  DoorOpenFill,
  GeoAltFill,
  PersonFillGear,
  PersonVcardFill,
  PersonWorkspace,
} from "react-bootstrap-icons";

import { GiCarWheel } from "react-icons/gi";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class EntityListTable extends React.Component {
  state = {
    pageSize: 8,
    currentPage: 1,
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageIncrement = (setCurrentPage, pagesCount) => {
    if (setCurrentPage < pagesCount) {
      setCurrentPage++;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  handlePageDecrement = (setCurrentPage) => {
    if (setCurrentPage != 1) {
      setCurrentPage--;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  columns = [
    {
      path: "entityName",
      label: "Entity Name",
      content: (entity) => (
        <Link to={`/entityForm/${entity.id}`}>
          <h6>{entity.entityName}</h6>
        </Link>
      ),
    },
    { path: "contactPerson", label: "Contact Person" },
    { path: "cell", label: "Cell" },
    { path: "email", label: "Email" },
    {
      path: "",
      // key: "resolved",
      key: "",
      content: (entity) => (
        <div>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={this.renderTooltip("Entity Users")}
          >
            <Link
              to={`/pageEnityUsers/${entity.id}`}
              className="btn btn-primary btn-sm button-color-dashboard"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 15,
                height: 35,
                marginBottom: 5,
                marginTop: 5,
                width: 40,
              }}
            >
              <PersonVcardFill color="white" size={30} />
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      path: "",
      key: "",
      content: (entity) => (
        <div>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={this.renderTooltip("Entity User Roles")}
          >
            <Link
              to={`/pageEntityUserRoles/${entity.id}`}
              className="btn btn-primary btn-sm button-color-dashboard"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 15,
                height: 35,
                marginBottom: 5,
                marginTop: 5,
                width: 40,
              }}
            >
              <PersonFillGear color="white" size={30} />
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      path: "",
      key: "",
      content: (entity) => (
        <div>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={this.renderTooltip("JOC Representatives")}
          >
            <Link
              to={`/pageEntityJocRep/${entity.id}`}
              className="btn btn-primary btn-sm button-color-dashboard"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 15,
                height: 35,
                marginBottom: 5,
                marginTop: 5,
                width: 40,
              }}
            >
              <PersonWorkspace color="white" size={30} />
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      path: "",
      key: "",
      content: (entity) => (
        <div>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={this.renderTooltip("Entity Vehicles")}
          >
            <Link
              to={`/pageVehicles/${entity.id}`}
              className="btn btn-primary btn-sm button-color-dashboard"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 15,
                height: 35,
                marginBottom: 5,
                marginTop: 5,
                width: 40,
              }}
            >
              <GiCarWheel color="white" size={30} />
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  render() {
    const { filteredRaceUsers, onSort, sortColumn, onDelete, allEntriesTotal } =
      this.props;
    const { pageSize, currentPage } = this.state;
    const raceUsersPag = paginate(filteredRaceUsers, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={raceUsersPag}
            onDelete={onDelete}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allEntriesTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPageDecrement={this.handlePageDecrement}
            onPageIncrement={this.handlePageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default EntityListTable;
