import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import EntityTicketAllocTable from "../components/entityTicketAllocTable";
import SearchBox from "../components/common/searchBox";
import withMyHook from "../components/common/withMyHook";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class pageEntityTicketAlloc extends React.Component {
  state = {
    dataRetreived: [],
    groupName: "",
    groupID: "",
    raceID: "",
    raceNameYear: "",

    searchQuery: "",
    sortColumn: { path: "EntityName", order: "asc" },

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    //  This will be the race id
    const ID = this.state.myParams.id;

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");

    this.setState({
      groupName,
      groupID,
      raceID: ID,
      raceNameYear,
    });

    // if the race Id has been passed in addition to the Group id
    if (ID) {
      // Get data by group ID and race ID
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointEntitesRaceAllocGetRaceID +
          "?groupID=" +
          groupID +
          "&" +
          "raceID=" +
          ID
      );
      this.setState({ dataRetreived: Dbdata });
    } else {
      let { data: Dbdata } = await httpService.get(
        config.apiEndpointEntitesRaceAllocGet + "?groupID=" + groupID
      );
      this.setState({ dataRetreived: Dbdata });
    }
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let { dataRetreived, searchQuery, sortColumn, groupName, raceNameYear } =
      this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents

    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.raceDesc.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allEntriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>{raceNameYear} - Ticket Allocation</h5>
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link
                  to={`/pageRaceHome/${this.state.raceID}`}
                  className="btn p-0"
                >
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>
              </span>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Entities..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <EntityTicketAllocTable
                filtereddata={filtered}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                allEntriesTotal={allEntriesTotal}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(pageEntityTicketAlloc);
