import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import PassengerTicketsTable from "../components/passengerTicketsTable";
import SearchBox from "../components/common/searchBox";
import withMyHook from "./../components/common/withMyHook";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class pagePassengerEntity extends React.Component {
  state = {
    currentPage: 1,
    dataRetreived: [],
    groupName: "",
    groupID: "",
    pageSize: 10,
    stageID: "",
    raceID: "",
    raceNameYear: "",
    raceUserTypesData: [],
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
    stage: "",

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const vehicleID = this.state.myParams.id;
    const stageID = localStorage.getItem("stageID");

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    this.setState({ groupName, groupID, stageID, raceID, raceNameYear });

    // Get the Stage details for display to the user
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );
    this.setState({ stage, stageID });

    // set the stage ID
    localStorage.setItem("stageName", stage.stageName);
    localStorage.setItem("stageID", stageID);

    //Get the passengers for every vehicle
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointVehiclePassEntityGet +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );
    this.setState({ dataRetreived: Dbdata });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handlePageIncrement = (sentCurrentPage, pagesCount) => {
    if (sentCurrentPage < pagesCount) {
      sentCurrentPage++;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageDecrement = (sentCurrentPage) => {
    if (sentCurrentPage != 1) {
      sentCurrentPage--;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      currentPage,
      dataRetreived,
      pageSize,
      searchQuery,
      sortColumn,
      raceNameYear,
      stage,
      stageID,
      raceID,
    } = this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.participantNo.toLowerCase().includes(searchQuery.toLowerCase())
      );
    const allRaceUsersTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>
              {raceNameYear} - {stage.stageName} - Passenger Tickets
            </h5>
            <div className="col" data-aos="fade-right">
              <Link to={`/pageVehiclePassenger/${stageID}`} className="btn p-0">
                <ArrowLeftCircle color="#002072" size={40} />
              </Link>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Participant No..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <PassengerTicketsTable
                allRaceUsersTotal={allRaceUsersTotal}
                currentPage={currentPage}
                filteredRaceUsers={filtered}
                onPageDecrement={this.handlePageDecrement}
                onPageIncrement={this.handlePageIncrement}
                onPageChange={this.handlePageChange}
                onSort={this.handleSort}
                pageSize={pageSize}
                sortColumn={sortColumn}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(pagePassengerEntity);
