import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import EntityUserRoleTable from "../components/entityUserRoleTable";
import SearchBox from "../components/common/searchBox";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

import withMyHook from "../components/common/withMyHook";

//Services
import httpService from "../services/httpService.js";
import config from "../config.json";

class pageEntityUserRoles extends React.Component {
  state = {
    dataRetreived: [],
    goToPage: "",
    groupName: "",
    groupID: "",
    entityName: "",
    entityID: "",
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    // Rather have the entity user id passed to this class and then set it to the local state.
    // this screen is also called from the Team member screen and an entity has not bee set yet as opposed to the enitity joc login
    const userTypeID = localStorage.getItem("userTypeID");
    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    let entityName = localStorage.getItem("entityName");
    let entityID = localStorage.getItem("entityID");

    if (entityID == "") {
      entityID = this.state.myParams.id;
      console.log("entityID = in = " + entityID);

      // get the entity details from the database
      let { data: entity } = await httpService.get(
        config.apiEndpointEntityGet + "?id=" + entityID
      );

      localStorage.setItem("entityID", entityID);
      localStorage.setItem("entityName", entity.entityName);
      entityName = entity.entityName;
    }

    // Set the back button address for each user type
    console.log("userTypeID = " + userTypeID);
    let goToPage = "pageEntityHome";

    if (userTypeID === "3") goToPage = "pageEntity";
    if (userTypeID === "2") goToPage = "pageGroupHome";

    this.setState({
      groupName,
      groupID,
      entityName,
      entityID,
      goToPage,
    });

    // Get Entity User Roles by Entity ID
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointEntityUserRolesGet + "?entityID=" + entityID
    );
    this.setState({ dataRetreived: Dbdata });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let { dataRetreived, searchQuery, sortColumn, entityName, goToPage } =
      this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allentriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>{entityName} - Member Roles</h5>
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link to={`/${goToPage}`} className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={40} />
                </Link>
              </span>
              <Link to="/entityUserRoleForm/new">
                <PlusCircle color="#002072" size={40} />
              </Link>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Roles..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <EntityUserRoleTable
                filteredRaceUsers={filtered}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                allentriesTotal={allentriesTotal}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(pageEntityUserRoles);
