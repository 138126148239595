import React, { Component } from "react";
import NavbarSiteAdmin from "../components/navbarSiteAdmin";
import { getGroups } from "./../services/fakeGroupService";
import _ from "lodash";
import SiteAdminHero from "../components/siteAdminHero";

class PageSiteAdmin extends React.Component {
  state = {
    groupData: [],
    sortColumn: { path: "groupName", order: "asc" },
    userName: "",
    userSurname: "",
  };

  componentDidMount() {
    let groupDataB = getGroups();
    const sortedGroupDataB = _.orderBy(
      groupDataB,
      [this.state.sortColumn.path],
      [this.state.sortColumn.order]
    );

    this.state.userName = localStorage.getItem("userName");
    this.state.userSurname = localStorage.getItem("userSurname");

    this.setState({ groupData: sortedGroupDataB });
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const { groupData } = this.state;
    let { userName, userSurname } = this.state;
    let userCombinedName = userName + " " + userSurname;

    return (
      <React.Fragment>
        <NavbarSiteAdmin />
        <div className="container container-top-70">
          <div className="row">
            <h5>{userCombinedName}</h5>
          </div>
          <SiteAdminHero />
        </div>
      </React.Fragment>
    );
  }
}

export default PageSiteAdmin;
