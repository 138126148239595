import React from "react";
import { EntityTicketMap } from "./entityTicketMap";
import { EntityUserMap } from "./entityUserMap";

import { Link } from "react-router-dom";
//Other
import { ArrowCounterclockwise } from "react-bootstrap-icons";

class EntitySettingsAccord extends React.Component {
  state = {};

  // const link = document.getElementById('btn');
  // for(let i = 0; i < 5; i++) {
  //    link.click();
  // }

  componentDidMount() {
    const { autoRefreshEntityUsers, autoRefreshTickets, autoRefreshVehicles } =
      this.props;

    // Now set the radio button as checked
    const cbTickets = document.querySelector("#mainTicketBox");
    cbTickets.checked = true;

    const cbEnityUser = document.querySelector("#mainEntityUserBox");
    cbEnityUser.checked = true;

    const cbVehicles = document.querySelector("#mainVehicleBox");
    cbVehicles.checked = true;

    // This enables the Auto refresh on the map so that by defualt the screen is refreshing.
    // if they need to it can be tunred off using the settings radio button.
    autoRefreshTickets();
    autoRefreshEntityUsers();
    autoRefreshVehicles();
  }

  render() {
    const {
      autoRefreshEntityUsers,
      autoRefreshTickets,
      autoRefreshVehicles,
      refreshTickets,
      refreshEntityUsers,
      refreshVehicles,
    } = this.props;

    return (
      <React.Fragment>
        <div className="marginTop-20"></div>
        <h6>Refresh Settings</h6>
        <div className="marginBottom10">
          <div className="accordion" id="settings">
            <div className="accordion-item">
              <h2 className="accordion-header" id="ticketSettings">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTicketSettings"
                  aria-expanded="false"
                  aria-controls="collapseTicketSettings"
                >
                  Tickets
                </button>
              </h2>
              <div
                id="collapseTicketSettings"
                className="accordion-collapse collapse"
                aria-labelledby="ticketSettings"
                data-bs-parent="#settings"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col button-align pt-3">
                      <label className="switch margin-left">
                        <input
                          id="mainTicketBox"
                          name="mainTicketBox"
                          type="checkbox"
                          onClick={() => autoRefreshTickets()}
                        />
                        <span className="slider round"></span>
                      </label>
                      <h6 className="button-padding">Auto refresh</h6>
                    </div>
                    <div className="col button-align-end ">
                      <div className="margin-left marginBottom-5">
                        <Link
                          to=""
                          onClick={() => refreshTickets()}
                          className="btn"
                        >
                          <ArrowCounterclockwise color="#002072" size={35} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="memberSettings">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseMemberSettings"
                  aria-expanded="false"
                  aria-controls="collapseMemberSettings"
                >
                  Members
                </button>
              </h2>
              <div
                id="collapseMemberSettings"
                className="accordion-collapse collapse"
                aria-labelledby="memberSettings"
                data-bs-parent="#settings"
              >
                <div className="accordion-body">
                  <div className="row marginTop-5">
                    <div className="col button-align p-3">
                      <label className="switch margin-left">
                        <input
                          id="mainEntityUserBox"
                          name="mainEntityUserBox"
                          type="checkbox"
                          onClick={() => autoRefreshEntityUsers()}
                        />

                        <span className="slider round"></span>
                      </label>
                      <h6 className="button-padding">Auto refresh</h6>
                    </div>
                    <div className="col button-align-end ">
                      <div className="margin-left marginBottom-5">
                        <Link
                          to=""
                          onClick={() => refreshEntityUsers()}
                          className="btn"
                        >
                          <ArrowCounterclockwise color="#002072" size={35} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="vehicleSettings">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseVehicleSettings"
                  aria-expanded="false"
                  aria-controls="collapseVehicleSettings"
                >
                  Vehicles
                </button>
              </h2>
              <div
                id="collapseVehicleSettings"
                className="accordion-collapse collapse"
                aria-labelledby="vehicleSettings"
                data-bs-parent="#settings"
              >
                <div className="accordion-body">
                  <div className="row marginTop-5">
                    <div className="col button-align p-3">
                      <label className="switch margin-left">
                        <input
                          id="mainVehicleBox"
                          name="mainVehicleBox"
                          type="checkbox"
                          onClick={() => autoRefreshVehicles()}
                        />

                        <span className="slider round"></span>
                      </label>
                      <h6 className="button-padding">Auto refresh</h6>
                    </div>
                    <div className="col button-align-end ">
                      <div className="margin-left marginBottom-5">
                        <Link
                          to=""
                          onClick={() => refreshVehicles()}
                          className="btn"
                        >
                          <ArrowCounterclockwise color="#002072" size={35} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EntitySettingsAccord;
