import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarGroup from "./../../components/navbarGroup";

// Services
import httpService from "../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class EntityDefaultPasswordForm extends Form {
  state = {
    data: {
      defaultPassword: "",
    },
    entityID: "",
    existingID: "",
    newDataEntry: "",
    raceID: "",
    raceNameYear: "",
    stageID: "",
    stageName: "",

    entityUsers: [],
    errors: {},
    roleData: [],
    stageData: [],
    dataDB: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    defaultPassword: Joi.string().label("Default Password"),
  };

  async componentDidMount() {
    const stageID = this.state.myParams.id;

    // get local storage
    const entityID = localStorage.getItem("entityID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");
    const groupID = localStorage.getItem("groupID");
    const userTypeID = localStorage.getItem("userTypeID");

    // Get the stages details based on the stage id
    let { data: stagedata } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    const stageName = stagedata.stageName;
    //Set Local storage
    localStorage.setItem("stageID", stageID);
    localStorage.setItem("stageName", stageName);

    // Set the State
    this.setState({
      entityID,
      raceID,
      raceNameYear,
      stageID,
      stageData: stagedata,
      stageName,
    });

    // Go to the database and get the existing password if there is one using stageID and Enity ID
    //go to entity default password and get the entry
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointEntityPasswordGet +
        "?entityID=" +
        entityID +
        "&" +
        "stageID=" +
        stageID
    );
    // set 'newDataEntry' to 'new' if one does not exist.
    // else load to screen and store the existing id into existingID

    // if one  exists then load to screen

    // Get data from database to display options in textfields
    // if (this.state.newDataEntry === "new") return;

    if (!retreivedData) {
      this.state.newDataEntry = "new";
      // return;
    } else {
      this.state.newDataEntry = retreivedData.id;
      this.setState({ data: this.mapToViewModel(retreivedData) });
    }
  }

  doSubmit = async () => {
    // Get the password id  for a new entry
    const ID = this.state.newDataEntry;

    //remeber to code the PHP file to update all the entity users with this stage id with the new password.

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // add the group ID to the form data
      formData.append("stageID", this.state.stageID);
      formData.append("entityID", this.state.entityID);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointEntityPasswordAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointEntityPasswordUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate(`/pageRaceEntityHome/${this.state.raceID}`);
  };

  //Submit a delete Group
  doDelete = async () => {
    // Get the password id  for a new entry
    const ID = this.state.newDataEntry;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointEntityPasswordDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/pageRaceEntityHome/${this.state.raceID}`);
  };

  mapToViewModel(data) {
    return {
      defaultPassword: data.defaultPassword,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={`/pageRaceEntityHome/${this.state.raceID}`}
                          className="btn p-0"
                        >
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.stageName}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Default Member Password</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Password</td>
                      <td>{this.renderInput("defaultPassword", "Password")}</td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(EntityDefaultPasswordForm);
