import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";

import { defaultIcon } from "../icons/defaultIcon";
import L from "leaflet";

import { FiUser } from "react-icons/fi";
import {
  Bicycle,
  CardText,
  Clock,
  ClockFill,
  GeoAltFill,
  GeoAlt,
  PersonVcardFill,
  QuestionLg,
} from "react-bootstrap-icons";

export const BusTicketMarkerComplete = ({ busTicket }) => {
  function GetIcon(Icon, resolved) {
    let IconUrlResolvedStatus = "";
    let IconUrlResolvedStatusShadow = "";

    if (Icon) {
      // setUp the Paths

      IconUrlResolvedStatus = Icon.iconUrlResolved;
      IconUrlResolvedStatusShadow = Icon.iconUrlResolved;

      return L.icon({
        iconUrl: require("../images/mapicons/tickets/" + IconUrlResolvedStatus),
        iconShadowUrl: require("../images/mapicons/tickets/" +
          IconUrlResolvedStatusShadow),
        iconAnchor: [Icon.iconAnchorH * 1, Icon.iconAnchorV * 1], // point of the icon which will correspond to marker's location
        iconSize: [Icon.iconSizeW * 1, Icon.iconSizeH * 1], // size of the icon,
        popupAnchor: [Icon.popupAnchorH * 1, Icon.popupAnchorV * 1], // point from which the popup should open relative to the iconAnchor
        shadowAnchor: [Icon.shadowAnchorH * 1, Icon.shadowAnchorV * 1], // the same for the shadow
        shadowSize: [Icon.shadowSizeW * 1, Icon.shadowSizeH * 1], // size of the shadow
      });
    } else {
      return defaultIcon;
    }
  }

  if (busTicket.disLatitude == "") {
    return <div></div>;
  } else {
    return (
      <div>
        <Marker
          draggable={true}
          position={[busTicket.disLatitude, busTicket.disLongitude]}
          icon={GetIcon(busTicket.icon, busTicket.completed)}
        >
          <Popup closeButton={false}>
            <div className="card" style={{ width: 16 + "rem" }}>
              <div className="card-body">
                <h5 className="card-title">
                  (#{busTicket.id}) {busTicket.participantNo}
                </h5>
                <p className="card-text">{busTicket.commentText}</p>
              </div>

              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <div className="center">
                    <div className="details-align py-1 px-2">
                      <div className="details-icon">
                        <FiUser color="#858796" size="20" />
                      </div>
                      <div className="card-passenger-sm">
                        {busTicket.passLoad}
                      </div>
                    </div>
                    <div className="details-align py-1 px-2">
                      <div className="details-icon">
                        <Bicycle color="#858796" size="25" />
                      </div>
                      <div className="card-passenger-sm">
                        {busTicket.cycleLoad}
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div>Drop off Time: {busTicket.disTime}</div>
                  <div>Vehicle: {busTicket.name}</div>
                </li>
                <li className="list-group-item">
                  <a
                    href={`https://www.google.com/maps/place/${busTicket.disLatitude},${busTicket.disLongitude}`}
                    target="_blank"
                  >
                    Google Maps: {busTicket.disLatitude},
                    {busTicket.disLongitude}
                  </a>
                </li>
              </ul>
            </div>
          </Popup>
        </Marker>
      </div>
    );
  }
};
