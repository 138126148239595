import React from "react";
import { Link } from "react-router-dom";

class EntityStageCard extends React.Component {
  render() {
    const {
      stageDescription,
      distance,
      elevation,
      endDate,
      endVenue,
      stageId,
      stageName,
      stageType,
      startDate,
      startVenue,
    } = this.props;

    return (
      <div
        className="card card-width-race-group card-margin-race shadow  "
        data-aos="zoom-in"
        data-aos-duration="5000"
      >
        <div className="card-body">
          <h5>{stageName}</h5>

          <p className="card-text">{stageDescription}</p>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Stage Type:</div>
              <div className="card-subtext">{stageType}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Start Date:</div>
              <div className="card-subtext">{startDate}</div>
              <div className="card-race-heading-sm">End Date:</div>
              <div className="card-subtext">{endDate}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Start Venue:</div>
              <div className="card-subtext">{startVenue}</div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">End Venue:</div>
              <div className="card-subtext">{endVenue}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Distance:</div>
              <div className="card-subtext">{distance}</div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="card-align-race">
              <div className="card-race-heading-sm">Elevation:</div>
              <div className="card-subtext">{elevation}</div>
            </div>
          </li>

          <div>
            <div className="row px-3">
              <Link
                to={`/PageStageEntityUserRoleAllocation/${stageId}`}
                className="btn btn-primary btn-sm px-4"
                style={{ marginBottom: 10, marginTop: 15 }}
              >
                {" "}
                Allocate Entity Users
              </Link>
            </div>
            <div className="row px-3">
              <Link
                to={`/pageTicketCards/${stageId}`}
                className="btn btn-primary btn-sm px-4"
                style={{ marginBottom: 10, marginTop: 5 }}
              >
                Tickets
              </Link>
            </div>
            <div className="row px-3">
              <Link
                to=""
                className="btn btn-primary btn-sm px-4"
                style={{ marginBottom: 10, marginTop: 5, marginRight: 10 }}
              >
                Go to map
              </Link>
            </div>
          </div>
        </ul>
      </div>
    );
  }
}

export default EntityStageCard;
