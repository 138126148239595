import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";

import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarEntity from "./../../components/navbarEntity";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class TicketForm extends Form {
  state = {
    data: {
      allocateToEntityID: "",
      commentText: "",
      latitude: "",
      longitude: "",
      participantNo: "",
      priorityCheck: "",
      resolvedCheck: "",
      ticketTypeID: "",
    },
    entityJocRepID: "",
    entityName: "",
    entityID: "",
    goToPage: "",
    groupID: "",
    raceID: "",
    raceNameYear: "",
    stageName: "",

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    entites: [],
    entityUsersDB: [],
    entitiesAllocated: [],
    errors: {},
    ticketTypesData: [],

    priorityData: [
      { id: 0, description: "No" },
      { id: 1, description: "Yes" },
    ],

    resolvedData: [
      { id: 0, description: "No" },
      { id: 1, description: "Yes" },
    ],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    ticketTypeID: Joi.string().label("Ticket Type"),
    allocateToEntityID: Joi.string().label("Allocated to Entity"),
    participantNo: Joi.string()
      .empty("")
      .optional()
      .allow("")
      .label("Participant No"),
    commentText: Joi.string().label("Comment"),
    latitude: Joi.string().empty("").optional().allow("").label("Latitude"),
    longitude: Joi.string().empty("").optional().allow("").label("Longitude"),
    priorityCheck: Joi.string().label("Priority"),
    resolvedCheck: Joi.string().label("Resolved"),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;

    const entityID = localStorage.getItem("entityID");
    const entityJocRepID = localStorage.getItem("entityJocRepID");
    const entityName = localStorage.getItem("entityName");
    const groupID = localStorage.getItem("groupID");
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const stageID = localStorage.getItem("stageID");
    const stageName = localStorage.getItem("stageName");
    const userTypeID = localStorage.getItem("userTypeID");

    if (userTypeID == 3) {
      // Get the Ticket Types
      let { data: ticketTypesDB } = await httpService.get(
        config.apiEndpointTicketTypesGet + "?raceID=" + raceID
      );
      this.setState({ ticketTypesData: ticketTypesDB });
    } else {
      // Get the Ticket Types
      let { data: ticketTypesDB } = await httpService.get(
        config.apiEndpointTicketTypesAllocatedGet +
          "?raceID=" +
          raceID +
          "&" +
          "entityID=" +
          entityID
      );
      this.setState({ ticketTypesData: ticketTypesDB });
    }

    // Get entites by group ID and race ID
    let { data: entityDB } = await httpService.get(
      config.apiEndpointEntitesRaceAllocGetRaceID +
        "?groupID=" +
        groupID +
        "&" +
        "raceID=" +
        raceID
    );

    //Get the entity users that are linked to thge stage and Entity
    let { data: entityUsersDB } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationsReturnUserID +
        "?stageID=" +
        stageID +
        "&" +
        "entityID=" +
        entityID
    );
    this.setState({ entityUsersDB });

    let goToPage = "pageTicketCards";

    if (userTypeID === "3") goToPage = "pageRDTicketCards";

    this.setState({
      entitiesAllocated: entityDB,
      entityJocRepID,
      entityID,
      entityName,
      goToPage,
      groupID,
      raceID,
      raceNameYear,
      stageName,
      stageID,
    });

    // Get data from database to display options in textfields
    if (ID === "new") return;
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointEntityStageTicketGet + "?id=" + ID
    );

    if (!retreivedData) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(retreivedData) });
  }

  doSubmit = async () => {
    // Get the group Id for a new entry
    const ID = this.state.myParams.id;
    this.on();

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // get date and time for the ticket submision
      let ticketDate = new Date().toISOString().slice(0, 10);

      let today = new Date();
      let ticketTime =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      // add the static info to the formData
      formData.append("entityJocRepID", this.state.entityJocRepID);
      formData.append("entityID", this.state.entityID);
      formData.append("stageID", this.state.stageID);
      formData.append("ticketDate", ticketDate);
      formData.append("ticketTime", ticketTime);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointEntityStageTicketsAdd,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry

      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(
          config.apiEndpointEntityStageTicketUpdate,
          formData
        );
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.off();
    this.state.myNavigate(`/${this.state.goToPage}/${this.state.stageID}`);
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointEntityStageTicketDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/${this.state.goToPage}/${this.state.stageID}`);
  };

  mapToViewModel(data) {
    return {
      allocateToEntityID: data.allocToEntityId,
      ticketTypeID: data.entityTicketTypeId,
      participantNo: data.participantNo,
      commentText: data.comment,
      latitude: data.latitude,
      longitude: data.longitude,
      priorityCheck: data.priorityCheck,
      resolvedCheck: data.resolvedCheck,
      // This causes the save button to not appear blue to save.
      // entityUserID: data.enitityUserId,
    };
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const { color, cssOverride, goToPage, loading } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarEntity />
        <div className="container container-top-70">
          <div id="overlay" className="overlay" onClick={() => this.off()}>
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={`/${goToPage}/${this.state.stageID}`}
                          className="btn p-0"
                        >
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.raceNameYear}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>Ticket - {this.state.stageName}</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ticket Type *</td>
                      <td>
                        {this.state.ticketTypesData &&
                          this.renderSelect(
                            "ticketTypeID",
                            "name",
                            this.state.ticketTypesData,
                            ""
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Allocate to Entity *</td>
                      <td>
                        {this.state.entitiesAllocated &&
                          this.renderSelect(
                            "allocateToEntityID",
                            "entityName",
                            this.state.entitiesAllocated,
                            ""
                          )}
                      </td>
                    </tr>

                    <tr>
                      <td>Participant No</td>
                      <td>
                        {this.renderInput("participantNo", "Participant No")}
                      </td>
                    </tr>
                    <tr>
                      <td>latitude</td>
                      <td>{this.renderInput("latitude", "Latitude")}</td>
                    </tr>
                    <tr>
                      <td>Longitude</td>
                      <td>{this.renderInput("longitude", "Longitude")}</td>
                    </tr>

                    <tr>
                      <td>Ticket Comment *</td>
                      <td>
                        {this.renderTextArea(
                          "commentText",
                          "Comment",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Priority ?</td>
                      <td>
                        {this.renderSelect(
                          "priorityCheck",
                          "description",
                          this.state.priorityData,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Resolved ? *</td>
                      <td>
                        {this.renderSelect(
                          "resolvedCheck",
                          "description",
                          this.state.resolvedData,
                          ""
                        )}
                      </td>
                    </tr>

                    {/* <tr>
                      <td>Upload Image</td>
                      <td>
                        {this.renderUpload("imageUploadDIR", "Image Upload")}
                      </td>
                    </tr> */}
                    {/*                     
                    <ClipLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      size={150}
                    /> */}
                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(TicketForm);
