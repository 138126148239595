import React, { Component } from "react";

import { Link } from "react-router-dom";

//Components
import MapOptions from "../components/mapOptions";
import withMyHook from "./../components/common/withMyHook";

// other
import { ToastContainer, Zoom, toast } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

class pageMapOptions extends Component {
  state = {
    centrePositionRet: [],
    entityID: "",
    goToPage: "",
    groupName: "",
    groupID: "",
    mapSettingsID: "",
    zoomLevel: "",
    raceID: "",
    raceNameYear: "",
    stage: "",
    stageID: "",

    mapURLs: [],

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const stageID = this.state.myParams.id;

    this.on();

    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    const userTypeID = localStorage.getItem("userTypeID");

    this.setState({
      entityID,
      groupName,
      groupID,
      stageID,
      raceID,
      raceNameYear,
    });

    // Get the Stage details for display to the user
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    // set the stage ID
    localStorage.setItem("stageName", stage.stageName);
    localStorage.setItem("stageID", stageID);

    let goToPage = "";
    if (userTypeID === "3") goToPage = `pageRaceHome/${raceID}`;

    // Get the stage Map options
    try {
      let { data: DbMapsConfigdata } = await httpService.get(
        config.apiEndpointStageOptions + "?id=" + stageID
      );

      let centrePositionRet = [DbMapsConfigdata.cpLat, DbMapsConfigdata.cpLong];
      let zoomLevel = DbMapsConfigdata.zoom;
      let mapSettingsID = DbMapsConfigdata.id;

      this.setState({
        centrePositionRet,
        mapSettingsID,
        zoomLevel,
      });
    } catch (error) {
      let centrePositionRet = [-5.794131, 31.120574];
      let zoomLevel = 3;
      let mapSettingsID = "New";

      this.setState({
        centrePositionRet,
        mapSettingsID,
        zoomLevel,
      });
    }

    // Get the gpx file names for the polylines
    let { data: mapURLs } = await httpService.get(
      config.apiEndpointGpxsGet + "?stageID=" + stageID
    );

    this.setState({
      goToPage,
      mapURLs,
      stage,
    });

    this.off();
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }
  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      centrePositionRet,
      color,
      cssOverride,
      goToPage,
      gotToForm,
      loading,
      mapSettingsID,
      mapURLs,
      stage,
      stageID,
      zoomLevel,
    } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div id="overlay" className="overlay" onClick={() => this.off()}>
          <div id="text" className="text">
            <PuffLoader
              color={color}
              loading={loading}
              cssOverride={cssOverride}
              size={50}
            />
            Loading Data...
          </div>
        </div>

        <div className="" data-aos="zoom-in" data-aos-duration="5000">
          <Link to={`/${goToPage}`} className="btn p-1 m-1">
            <ArrowLeftCircle color="#002072" size={35} />
          </Link>

          <span className="entity-map-logo">
            {stage.stageName} - Map layout
          </span>
          <div className="leaflet-container">
            <MapOptions
              zoomLevel={zoomLevel}
              centrePositionRet={centrePositionRet}
              mapSettingsID={mapSettingsID}
              stageID={stageID}
              mapURLs={mapURLs}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(pageMapOptions);
