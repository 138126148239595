import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";

// import ReactJoiValidations from "react-joi-validation";

import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarEntity from "./../../components/navbarEntity";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class gpxUploadForm extends Form {
  state = {
    data: {
      colorCheck: "",
      description: "",
      givenName: "",
      priorityCheck: "",
    },
    entityJocRepID: "",
    entityName: "",
    entityID: "",
    entityUserId: "",
    groupID: "",

    raceID: "",
    raceNameYear: "",
    selectedFile: "",
    stageName: "",
    stageID: "",

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    entites: [],
    entityUsersDB: [],
    entitiesAllocated: [],
    errors: {},
    ticketTypesData: [],

    priorityData: [
      { id: 1, priority: "1" },
      { id: 2, priority: "2" },
      { id: 3, priority: "3" },
      { id: 4, priority: "4" },
      { id: 5, priority: "5" },
      { id: 6, priority: "6" },
      { id: 7, priority: "7" },
      { id: 8, priority: "8" },
      { id: 9, priority: "9" },
      { id: 10, priority: "10" },
      { id: 11, priority: "11" },
      { id: 12, priority: "12" },
      { id: 13, priority: "13" },
      { id: 14, priority: "14" },
      { id: 15, priority: "15" },
    ],

    colorData: [
      { id: "beige", lineColor: "Beige" },
      { id: "black", lineColor: "Black" },
      { id: "blue", lineColor: "Blue" },
      { id: "darkblue", lineColor: "Blue Dark" },
      { id: "brown", lineColor: "Brown" },
      { id: "gold", lineColor: "Gold" },
      { id: "green", lineColor: "Green" },
      { id: "grey", lineColor: "Grey" },
      { id: "indigo", lineColor: "Indigo" },
      { id: "magenta", lineColor: "Magenta" },
      { id: "orange", lineColor: "Orange" },
      { id: "pink", lineColor: "Pink" },
      { id: "purple", lineColor: "Purple" },
      { id: "red", lineColor: "Red" },
      { id: "silver", lineColor: "Silver" },
      { id: "turquoise", lineColor: "Turquoise" },
      { id: "yellow", lineColor: "Yellow" },
      { id: "white", lineColor: "White" },
    ],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    description: Joi.string().label("Priority"),
    givenName: Joi.string().label("Priority"),
    priorityCheck: Joi.string().label("Priority"),
    colorCheck: Joi.string().label("Priority"),
  };

  async componentDidMount() {
    const ID = this.state.myParams.id;
    const raceID = localStorage.getItem("raceID");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const stageID = localStorage.getItem("stageID");
    const stageName = localStorage.getItem("stageName");

    this.setState({
      raceID,
      raceNameYear,
      stageName,
      stageID,
    });

    // Get data from database to display options in text fields
    if (ID === "new") return;
    let { data: retreivedData } = await httpService.get(
      config.apiEndpointGpxGet + "?id=" + ID
    );

    if (!retreivedData) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(retreivedData) });
  }

  doSubmit = async () => {
    // Get the group Id for a new entry
    const ID = this.state.myParams.id;
    const stageID = this.state.stageID;

    this.on();

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      // add the static info to the formData

      formData.append("file", this.state.selectedFile);
      formData.append("stageID", stageID);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointGpxAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry

      let dataToSend = this.state.data;
      let formData = new FormData();

      // add the static info to the formData
      formData.append("id", ID);
      formData.append("file", this.state.selectedFile);
      formData.append("stageID", stageID);

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointGpxUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.off();
    this.state.myNavigate(`/pageGPXfiles/${stageID}`);
  };
  //Submit a delete
  doDelete = async () => {
    this.on();
    const ID = this.state.myParams.id;
    const stageID = this.state.stageID;

    try {
      const deleteReply = await httpService.get(
        config.apiEndpointGpxDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate(`/pageGPXfiles/${stageID}`);
    this.off();
  };
  mapToViewModel(data) {
    return {
      colorCheck: data.lineColor,
      description: data.description,
      givenName: data.givenName,
      priorityCheck: data.priority,
    };
  }
  handleInputChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };
  on() {
    document.getElementById("overlay").style.display = "block";
  }
  off() {
    document.getElementById("overlay").style.display = "none";
  }
  selectElement(id, valueToSelect) {
    let element = document.getElementById(id);
    element.value = valueToSelect;
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const { color, cssOverride, loading, stageID } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />

        <NavbarEntity />

        <div className="container container-top-70">
          <div id="overlay" className="overlay" onClick={() => this.off()}>
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={`/pageGPXfiles/${stageID}`}
                          className="btn p-0"
                        >
                          <ArrowLeftCircle color="#002072" size={40} />
                        </Link>
                      </th>
                      <th>
                        <h4>{this.state.raceNameYear}</h4>
                      </th>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h6>GPX upload - {this.state.stageName}</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{this.renderInput("givenName", "Name")}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>
                        {this.renderTextArea(
                          "description",
                          "Description",
                          "",
                          "14",
                          "5"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Upload File</td>
                      <td>
                        <input
                          type="file"
                          className="form-control"
                          name="upload_file"
                          onChange={this.handleInputChange}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Priority / Order</td>
                      <td>
                        {this.renderSelect(
                          "priorityCheck",
                          "priority",
                          this.state.priorityData,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Line Color</td>
                      <td>
                        {this.renderSelect(
                          "colorCheck",
                          "lineColor",
                          this.state.colorData,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(gpxUploadForm);
