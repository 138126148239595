import React, { Component } from "react";

import { Link } from "react-router-dom";

// Services
import httpService from "../services/httpService";
import config from "../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Components
import VehicleCardRD from "./vehicleCardRD";
import EntityUserCardRD from "./entityUserCardRD";
import EntityTicketCardRD from "./entityTicketCardRD";

//  Icons

import {
  PersonSquare,
  Bicycle,
  Clock,
  ClockFill,
  GeoAltFill,
  GeoAlt,
  PersonVcardFill,
  QuestionLg,
  Whatsapp,
  TicketDetailed,
} from "react-bootstrap-icons";

import { FaEdit } from "react-icons/fa";
import { TbBus } from "react-icons/tb";
// IoMdExit
import { IoIosExit } from "react-icons/io";
import { BsChatSquareDots, BsListUl } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { GiCarWheel, GiSteeringWheel } from "react-icons/gi";

class entityStatistic extends Component {
  state = {
    ticketIdLocal: this.props.entity.entityID,
  };

  async componentDidMount() {}

  createIDHash() {
    let str1 = "#abc";
    let accordIdHash = str1.concat(this.state.ticketIdLocal);

    return accordIdHash;
  }
  createID() {
    let str1 = "abc";
    let accordId = str1.concat(this.state.ticketIdLocal);

    return accordId;
  }

  createIDHashPass() {
    let str1 = "#abcd";
    let accordIdHash = str1.concat(this.state.ticketIdLocal);

    return accordIdHash;
  }

  createIDPass() {
    let str1 = "abcd";
    let accordId = str1.concat(this.state.ticketIdLocal);
    return accordId;
  }

  createIDHash2() {
    let str1 = "#abc2";
    let accordIdHash = str1.concat(this.state.ticketIdLocal);

    return accordIdHash;
  }
  createIDHashPass2() {
    let str1 = "#abcd2";
    let accordIdHash = str1.concat(this.state.ticketIdLocal);
    return accordIdHash;
  }
  createID2() {
    let str1 = "abc2";
    let accordId = str1.concat(this.state.ticketIdLocal);
    return accordId;
  }
  createIDPass2() {
    let str1 = "abcd2";
    let accordId = str1.concat(this.state.ticketIdLocal);
    return accordId;
  }

  refreshTickets = async () => {};

  render() {
    const { entity } = this.props;
    console.log(entity);

    return (
      <div className="card shadow-sm m-3 passeger-card-border pb-3 card-width-entity-stats">
        <div className="row">
          <div className="card-group-display align-vert">
            <div className="row">
              <div className="details-align mt-1 ">
                <div className="card-vehicle-heading-lg ">
                  <Link
                    // to={`/pagePassengerVehicle/${ticketIdLocal}`}
                    to=""
                    className="btn p-0 card-vehicle-heading-lg "
                  >
                    {entity.entityArray.entityName}
                  </Link>
                </div>
              </div>
            </div>

            {/* Entity Details */}
            <hr className="my-1" />
            <div className="row card-entity-stats-heading-18 card-heading-align-left  ">
              Entity Information
              <div className="col button-align-end">
                <div className="details-align ">
                  <div className="details-icon">
                    <PersonSquare color="#858796" size={20} />
                  </div>
                  <div className="card-vehicle-heading-nc">
                    {entity.entityUserTotal}
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-1">
              <div className="col">
                <div className="details-align ">
                  {entity.entityArray.contactPerson}: {entity.entityArray.cell}
                </div>
              </div>
            </div>

            {/* Details of Entity users */}
            {entity.entityUserArray && (
              <div className="col px-1 m-1">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={this.createIDHashPass2()}
                      aria-expanded="false"
                      aria-controls={this.createIDPass2()}
                    >
                      <div className="accordion-heading-text-stats">
                        <BsListUl />
                        <span className="icon-text-padding">
                          Member Details
                        </span>
                      </div>
                    </button>
                  </h2>
                  <div
                    id={this.createIDPass2()}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ">
                      <div className="card details-card-ticket">
                        <div className="row">
                          <div className="card-vehicle-heading center">
                            Entity Members
                          </div>
                          <div className="center">
                            <hr className="m-1 hr-width-300" />
                          </div>
                          <div className="details-align">
                            <div className="details-icon"></div>
                            <div className="row m-0 center  card-details-height">
                              {entity.entityUserArray &&
                                entity.entityUserArray.map((ticket) => (
                                  <EntityUserCardRD
                                    key={ticket.id}
                                    ticket={ticket}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {entity.TicketTypeStatsArray && (
              <div>
                <hr className="my-1" />
                <div className="row card-entity-stats-heading-18 card-heading-align-left  ">
                  Tickets
                </div>
                <div className="row">
                  <div className="col center">
                    <div className="details-align ">
                      <button
                        type="button"
                        className="btn btn-light position-relative m-3"
                      >
                        <span className="card-vehicle-heading-nc">All</span>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary opacity-6">
                          {entity.TicketStatsArray.AllTicketsTotal}
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-light position-relative m-3"
                      >
                        <span className="card-vehicle-heading-nc">pending</span>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary opacity-6">
                          {entity.TicketStatsArray.PendingTicketsTotal}
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-light position-relative m-3"
                      >
                        <span className="card-vehicle-heading-nc">
                          Priority
                        </span>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger opacity-6 ">
                          {entity.TicketStatsArray.PriorityTicketsTotal}
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-light position-relative m-3"
                      >
                        <span className="card-vehicle-heading-nc">
                          Resolved
                        </span>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary opacity-6">
                          {entity.TicketStatsArray.ResolvedTicketsTotal}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* Details of tickets */}
                <div className="col px-1 m-1">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={this.createIDHash()}
                        aria-expanded="false"
                        aria-controls={this.createID()}
                      >
                        <div className="accordion-heading-text-stats">
                          <BsListUl />
                          <span className="icon-text-padding">
                            Ticket Details
                          </span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={this.createID()}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="card details-card-ticket">
                          <div className="row">
                            <div className="card-vehicle-heading center">
                              Ticket Summary
                            </div>
                            <div className="row">
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                            <div className="center">
                              <hr className="m-2 hr-width-300" />
                            </div>
                            <div className=" center">
                              <div className="details-align">
                                <div className="details-icon"></div>
                                <div className="row m-0 center  card-details-height">
                                  {entity.TicketTypeStatsArray &&
                                    entity.TicketTypeStatsArray.map(
                                      (ticket) => (
                                        <EntityTicketCardRD
                                          key={
                                            ticket.TicketTypeID +
                                            entity.entityID
                                          }
                                          ticket={ticket}
                                        />
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {entity.vehicleArray && (
              <div>
                <hr className="my-1" />
                <div className="row card-entity-stats-heading-18  card-heading-align-left">
                  Passengers
                </div>
                <div className="row m-1">
                  <div className="col-2">
                    <div className="details-align ">
                      <div className="details-icon">
                        <TbBus color="#858796" size={20} />
                      </div>
                      <div className="card-vehicle-heading-nc">
                        {entity.entityVehicleTotal}
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="center">
                      <div className="details-align ">
                        <div className="details-icon">
                          <FiUser color="#858796" size="20" />
                        </div>
                        <div className="card-vehicle-heading-nc">
                          ({entity.PassengerCollectionTotal}/
                          {entity.totalCapacityPass})-
                          {entity.PassengerTotal}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="center">
                      <div className="details-align ">
                        <div className="details-icon">
                          <Bicycle color="#858796" size="22" />
                        </div>
                        <div className="card-vehicle-heading-nc">
                          ({entity.bicycleCollectionTotal}/
                          {entity.totalCapacitybicyle})-
                          {entity.bicycleTotal}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Details of Passengers */}
                <div className="col px-1 m-1">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={this.createIDHashPass()}
                        aria-expanded="false"
                        aria-controls={this.createIDPass()}
                      >
                        <div className="accordion-heading-text-stats">
                          <BsListUl />
                          <span className="icon-text-padding">
                            Vehicle Details
                          </span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={this.createIDPass()}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body ">
                        <div className="card details-card-ticket">
                          <div className="row">
                            <div className="card-vehicle-heading center">
                              Vehicles
                            </div>
                            <div className="center">
                              <hr className="m-1 hr-width-300" />
                            </div>
                            <div className="details-align">
                              <div className="details-icon"></div>
                              <div className="row m-0 center  card-details-height">
                                {/* this makes the dashboard crash for some reason */}
                                {/* {entity.vehicleArray &&
                                  entity.vehicleArray.map((ticket) => (
                                    <VehicleCardRD
                                      key={ticket.vehicleID + entity.entityID}
                                      ticket={ticket}
                                    />
                                  ))} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default entityStatistic;
