import React from "react";

import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

class RaceUserRaceAllocationTable extends React.Component {
  state = {
    pageSize: 8,
    currentPage: 1,
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageIncrement = (setCurrentPage, pagesCount) => {
    if (setCurrentPage < pagesCount) {
      setCurrentPage++;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  handlePageDecrement = (setCurrentPage) => {
    if (setCurrentPage != 1) {
      setCurrentPage--;
      this.setState({ currentPage: setCurrentPage });
    }
  };

  columns = [
    {
      path: "teamUser.name",
      label: "Name",
      content: (raceUser) => (
        <Link to={`/raceUserAllocationForm/${raceUser.id}`}>
          <h6>{raceUser.teamUser.name}</h6>
        </Link>
      ),
    },
    { path: "teamUser.surname", label: "Surname" },
    // { path: "race.description", label: "Race" },
    // { path: "race.year", label: "Year" },
    { path: "teamUserRole.description", label: "Type" },
  ];

  // $teamUser        = array("id" => $row[1], "name" => $row[4], "surname " => $row[5]);
  // $race            = array("id" => $row[2], "description" => $row[6], "year" => $row[7]);
  // $teamUserRole    = array("id" => $row[3], "description" => $row[8]);

  render() {
    const {
      filteredRaceUsers,
      onSort,
      sortColumn,
      onDelete,
      allRaceUsersTotal,
    } = this.props;
    const { pageSize, currentPage } = this.state;
    const raceUsersPag = paginate(filteredRaceUsers, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={raceUsersPag}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allRaceUsersTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPageDecrement={this.handlePageDecrement}
            onPageIncrement={this.handlePageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default RaceUserRaceAllocationTable;
