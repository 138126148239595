import React from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import { PoiMarker } from "./poiMarker";

export const PoiLayer = ({ POIMarkerData }) => {
  if (!POIMarkerData) {
    return null;
  } else {
    return (
      <LayersControl.Overlay checked name="Global POI's">
        <LayerGroup>
          {POIMarkerData.map((poiMarker) => (
            <PoiMarker key={poiMarker.id} poiMarker={poiMarker} />
          ))}
        </LayerGroup>
      </LayersControl.Overlay>
    );
  }
};
