import React, { Component } from "react";
import { Link } from "react-router-dom";

class notification extends Component {
  render() {
    const { notificationSingleData } = this.props;

    const userTypeID = localStorage.getItem("userTypeID");
    const entityID = localStorage.getItem("entityID");

    let source = "";
    let goToPage = "/";

    source = notificationSingleData.entityName + " - ";

    // Race Director or Team Member
    if (userTypeID == 3) {
      goToPage = `notificationForm/${notificationSingleData.id}`;
    }

    // Entity head
    if (userTypeID === "4") {
      if (entityID == notificationSingleData.allocatedEntityID) {
        goToPage = `notificationEntityForm/${notificationSingleData.id}`;
      }
    }

    // Legend

    // "id": "2",
    // "notificTypeName": "Race Start",
    // "notificStatusName": "Informational",
    // "comment": "Everyone should be in place. Here we go",
    // "notDate": "2022-12-27",
    // "notTime": "16:1:37",
    // "allocatedEntityID": "11",
    // "userTypeID": "3",
    // "notificDisplayText": "I",
    // "notifiDisplayColor": "#002072"

    //   <Link to={`/${goToPage}`} className="btn p-0 ">
    //   <ArrowLeftCircle color="#002072" size={35} />
    //   </Link>

    return (
      <div>
        <div className="card shadow-sm m-2 note-card-border">
          <div className="row p-1 ">
            <div className="col note-avatar-box py-2">
              <div>
                <Link
                  to={`/${goToPage}`}
                  className="note-avatar center text-capitalize font-weight-bold text-darkblue"
                  style={{
                    borderColor: notificationSingleData.notifiDisplayColor,
                  }}
                >
                  {notificationSingleData.notificDisplayText}
                </Link>
              </div>
            </div>
            <div className="col py-2 px-0 center-left">
              <div className="note-heading font-weight-bold">
                {notificationSingleData.notificTypeName}
              </div>
            </div>
          </div>
          <div className="note-text-align pb-2 m-0">
            <div className="note-text">{notificationSingleData.comment}</div>

            <div className="note-subtext mb-2">
              <div className=" pt-2"></div>
              <div className="note-time">
                {source}
                {notificationSingleData.notTime}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default notification;
