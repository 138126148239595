
export const groups = [
    {
        id:"1",
        groupName: "FACES Venture Capital",
        addressLineOne: "116B",
        addressLineTwo: "Glen Ave",
        addressLineThree: "n/a",
        addressSuburb: "Equestria",
        addressTownCity: "Pretoria",
        addressProvince:"Gauteng",
        addressCountry: "South Africa",
        addressCode: "0184",
        tel: "+27 12 012 5205",
        contactPerson: "TBD",
        email: "info@advendurance.com",
        website:"https://faces.africa/",
        companyReg:"TBD",
        companyVat: "TBD",
      },
      {
        id:"2",
        groupName: "Cape Town Cycle Tour Trust",
        addressLineOne: "No 65",
        addressLineTwo: "Avenue De Mist",
        addressLineThree: "n/a",
        addressSuburb: "Newlands",
        addressTownCity: "Cape Town",
        addressProvince:"Western Cape",
        addressCountry: "South Africa",
        addressCode: "8000",
        tel: "+27 87 820 7223",
        contactPerson: "David Bellairs",
        email: "Na",
        website:"Na",
        companyReg:"789",
        companyVat: "36w",
      },
      {
        id:"3",
        groupName: "De Villiers National Cycling",
        addressLineOne: "No 1041",
        addressLineTwo: "Wagtail Place",
        addressLineThree: "Featherbrooke Estate",
        addressSuburb: "n/a",
        addressTownCity: "Pretoria",
        addressProvince:"Gauteng",
        addressCountry: "South Africa",
        addressCode: "0002",
        tel: "+27 83 269 7659",
        contactPerson: "Wynand De Villiers",
        email: "wynanddevilliers087@gmail.com",
        website:"Na",
        companyReg:"64845",
        companyVat: "q76",
      },
      {
        id:"4",
        groupName: "Challenge South Africa",
        addressLineOne: "No 2",
        addressLineTwo: "Irvine Road",
        addressLineThree: "Bonnie Doon",
        addressSuburb: "n/a",
        addressTownCity: "East London",
        addressProvince:"Eastern Cape",
        addressCountry: "South Africa",
        addressCode: "5241",
        tel: "+27 83 559 2080",
        contactPerson: "Damian Bradly",
        email: "damian@bactive.com",
        website:"https://challenge-cape-town.com/",
        companyReg:"087",
        companyVat: "246456",
      },
      {
        id:"5",
        groupName: "Two Oceans Marathon",
        addressLineOne: "125",
        addressLineTwo: "Main Road",
        addressLineThree: "n/a",
        addressSuburb: "Bergvliet",
        addressTownCity: "Cape Town",
        addressProvince:"Western Cape",
        addressCountry: "South Africa",
        addressCode: "7945",
        tel: "+27 87 133 2285",
        contactPerson: "",
        email: "racedirector@twooceansmarathon.org.za",
        website:"https://www.twooceansmarathon.org.za/",
        companyReg:"tbd",
        companyVat: "4650256441",
      },
      {
        id:"6",
        groupName: "Pedal Power Association",
        addressLineOne: "No 9",
        addressLineTwo: "Hill Park Lane",
        addressLineThree: "n/a",
        addressSuburb: "Mowbray",
        addressTownCity: "Cape Town",
        addressProvince:"Western Cape",
        addressCountry: "South Africa",
        addressCode: "7700",
        tel: "+27 83 414 1442",
        contactPerson: "",
        email: "",
        website:"https://pedalpower.org.za/",
        companyReg:"tbd",
        companyVat: "tbd",
      },
      {
        id:"7",
        groupName: "Celtic Harriers",
        addressLineOne: "No 11",
        addressLineTwo: "Imam Haron Road",
        addressLineThree: "n/a",
        addressSuburb: "Claremont",
        addressTownCity: "Cape Town",
        addressProvince:"Western Cape",
        addressCountry: "South Africa",
        addressCode: "7708",
        tel: "+27 ",
        contactPerson: "",
        email: "info@celticharriers.co.za",
        website:"https://celticharriers.co.za/",
        companyReg:"tbd",
        companyVat: "tbd",
      },
      {
        id:"8",
        groupName: "Top Form Athlectic Club",
        addressLineOne: "n/a",
        addressLineTwo: "n/a",
        addressLineThree: "n/a",
        addressSuburb: "Athlone",
        addressTownCity: "Cape Town",
        addressProvince:"Western Cape",
        addressCountry: "South Africa",
        addressCode: "7708",
        tel: "+27 84 545 5851",
        contactPerson: "Valma Hendricks",
        email: "topformathleticclub@gmail.com",
        website:"http://topformathletics.over-blog.com/",
        companyReg:"tbd",
        companyVat: "tbd",
      },

      
    
];
export function getGroups() {
    return groups;
}

export function getGroup(passedId) {
  return  groups.find(m => m.id === passedId);
}

export function saveGroup(group) {
  let groupInDb = groups.find(m => m.id === group.groupId) || {};

  groupInDb.addressLineOne = group.addressOne;
  groupInDb.addressLineTwo = group.addressTwo;
  groupInDb.addressLineThree = group.addressThree;
  groupInDb.addressSuburb = group.addressSuburb;
  groupInDb.addressTownCity = group.addressTownCity;
  groupInDb.addressProvince = group.addressProvince;
  groupInDb.addressCountry = group.addressCountry;
  groupInDb.addressCode = group.addressCode;
  groupInDb.companyReg = group.companyReg;
  groupInDb.companyVat = group.companyVat;
  groupInDb.contactPerson = group.contactPerson;
  groupInDb.groupName = group.groupName;
  groupInDb.email = group.email;
  groupInDb.tel = group.tel;
  groupInDb.website = group.website;
  

  if (!groupInDb.id) {
    // console.log("Before Group.push and groupInDb.id = "+groupInDb.id)
    groupInDb.id = Date.now().toString();
    groups.push(groupInDb);
  }

  return groupInDb;
}

export function deleteGroup(group) {
  let groupInDb = groups.find(m => m.id === group.groupId);
  groups.splice(groups.indexOf(groupInDb), 1);
  return groupInDb;
}
