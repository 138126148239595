import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarGroup from "../components/navbarGroup";
import EntityListTable from "../components/entityListTable";
import SearchBox from "../components/common/searchBox";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class PageEntity extends React.Component {
  state = {
    backButtonPage: "",
    dataRetreived: [],
    groupName: "",
    groupID: "",

    raceUserTypesData: [],
    searchQuery: "",
    sortColumn: { path: "entityName", order: "asc" },
  };

  async componentDidMount() {
    const groupName = localStorage.getItem("groupName");
    const groupID = localStorage.getItem("groupID");
    const userTypeID = localStorage.getItem("userTypeID");

    // Set the back button address for each user type
    let backButtonPage = "";
    if (userTypeID === "2") backButtonPage = "pageGroupHome";
    if (userTypeID === "3") backButtonPage = "pageTeamMemberHome";

    this.setState({
      groupName,
      groupID,
      backButtonPage,
    });

    localStorage.setItem("entityID", "");
    localStorage.setItem("entityName", "");

    // Get data by Group ID
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointEntitesGet + "?groupID=" + groupID
    );
    this.setState({ dataRetreived: Dbdata });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    let { dataRetreived, searchQuery, sortColumn, groupName, backButtonPage } =
      this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allEntriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>{groupName} - Entity</h5>
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("Back To Group Home")}
                >
                  <Link to={`/${backButtonPage}`} className="btn p-0">
                    <ArrowLeftCircle color="#002072" size={40} />
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("Add A Group Entity")}
                >
                  <Link to="/entityForm/new" className="btn p-0">
                    <PlusCircle color="#002072" size={40} />
                  </Link>
                </OverlayTrigger>
              </span>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Entities..."
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col table-top-padding"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <EntityListTable
                filteredRaceUsers={filtered}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                allEntriesTotal={allEntriesTotal}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageEntity;
