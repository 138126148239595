import React from "react";
import { Link } from "react-router-dom";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Other
import {
  Calendar2Event,
  Diagram2Fill,
  DoorOpenFill,
} from "react-bootstrap-icons";

class TeamMemberHero extends React.Component {
  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    const { groupName } = this.props;
    return (
      <div>
        <div className="container p-2 border-radius-4 shadow-lg row-fit container-fluid">
          <h4 className="display-6 fw-bold mt-2 mb-4 px-3 button-200">
            {groupName}
          </h4>

          <div className=" card px-3 mb-4  center-horizontal-components container-fluid row-fit">
            <div className="row container-fluid dashboard-bottom-margin mt-2">
              <div className="col bt-2 ">
                <div className="button-center">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("A list of races")}
                  >
                    <Link
                      to="/pageGroupRaces"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <Calendar2Event color="white" size={30} />
                    </Link>
                  </OverlayTrigger>
                </div>
                <div className="text-darkblue">Races</div>
              </div>

              <div className="col">
                <div className="button-center">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("A list of Entities")}
                  >
                    <Link
                      to="/pageEntity"
                      className="btn btn-primary btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <Diagram2Fill color="white" size={30} />
                    </Link>
                  </OverlayTrigger>
                </div>
                <div className="text-darkblue">Entity</div>
              </div>

              <div className="col">
                <div className="button-center">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("Exit")}
                  >
                    <Link
                      to="/"
                      className="btn btn-danger btn-sm button-color-dashboard"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 15,
                        height: 55,
                        marginBottom: 5,
                        marginTop: 5,
                        width: 60,
                      }}
                    >
                      <DoorOpenFill color="white" size={30} />
                    </Link>
                  </OverlayTrigger>
                </div>
                <div className="text-darkblue">Logout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamMemberHero;
