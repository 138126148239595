import React from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import { BusTicketMarker } from "./busTicketMarker";

export const BusTicketLayer = ({ busTicketdata }) => {
  return (
    <LayersControl.Overlay checked name="Passengers Picked Up">
      <LayerGroup>
        {busTicketdata.map((busTicket) => (
          <BusTicketMarker key={busTicket.id} busTicket={busTicket} />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};
