import React from "react";
import { Link } from "react-router-dom";

//Components
import StageInfoUserSplash from "./../components/stageInfoUserSplash";

class RaceEntityUserHero extends React.Component {
  render() {
    const {
      displayGeoData,
      entityUserRoleData,
      myLatitude,
      myLongitude,
      raceNameYear,
      raceDescription,
      stagedataRetreived,
      vehicleID,
      vehicleName,
      vehicleRegistration,
    } = this.props;

    return (
      <div className="center">
        <div className="row p-2 pb-0 pe-lg-0 pt-lg-3 align-items-center border-radius-4 shadow-lg row-fit center">
          <div className="col-lg-12 p-0 p-lg-4 pt-lg-0 ">
            <h4 className="center">{raceNameYear}</h4>

            <h5 className="center"> {entityUserRoleData.name}</h5>

            <div className="row row-fit center">
              {stagedataRetreived.map((stage) => (
                <StageInfoUserSplash
                  distance={stage.distance}
                  elevation={stage.elevation}
                  key={stage.id}
                  myLatitude={myLatitude}
                  myLongitude={myLongitude}
                  stageDescription={stage.stageDescription}
                  stageName={stage.stageName}
                  stageType={stage.stageType.description}
                  stageID={stage.id}
                  vehicleID={vehicleID}
                  vehicleName={vehicleName}
                  vehicleRegistration={vehicleRegistration}
                />
              ))}
              <div></div>
            </div>
          </div>
        </div>

        {/* Geo data: {myLatitude},{myLongitude} */}
      </div>
    );
  }
}

export default RaceEntityUserHero;
