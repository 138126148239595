import PropTypes from "prop-types";
import React, { useState } from "react";

import { MapEntity } from "./mapEntity";
import EntityOffCanvas from "./entityOffCanvas";

import "leaflet.fullscreen/Control.FullScreen.js";
import "leaflet.fullscreen/Control.FullScreen.css";
/**
 * Renders a map with markers and an off-canvas panel.
 * @param {Object} props - The props object.
 * @param {Array} props.busTicketdata - Data for bus tickets.
 * @param {number} props.defaultZoom - Default zoom level for the map.
 * @param {Object} props.centrePosition - Center position of the map.
 * @param {string} props.entityName - Name of the entity.
 * @param {Array} props.entityUserData - User data for the entity.
 * @param {Array} props.mapURLs - URLs for the map.
 * @param {Array} props.POIMarkerData - Data for POI markers.
 * @param {Array} props.ticketMarkerData - Data for ticket markers.
 * @param {Function} props.refreshTickets - Function to refresh tickets.
 * @param {Function} props.refreshEntityUsers - Function to refresh entity users.
 * @param {Function} props.refreshVehicles - Function to refresh vehicles.
 * @param {boolean} props.autoRefreshEntityUsers - Flag to enable/disable auto-refresh of entity users.
 * @param {boolean} props.autoRefreshTickets - Flag to enable/disable auto-refresh of tickets.
 * @param {boolean} props.autoRefreshVehicles - Flag to enable/disable auto-refresh of vehicles.
 * @param {Array} props.vehicleData - Data for vehicles.
 * @returns {JSX.Element} - The rendered map component.
 */

export const Map = ({
  busTicketdata,
  defaultZoom,
  centrePosition,
  entityName,
  entityUserData,
  mapURLs,
  POIMarkerData,
  ticketMarkerData,
  refreshTickets,
  refreshEntityUsers,
  refreshVehicles,
  autoRefreshEntityUsers,
  autoRefreshTickets,
  autoRefreshVehicles,
  vehicleData,
}) => {
  const [mapInstance, setMapInstance] = useState(null);

  if (centrePosition != "") {
    return (
      <>
        <div className="leaflet-container">
          <MapEntity
            busTicketdata={busTicketdata}
            defaultZoom={defaultZoom}
            centrePosition={centrePosition}
            entityName={entityName}
            entityUserData={entityUserData}
            mapURLs={mapURLs}
            POIMarkerData={POIMarkerData}
            setMapInstance={setMapInstance}
            ticketMarkerData={ticketMarkerData}
            vehicleData={vehicleData}
          />
        </div>

        <EntityOffCanvas
          refreshTickets={refreshTickets}
          refreshEntityUsers={refreshEntityUsers}
          refreshVehicles={refreshVehicles}
          autoRefreshEntityUsers={autoRefreshEntityUsers}
          autoRefreshTickets={autoRefreshTickets}
          autoRefreshVehicles={autoRefreshVehicles}
          entityUserData={entityUserData}
          ticketMarkerData={ticketMarkerData}
          mapInstance={mapInstance}
          vehicleData={vehicleData}
        />
      </>
    );
  } else {
    return (
      <div className="container">
        <div className="navbar-toggler center">loading....</div>
      </div>
    );
  }
};
Map.propTypes = {
  busTicketdata: PropTypes.array.isRequired,
  defaultZoom: PropTypes.number.isRequired,
  centrePosition: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  entityUserData: PropTypes.array.isRequired,
  mapURLs: PropTypes.array.isRequired,
  POIMarkerData: PropTypes.array.isRequired,
  ticketMarkerData: PropTypes.array.isRequired,
  refreshTickets: PropTypes.func.isRequired,
  refreshEntityUsers: PropTypes.func.isRequired,
  refreshVehicles: PropTypes.func.isRequired,
  autoRefreshEntityUsers: PropTypes.bool.isRequired,
  autoRefreshTickets: PropTypes.bool.isRequired,
  autoRefreshVehicles: PropTypes.bool.isRequired,
  vehicleData: PropTypes.array.isRequired,
};
