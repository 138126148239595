import React, { Component } from "react";
import PassengerCard from "./passengerCard";

import Pagination from "./common/pagination";
import { paginate } from "../utils/paginate";

class PassengerCards extends Component {
  render() {
    const {
      allTicketsTotal,
      currentPage,
      onPageChange,
      ticketData,
      onPageDecrement,
      onPageIncrement,
      pageSize,
      refreshTickets,
    } = this.props;

    const ticketsPag = paginate(ticketData, currentPage, pageSize);

    if (ticketsPag.length === 0) {
      return (
        <div className="card shadow-sm m-1 passeger-card-border ">
          <div className="row">
            <div className="card-group-display ">
              <div className="row p-3">No tickets for this vehicle</div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <Pagination
          itemsCount={allTicketsTotal}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onPageDecrement={onPageDecrement}
          onPageIncrement={onPageIncrement}
        />
        <div className="row m-0 center">
          {ticketsPag.map((ticket) => (
            <PassengerCard
              key={ticket.id}
              ticket={ticket}
              refreshTickets={refreshTickets}
            />
          ))}
        </div>
        <Pagination
          itemsCount={allTicketsTotal}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onPageDecrement={onPageDecrement}
          onPageIncrement={onPageIncrement}
        />
      </div>
    );
  }
}

export default PassengerCards;
