import React from "react";
import { Link } from "react-router-dom";

import "leaflet/dist/leaflet.css";

// Services
import httpService from "../services/httpService.js";
import config from "../config.json";

//Components
import { Map } from "../components/map";

import withMyHook from "../components/common/withMyHook";
//Other
import { ToastContainer, Zoom, toast } from "react-toastify";
import { ArrowLeftCircle, GearFill } from "react-bootstrap-icons";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

class PageRDMap extends React.Component {
  state = {
    defaultZoom: "13",
    entityID: "",
    entityName: "",
    goToPage: "",
    intervalTickets: "",
    map: "",
    raceID: "",
    stageID: "",
    userTypeID: "",

    // loader variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    // auto refresh timers
    interval: "",
    intervalUsers: "",
    intervalTicket: "",
    intervalVehicle: "",

    // arrays
    busTicketdata: [],
    centrePosition: [],
    data: [],
    entityUserData: [],
    mapURLs: [],
    ticketMarkerData: [],
    POIMarkerData: [],
    vehicleData: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    this.on();

    // Get the application storage
    const raceID = localStorage.getItem("raceID");
    const entityID = localStorage.getItem("entityID");
    const entityName = localStorage.getItem("entityName");
    const raceNameYear = localStorage.getItem("raceNameYear");
    const userTypeID = localStorage.getItem("userTypeID");
    const stageID = this.state.myParams.id;

    // Get the Stage information
    let { data: stage } = await httpService.get(
      config.apiEndpointStageGetbyID + "?id=" + stageID
    );

    // set the stage details
    localStorage.setItem("stageID", stageID);
    localStorage.setItem("stageName", stage.stageName);
    const stageName = stage.stageName;

    // Get the Ticket types and pass it to the mapEntity
    let { data: allTicketDataDB } = await httpService.get(
      `${config.apiEndpointRDStageTicketsByTypeGet}?raceID=${raceID}&stageID=${stageID}`
    );

    // Get the stage Map options
    let { data: DbMapsConfigdata } = await httpService.get(
      `${config.apiEndpointStageOptions}?id=${stageID}`
    );

    try {
      let { data: Dbdata } = await httpService.get(
        `${config.apiEndpointStageUserRoleAllocationsGEORD}?stageID=${stageID}`
      );
      this.setState({ entityUserData: Dbdata });
    } catch (error) {
      toast.error("Failed to load user data. Please try again later.");
    }

    // Get the list of vehicles that have been allocated to the stage
    try {
      let { data: vehicleData } = await httpService.get(
        `${config.apiEndpointStageVehicleAllocationsGEORD}?stageID=${stageID}`
      );
      this.setState({ vehicleData });
    } catch (error) {
      toast.error("Failed to load vehicle data. Please try again later.");
    }

    // Get the list of poi markers for this stage
    let { data: POIdata } = await httpService.get(
      `${config.apiEndpointPoigetGet}?stageID=${stageID}`
    );

    // Get the list of bus tickets for the stage
    let { data: busTicketdata } = await httpService.get(
      `${config.apiEndpointPassGet}?stageID=${stageID}`
    );

    // Get the gpx file names for the polylines
    let { data: mapURLs } = await httpService.get(
      `${config.apiEndpointGpxsGet}?stageID=${stageID}`
    );

    let goToPage = "pageEntityHome";

    if (userTypeID === "2") goToPage = `pageRaceHome/${raceID}`;
    if (userTypeID === "3") goToPage = `pageRaceHome/${raceID}`;
    if (userTypeID === "4") goToPage = `pageRaceEntityHome/${raceID}`;
    if (userTypeID === "5") goToPage = "pageEntityUserHome";
    if (userTypeID === "6") goToPage = `pageRaceEntityHome/${raceID}`;

    const CPlat = parseFloat(DbMapsConfigdata.cpLat);
    const CPlong = parseFloat(DbMapsConfigdata.cpLong);
    let centrePosition = [DbMapsConfigdata.cpLat, DbMapsConfigdata.cpLong];

    if (!DbMapsConfigdata.zoom) {
      DbMapsConfigdata.zoom = "13";
    }

    if (!centrePosition) {
      centrePosition = [-5.794131, 31.120574];
    }

    this.setState({
      busTicketdata,
      defaultZoom: DbMapsConfigdata.zoom,
      centrePosition,
      entityID,
      entityName,
      goToPage,
      mapURLs,
      POIMarkerData: POIdata,
      raceID,
      raceNameYear,
      stageID,
      stageName,
      ticketMarkerData: allTicketDataDB,
      userTypeID,
    });

    this.off();

    const cbTickets = document.querySelector("#mainTicketBox");
    cbTickets.checked = true;

    const cbEntityUser = document.querySelector("#mainEntityUserBox");
    cbEntityUser.checked = true;

    this.handleAutoRefreshEntityUsers();
    this.handleAutoRefreshTickets();
    this.handleRefreshVehicles();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    clearInterval(this.state.intervalUsers);
    clearInterval(this.state.intervalTicket);
    clearInterval(this.state.intervalVehicle);
  }

  handleRefreshTickets = async () => {
    // Get the Ticket types and pass it to the mapEntity
    let { data: allTicketDataDB } = await httpService.get(
      config.apiEndpointRDStageTicketsByTypeGet +
        "?raceID=" +
        this.state.raceID +
        "&" +
        "stageID=" +
        this.state.stageID
    );

    // Get the list of bus tickets for the stage
    let { data: busTicketdata } = await httpService.get(
      config.apiEndpointPassGet + "?stageID=" + this.state.stageID
    );

    this.setState({ ticketMarkerData: allTicketDataDB, busTicketdata });
  };

  handleRefreshEntityUsers = async () => {
    // Get the Ticket types and pass it to the mapEntity
    let { data: Dbdata } = await httpService.get(
      config.apiEndpointStageUserRoleAllocationsGEORD +
        "?stageID=" +
        this.state.stageID
    );

    this.setState({ entityUserData: Dbdata });
  };

  handleRefreshVehicles = async () => {
    // Get the list of vehicles that have been allocated to the stage
    const stageID = localStorage.getItem("stageID");

    try {
      let { data: vehicleData } = await httpService.get(
        config.apiEndpointStageVehicleAllocationsGEORD + "?stageID=" + stageID
      );
      this.setState({ vehicleData });
    } catch (error) {
      console.log("error in PageRD map line 224");
    }
  };

  handleAutoRefreshVehicles = async () => {
    // This code will be used to refresh the tickets every 1 minute
    const cbVehicles = document.querySelector("#mainVehicleBox");

    if (cbVehicles.checked == true) {
      // set the 1 minute to a variable and let the user set it
      this.intervalVehicle = setInterval(
        () => this.handleRefreshVehicles(),
        5000
      );
    } else {
      clearInterval(this.intervalVehicle);
    }
  };

  handleAutoRefreshTickets = async () => {
    // This code will be used to refresh the tickets every 1 minute
    const cbTickets = document.querySelector("#mainTicketBox");

    if (cbTickets.checked == true) {
      // set the 1 minute to a variable and let the user set it
      this.intervalTicket = setInterval(
        () => this.handleRefreshTickets(),
        5000
      );
    } else {
      clearInterval(this.intervalTicket);
    }
  };

  handleAutoRefreshEntityUsers = async () => {
    // This code will be used to refresh the tickets every 1 minute
    const cbEntityUser = document.querySelector("#mainEntityUserBox");

    if (cbEntityUser.checked == true) {
      // set the 1 minute to a variable and let the user set it
      this.intervalUsers = setInterval(
        () => this.handleRefreshEntityUsers(),
        5000
      );
    } else {
      clearInterval(this.intervalUsers);
    }
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let {
      busTicketdata,
      color,
      cssOverride,
      defaultZoom,
      centrePosition,
      entityName,
      entityUserData,
      goToPage,
      loading,
      mapURLs,
      POIMarkerData,
      raceNameYear,
      stageName,
      ticketMarkerData,
      vehicleData,
    } = this.state;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div id="overlay" className="overlay" onClick={() => this.off()}>
          <div id="text" className="text">
            <PuffLoader
              color={color}
              loading={loading}
              cssOverride={cssOverride}
              size={50}
            />
            Loading Data...
          </div>
        </div>

        <Link
          to={`/${goToPage}`}
          className="btn p-0"
          style={{
            marginBottom: 10,
            marginTop: 10,
            marginLeft: 10,
          }}
        >
          <ArrowLeftCircle color="#002072" size={35} />
        </Link>
        <button
          className="btn "
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling"
          aria-controls="offcanvasScrolling"
        >
          <GearFill color="#002072" size={35} />
        </button>

        <span className="entity-map-logo">
          {raceNameYear} - {stageName}
        </span>

        <Map
          busTicketdata={busTicketdata}
          defaultZoom={defaultZoom}
          centrePosition={centrePosition}
          entityUserData={entityUserData}
          mapURLs={mapURLs}
          POIMarkerData={POIMarkerData}
          ticketMarkerData={ticketMarkerData}
          entityName={entityName}
          refreshTickets={this.handleRefreshTickets}
          refreshEntityUsers={this.handleRefreshEntityUsers}
          refreshVehicles={this.handleRefreshVehicles}
          autoRefreshEntityUsers={this.handleAutoRefreshEntityUsers}
          autoRefreshTickets={this.handleAutoRefreshTickets}
          autoRefreshVehicles={this.handleAutoRefreshVehicles}
          vehicleData={vehicleData}
          vehicleEntityName={vehicleData}
        />
      </React.Fragment>
    );
  }
}

export default withMyHook(PageRDMap);
