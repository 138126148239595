import React, { Component } from "react";

import { Link } from "react-router-dom";

// Services
import httpService from "../services/httpService";
import config from "../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//  Icons

import {
  PersonSquare,
  Bicycle,
  Clock,
  ClockFill,
  GeoAltFill,
  GeoAlt,
  PersonVcardFill,
  QuestionLg,
  Whatsapp,
} from "react-bootstrap-icons";

import { FaEdit } from "react-icons/fa";
import { TbBus } from "react-icons/tb";
// IoMdExit
import { IoIosExit } from "react-icons/io";
import { BsChatSquareDots, BsListUl } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { GiCarWheel, GiSteeringWheel } from "react-icons/gi";

class VehicleCardRD extends Component {
  state = {
    ticketIdLocal: this.props.ticket.vehicleID,

    gotToForm: "",
    // gps info
    myLatitude: "",
    myLongitude: "",
    myAltitude: "",
    myHeading: "",
    mySpeed: "",
    mySpeedKM: "",

    warningColor: "",
  };

  async componentDidMount() {
    const userTypeID = localStorage.getItem("userTypeID");
    const stageID = localStorage.getItem("stageID");

    let str1 = "abcClean";
    let accordId = str1.concat(this.state.ticketIdLocal);

    let gotToForm = "";
    if (userTypeID === "3") gotToForm = ``;
    if (userTypeID === "4") gotToForm = `PageEntityUserPassengers/${stageID}`;
    if (userTypeID === "5") gotToForm = `PageEntityUserPassengers/${stageID}`;

    this.setState({ gotToForm });

    // set the colors based on the capacity and load for each vehicle
  }

  render() {
    const { refreshTickets, ticket } = this.props;
    const { ticketIdLocal } = this.state;

    // set the colors for each depending on the percentage

    const totalLoadPerc = Math.round(
      (ticket.passengerCollTotal / ticket.passengerCapacity) * 100
    );
    const bicycleCapacityPerc = Math.round(
      (ticket.bicycleCollTotal / ticket.bicycleCapacity) * 100
    );

    let wheelColor = "#858796";
    let passColor = "m-1";
    let bicycleColor = "m-1";

    if (bicycleCapacityPerc > 0) {
      bicycleColor = "card-vehicle-text-green m-1";
    }
    if (bicycleCapacityPerc >= 50) {
      bicycleColor = "card-vehicle-text-yellow m-1";
    }
    if (bicycleCapacityPerc >= 80) {
      bicycleColor = "card-vehicle-text-red m-1";
    }

    if (totalLoadPerc > 0) {
      passColor = "card-vehicle-text-green m-1";
    }
    if (totalLoadPerc >= 50) {
      passColor = "card-vehicle-text-yellow m-1";
    }
    if (totalLoadPerc >= 80) {
      passColor = "card-vehicle-text-red m-1";
    }

    if (bicycleCapacityPerc > 0 || totalLoadPerc > 0) {
      wheelColor = "#128c7e";
    }
    if (bicycleCapacityPerc >= 50 || totalLoadPerc >= 50) {
      wheelColor = "#f6c23e";
    }
    if (bicycleCapacityPerc >= 80 || totalLoadPerc >= 80) {
      wheelColor = "#e74a3b";
    }

    // normal grey    = #858796 no activity
    // green          = #128c7e some activity
    // warning yellow = #f6d43e 50% capacity
    // red danger     = #e74a3b 90% capacity

    const { gotToForm } = this.state;
    const { ticketID } = ticket.vehicleID;

    return (
      <div className="card shadow-sm m-1 passeger-card-border card-width-vehicle">
        <div className="row">
          <div className="card-group-display align-vert">
            <div className="row">
              <div className="details-align mt-1">
                <div className="details-icon ">
                  <GiCarWheel color={wheelColor} size={18} />
                </div>
                <div className="card-vehicle-heading-lg">
                  <Link
                    // to={`/pagePassengerVehicle/${ticketIdLocal}`}
                    to=""
                    className="btn p-0"
                  >
                    {ticket.vehicleName}{" "}
                    <span className="card-vehicle-heading-nc"></span>
                  </Link>
                </div>
              </div>
            </div>
            <hr className="my-1" />
            <div className="row p-0 m-0">
              <div className="col center">
                <div className="center">
                  <FiUser color="#858796" size="20" />
                  <span className={passColor}>
                    ({ticket.passengerCollTotal}/{ticket.passengerCapacity}) -{" "}
                    {ticket.passengerDroppTotal}
                  </span>
                </div>
              </div>
              <div className="col center">
                <div className="center">
                  <Bicycle color="#858796" size="20" />
                  <span className={bicycleColor}>
                    ({ticket.bicycleCollTotal}/{ticket.bicycleCapacity}) -
                    {ticket.bicycleDroppTotal}
                  </span>
                </div>
              </div>
            </div>
            <hr className="my-1" />
            <div className="mb-3">
              {ticket.entityUserAllocation.map((entityUserAllocated) => (
                <div className="row " key={entityUserAllocated.id}>
                  <div className="details-align">
                    <div className="details-icon">
                      <Whatsapp color="#128c7e" size={20} />
                    </div>
                    <div>
                      <a
                        href={`https://wa.me/+27${entityUserAllocated.cellInt}?text=`}
                        target="_blank"
                      >
                        {entityUserAllocated.name} {entityUserAllocated.surname}
                        {" - "}
                        {entityUserAllocated.userRole}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VehicleCardRD;
