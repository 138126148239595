import React from "react";
import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

class EntityUserListTable extends React.Component {
  columns = [
    {
      path: "name",
      label: "Name",
      content: (entityUser) => (
        <Link to={`/entityUserForm/${entityUser.id}`}>
          <h6>{entityUser.name}</h6>
        </Link>
      ),
    },
    { path: "surname", label: "Surname" },
    { path: "cell", label: "Cell" },
    { path: "email", label: "Email" },
  ];

  render() {
    const {
      allEntriesTotal,
      currentPage,
      filtereddata,
      onDelete,
      onPageChange,
      onPageDecrement,
      onPageIncrement,
      onSort,
      pageSize,
      sortColumn,
    } = this.props;

    const dataPag = paginate(filtereddata, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={dataPag}
            onDelete={onDelete}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allEntriesTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onPageDecrement={onPageDecrement}
            onPageIncrement={onPageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default EntityUserListTable;
