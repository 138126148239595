import React from "react";

import { Link } from "react-router-dom";

//common
import Table from "./common/table";
import Pagination from "./common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

class PassengerTicketsTable extends React.Component {
  columns = [
    {
      path: "id",
      label: "ID",
      content: (passengerTicket) => (
        <Link to={`/entityUserPassengerForm/${passengerTicket.id}`}>
          <h6>{passengerTicket.id}</h6>
        </Link>
      ),
    },
    { path: "participantNo", label: "Participant No" },
    { path: "passLoad", label: "Passengers" },
    { path: "cycleLoad", label: "Bicycles" },
    { path: "ticketTime", label: "Coll Time" },
    { path: "disTime", label: "Drop Time" },
    { path: "completedText", label: "Dropped" },
  ];

  render() {
    const {
      currentPage,
      filteredRaceUsers,
      onPageDecrement,
      onPageIncrement,
      onPageChange,
      onSort,
      sortColumn,
      allRaceUsersTotal,
      pageSize,
    } = this.props;

    const raceUsersPag = paginate(filteredRaceUsers, currentPage, pageSize);

    return (
      <React.Fragment>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={raceUsersPag}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allRaceUsersTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onPageDecrement={onPageDecrement}
            onPageIncrement={onPageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default PassengerTicketsTable;
