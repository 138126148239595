import React from "react";

class EntityUserInfoSplashRow extends React.Component {
  render() {
    const { name, surname, cell } = this.props;

    return (
      <tbody>
        <tr className="leftComponents card-rowtext ">
          <th>{name}</th>
          <th>{surname}</th>
          <th>{cell}</th>
        </tr>
      </tbody>
    );
  }
}

export default EntityUserInfoSplashRow;
