import React from "react";
import { Link } from "react-router-dom";

// Components
import StageRDInfoSplash from "./StageRDInfoSplash";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  ChatSquareTextFill,
  Diagram2Fill,
  MotherboardFill,
  PersonVcardFill,
  TicketPerforatedFill,
} from "react-bootstrap-icons";

class RaceHero extends React.Component {
  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    const { raceNameYear, raceDescription, raceID, stagedataRetreived } =
      this.props;

    if (stagedataRetreived) {
      return (
        <main>
          <section className="container p-4 border-radius-4 shadow-lg row-fit container-fluid">
            <div className="col-lg-12 p-3  pt-lg-1">
              <h4 className="display-6 fw-bold lh-">{raceNameYear}</h4>

              <div className="row dashboard-stage mt-0">
                <div className="card marginTop10 dashboard-stage-card px-0 ">
                  <div className="card-body  px-0 pt-0">
                    <h6 className="card-header border-radius-4 ">{"Setup"}</h6>
                    <div className="col-sm mt-2 marginBottom-5">
                      <div className="button-center ">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip("Setup Race Stages")}
                        >
                          <Link
                            to="/pageStages"
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <MotherboardFill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue">{"Stages"}</div>
                    </div>

                    <div className="col-sm marginBottom-5">
                      <div className="button-center">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip(
                            "Setup Race Ticket Types"
                          )}
                        >
                          <Link
                            to="/pageTicketTypes"
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <TicketPerforatedFill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue ">Ticket Types</div>
                    </div>

                    <div className="col-sm marginBottom-5">
                      <div className="button-center">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip(
                            "Setup Race Notification Types"
                          )}
                        >
                          <Link
                            to="/pageNotificationTypes"
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <ChatSquareTextFill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue ">Notification Types</div>
                    </div>
                  </div>
                </div>

                <div className="card marginTop10 dashboard-stage-card px-0 ">
                  <div className="card-body  px-0 pt-0">
                    <h6 className="card-header border-radius-4 ">
                      Allocations
                    </h6>
                    <div className="col-sm mt-2 marginBottom-5">
                      <div className="button-center ">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip("Allocate Team Members")}
                        >
                          <Link
                            to={`/pageRaceUserAllocation/${raceID}`}
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <PersonVcardFill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue">Team Members</div>
                    </div>

                    <div className="col-sm marginBottom-5">
                      <div className="button-center">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip("Allocate An Entity")}
                        >
                          <Link
                            to={`/pageEntityRaceAlloc/${raceID}`}
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <Diagram2Fill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue ">Entity</div>
                    </div>

                    <div className="col-sm marginBottom-5">
                      <div className="button-center">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip(
                            "Allocate Ticket Types To An Entity"
                          )}
                        >
                          <Link
                            to={`/pageEntityTicketAlloc/${raceID}`}
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <Diagram2Fill color="white" size={20} />
                            <TicketPerforatedFill color="white" size={20} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue ">Tickets / Entity</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {stagedataRetreived.map((stage) => (
                <StageRDInfoSplash
                  distance={stage.distance}
                  elevation={stage.elevation}
                  key={stage.id}
                  stageDescription={stage.stageDescription}
                  stageName={stage.stageName}
                  stageType={stage.stageType.description}
                  stageID={stage.id}
                />
              ))}
            </div>
          </section>
        </main>
      );
    } else {
      return (
        <main>
          <section className="container p-4 border-radius-4 shadow-lg row-fit container-fluid">
            <div className="col-lg-12 p-3  pt-lg-1">
              <h4 className="display-6 fw-bold lh-">{raceNameYear}</h4>

              <div className="row dashboard-stage mt-0">
                <div className="card marginTop10 dashboard-stage-card px-0 ">
                  <div className="card-body  px-0 pt-0">
                    <h6 className="card-header border-radius-4 ">Setup</h6>
                    <div className="col-sm mt-2 marginBottom-5">
                      <div className="button-center ">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip("Setup Race Stages")}
                        >
                          <Link
                            to="/pageStages"
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <MotherboardFill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue">Stages</div>
                    </div>

                    <div className="col-sm marginBottom-5">
                      <div className="button-center">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip(
                            "Setup Race Ticket Types"
                          )}
                        >
                          <Link
                            to="/pageTicketTypes"
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <TicketPerforatedFill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue ">Ticket Types</div>
                    </div>

                    <div className="col-sm marginBottom-5">
                      <div className="button-center">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip(
                            "Setup Race Notification Types"
                          )}
                        >
                          <Link
                            to="/pageNotificationTypes"
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <ChatSquareTextFill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue ">Notification Types</div>
                    </div>
                  </div>
                </div>

                <div className="card marginTop10 dashboard-stage-card px-0 ">
                  <div className="card-body  px-0 pt-0">
                    <h6 className="card-header border-radius-4 ">
                      Allocations
                    </h6>
                    <div className="col-sm mt-2 marginBottom-5">
                      <div className="button-center ">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip("Allocate Team Members")}
                        >
                          <Link
                            to={`/pageRaceUserAllocation/${raceID}`}
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <PersonVcardFill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue">Team Members</div>
                    </div>

                    <div className="col-sm marginBottom-5">
                      <div className="button-center">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip("Allocate An Entity")}
                        >
                          <Link
                            to={`/pageEntityRaceAlloc/${raceID}`}
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <Diagram2Fill color="white" size={30} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue ">Entity</div>
                    </div>

                    <div className="col-sm marginBottom-5">
                      <div className="button-center">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip(
                            "Allocate Ticket Types To An Entity"
                          )}
                        >
                          <Link
                            to={`/pageEntityTicketAlloc/${raceID}`}
                            className="btn btn-primary button-color-dashboard"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 15,
                              height: 55,
                              marginBottom: 5,
                              marginTop: 5,
                              width: 60,
                            }}
                          >
                            <Diagram2Fill color="white" size={20} />
                            <TicketPerforatedFill color="white" size={20} />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="text-darkblue ">Tickets / Entity</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      );
    }
  }
}

export default RaceHero;
