import React from "react";
import NavbarGroup from "../components/navbarGroup";
import GroupHero from "../components/groupHero";

class PageGroupHome extends React.Component {
  state = {
    groupName: "",
    userName: "",
    userSurname: "",
  };

  componentDidMount() {
    const groupName = localStorage.getItem("groupName");
    const userName = localStorage.getItem("userName");
    const userSurname = localStorage.getItem("userSurname");

    this.setState({
      groupName,
      userName,
      userSurname,
    });
  }
  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let { groupName, userName, userSurname } = this.state;

    return (
      <React.Fragment>
        <NavbarGroup />

        <div className="container container-top-70">
          <div className="row">
            <h5>
              {userName} {userSurname}
            </h5>
          </div>
          <GroupHero groupName={groupName} />
        </div>
      </React.Fragment>
    );
  }
}

export default PageGroupHome;
