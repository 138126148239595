import React from "react";
import { Link } from "react-router-dom";

//Components
import RaceCard from "../components/raceCard";
import NavbarSiteAdmin from "../components/navbarSiteAdmin";

//Common
import SearchBox from "../components/common/searchBox";
import Pagination from "../components/common/pagination";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";

//Services
import httpService from "./../services/httpService.js";
import config from "../config.json";

//Utilites
import { paginate } from "../utils/paginate";

//Other
import { ArrowLeftCircle, PlusCircle } from "react-bootstrap-icons";

class PageGroupRaces extends React.Component {
  state = {
    currentPage: "1",
    pageSize: 3,
    dataRetreived: [],
    searchQuery: "",
    sortColumn: { path: "raceName", order: "asc" },
  };

  async componentDidMount() {
    let { data: Dbdata } = await httpService.get(config.apiEndpointRacesGet);
    this.setState({ dataRetreived: Dbdata });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const { dataRetreived, searchQuery, pageSize, currentPage } = this.state;

    let allDataTotal = dataRetreived.length;

    // Order the data
    const sortedData = _.orderBy(
      dataRetreived,
      [this.state.sortColumn.path],
      [this.state.sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.raceName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    allDataTotal = filtered.length;

    //Paginate the data
    const paginatedData = paginate(filtered, currentPage, pageSize);

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarSiteAdmin />

        <div className="container container-top-70 ">
          <div className="row">
            <div className="col" data-aos="fade-right">
              <span className="button-padding">
                <Link to="/pageSiteAdmin" className="btn p-0">
                  <ArrowLeftCircle color="#002072" size={35} />
                </Link>

                <Link to="/raceForm/new" className="btn p-0">
                  <PlusCircle color="#002072" size={35} />
                </Link>
              </span>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Names..."
              />
            </div>
          </div>

          <div className="row card-race-display">
            {paginatedData.map((race) => (
              <RaceCard
                endDate={race.endDate}
                group={race.group.groupName}
                key={race.id}
                month={race.month.description}
                raceDescription={race.raceDescription}
                raceId={race.id}
                raceName={race.raceName}
                raceType={race.raceType.description}
                startDate={race.startDate}
                year={race.year.description}
                raceNameYear={race.raceNameYear}
              />
            ))}

            <Pagination
              itemsCount={allDataTotal}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageGroupRaces;
