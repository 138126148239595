import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../../components/common/form";
import withMyHook from "../../components/common/withMyHook";
import NavbarSiteAdmin from "./../../components/navbarSiteAdmin";

// Services
import httpService from "./../../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

//Other
import { ArrowLeftCircle } from "react-bootstrap-icons";

class raceForm extends Form {
  state = {
    data: {
      endDate: "",
      group: "",
      month: "",
      raceDescription: "",
      raceType: "",
      raceName: "",
      startDate: "",
      year: "",
    },
    errors: {},
    groups: [],
    months: [],
    raceTypes: [],
    years: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    raceID: Joi.string().label("Race id"),
    raceName: Joi.string().label("Race name"),
    group: Joi.string().label("Group"),
    raceType: Joi.string().label("Race Type"),
    raceDescription: Joi.string().label("Race Description"),
    startDate: Joi.string().label("Start Date"),
    endDate: Joi.string().label("End Date"),
    month: Joi.string().label("Month"),
    year: Joi.string().label("Year"),
  };

  async componentDidMount() {
    const raceID = this.state.myParams.id;

    // get data from database to display options in textfields

    const { data: retrievedGroups } = await httpService.get(
      config.apiEndpointGroupsGet
    );
    this.setState({ groups: retrievedGroups });

    const { data: retrievedRaceTypes } = await httpService.get(
      config.apiEndpointRaceTypesGet
    );
    this.setState({ raceTypes: retrievedRaceTypes });

    const { data: retrievedMonths } = await httpService.get(
      config.apiEndpointMonthsGet
    );
    this.setState({ months: retrievedMonths });

    const { data: retrievedYears } = await httpService.get(
      config.apiEndpointYearsGet
    );
    this.setState({ years: retrievedYears });

    if (raceID === "new") return;
    let { data: race } = await httpService.get(
      config.apiEndpointRaceGet + "?id=" + raceID
    );

    if (!race) return this.state.myNavigate("/not-found");

    this.setState({ data: this.mapToViewModel(race) });
  }

  // Submit a new Race

  doSubmit = async () => {
    const ID = this.state.myParams.id;

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointRaceAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });

      try {
        await httpService.post(config.apiEndpointRaceUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }

    this.state.myNavigate("/pageSiteAdminRaces");
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      const deleteReply = await httpService.get(
        config.apiEndpointRaceDelete + "?id=" + ID
      );

      var myObject = JSON.parse(deleteReply);
      // console.log("myObject = " + { ...myObject });
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }

    this.state.myNavigate("/pageSiteAdminRaces");
  };

  mapToViewModel(race) {
    return {
      endDate: race.endDate,
      group: race.group.id,
      month: race.month.id,
      raceDescription: race.raceDescription,
      raceID: race.id,
      raceName: race.raceName,
      raceType: race.raceType.id,
      startDate: race.startDate,
      year: race.year.id,
    };
  }

  render() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarSiteAdmin />
        <div className="container container-top-70">
          <div className="row">
            <div className="form-container">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <Link to="/pageSiteAdminRaces" className="btn p-0">
                        <ArrowLeftCircle color="#002072" size={40} />
                      </Link>
                    </tr>
                    <tr align="center">
                      <th colSpan={2}>
                        <h4>Race Details</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Race Name</td>
                      <td>{this.renderInput("raceName", "Race Name")}</td>
                    </tr>
                    <tr>
                      <td>Group Name</td>
                      <td>
                        {this.renderSelect(
                          "group",
                          "groupName",
                          this.state.groups,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Race Type</td>
                      <td>
                        {this.renderSelect(
                          "raceType",
                          "description",
                          this.state.raceTypes,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Race Description</td>
                      <td>
                        {this.renderTextArea(
                          "raceDescription",
                          "Race Description",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Start Date</td>
                      <td>
                        {this.renderInput("startDate", "Start Date", "date")}
                      </td>
                    </tr>
                    <tr>
                      <td>End Date</td>
                      <td>{this.renderInput("endDate", "End Date", "date")}</td>
                    </tr>
                    <tr>
                      <td>Month</td>
                      <td>
                        {this.renderSelect(
                          "month",
                          "description",
                          this.state.months,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Year</td>
                      <td>
                        {this.renderSelect(
                          "year",
                          "description",
                          this.state.years,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(raceForm);
