import React from "react";
import Ticket from "./ticket";

import Pagination from "./common/pagination";
import { paginate } from "../utils/paginate";

class Tickets extends React.Component {
  state = {
    // pageSize: 4,
    // currentPage: 1,
  };
  // move this to pageTicketCards and get from Props
  // handlePageChange = (page) => {
  //   this.setState({ currentPage: page });
  // };

  render() {
    const {
      allTicketsTotal,
      currentPage,
      onPageChange,
      ticketData,
      onDelete,
      onStatus,
      onToggleIcon,
      onCommentSave,
      onPageDecrement,
      onPageIncrement,
      onEntityUserUpdate,
      pageSize,
    } = this.props;

    // console.log("allTicketsTotal in tickets render  = " + allTicketsTotal);

    const ticketsPag = paginate(ticketData, currentPage, pageSize);

    if (ticketsPag.length === 0) {
      return (
        <div className="card m-2">
          <div>
            <div className="card-body">
              <div className="cardHeading">
                <h5 className="card-title">No tickets for this ticket type.</h5>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    return (
      <div>
        <Pagination
          itemsCount={allTicketsTotal}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onPageDecrement={onPageDecrement}
          onPageIncrement={onPageIncrement}
        />
        <div>
          {ticketsPag.map((ticket) => (
            <Ticket
              key={ticket.id}
              onDelete={onDelete}
              onStatus={onStatus}
              onToggleIcon={onToggleIcon}
              ticket={ticket}
              onCommentSave={onCommentSave}
              onEntityUserUpdate={onEntityUserUpdate}
            />
          ))}
        </div>
        <Pagination
          itemsCount={allTicketsTotal}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onPageDecrement={onPageDecrement}
          onPageIncrement={onPageIncrement}
        />
      </div>
    );
  }
}

export default Tickets;
